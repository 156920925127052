/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useContext, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ForbiddenComponent } from '@splunk/olly-common/ForbiddenComponent';
import park from '../../legacy/app/charting/chart/park';
import { Ng2React } from '../../common/util/Ng2React';
import { SmartGatewayDownload } from './RouteComponents.ng';
import { Route, Strap, Switch, useInitializeRoute } from './ReactRouteUtils';
import { ChartRoutes } from './ChartRoutes';
import ContentError from './ContentError';
import { metricsPage } from '../../legacy/app/metricsCatalog/metricsPage';
import alertsOverviewPage from '../../legacy/app/alerts/overview/alertsOverviewPage';
import { organizationPage } from '../../legacy/app/administrative/organization/organizationPage';
import { ngRoute } from './ngRoute';
import teamPage from '../../legacy/app/teams/teamPage';
import CrossLinkRedirect from '../../legacy/app/crossLinks/CrossLinkRedirect';
import { goToOrgHome } from '../Home';
import { IntegrationsPage } from '../../legacy/app/orgintegrations/integrationsPage';
import { catalog } from '../../legacy/app/catalog/catalog';
import kubeNavigator from '../../legacy/app/kubeNavigator/kubeNavigator';
import { NavigatorRoutes } from './NavigatorRoutes';
import aboutUs from '../../legacy/app/globalNav/aboutUs';
import { DashboardRoutes } from './DashboardRoutes';
import { DetectorRoutes } from './DetectorRoutes';
import { LegacyStyleGuideRoutes } from './LegacyStyleGuideRoutes';
import { ApmApp } from '../apm/ApmApp';
import { AuthenticationRoutes } from './AuthenticationRoutes';
import superpowersHost from '../../legacy/app/superpowers/superpowersHost';
import * as sitemaps from './Sitemaps';
import { SignalviewTopNavContext } from '../TopNavContextProvider';

export const AppRoutes: FC = () => {
    const history = useHistory();
    const { setSiteMap } = useContext(SignalviewTopNavContext);
    const { search } = useLocation();

    Object.values(sitemaps).forEach((sitemap) => setSiteMap(sitemap.name, sitemap.map));

    useEffect(() => {
        ngRoute.history = history;
    }, [history]);

    const routeUtilsBootStrap = useInitializeRoute();

    const alwaysBootStraps: Array<() => Promise<never>> = [];

    return (
        <Strap straps={alwaysBootStraps} reStraps>
            <Switch>
                <Redirect exact from="/" to="/signin" />

                <Route
                    path={[
                        '/invite',
                        '/signin',
                        '/signindomain',
                        '/idp/signin',
                        '/google',
                        '/saml',
                        '/saml-notify',
                        '/password-reset',
                        '/forgot-password',
                        '/oidc',
                        '/verify',
                    ]}
                >
                    <AuthenticationRoutes />
                </Route>

                <Route
                    exact
                    path={[
                        '/alerts/:orgID?',
                        '/detectors/:orgID?',
                        '/logs/:orgID?',
                        '/bounces/:orgID?',
                    ]}
                    straps={[routeUtilsBootStrap]}
                >
                    <Ng2React name="alertsOverviewPage" definition={alertsOverviewPage} />
                </Route>

                <Redirect exact from="/mypage" to="/home" />
                <Route
                    exact
                    path="/home/:orgId"
                    straps={[routeUtilsBootStrap]}
                    render={goToOrgHome}
                />

                <Route path={['/chart', '/_/chart', '/_/temp/chart', '/temp/chart']}>
                    <ChartRoutes />
                </Route>

                <Route path={['/page', '/dashboard', '/temp/dashboard', '/dashboards', '/home']}>
                    <DashboardRoutes />
                </Route>

                <Route
                    exact
                    path={[
                        '/catalog',
                        '/catalog/:orgID',
                        '/data-management/catalog',
                        '/data-management/catalog/:orgID',
                    ]}
                    title="Catalog"
                    straps={[routeUtilsBootStrap]}
                    reloadWithParams
                >
                    <Ng2React name="catalog" definition={catalog} />
                </Route>

                <Route
                    exact
                    path={[
                        '/organization/current/:tab?/:action?',
                        '/organization/:orgID/:tab?/:action?',
                    ]}
                    straps={[routeUtilsBootStrap]}
                    reloadWithParams="path"
                >
                    <Ng2React name="organizationPage" definition={organizationPage} />
                </Route>

                <Route exact path="/_" title="Parked">
                    <Ng2React name="park" definition={park} />
                </Route>

                <Route exact path="/metrics/:orgID?" title="Metrics" straps={[routeUtilsBootStrap]}>
                    <Ng2React name="metricsPage" definition={metricsPage} />
                </Route>

                <Route
                    path="/team/:teamId"
                    exact
                    title="Team"
                    straps={[routeUtilsBootStrap]}
                    resolves={{ teamId: () => ngRoute.params.teamId }}
                >
                    <Ng2React name="teamPage" definition={teamPage} />
                </Route>

                <Route
                    exact
                    path={[
                        '/crosslink/:toDashboardId',
                        '/crosslink/:toDashboardId/:fromDashboardId',
                    ]}
                    straps={[routeUtilsBootStrap]}
                    resolves={{
                        redirection: () => ({
                            toDashboardId: ngRoute.params.toDashboardId,
                            toDashboardGroupId: ngRoute.params.toDashboardGroupId,
                            fromDashboardId: ngRoute.params.fromDashboardId,
                            fromDashboardGroupId: ngRoute.params.fromDashboardGroupId,
                            destinationType: ngRoute.params.destinationType,
                            toNavigatorId: ngRoute.params.toNavigatorId,
                            toNavigatorView: ngRoute.params.toNavigatorView,
                            toNavigatorCode: ngRoute.params.toNavigatorCode,
                            mapSelection: ngRoute.params.mapSelection,
                            breadcrumbFilters: ngRoute.params.breadcrumbFilters,
                            navigatorContext: ngRoute.params.navigatorContext,
                        }),
                    }}
                >
                    <Ng2React name="crossLinkRedirect" definition={CrossLinkRedirect} />
                </Route>

                <Route path={['/detector', '/muting']}>
                    <DetectorRoutes />
                </Route>

                <Route
                    exact
                    path="/integrations"
                    title="Integrations"
                    straps={[routeUtilsBootStrap]}
                >
                    <Ng2React name="integrationsPage" definition={IntegrationsPage} />
                </Route>

                <Route
                    exact
                    path={['/k8s', '/k8s/:selectedTab']}
                    title="Kubernetes Navigator"
                    straps={[routeUtilsBootStrap]}
                >
                    <Ng2React name="kubeNavigator" definition={kubeNavigator} />
                </Route>

                <Route
                    exact
                    path="/smart-gateway/download/:version"
                    title="Download Smart Gateway"
                    straps={[routeUtilsBootStrap]}
                    render={({ match }) => <SmartGatewayDownload version={match.params.version} />}
                />

                <Redirect exact from="/hosts" to={{ pathname: '/navigator', search }} />
                <Redirect
                    exact
                    from="/hosts/:orgID"
                    to={{ pathname: '/navigator/:orgID', search }}
                />
                <Route path="/navigator">
                    <NavigatorRoutes />
                </Route>

                <Route path="/apm" straps={[routeUtilsBootStrap]}>
                    <ApmApp />
                </Route>

                <Route path="/detector-wizard"></Route>

                <Route exact path="/about">
                    <Ng2React name="aboutUs" definition={aboutUs} />
                </Route>

                <Route path="/superpowers">
                    <Ng2React name="superpowersHost" definition={superpowersHost} />
                </Route>

                <Route path="/styleguide" title="Superpowers">
                    <LegacyStyleGuideRoutes />
                </Route>

                <Route path="/forbidden">
                    <ForbiddenComponent />
                </Route>

                <Route exact path="/error" title="There was an error">
                    <ContentError />
                </Route>

                <Route path="*">
                    <ContentError missing />
                </Route>
            </Switch>
        </Strap>
    );
};
