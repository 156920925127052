import customDateTimePickerTemplateUrl from './customDateTimePicker.tpl.html';

angular
    .module('signalview.timePicker')

    .controller('singleTimePickerController', [
        '$filter',
        '$timeout',
        'ES_INDEXING_DELAY',
        'urlOverridesService',
        'TimeParser',
        '$scope',
        'timeOptions',
        'TIMEPICKER_SHORT_FORMAT',
        'timeZoneService',
        'timepickerUtils',
        'absoluteTimeOptions',
        'richTimeOptions',
        'sfxModal',
        'timeDisplayValue',
        function (
            $filter,
            $timeout,
            ES_INDEXING_DELAY,
            urlOverridesService,
            TimeParser,
            $scope,
            timeOptions,
            TIMEPICKER_SHORT_FORMAT,
            timeZoneService,
            timepickerUtils,
            absoluteTimeOptions,
            richTimeOptions,
            sfxModal,
            timeDisplayValue
        ) {
            $scope.waitBeforeBroadcastMs = ES_INDEXING_DELAY;
            $scope.displayOnly = false;
            $scope.beginTime = '';
            $scope.endTime = '';
            $scope.time = '';
            $scope.shortFmt = TIMEPICKER_SHORT_FORMAT;
            $scope.beginDateCalendar = timeZoneService.moment().format($scope.shortFmt);
            $scope.endDateCalendar = $scope.beginDateCalendar;
            $scope.minBeginDate = '';
            $scope.maxBeginDate = '';
            $scope.minEndDate = $scope.minBeginDate;
            $scope.maxEndDate = $scope.maxBeginDate;
            $scope.nextBroadcastImmediate = false;
            $scope.timeInitialized = false;
            $scope.timeCollapse = false;
            $scope.focused = false;

            // For tracking recent;
            $scope.currentTime = '';
            $scope.previousTime = '';

            function getTime() {
                return getTimeFromParameters($scope.beginTime, $scope.endTime);
            }

            function getTimeFromParameters(begin, end) {
                return begin + (end === 'Now' ? '' : ' to ' + end);
            }

            function setTime() {
                $scope.time = getTime();
            }

            $scope.beginToggled = function (toggledState) {
                if (!toggledState && $scope.beginToggledPreviously === true) {
                    $scope.beginToggledPreviously = false;
                    if ($scope.beginValueOnOpen !== $scope.beginDateCalendar) {
                        $scope.setBeginDateCalendar();
                    }
                }
                $scope.beginToggledPreviously = toggledState;
                $scope.beginValueOnOpen = $scope.beginDateCalendar;
            };

            $scope.endToggled = function (toggledState) {
                if (!toggledState && $scope.endToggledPreviously === true) {
                    $scope.endToggledPreviously = false;
                    if ($scope.endValueOnOpen !== $scope.endDateCalendar) {
                        $scope.setEndDateCalendar();
                    }
                }
                $scope.endToggledPreviously = toggledState;
                $scope.endValueOnOpen = $scope.endDateCalendar;
            };

            $scope.$on('setNewGlobalTimeRange', function (evt, start, end) {
                if ($scope.context && $scope.context !== 'global') {
                    return;
                }

                if (!start || !end) {
                    urlOverridesService.clearTimePicker();
                    return;
                }

                // these chained digests take way too long to occur/propagate.  update all dependencies and fire what we know.
                // a second event will be broadcast and ignored because it is unchanged.

                $scope.beginDateCalendar = timeZoneService.moment(start).format($scope.shortFmt);
                $scope.setBeginDateCalendar();
                $scope.endDateCalendar = timeZoneService.moment(end).format($scope.shortFmt);
                $scope.nextBroadcastImmediate = true;
                $scope.setEndDateCalendar();

                $scope.applyDuration(false);
            });

            $scope.setBeginDateCalendar = function () {
                $scope.beginTime = timeZoneService
                    .stringToMoment($scope.beginDateCalendar)
                    .format($scope.shortFmt);
            };

            $scope.setEndDateCalendar = function () {
                $scope.endTime = timeZoneService
                    .stringToMoment($scope.endDateCalendar)
                    .format($scope.shortFmt);
            };

            $scope.getValidBeginDates = function () {
                const e = new TimeParser($scope.endTime, true);
                if (e === null || !e.num) {
                    return timeOptions;
                }
                const bt = [];
                angular.forEach(timeOptions, function (t) {
                    if ($scope.validateBeginAndEnd(new TimeParser(t, false), e)) {
                        bt.push(t);
                    }
                });
                return bt;
            };

            $scope.getValidEndDates = function () {
                const b = new TimeParser($scope.beginTime, false);
                const et = ['Now'];
                if (b === null || !b.num) {
                    return et.concat(timeOptions);
                }
                angular.forEach(timeOptions, function (t) {
                    if ($scope.validateBeginAndEnd(b, new TimeParser(t, true))) {
                        et.push(t);
                    }
                });
                return et;
            };

            $scope.getBeginDatesFor = function (str) {
                const beginDates = $scope.getValidBeginDates();
                if (beginDates.length > 0) {
                    return beginDates;
                } else {
                    const matches = [];
                    const exp = new RegExp(str, 'g');
                    angular.forEach(beginDates, function (s) {
                        if (s.match(exp)) {
                            matches.push(s);
                        }
                    });
                    return matches;
                }
            };

            $scope.getEndDatesFor = function (str) {
                const endDates = $scope.getValidEndDates();
                if (endDates.length > 0) {
                    return endDates;
                } else {
                    const matches = [];
                    const exp = new RegExp(str, 'g');
                    angular.forEach(endDates, function (s) {
                        if (s.match(exp)) {
                            matches.push(s);
                        }
                    });
                    return matches;
                }
            };

            $scope.validateInput = TimeParser;

            $scope.getUTC = function (t) {
                if (t && t.utc) {
                    return t.utc;
                }
                const now = new Date().getTime();
                if (t && t.num && t.type) {
                    const rangeMs = timepickerUtils.getRangeFromRelative(t);
                    return now + rangeMs;
                } else {
                    return now;
                }
            };

            $scope.publishTimeRange = function (range, fromUrl, store, rawInputStart, rawInputEnd) {
                if (store) {
                    angular.extend(store, range);
                    return;
                }
                if ($scope.onPublishTimeRange) {
                    $scope.onPublishTimeRange();
                }
                const start = range.startUTC || range.start;
                const end = range.endUTC || range.end;
                const relative = !range.startUTC;
                if (!fromUrl && !$scope.disableUrl) {
                    if (relative) {
                        urlOverridesService.setGlobalTimePicker(
                            relativeStr(rawInputStart),
                            end ? relativeStr(rawInputEnd) : 'Now',
                            true
                        );
                    } else {
                        urlOverridesService.setGlobalTimePicker(start, end, false);
                    }
                }
                $scope.$emit('timePickerChanged', range, $scope.context);
            };

            function setDurationInternal(b, e, fromUrl, store) {
                if ($scope.validateBeginAndEnd(b, e)) {
                    if (b && b.utc) {
                        $scope.publishTimeRange(
                            {
                                startUTC: b.utc,
                                endUTC: e.utc,
                            },
                            fromUrl,
                            store
                        );
                        return;
                    }
                    const beginUTC = b.getUTC();
                    let endUTC = e.getUTC();
                    if (
                        $scope.endTime.trim().toLowerCase() === 'now' ||
                        $scope.endTime.trim().length === 0
                    ) {
                        endUTC = 0;
                    }
                    $scope.publishTimeRange(
                        {
                            start: beginUTC,
                            end: endUTC,
                            range: endUTC === 0 ? Date.now() - beginUTC : null,
                        },
                        fromUrl,
                        store,
                        b,
                        e
                    );
                }
            }

            $scope.setDuration = function (b, e, fromUrl, store) {
                if ($scope.displayOnly && !store) {
                    return;
                }
                setDurationInternal(b, e, fromUrl, store);
            };
            // returns time array in str format, example [1,1,1]['d','m','s'] as -1d1m1s
            function relativeStr(t) {
                let str = '-';
                angular.forEach(t.num, function (v, i) {
                    str += v + t.type[i];
                });
                return str;
            }

            $scope.validateBeginAndEnd = function (b, e) {
                if (b.isEmpty()) {
                    return false;
                }
                // relative begin and no end (implicit now)
                if (b.isValid() && !b.isUTC() && e.isEmpty()) {
                    return true;
                }

                // abs begin or end but not both is invalid
                if (
                    b.isValid() &&
                    e.isValid() &&
                    ((b.isUTC() && !e.isUTC()) || (!b.isUTC() && e.isUTC()))
                ) {
                    return false;
                }
                return e.getUTC() - b.getUTC() > 0;
            };

            $scope.validateTimes = function () {
                if (
                    $scope.beginTime !== null &&
                    $scope.beginTime.trim().length === 0 &&
                    $scope.endTime !== null &&
                    $scope.endTime.trim().length === 0
                ) {
                    return true;
                }
                const b = new TimeParser($scope.beginTime, false);
                const e = new TimeParser($scope.endTime, true);
                if (b === null || e === null) {
                    return false;
                }
                return $scope.validateBeginAndEnd(b, e);
            };

            $scope.beginCollapse = false;
            $scope.endCollapse = false;
            $scope.setBeginTime = function (t) {
                $scope.beginTime = t;
                $scope.beginCollapse = false;
            };
            $scope.setEndTime = function (t) {
                $scope.endTime = t;
                $scope.endCollapse = false;
            };
            $scope.applyDuration = function (relative, fromUrl, store) {
                const beginTime = new TimeParser($scope.beginTime, false);
                const endTime = new TimeParser($scope.endTime, true);
                if (relative || (beginTime && beginTime.utc && endTime && endTime.utc)) {
                    $scope.setDuration(beginTime, endTime, fromUrl, store);
                }
            };

            $scope.applyTimeRange = function (event) {
                if (angular.isDefined(event) && (event.keyCode === 13 || event.keyCode === 9)) {
                    if (event.target.value !== $scope.currentTime) {
                        const beginTime = new TimeParser($scope.beginTime, false);
                        if (beginTime.valid) {
                            $scope.$emit('singleTimePickerSet');
                        }
                    }
                    if ($scope.beginCollapse) {
                        $scope.beginCollapse = false;
                    }
                    if ($scope.endCollapse) {
                        $scope.endCollapse = false;
                    }
                    $scope.applyDuration(true);
                    $scope.timeCollapse = false;
                } else {
                    if (!$scope.beginCollapse) {
                        $scope.beginCollapse = true;
                    }
                    if (!$scope.endCollapse) {
                        $scope.endCollapse = true;
                    }
                }
            };

            let inputTimeout = null;
            $scope.applyInput = function () {
                if (inputTimeout) {
                    $timeout.cancel(inputTimeout);
                }
                inputTimeout = $timeout(
                    function () {
                        $scope.applyDuration(true);
                        inputTimeout = null;
                        $scope.nextBroadcastImmediate = false;
                    },
                    $scope.nextBroadcastImmediate ? 0 : $scope.waitBeforeBroadcastMs
                );
            };

            let calendarTimeout = null;
            $scope.applyCalendarInput = function () {
                if (calendarTimeout) {
                    $timeout.cancel(calendarTimeout);
                }
                const to = $scope.nextBroadcastImmediate ? 0 : $scope.waitBeforeBroadcastMs;
                calendarTimeout = $timeout(function () {
                    $scope.applyDuration(false);
                    calendarTimeout = null;
                    $scope.nextBroadcastImmediate = false;
                }, to);
            };

            function convertChartConfigTimeToBeginEndStrings(data) {
                let retObj = {};
                if (data.absoluteStart && data.absoluteEnd) {
                    retObj.beginDateCalendar = data.absoluteStart;
                    retObj.endDateCalendar = data.absoluteEnd;
                    retObj.beginTime = timeZoneService
                        .moment(retObj.beginDateCalendar)
                        .format($scope.shortFmt);
                    retObj.endTime = timeZoneService
                        .moment(retObj.endDateCalendar)
                        .format($scope.shortFmt);
                } else if (data.range) {
                    if (!data.rangeEnd) {
                        retObj.endTime = 'Now';
                    } else {
                        retObj.endTime = timepickerUtils.msToRelative(data.rangeEnd);
                    }
                    retObj.beginTime = timepickerUtils.msToRelative(data.range);
                } else {
                    retObj = null;
                }
                return retObj;
            }

            $scope.setInitRange = function () {
                if (!$scope.initRange) {
                    return;
                }
                const lastVal = $scope.displayOnly;
                $scope.displayOnly = true;

                const processedConfigTime = convertChartConfigTimeToBeginEndStrings(
                    $scope.initRange
                );
                if (processedConfigTime) {
                    if (processedConfigTime.beginDateCalendar) {
                        $scope.beginDateCalendar = processedConfigTime.beginDateCalendar;
                    }

                    if (processedConfigTime.endDateCalendar) {
                        $scope.endDateCalendar = processedConfigTime.endDateCalendar;
                    }

                    $scope.beginTime = processedConfigTime.beginTime;
                    $scope.endTime = processedConfigTime.endTime;

                    setTime();
                }

                if ($scope.onPublishTimeRange) {
                    $scope.onPublishTimeRange();
                }

                $timeout(function () {
                    $scope.displayOnly = lastVal;
                }, 500);
            };

            $scope.$watch('beginTime', function (nval, oval) {
                if (!nval) {
                    nval = '';
                }
                if (
                    $scope.displayOnly ||
                    (oval && oval.trim() === nval.trim()) ||
                    nval.trim() === ''
                ) {
                    return;
                }
                const btime = new TimeParser($scope.beginTime, false);
                if (!btime.isEmpty()) {
                    if (btime.num) {
                        if (!$scope.beginTime.match(/^-/)) {
                            $scope.beginTime = '-' + $scope.beginTime;
                        }
                        if ($scope.endTime.trim().length === 0) {
                            $scope.endTime = 'Now';
                        } else {
                            $scope.applyInput();
                        }
                    } else if (btime.utc) {
                        if (
                            $scope.beginTime !==
                            $filter('date')($scope.beginDateCalendar, $scope.shortFmt)
                        ) {
                            $scope.beginDateCalendar = $scope.beginTime;
                        }
                        $scope.applyCalendarInput();
                    }
                }
            });

            $scope.$watch('endTime', function (nval, oval) {
                if (!nval) {
                    nval = '';
                }
                if (
                    $scope.displayOnly ||
                    (oval && oval.trim() === nval.trim()) ||
                    nval.trim() === ''
                ) {
                    return;
                }
                const etime = new TimeParser($scope.endTime, true);
                if (!etime.isEmpty()) {
                    if (!etime.utc) {
                        if (etime.num && !$scope.endTime.match(/^-/)) {
                            $scope.endTime = '-' + $scope.endTime;
                        }
                        $scope.applyInput();
                    } else {
                        if (
                            $scope.endTime !==
                            $filter('date')($scope.endDateCalendar, $scope.shortFmt)
                        ) {
                            $scope.endDateCalendar = $scope.endTime;
                        }
                        $scope.applyCalendarInput();
                    }
                }
            });

            const initRangeOnce = $scope.$watch('initRange', function (nval) {
                if (!nval) {
                    return;
                }
                const timeObject = convertChartConfigTimeToBeginEndStrings(nval);
                if (timeObject) {
                    $scope.initTime = getTimeFromParameters(
                        timeObject.beginTime,
                        timeObject.endTime
                    );
                } else {
                    $scope.initTime = '';
                }
                $scope.timeInitialized = true;
                initRangeOnce();
            });

            $scope.$watch('time', function (nval, oval) {
                if (!nval && !oval) {
                    return;
                }
                if (!nval) {
                    nval = '';
                }
                if ($scope.displayOnly || (oval && oval.trim() === nval.trim())) {
                    return;
                }

                if (nval.trim() === '' && !$scope.disableUrl) {
                    urlOverridesService.clearTimePicker();
                } else {
                    const timeObject = splitTimeStringToTimeObject(nval);
                    $scope.beginTime = timeObject.start;
                    $scope.endTime = timeObject.end;
                }
            });

            function initializeTimePicker(data) {
                const lastVal = $scope.displayOnly;
                $scope.displayOnly = true;
                if (!data.start || !data.end) {
                    $scope.beginTime = '';
                    $scope.endTime = '';
                    $scope.displayOnly = lastVal;
                    $scope.time = '';
                    if ($scope.onPublishTimeRange) {
                        // for empty and reset case.
                        $scope.onPublishTimeRange();
                    }
                    return;
                }
                if (data.relative) {
                    $scope.beginTime = data.start;
                    $scope.endTime = data.end;
                } else {
                    $scope.beginDateCalendar = data.start;
                    $scope.endDateCalendar = data.end;
                    $scope.beginTime = timeZoneService
                        .moment($scope.beginDateCalendar)
                        .format($scope.shortFmt);
                    $scope.endTime = timeZoneService
                        .moment($scope.endDateCalendar)
                        .format($scope.shortFmt);
                }
                setTime();
                $scope.displayOnly = lastVal;
                $scope.applyDuration(data.relative, true, data.store);

                if ($scope.onPublishTimeRange) {
                    $scope.onPublishTimeRange();
                }
            }

            $scope.$on('initializeTimePicker', function (ev, data, context) {
                if ($scope.context === context) {
                    initializeTimePicker(data);
                }
            });

            if ($scope.timeOptions) {
                $scope.richTimeOptions = $scope.timeOptions;
            } else {
                $scope.richTimeOptions = $scope.absoluteOnly
                    ? absoluteTimeOptions()
                    : richTimeOptions;
            }

            $scope.setTime = function (t) {
                $scope.nextBroadcastImmediate = true;
                if (t !== $scope.time) {
                    $scope.$emit('singleTimePickerSet');
                }
                $scope.time = t;
                $scope.timeCollapse = false;
            };

            $scope.custom = function () {
                sfxModal
                    .open({
                        templateUrl: customDateTimePickerTemplateUrl,
                        controller: [
                            '$scope',
                            'beginDate',
                            'endDate',
                            'customScheduleConfig',
                            function ($scope, beginDate, endDate, customScheduleConfig) {
                                // date and time models needs to be separated
                                // sftimepicker converts to user's timezone but angular-ui-bootstrap.datepicker is buggy
                                // and converts to local (browser) timezone
                                // https://github.com/angular-ui/bootstrap/issues/6235
                                $scope.$watchGroup(
                                    ['beginDate', 'beginTime'],
                                    function (newValues) {
                                        $scope.beginDatetime =
                                            timeZoneService.timeAndDateToDatetimeShortString(
                                                ...newValues
                                            );
                                    }
                                );

                                $scope.$watchGroup(['endDate', 'endTime'], function (newValues) {
                                    $scope.endDatetime =
                                        timeZoneService.timeAndDateToDatetimeShortString(
                                            ...newValues
                                        );
                                });

                                $scope.beginDate = beginDate;
                                $scope.beginTime = beginDate;

                                $scope.endDate = endDate;
                                $scope.endTime = endDate;

                                $scope.customScheduleConfig = customScheduleConfig;

                                $scope.isValid = function () {
                                    return (
                                        $scope.beginDatetime &&
                                        $scope.endDatetime &&
                                        timeZoneService
                                            .stringToMoment($scope.endDatetime)
                                            .isAfter(
                                                timeZoneService.stringToMoment($scope.beginDatetime)
                                            )
                                    );
                                };
                            },
                        ],
                        windowClass: 'custom-time-picker-modal',
                        resolve: {
                            beginDate: function () {
                                return timeZoneService
                                    .moment($scope.beginDateCalendar)
                                    .format($scope.shortFmt);
                            },
                            endDate: function () {
                                return timeZoneService
                                    .moment($scope.endDateCalendar)
                                    .format($scope.shortFmt);
                            },
                            customScheduleConfig: function () {
                                return $scope.customScheduleConfig || {};
                            },
                        },
                        backdrop: 'static',
                        keyboard: false,
                    })
                    .result.then(function ({ begin, end }) {
                        $scope.setTime(begin + ' to ' + end);
                    });
            };

            $scope.$on('resetTimePicker', function (context) {
                if ($scope.context === context) {
                    $scope.setTime($scope.initTime);
                }
            });

            $scope.$on('setTimePicker', function (evt, time, context) {
                // Empty implies global context
                if ($scope.context === context) {
                    $scope.setTime(time);
                }
            });

            $scope.$on('clearTimePicker', function (evt, context) {
                // Empty implies global context
                if ($scope.context === context) {
                    $scope.setTime('');
                }
            });

            $scope.onPublishTimeRange = function () {
                if ($scope.currentTime !== $scope.time) {
                    const previousTime = $scope.currentTime;
                    $scope.currentTime = $scope.time;

                    // if the new time is different than the old time and is different than the original
                    // configured time, set it as the "recent" time
                    const selectedTimeIsDifferentFromInitializedTime = $scope.timeInitialized
                        ? timesAreSemanticallyDifferent(previousTime, $scope.initTime)
                        : false;
                    const selectedTimeIsDifferentFromPreviousTime = timesAreSemanticallyDifferent(
                        previousTime,
                        $scope.time
                    );
                    if (
                        $scope.time &&
                        selectedTimeIsDifferentFromInitializedTime &&
                        selectedTimeIsDifferentFromPreviousTime
                    ) {
                        $scope.previousTime = previousTime;
                        $scope.previousTimeDisplay = timeDisplayValue(previousTime);
                    }
                }
            };

            function timesAreSemanticallyDifferent(time1, time2) {
                if (!!time1 !== !!time2) {
                    return true;
                }
                const timeObject1 = getTimeObject(time1);
                const timeObject2 = getTimeObject(time2);
                return (
                    timeObject1.absoluteEnd !== timeObject2.absoluteEnd ||
                    timeObject1.absoluteStart !== timeObject2.absoluteStart ||
                    timeObject1.range !== timeObject2.range ||
                    timeObject1.rangeEnd !== timeObject2.rangeEnd
                );
            }

            function splitTimeStringToTimeObject(str) {
                const time = {};
                if (str.indexOf(' to ') > -1) {
                    const timeLeftAndRight = str.split(' to ');
                    time.start = timeLeftAndRight[0];
                    time.end = timeLeftAndRight[1];
                    time.relative = time.end === 'Now';
                } else {
                    time.start = str;
                    time.end = 'Now';
                    time.relative = true;
                }
                return time;
            }

            function getTimeObject(str) {
                const time = splitTimeStringToTimeObject(str);
                return timepickerUtils.getChartConfigParametersFromURLTimeObject(time);
            }

            $scope.showReset = function () {
                return (
                    $scope.time !== '' &&
                    (!$scope.initTime ||
                        timesAreSemanticallyDifferent($scope.time, $scope.initTime))
                );
            };

            // self publish the first time on timepicker.

            $scope.setInitRange();

            $scope.onPublishTimeRange();
        },
    ]);
