import templateUrl from './insights.tpl.html';

angular.module('signalview.heatmap').directive('navigatorInsights', [
    'calculateOverrepresentation',
    'metricsDataService',
    'getDataFacets',
    'd3',
    'HEATMAP_THRESHOLD',
    '$timeout',
    '$log',
    'mustache',
    'plotToSignalflowV2',
    'colorAccessibilityService',
    'HEATMAP_PROPERTY_DENYLIST',
    'heatmapUtilsService',
    function (
        calculateOverrepresentation,
        metricsDataService,
        getDataFacets,
        d3,
        HEATMAP_THRESHOLD,
        $timeout,
        $log,
        mustache,
        plotToSignalflowV2,
        colorAccessibilityService,
        HEATMAP_PROPERTY_DENYLIST,
        heatmapUtilsService
    ) {
        return {
            restrict: 'E',
            scope: {
                heatmap: '=',
                stats: '=?',
                highlightedHosts: '=',
            },
            templateUrl,
            link: function ($scope, element) {
                // The lower, the tighter the correlation
                const CORRELATION_RELEVANCE_LIMIT = 0.01;
                const heatmap = $scope.heatmap;
                const eventBindings = [];

                const colorPalette = colorAccessibilityService.get().getHeatmapColors();

                function nodeInGroup(node, group) {
                    if (!group || !group.groupKey) return true;

                    // If a group was selected, go up the parent chain to trim according
                    // to parent dimensions
                    let match = node[group.groupKey] === group.name;
                    group = group.parent;

                    while (match && group.depth !== 0) {
                        match = node[group.groupKey] === group.name;
                        group = group.parent;
                    }

                    return match;
                }

                function isMuted(d) {
                    const selection = heatmap.selection();

                    if (selection && !selection.groupKey) {
                        return d.id !== selection.id;
                    }

                    return !nodeInGroup(d, selection);
                }

                function isHighlighted(d) {
                    const highlighted = heatmap.highlighted();
                    if (!highlighted || highlighted === heatmap.selection()) {
                        if (highlightedCorrelation) {
                            return d[highlightedCorrelation[0]] === highlightedCorrelation[1];
                        } else {
                            return false;
                        }
                    }

                    // If this isn't a group, check node id against highlighted id
                    if (!highlighted.groupKey) return d.id === highlighted.id;

                    return nodeInGroup(d, highlighted);
                }

                let highlightedCorrelation;
                $scope.insights = {
                    filterMap: {},
                    muteFilter: isMuted,
                    highlightFilter: isHighlighted,
                    data: [],
                    selectedHosts: [],
                    metrics: [],
                    selectedMetric: heatmap.colorBy(),
                };

                const CORRELATION_MODE = ($scope.CORRELATION_MODES = {
                    FILTERS: 'FILTERS',
                    CURRENT_METRIC: 'CURRENT_METRIC',
                    OUTLIERS: 'OUTLIERS',
                });

                $scope.onMouseEnterCorrelation = function (key, value) {
                    highlightedCorrelation = [key, value];
                    $scope.$broadcast('update highlighted');
                };

                $scope.onMouseLeaveCorrelation = function () {
                    highlightedCorrelation = null;
                    $scope.$broadcast('update highlighted');
                };

                $scope.selectCorrelation = function (key, value) {
                    highlightedCorrelation = null;
                    heatmap.groupBy([key]);
                    $timeout(function () {
                        heatmap.selection({ id: value });
                        $scope.$broadcast('update highlighted');
                    }, 300);
                };

                $scope.correlation = [];

                function updateCorrelations() {
                    const filterMap = $scope.insights.filterMap;
                    if (!$scope.insights.data || !filterMap || $scope.insights.loading) return;

                    highlightedCorrelation = null;
                    $scope.correlation = getCorrelations(heatmap, filterMap, idToData);
                }

                function getCorrelationMode(heatmap, filterMap) {
                    if (Object.keys(filterMap).length) {
                        // Correlate using the current filter set when filters are set
                        return CORRELATION_MODE.FILTERS;
                    } else if (
                        heatmap.threshold() !== HEATMAP_THRESHOLD[0] &&
                        heatmap.groupByDepth() === 0
                    ) {
                        // If base outlier detection is enabled (where group by depth is 0)
                        // Do the correlation based off the stat function
                        return CORRELATION_MODE.OUTLIERS;
                    } else {
                        // By default to the correlation on the top 20% band of the current
                        // metric values
                        return CORRELATION_MODE.CURRENT_METRIC;
                    }
                }

                function getCorrelationFilter(
                    heatmap,
                    filterMap,
                    idToData,
                    correlationMode,
                    stats
                ) {
                    const colorBy = heatmap.colorBy();
                    const filterMetrics = Object.keys(filterMap);

                    function createLimitFilter(limit) {
                        return function limitFilter(d) {
                            return idToData[d.id][colorBy.displayName] >= limit;
                        };
                    }

                    function currentFiltersFilter(d) {
                        return filterMetrics.every(function (metric) {
                            const filter = filterMap[metric];
                            const value = idToData[d.id][metric];
                            return value >= filter[0] && value <= filter[1];
                        });
                    }

                    let minValue;
                    let maxValue;
                    switch (correlationMode) {
                        case CORRELATION_MODE.FILTERS:
                            return currentFiltersFilter;
                        case CORRELATION_MODE.OUTLIERS:
                            return createLimitFilter(stats);
                        // case CORRELATION_MODE.CURRENT_METRIC:
                        default: {
                            minValue = colorBy.minValue;
                            maxValue = colorBy.maxValue;

                            const accessor = function (d) {
                                return d[colorBy.metric];
                            };

                            if (minValue === undefined) {
                                minValue = d3.min($scope.insights.data, accessor);
                            }

                            if (maxValue === undefined) {
                                maxValue = d3.max($scope.insights.data, accessor);
                            }

                            // Select top 20% range of domain
                            return createLimitFilter(maxValue - (maxValue - minValue) / 5);
                        }
                    }
                }

                function getCorrelations(heatmap, filterMap, idToData) {
                    $scope.correlationMode = getCorrelationMode(heatmap, filterMap);
                    const filterFunction = getCorrelationFilter(
                        heatmap,
                        filterMap,
                        idToData,
                        $scope.correlationMode,
                        $scope.stats
                    );
                    const nodes = heatmap.getNodes();
                    const facets = getDataFacets(nodes, HEATMAP_PROPERTY_DENYLIST);
                    return calculateOverrepresentation(nodes, facets, filterFunction)
                        .filter(function (correlation) {
                            return correlation[1] < CORRELATION_RELEVANCE_LIMIT;
                        })
                        .slice(0, 10);
                }

                function reloadData() {
                    $scope.insights.loading = true;
                    reset();
                    idToData = null;
                    $scope.insights.data = null;
                    $log.info('reloading insights data');
                    heatmap.mode();
                    const filterBy = heatmap.filterBy() || [];

                    const metrics = [];
                    const colorBys = heatmap.mode().metrics.filter(function (metric) {
                        return metric.type !== 'event';
                    });

                    const program = colorBys
                        .map(function (colorBy) {
                            metrics.push(colorBy);

                            const varName = colorBy.job.varName;

                            const signalTextToStream = mustache.render(colorBy.job.template, {
                                filter: plotToSignalflowV2.filters(
                                    filterBy.concat(
                                        heatmapUtilsService.convertEntityMetricFiltersToPropertyFilters(
                                            colorBy.job.filters
                                        )
                                    )
                                ),
                            });

                            return (
                                signalTextToStream +
                                '\n' +
                                varName +
                                '.publish("' +
                                colorBy.displayName +
                                '")'
                            );
                        })
                        .join('\n');

                    $scope.insights.metrics = metrics.slice(0, 5);
                    $scope.insights.selectedMetric = heatmap.colorBy();
                    $scope.comparisons = [];

                    dataService.resolution(colorBys[0].job.resolution);
                    dataService.program(program);

                    dataService.start();
                }

                const dataService = metricsDataService.create(function (metadata) {
                    return mustache.render(heatmap.getId(metadata));
                });

                $scope.comparisons = [];

                function makeDomainFunction(colorBy) {
                    return function (data, accessor) {
                        let minValue = colorBy.minValue;
                        let maxValue = colorBy.maxValue;

                        if (minValue === undefined) {
                            minValue = d3.min(data, accessor);
                        }

                        if (maxValue === undefined) {
                            maxValue = d3.max(data, accessor);
                        }

                        return [minValue, maxValue];
                    };
                }

                function updateComparisons() {
                    const selectedMetric = heatmap.colorBy();
                    const coloringFunction = heatmap.coloringFunction();

                    $scope.insights.histogramConfig = {
                        dataProperty: selectedMetric.displayName,
                        domain: makeDomainFunction(selectedMetric),
                        coloringFunction: function (d, i) {
                            return colorPalette[i];
                        },
                    };

                    if ($scope.comparisons.length) {
                        $scope.comparisons.forEach(function (comparison) {
                            const config = comparison.scatterPlotConfig;
                            config.xAxisProperty = selectedMetric.displayName;
                            config.xAxisDomain = makeDomainFunction(selectedMetric);
                            config.coloringFunction = coloringFunction;
                        });
                    } else {
                        $scope.comparisons = $scope.insights.metrics.map(function (metric) {
                            const comparison = {
                                metric: metric.id,
                            };

                            comparison.scatterPlotConfig = {
                                yAxisProperty: metric.displayName,
                                yAxisDomain: makeDomainFunction(metric),
                                xAxisProperty: selectedMetric.displayName,
                                xAxisDomain: makeDomainFunction(selectedMetric),
                                coloringFunction: coloringFunction,
                            };

                            return comparison;
                        });
                    }
                }

                $scope.insights.loading = true;

                eventBindings.push(
                    dataService.on('loading', function () {
                        $scope.insights.loading = true;
                    })
                );

                let idToData;

                eventBindings.push(
                    dataService.on('data', function ($event, data) {
                        $scope.insights.loading = false;
                        idToData = data;
                        mergeMetadataToData();
                        $scope.$apply();
                    })
                );

                function mergeMetadataToData() {
                    if (!idToData) return;

                    const idToMetadata = {};

                    heatmap.getNodes().forEach(function (node) {
                        idToMetadata[node.id] = node;
                    });

                    const data = Object.keys(idToData).map(function (id) {
                        return angular.extend(idToData[id], idToMetadata[id]);
                    });

                    $scope.insights.data = data;
                    $scope.insights.histogramConfig = {
                        dataProperty: heatmap.colorBy().displayName,
                        coloringFunction: function (d, i) {
                            return colorPalette[i];
                        },
                    };

                    updateComparisons();
                    updateCorrelations();
                }

                eventBindings.push(
                    dataService.on('reset', function () {
                        $scope.insights.loading = false;
                    })
                );

                function reset() {
                    $scope.insights.filterMap = {};
                    $scope.highlightedHosts = [];
                }

                eventBindings.push(heatmap.on('reset', reset));
                eventBindings.push(heatmap.on('filterBy updated', reloadData));
                eventBindings.push(heatmap.on('nodes updated', mergeMetadataToData));
                eventBindings.push(
                    heatmap.on('mode updated', function () {
                        reloadData();
                    })
                );

                eventBindings.push(
                    heatmap.on('colorBy updated', function ($event, colorBy) {
                        $scope.insights.selectedMetric = colorBy;
                    })
                );

                eventBindings.push(
                    heatmap.on('highlighted updated', function () {
                        $scope.$broadcast('update highlighted');
                    })
                );

                eventBindings.push(
                    heatmap.on('selection updated', function ($event, selected) {
                        // clear insights filters on selection
                        if (selected) {
                            Object.keys($scope.insights.filterMap).forEach(function (key) {
                                delete $scope.insights.filterMap[key];
                            });
                        }

                        $scope.$broadcast('update highlighted');
                    })
                );

                $scope.$watch(
                    'insights.filterMap',
                    function (filterMap) {
                        if (!$scope.insights.data) return;

                        // If the filterMap isn't empty, clear heatmap selection
                        if (Object.keys(filterMap).length) {
                            heatmap.selection(null);
                        }

                        $scope.highlightedHosts = $scope.insights.data
                            .filter(function (d) {
                                return Object.keys(filterMap).every(function (key) {
                                    const extents = filterMap[key];
                                    return extents[0] <= d[key] && d[key] <= extents[1];
                                });
                            })
                            .map(function (d) {
                                return d.id;
                            });

                        updateCorrelations();
                    },
                    true
                );

                $scope.$watch('insights.selectedMetric', function (highlight) {
                    if (!highlight) return;

                    heatmap.colorBy(highlight);
                    updateComparisons();
                    updateCorrelations();
                });

                $scope.highlightedHosts = [];
                $scope.$watch('insights.selectedHosts', function (selected) {
                    $scope.highlightedHosts = selected.map(function (item) {
                        return item.id;
                    });
                });

                function resize() {
                    const continerWidth = element[0].getBoundingClientRect().width;
                    $scope.columnWidth = (continerWidth - 50) / 12;
                }
                resize();

                $scope.$on('$destroy', function () {
                    dataService.stop();

                    // Unbind event bindings
                    eventBindings.forEach(function (unbind) {
                        unbind();
                    });
                });

                $scope.$on('resize', resize);
                reloadData();
            },
        };
    },
]);
