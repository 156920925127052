angular.module('sfx.api.v2').service('dashboardV2Util', [
    '$q',
    '$log',
    'dashboardGroupService',
    'dashboardV2Service',
    'chartV2Service',
    'recentPagesService',
    function (
        $q,
        $log,
        dashboardGroupService,
        dashboardV2Service,
        chartV2Service,
        recentPagesService
    ) {
        function onError(msg) {
            return function (e) {
                $log.error(msg + ': ', e);
                return $q.reject(e);
            };
        }

        const dbUtilService = {
            deleteDashboard(dashId) {
                return dashboardV2Service
                    .get(dashId)
                    .then(function (dashboard) {
                        let chartDeletions = [];

                        angular.forEach(dashboard.charts, function (chart) {
                            chartDeletions.push(chartV2Service.delete(chart.chartId));
                        });

                        chartDeletions = $q
                            .all(chartDeletions)
                            .catch(
                                onError('Unable to delete all charts associated with dashboard')
                            );

                        return $q.all([chartDeletions, dashboardV2Service.delete(dashId)]);
                    })
                    .then((result) => {
                        return recentPagesService
                            .removeAllRecentEntriesOfDashboard(dashId)
                            .then(() => result);
                    })
                    .catch(onError('Unable to delete dashboard'));
            },
            getChartsForDashboard(dashboard) {
                const chartFetches = [];
                angular.forEach(dashboard.charts, function (chart) {
                    chartFetches.push(chartV2Service.get(chart.chartId));
                });

                return $q
                    .all(chartFetches)
                    .catch(onError('Unable to fetch all charts associated with dashboard'));
            },
            getDashboardAndParentPage(dashId) {
                function fetchParentPage(dash) {
                    if (dash.groupId) {
                        return dashboardGroupService
                            .get(dash.groupId)
                            .catch(onError('Unable to fetch dashboard group'));
                    } else {
                        return $q.reject('Dashboard model object is missing groupId');
                    }
                }

                const dashboard = dashboardV2Service.get(dashId);
                const page = dashboard.then(fetchParentPage, onError('Unable to fetch dashboard'));

                return $q.all({ page, dashboard });
            },
            reserializeData: function (dashboard) {
                dashboard.charts.sort(function (v1, v2) {
                    if (v1.row < v2.row) {
                        return -1;
                    } else if (v1.row > v2.row) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            },
            deleteChart: function (dashboard, chartId) {
                const retainedCharts = [];
                angular.forEach(dashboard.charts, function (chart) {
                    if (chart.chartId !== chartId) {
                        retainedCharts.push(chart);
                    }
                });

                const updatedDashboard = angular.copy(dashboard);
                updatedDashboard.charts = retainedCharts;

                this.reserializeData(updatedDashboard);

                return dashboardV2Service.update(updatedDashboard).then(function (dash) {
                    chartV2Service.delete(chartId).catch(onError('Failed deleting chart'));
                    return dash;
                }, onError('Failed to update dashboard to remove deleted chart member'));
            },
            /*
             * groupId should be specified if it is possible that the dashboard in
             * question appears in multiple dashboard groups i.e. if the org has
             * dashboardViews enabled
             */
            getAndProcessHierarchy: function (dashId, groupId, disableErrorHandling = false) {
                return dashboardV2Service
                    .getHierarchyV2(dashId, groupId, disableErrorHandling)
                    .then((hierarchy) => {
                        hierarchy.charts = hierarchy.charts || [];
                        hierarchy.charts = hierarchy.charts.map((chart) => {
                            chart.sf_chartIndex = chart.sf_index;
                            chart.sf_modelVersion = 2;
                            return chart;
                        });

                        if (!hierarchy.dashboards) {
                            hierarchy.dashboards = [];
                        }
                        hierarchy.dashboard.organizationId = hierarchy.organizationId;

                        hierarchy.dashboard = Array.isArray(hierarchy.dashboard)
                            ? hierarchy.dashboard[0]
                            : hierarchy.dashboard;
                        hierarchy.dashboard = dashboardV2Service.apiDashboardToUI(
                            hierarchy.dashboard
                        );
                        hierarchy.dashboards.push(hierarchy.dashboard);

                        const group = Array.isArray(hierarchy.group)
                            ? hierarchy.group[0]
                            : hierarchy.group;

                        return {
                            group: dashboardGroupService.apiDashboardGroupToUI(group),
                            allDashboards: hierarchy.dashboards,
                            dashboard: hierarchy.dashboard,
                            charts: hierarchy.charts,
                        };
                    });
            },
        };

        return dbUtilService;
    },
]);
