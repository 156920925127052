import singleTimePickerTemplateUrl from './singletimepicker.tpl.html';

angular.module('signalview.timePicker').directive('singleTimePicker', [
    function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                initRange: '=?',
                disableUrl: '=?',
                hideLabel: '=?',
                context: '@?', // Empty implies global context
                trackClickValue: '@?',
                absoluteOnly: '=?',
                hideReset: '=?',
                disabled: '<?',
                customScheduleConfig: '=?',
                helpText: '<?',
                timePickerTabIndex: '<?',
                timeOptions: '<?',
                hidePresetTimeOptions: '<?',
            },
            templateUrl: singleTimePickerTemplateUrl,
            controller: 'singleTimePickerController',
            link: function () {},
        };
    },
]);
