import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';

import SimpleSpec from './nextSteps/components/SimpleSpec';
import DateTimeFormatter from '../../common/ui/formatting/DateTimeFormatter';

const TITLE_DETECTOR = 'About this Detector';
const DETECTOR_LABEL = 'Detector';
const DETECTOR_NOT_AVAILABLE = 'Not Available';
const DETECTOR_UPDATED_PREFIX = 'Updated';
const DETECTOR_CREATED_PREFIX = 'Created';
const DETECTOR_MODIFIED_BY = 'by';

function AboutThisDetectorChangeMessage(props) {
    const modifiedByBy = props.name ? ' ' + DETECTOR_MODIFIED_BY + ' ' : '';
    const modifiedByName = props.name ? `${props.name}` : '';
    const userLink = '#/userprofile/' + props.userId;
    const className = new BEMHelper('about-detector');

    return (
        <>
            {props.prefix}
            {modifiedByBy}
            <a {...className('link')} href={userLink} target={'_blank'} rel="noreferrer">
                {modifiedByName}
            </a>{' '}
            at {props.time}
        </>
    );
}

AboutThisDetectorChangeMessage.propTypes = {
    prefix: PropTypes.string,
    name: PropTypes.string,
    userId: PropTypes.string,
    time: PropTypes.string,
};

function AboutThisDetectorInformation(props) {
    if (props.foundDetector) {
        return (
            <>
                <SimpleSpec
                    label={DETECTOR_LABEL}
                    value={
                        <AboutThisDetectorChangeMessage
                            prefix={DETECTOR_UPDATED_PREFIX}
                            name={props.updatedByName}
                            userId={props.updatedByUserId}
                            time={<DateTimeFormatter timestamp={props.updateTimestamp} />}
                        />
                    }
                />

                <SimpleSpec
                    label=""
                    value={
                        <AboutThisDetectorChangeMessage
                            prefix={DETECTOR_CREATED_PREFIX}
                            name={props.createdByName}
                            userId={props.createdByUserId}
                            time={<DateTimeFormatter timestamp={props.createTimestamp} />}
                        />
                    }
                />
            </>
        );
    } else {
        return (
            <SimpleSpec label={DETECTOR_LABEL} value={DETECTOR_NOT_AVAILABLE} inactive={'true'} />
        );
    }
}

AboutThisDetectorInformation.propTypes = {
    foundDetector: PropTypes.bool.isRequired,
    createTimestamp: PropTypes.number,
    updateTimestamp: PropTypes.number,
    createdByName: PropTypes.string,
    updatedByName: PropTypes.string,
    createdByUserId: PropTypes.string,
    updatedByUserId: PropTypes.string,
};

export default function AboutThisDetector(props) {
    // create component className helper or use passed in parentClass helper
    const className = new BEMHelper('about-detector');
    const titleClassName = className('section-title');

    return (
        <div {...className()}>
            <h4 {...titleClassName}>{TITLE_DETECTOR}</h4>
            <AboutThisDetectorInformation
                foundDetector={props.foundDetector}
                createTimestamp={props.createTimestamp}
                updateTimestamp={props.updateTimestamp}
                createdByName={props.createdByName}
                updatedByName={props.updatedByName}
                createdByUserId={props.createdByUserId}
                updatedByUserId={props.updatedByUserId}
            />
        </div>
    );
}

AboutThisDetector.propTypes = {
    parentClass: PropTypes.func,
    foundDetector: PropTypes.bool.isRequired,
    createTimestamp: PropTypes.number,
    updateTimestamp: PropTypes.number,
    createdByName: PropTypes.string,
    updatedByName: PropTypes.string,
    createdByUserId: PropTypes.string,
    updatedByUserId: PropTypes.string,
};
