/*
 * NOTE: some of the logic in this file is duplicated in src/common/ui/eventModal/alertEventModalController.js.
 * A change to one might require a change to the other.
 */
import { convertStringToMS, safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

/*
 * list of known internal metadata properties that will not be included when
 * searching for IMM Infrastructure associations
 */
const IMM_INFRA_LINK_INTERNAL_PROPERTY_DENYLIST = [
    'jobId',
    'tsid',
    'value',
    'id',
    'parent',
    'depth',
    'computationId',
    'computingFor',
    'key',
    'metric',
    'metricKey',
    'pointMetadata',
    'depth',
    'parent',
    'visualizationKey',
    'metric_source',
    'workflow.name',
    'test_type',
    'test_id',
    'location_id',
];

export default class AlertEventV2Service {
    constructor(
        featureEnabled,
        timeZoneService,
        resolutionToChartRangeService,
        detectorPriorityService,
        chartbuilderUtil,
        $http,
        API_URL,
        v2DetectorResolverUtil
    ) {
        this.featureEnabled = featureEnabled;
        this.timeZoneService = timeZoneService;
        this.resolutionToChartRangeService = resolutionToChartRangeService;
        this.detectorPriorityService = detectorPriorityService;
        this.chartbuilderUtil = chartbuilderUtil;
        this.$http = $http;
        this.API_URL = API_URL;
        this.v2DetectorResolverUtil = v2DetectorResolverUtil;
    }

    // it returns minimal required model for chartdisplay inside
    // detector-chart directive
    getInitialEventModel(event, defaultRange) {
        const highThreshold = event.properties.inputs.high;
        const lowThreshold = event.properties.inputs.low;
        return {
            name: 'Alert Modal',
            id: 'alert-model-id',
            showAllSynthetic: true,
            $isOriginallyV2: true,
            sf_type: 'Detector',
            sf_uiModel: {
                chartMode: 'graph',
                chartconfig: {
                    ...defaultRange,
                    verticalLines: [event.timestamp],
                    // Show high and low threshold lines, if they are static thresholds (no key).
                    yAxisConfigurations: [
                        {
                            plotlines: {
                                high: this.getThresholdValue(highThreshold),
                                low: this.getThresholdValue(lowThreshold),
                            },
                        },
                    ],
                },
                currentUniqueKey: 1,
                revisionNumber: 1,
                allPlots: [],
            },
            sf_labelResolutions: {
                0: this.getResolution(event),
            },
        };
    }

    extendModelWithNewPlot(uiModel, plotData) {
        const plot = this.chartbuilderUtil.addNewPlot(uiModel);
        angular.extend(plot, plotData);
    }

    getDefaultRangeConfiguration(eventTimestamp, resolution) {
        const rangeBasedOnResolution = convertStringToMS(
            this.resolutionToChartRangeService(resolution)
        );
        const defaultRange = -15 * 60 * 1000; // 15 minutes
        // miliseconds
        const appliedRange = rangeBasedOnResolution || defaultRange;

        const [start, end] = this.getEventCenteredTimespan(eventTimestamp, appliedRange / 1000);
        const configBasedOnEvent = {
            absoluteStart: start.valueOf(),
            absoluteEnd: end.valueOf(),
        };

        return configBasedOnEvent;
    }

    getThresholdValue(threshold) {
        if (threshold && !threshold.key) {
            return threshold.value;
        }
        return null;
    }

    resetChartConfig(chartconfig) {
        delete chartconfig.pointDensity;
        delete chartconfig.absoluteStart;
        delete chartconfig.absoluteEnd;
        delete chartconfig.range;
        delete chartconfig.rangeEnd;
    }

    getEventCenteredTimespan(eventTimestamp, timespan) {
        const end = eventTimestamp.clone().add(Math.abs(timespan) / 2, 'second');
        const start = end.clone().subtract(Math.abs(timespan), 'second');

        return [start, end];
    }

    getResolution(event) {
        return safeLookup(event, 'properties.sf_resolutionMs') || 1000;
    }

    parseInputs(event) {
        const inputs = safeLookup(event, 'properties.inputs');
        const computationId = event.metadata.computationId;

        const plots = [];
        const DEFAULT_NAME = '-';
        // those maps are created for human-friendly translation
        // of detector condition in event.metadata.sf_detectOnCondition
        const inputIdentifierToPlotLabel = {};
        const identifierToValue = {};

        Object.keys(inputs || {}).forEach((inputName) => {
            const input = inputs[inputName];
            const port = inputName === 'in' ? 'out' : inputName;
            const plot = {
                _originalLabel: input.fragment,
                // only signal plots with value should be visible
                invisible: input.value === false,
                name: DEFAULT_NAME,
                seriesData: {
                    withProgramId: true,
                    // this allows to correlate threshold with signal plot
                    // (see: ThresholdCorrelator.getCorrelatedTSIDs)
                    metric: `${event.metadata.sf_detectLabel}.${port}`,
                },
            };

            if (input.identifiers) {
                const plotLabelIdentifier = input.identifiers[0];
                inputIdentifierToPlotLabel[input.identifier] = plotLabelIdentifier;

                if (input.fragment && input.fragment.includes('const')) {
                    identifierToValue[plotLabelIdentifier] = input.value;
                    // hide threshold plots
                    plot.invisible = true;
                }
            }

            if (input.key) {
                // it is used by chart hover tooltip to display signal name
                plot.name = safeLookup(input, 'key.sf_metric') || DEFAULT_NAME;
                plot.queryItems = this.generatePlotFilters(input.key, computationId);
            }

            plots.push(plot);
        });

        return { plots, inputIdentifierToPlotLabel, identifierToValue };
    }

    generatePlotFilters(sourceFilter, computationId) {
        const plotFilters = [];
        Object.keys(sourceFilter || {}).forEach((key) => {
            if (key !== 'sf_metric') {
                plotFilters.push(this.createPlotFilter(key, sourceFilter[key]));
            }
        });

        plotFilters.push(this.createPlotFilter('computationId', computationId));

        return plotFilters;
    }

    createPlotFilter(key, value) {
        return {
            iconClass: 'icon-properties',
            type: 'property',
            query: `${key}:${value}`,
            propertyValue: value,
            property: key,
        };
    }

    prioritizeDashboards(dashboards) {
        return dashboards.map((dashboard) => {
            const topAlertSeverity = this.getDashboardTopAlertSeverity(dashboard);

            return {
                name: dashboard.dashboardName,
                id: dashboard.dashboardId,
                swatchClass:
                    this.detectorPriorityService.getSwatchClassBySeverity(topAlertSeverity),
                isNormal:
                    this.detectorPriorityService.getDisplayNameBySeverity(topAlertSeverity) ===
                    'Normal',
            };
        });
    }

    getDataLinkUrl({ propertyName, propertyValue }, org) {
        const PARAMS_MAP = { sf_metric: 'sf_originatingMetric' };
        const name = PARAMS_MAP[propertyName] || propertyName;

        return `#/alerts/${org}?sources[]=${encodeURIComponent(name)}:${encodeURIComponent(
            propertyValue
        )}`;
    }

    getAllDimensions(event) {
        const allDimensions = Object.values(event.properties.inputs)
            .filter((input) => input.key !== undefined && Object.keys(input.key).length > 0)
            .flatMap((input) =>
                Object.entries(input.key).map(([propertyName, propertyValue]) => ({
                    propertyName,
                    propertyValue,
                }))
            );
        return _.uniqWith(allDimensions, _.isEqual);
    }

    getDataLinks(event) {
        return this.getAllDimensions(event);
    }

    getUniqueFilterPropertiesFromDetector(detector, existingDimensions) {
        // convert the detector to ui model (which parses the program text from detector) and parse out the filter prop/values. We want to include
        // these on the navigator association search request if they are not part of the alert event's list of dimensions
        return this.v2DetectorResolverUtil
            .convertToUIModel(detector)
            .then((v1Detector) => v1Detector.sf_uiModel)
            .then((sf_uiModel) => {
                if (!sf_uiModel?.allPlots) {
                    return [];
                }
                const queryItems = sf_uiModel.allPlots
                    .filter((p) => !p.invisible && p.queryItems?.length)
                    .flatMap((p) => p.queryItems);

                // check all query items (filter properties) and return those that are not in the list of existing alert dimensions
                return queryItems
                    .filter((queryItem) => {
                        // ignore `!=` filters
                        if (queryItem.NOT) {
                            return false;
                        }
                        return (
                            existingDimensions.findIndex(
                                (dim) => dim.propertyName === queryItem.property
                            ) === -1
                        );
                    })
                    .map((queryItem) => ({
                        propertyName: queryItem.property,
                        propertyValue: queryItem.propertyValue,
                    }));
            })
            .catch(() => {
                // model conversion error (such as autodetect detector). In this case, we
                // can not parse any additional filter properties.
                return [];
            });
    }

    convertToMetricMetadata(metrics, propertyDimensions) {
        // convert list of properties to the metricMetadata request body format
        const metricMetadataArr = metrics.map((metricName) => {
            const metricMetadata = { metricName: metricName };
            metricMetadata.properties = propertyDimensions.map(
                ({ propertyName, propertyValue }) => ({
                    property: propertyName,
                    propertyValues: [propertyValue],
                })
            );
            return metricMetadata;
        });
        return metricMetadataArr;
    }

    normalizePropertyName(originalKey) {
        // NOTE: The period character used in the Object keys is a ***NON STANDARD DOT CHARACTER*** (ascii 8228 "one dot leader").
        // Normalize to a standard dot character ('.')
        return originalKey.replaceAll('․', '.');
    }

    getIMMInfrastructureLinks(event, detector) {
        // check for metrics in detector object program text AND dimensions sf_metric
        const metrics = detector.sf_metricsInObjectProgramText ?? [];
        const allDimensions = this.getAllDimensions(event);
        const metricProperty = allDimensions.find((f) => f.propertyName === 'sf_metric');
        if (metricProperty && metrics.indexOf(metricProperty.propertyValue) === -1) {
            metrics.push(metricProperty.propertyValue);
        }

        if (metrics.length === 0) {
            // if no metric was included in the alert event or detector, then we will not be able to determine
            // any Infrastructure links
            return Promise.resolve(null);
        }

        // get event time window range
        const eventTimestamp = this.timeZoneService.moment(event.timestamp);
        const eventResolution = this.getResolution(event);
        const { absoluteStart, absoluteEnd } = this.getDefaultRangeConfiguration(
            eventTimestamp,
            eventResolution
        );

        const propertyDimensions = metricProperty
            ? allDimensions.filter((item) => item !== metricProperty)
            : allDimensions;

        // check alert metadata for additional properties
        if (!this.isIMMInfrastructureExcludeAlertMetadata() && event.metadata) {
            const validMetadataProperties = this.getValidMetadataProperties(
                event,
                propertyDimensions
            );
            propertyDimensions.push(...validMetadataProperties);
        }

        return this.getUniqueFilterPropertiesFromDetector(detector, propertyDimensions).then(
            (detectorFilterProps) => {
                // include any properties that were filtered by in the detector rules
                propertyDimensions.push(...detectorFilterProps);

                // convert list of properties to the metricMetadata request body format
                const metricMetadata = this.convertToMetricMetadata(metrics, propertyDimensions);

                return this.getAssociationsForInfrastructure(
                    metricMetadata,
                    absoluteStart,
                    absoluteEnd
                );
            }
        );
    }

    getValidMetadataProperties(event, propertyDimensions) {
        // return any unique properties that are on the alert event metadata (and are not internal fields)
        const validMetadataProperties = Object.entries(event.metadata)
            .map(([key, value]) => [this.normalizePropertyName(key), value])
            .filter(([key]) => {
                const isInternalProperty = key.indexOf('sf_') === 0 || key.indexOf('_') === 0;
                const isDisallowed = IMM_INFRA_LINK_INTERNAL_PROPERTY_DENYLIST.indexOf(key) !== -1;
                if (isInternalProperty || isDisallowed) {
                    return false;
                }
                return true;
            })
            .filter(([key]) => {
                return propertyDimensions.findIndex((dim) => dim.propertyName === key) === -1;
            })
            .map(([key, value]) => {
                return {
                    propertyName: key,
                    propertyValue: value,
                };
            });
        return validMetadataProperties;
    }

    getAssociationsForInfrastructure(metricMetadata, absoluteStart, absoluteEnd) {
        const url = this.API_URL + '/v2/association/_/search';
        const associationSearchRequest = { types: ['infraNavigator'], metadata: metricMetadata };
        if (absoluteStart) {
            associationSearchRequest.startTimeUTC = absoluteStart;
        }
        if (absoluteEnd) {
            associationSearchRequest.endTimeUTC = absoluteEnd;
        }

        return this.$http.post(url, associationSearchRequest).then((response) => {
            if (response.data) {
                return response.data.results;
            }
            return null;
        });
    }

    getAPM2TroubleshootUrl(event) {
        const tracingParametersAPM2 = this.getAPM2TracingParameters(event);
        return `#/apm/troubleshooting?${tracingParametersAPM2}`;
    }

    getSyntheticsTroubleshootUrl(event) {
        const [test_type, test_id, location_id, timestamp, sf_resolutionMs] = [
            'metadata.test_type',
            'metadata.test_id',
            'metadata.location_id',
            'timestamp',
            'properties.sf_resolutionMs',
        ].map((item) => safeLookup(event, item));
        // 1. We have all the information to route to a specific Synthetics Test Run.
        // 2. We have just the information for a Synthetics Test.
        // 3. Not enough info, route to Synthetics app root.
        // - Andrew D., 06/22/2022
        // 1.
        if (test_type && test_id && location_id && timestamp && sf_resolutionMs)
            return `#/synthetics/redirect?testType=${test_type}&testId=${test_id}&locationId=${location_id}&timestamp=${timestamp}&resolution=${sf_resolutionMs}`;
        // 2.
        if (test_type && test_id) return `#/synthetics/tests/view/${test_type}/${test_id}`;
        // 3.
        return `#/synthetics`;
    }

    getRumTroubleshootUrl(event) {
        const tracingParametersRum = this.getRumTracingParameters(event);
        return `#/rum/tagspotlight?${tracingParametersRum}`;
    }

    getDashboardUrl(dashboardId) {
        return `#/dashboard/${dashboardId}`;
    }

    getTip(event) {
        return safeLookup(event, 'properties.sf_tip');
    }

    getDetectorId(detector) {
        return safeLookup(detector, 'sf_id') || safeLookup(detector, 'id');
    }

    getDetectorUrl(detector) {
        const detectorId = this.getDetectorId(detector);
        return `#/detector/${detectorId}/edit`;
    }

    getSloId(event) {
        return safeLookup(event, 'metadata.sf_eventSloId');
    }

    getSloUrl(event) {
        const sloId = this.getSloId(event);
        return `#/slo/${sloId}`;
    }

    getDetectorName(detector) {
        return detector.sf_detector || detector.name || null;
    }

    getDetectorAlertsUrl(detector, userOrg) {
        const detectorName = this.getDetectorName(detector);
        if (!detectorName || !userOrg) {
            return '#';
        }

        const detectorNameParam = encodeURIComponent(detectorName);
        return `#/alerts/${userOrg}?sources[]=sf_detector:${detectorNameParam}`;
    }

    getIncidentId(event) {
        return safeLookup(event, 'properties.incidentId');
    }

    getRunbookUrl(event) {
        return safeLookup(event, 'properties.sf_runbookUrl');
    }

    getMessage(event) {
        return safeLookup(event, 'properties.sf_notificationString');
    }

    convertToAPM2TagFilterInput(tag) {
        return {
            tag: tag.tagName,
            operation: 'IN',
            values: tag.values,
            inverted: false,
        };
    }

    getAPM2TracingParameters(event) {
        const eventTimestamp = this.timeZoneService.moment(event.timestamp);
        const eventResolution = this.getResolution(event);

        const { absoluteStart, absoluteEnd } = this.getDefaultRangeConfiguration(
            eventTimestamp,
            eventResolution
        );
        const apm2tracingTimeParameters = [
            'startTimeUTC=' + absoluteStart,
            'endTimeUTC=' + absoluteEnd,
        ];
        const globalTagInputs = [];
        const spanFilters = [];
        const queryParams = {};
        const selectedTag = [];

        if (event && event.metadata) {
            const metadata = event.metadata;

            if (metadata.sf_environment) {
                globalTagInputs.push(
                    this.convertToAPM2TagFilterInput({
                        tagName: 'sf_environment',
                        values: [metadata.sf_environment],
                    })
                );
            }

            if (metadata.sf_workflow) {
                globalTagInputs.push(
                    this.convertToAPM2TagFilterInput({
                        tagName: '_sf_workflow',
                        values: [metadata.sf_workflow],
                    })
                );
            }

            if (metadata.sf_service) {
                spanFilters.push(
                    this.convertToAPM2TagFilterInput({
                        tagName: 'sf_service',
                        values: [metadata.sf_service],
                    })
                );
                // adding the selected param to the query if sf_service exists
                selectedTag.push({ tagName: 'sf_service', value: metadata.sf_service });
            }

            if (metadata.sf_operation) {
                let operationTagName = 'sf_endpoint';
                // in the case of self-initiated traffic using a trace-level metric, there's no guarantee that the operation will map to an endpoint,
                // in which case no traces will be found in full trace search. to be safe we use the `sf_operation` tag instead, which will bring back
                // traces though some might not be related to the alert itself. See https://signalfuse.atlassian.net/browse/APMF-2945
                if (
                    this._isSelfInitiatedApmTraffic(metadata) &&
                    this._hasTraceLevelOriginatingMetric(metadata)
                ) {
                    operationTagName = 'sf_operation';
                }
                spanFilters.push(
                    this.convertToAPM2TagFilterInput({
                        tagName: operationTagName,
                        values: [metadata.sf_operation],
                    })
                );
                selectedTag.push({ tagName: operationTagName, value: metadata.sf_operation });
            }

            if (globalTagInputs.length > 0) {
                queryParams.traceFilter = { tags: globalTagInputs };
            }

            if (spanFilters.length > 0) {
                queryParams.spanFilters = [{ tags: spanFilters }];
            }

            return (
                `filters=${encodeURIComponent(
                    JSON.stringify(queryParams)
                )}&${apm2tracingTimeParameters.join('&')}` +
                `${
                    selectedTag.length > 0
                        ? `&selected=${encodeURIComponent(
                              JSON.stringify([{ nodeTags: selectedTag }])
                          )}`
                        : ''
                }`
            );
        }

        return apm2tracingTimeParameters.join('&');
    }

    convertToRumTagFilterInput(tag) {
        return {
            tag: tag.tagName,
            operation: 'IN',
            values: tag.values,
        };
    }

    hasRumAnalysisResourceType(event) {
        return !!(event?.properties?.detector_type === 'rum');
    }

    getRumTracingParameters(event) {
        const eventTimestamp = this.timeZoneService.moment(event.timestamp);
        const eventResolution = this.getResolution(event);

        const { absoluteStart, absoluteEnd } = this.getDefaultRangeConfiguration(
            eventTimestamp,
            eventResolution
        );
        const now = Date.now();
        const ONE_MINUTE_MILLIS = 60000;

        const rumTracingTimeParameters = [
            'startTimeUTC=' + absoluteStart,
            'endTimeUTC=' + Math.min(absoluteEnd, now - ONE_MINUTE_MILLIS),
        ];
        let rumFilters = [];
        const globalTagInputs = [];
        const spanFilters = [];
        const alert_type = event.properties?.alert_type;
        const resource_type = event.properties?.resource_type;
        const mergedResourceType = [resource_type, alert_type].join('.');
        const RUM_RESOURCE_PARAM_KEY = 'resource_type';

        const analysisType =
            resource_type && alert_type ? `${RUM_RESOURCE_PARAM_KEY}=${mergedResourceType}&` : '';
        if (event.metadata) {
            const metadata = event.metadata;

            if (metadata.sf_environment) {
                globalTagInputs.push(
                    this.convertToRumTagFilterInput({
                        tagName: 'sf_environment',
                        values: [metadata.sf_environment],
                    })
                );
            }

            if (metadata.sf_product) {
                spanFilters.push(
                    this.convertToRumTagFilterInput({
                        tagName: 'sf_product',
                        values: [metadata.sf_product],
                    })
                );
            }
            if (metadata.app) {
                spanFilters.push(
                    this.convertToRumTagFilterInput({
                        tagName: 'app',
                        values: [metadata.app],
                    })
                );
            }
            // NOTE: The period character used in this label
            // is a ***NON STANDARD DOT CHARACTER***
            // searching metadata for 'workflow.name' returns empty.
            // checking here for versions.
            const workflowName = metadata['workflow․name'] || metadata['workflow.name'];
            if (workflowName) {
                spanFilters.push(
                    this.convertToRumTagFilterInput({
                        // force tagname to normal period
                        // if this is not done,
                        // rum will not recognize the tag key,
                        // and will redirect to the session details page
                        tagName: 'workflow.name',
                        values: [workflowName],
                    })
                );
            }

            if (globalTagInputs.length > 0) {
                rumFilters = [...rumFilters, ...globalTagInputs];
            }

            if (spanFilters.length > 0) {
                rumFilters = [...rumFilters, ...spanFilters];
            }
            return `${analysisType}filters=${encodeURIComponent(
                JSON.stringify(rumFilters)
            )}&${rumTracingTimeParameters.join('&')}`;
        }
        return `${analysisType}${rumTracingTimeParameters.join('&')}`;
    }

    _isSelfInitiatedApmTraffic(metadata) {
        return !['SERVER', 'CONSUMER'].includes(metadata.sf_kind);
    }

    _hasTraceLevelOriginatingMetric(metadata) {
        return metadata.sf_originatingMetric && metadata.sf_originatingMetric.startsWith('traces.');
    }

    getNotificationRecipients(event) {
        return JSON.parse(safeLookup(event, 'properties.sf_recipients'));
    }

    isAPM2Enabled() {
        return this.featureEnabled('apm2');
    }

    isSyntheticsEnabled() {
        return this.featureEnabled('synthetics');
    }

    isRumEnabled() {
        return this.featureEnabled('rum');
    }

    isIMMInfrastructureEnabled() {
        return this.featureEnabled('alertToNavigatorExperience');
    }

    isIMMInfrastructureExcludeAlertMetadata() {
        return this.featureEnabled('alertToNavigatorExcludeAlertMetadata');
    }

    inputHasKeys(input) {
        return input.key !== undefined && Object.keys(input.key).length > 0;
    }

    getDashboardTopAlertSeverity(dashboard) {
        return Object.keys(dashboard.severityToAlertCount)
            .filter(
                (severityDisplayName) => dashboard.severityToAlertCount[severityDisplayName] !== 0
            )
            .map((severityDisplayName) =>
                this.detectorPriorityService.getSeverityByDisplay(severityDisplayName)
            )
            .sort((a, b) => b - a)[0];
    }
}
