import AlertEventModalV2Service from '../../../legacy/app/alertEventModalV2/service/alertEventModalV2Service';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';

import { AngularInjector } from '../../../common/AngularUtils';
import {
    trackInfrastructureLinkClick,
    trackInfrastructureLinkGenerated,
} from '../../../common/telemetry';
import ShowMore from './components/ShowMore';
import { CrossLinkReact } from '../../crossLinks/crossLinkReact';
import SimpleSpec from './components/SimpleSpec';
import ShowMoreList from './components/ShowMoreList';
import {
    SPLUNK_APM_PRODUCT_NAME,
    SPLUNK_IMM_INFRASTRUCTURE_PRODUCT_NAME,
    SPLUNK_RUM_PRODUCT_NAME,
    SPLUNK_SYNTHETICS_PRODUCT_NAME,
} from '../../../legacy/common/consts';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import WaitSpinner from '@splunk/react-ui/WaitSpinner';

const NEXT_STEPS_TITLE = 'Next Steps';
const RUNBOOK_LABEL = 'Runbook';
const OPEN_RUNBOOK_LABEL = 'Open Runbook in New Tab';
const TIP_LABEL = 'Tip';
const NO_TIP_MESSAGE = 'No tip has been set for this detector';
const NO_RUNBOOK_LINK_MESSAGE = 'No runbook has been set for this detector';

const EXPLORE_FURTHER_TITLE = 'Explore Further';
const DASHBOARDS_LABEL = 'Dashboards';
const SHOW_ALL_DASHBOARDS_LABEL = 'Show all linked dashboards';
const SHOW_LESS_DASHBOARDS_LINKS_LABEL = 'Show fewer linked dashboards';
const SHOW_ALL_DATA_LINKS_LABEL = 'Show all configured data links';
const SHOW_LESS_DATA_LINKS_LABEL = 'Show fewer configured data links';
const SHOW_ALL_INFRASTRUCTURE_LINKS_LABEL = 'Show all linked infrastructure navigators';
const SHOW_LESS_INFRASTRUCTURE_LINKS_LABEL = 'Show fewer linked infrastructure navigators';
const DATA_LINKS_LABEL = 'Data Links';
const DETECTOR_LABEL = 'Detector';
const OPEN_DETECTOR_LABEL = 'Open Detector';
const SLO_LABEL = 'SLO';
const OPEN_SLO_LABEL = 'Open SLO';
const SEE_ALL_ALERTS_LABEL = 'See all alerts from this detector';
const TROUBLESHOOT_LABEL = 'Troubleshoot';
const SYNTHETICS_RUN_RESULT_LABEL = 'Run result';
const VIEW_RUM_TAG_SPOTLIGHT_LABEL = 'Tag Spotlight';
const NOT_APPLICABLE_LABEL = 'Not available';

const MANAGE_ALERT_TITLE = 'Manage Alert';
const RESOLVE_LABEL = 'Resolve';
const RESOLVED_LABEL = 'Resolved';

export default function NextSteps(props) {
    const { event, detector, dashboards, userOrg, isAlertActive, hasCapability } = props;
    const service = props.alertEventService;
    const APM2_TROUBLESHOOT_LABEL = SPLUNK_APM_PRODUCT_NAME;
    const RUM_TROUBLESHOOT_LABEL = SPLUNK_RUM_PRODUCT_NAME;
    const SYNTHETICS_TROUBLESHOOT_LABEL = SPLUNK_SYNTHETICS_PRODUCT_NAME;
    const IMM_INFRA_TROUBLESHOOT_LABEL = SPLUNK_IMM_INFRASTRUCTURE_PRODUCT_NAME;

    // create component className helper
    const className = new BEMHelper('next-steps');

    const [isAlertResolved, updateIsAlertResolved] = useState(!isAlertActive);
    const [hasReadEventCapability, setHasReadEventCapability] = useState(false);
    const [hasUpdateIncidentCapability, setHasUpdateIncidentCapability] = useState(false);
    const [infrastructureLinks, setInfrastructureLinks] = useState(null);
    const [infrastructureLinksLoading, setInfrastructureLinksLoading] = useState(true);
    const userAnalytics = AngularInjector.useInjectedClass('userAnalytics');

    useEffect(() => {
        hasCapability(Capability.READ_EVENT).then(setHasReadEventCapability);
        hasCapability(Capability.UPDATE_INCIDENT).then(setHasUpdateIncidentCapability);
    }, [hasCapability]);

    useEffect(() => {
        if (detector && service.isIMMInfrastructureEnabled()) {
            service
                .getIMMInfrastructureLinks(event, detector)
                .then(setInfrastructureLinks)
                .finally(() => setInfrastructureLinksLoading(false));
        }
    }, [detector, event, service]);

    useEffect(() => {
        if (infrastructureLinks?.length > 0) {
            trackInfrastructureLinkGenerated(userAnalytics);
        }
    }, [infrastructureLinks, userAnalytics]);

    const dataLinks = service.getDataLinks(event);

    const infrastructureLinksContent =
        infrastructureLinks?.length > 0 ? (
            <ShowMoreList
                showAllLabel={SHOW_ALL_INFRASTRUCTURE_LINKS_LABEL}
                showLessLabel={SHOW_LESS_INFRASTRUCTURE_LINKS_LABEL}
                list={infrastructureLinks}
                renderRow={(item) => renderInfrastructureLink(item)}
            />
        ) : (
            <span>{NOT_APPLICABLE_LABEL}</span>
        );

    return (
        <section {...className()}>
            <section {...className('subsection')}>
                <h4 {...className('subsection-title')}>{NEXT_STEPS_TITLE}</h4>
                <SimpleSpec
                    inactive={!hasTipMessage()}
                    label={TIP_LABEL}
                    value={<ShowMore text={getTipMessage()}></ShowMore>}
                ></SimpleSpec>
                <SimpleSpec
                    inactive={!hasRunbookUrl()}
                    label={RUNBOOK_LABEL}
                    value={renderRunbookUrl()}
                ></SimpleSpec>
            </section>
            <section {...className('subsection')}>
                <h4 {...className('subsection-title')}>{EXPLORE_FURTHER_TITLE}</h4>

                {shouldShowAPM2Troubleshoot() && (
                    <SimpleSpec
                        label={APM2_TROUBLESHOOT_LABEL}
                        value={
                            <a
                                {...className({
                                    element: 'link',
                                    extra: 'sf-automation-troubleshoot-alert',
                                })}
                                href={service.getAPM2TroubleshootUrl(event)}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {TROUBLESHOOT_LABEL}
                            </a>
                        }
                    ></SimpleSpec>
                )}

                {shouldShowIMMInfrastructureTroubleshoot() && (
                    <SimpleSpec
                        label={<span {...className('nowrap')}>{IMM_INFRA_TROUBLESHOOT_LABEL}</span>}
                        value={
                            infrastructureLinksLoading ? (
                                <WaitSpinner size="small" />
                            ) : (
                                infrastructureLinksContent
                            )
                        }
                        inactive={
                            !infrastructureLinksLoading && (infrastructureLinks ?? []).length === 0
                        }
                    ></SimpleSpec>
                )}

                {shouldShowRumTagSpotlight() && service.hasRumAnalysisResourceType(event) && (
                    <SimpleSpec
                        label={RUM_TROUBLESHOOT_LABEL}
                        value={
                            <a
                                {...className({
                                    element: 'link',
                                    extra: 'sf-automation-rum-troubleshoot-alert',
                                })}
                                href={service.getRumTroubleshootUrl(event)}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {VIEW_RUM_TAG_SPOTLIGHT_LABEL}
                            </a>
                        }
                    ></SimpleSpec>
                )}

                {shouldShowSyntheticsTroubleshoot() && (
                    <SimpleSpec
                        label={
                            <span {...className('nowrap')}>{SYNTHETICS_TROUBLESHOOT_LABEL}</span>
                        }
                        value={
                            <a
                                {...className({
                                    element: 'link',
                                    extra: 'sf-automation-synthetics-run-result',
                                })}
                                href={service.getSyntheticsTroubleshootUrl(event)}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {SYNTHETICS_RUN_RESULT_LABEL}
                            </a>
                        }
                    ></SimpleSpec>
                )}

                {shouldShowDashboards() && (
                    <SimpleSpec
                        label={DASHBOARDS_LABEL}
                        value={
                            <ShowMoreList
                                showAllLabel={SHOW_ALL_DASHBOARDS_LABEL}
                                showLessLabel={SHOW_LESS_DASHBOARDS_LINKS_LABEL}
                                list={service.prioritizeDashboards(dashboards)}
                                renderRow={(item) => renderDashboardLink(item)}
                            />
                        }
                    ></SimpleSpec>
                )}

                {!!dataLinks.length && (
                    <SimpleSpec
                        label={DATA_LINKS_LABEL}
                        value={
                            <ShowMoreList
                                showAllLabel={SHOW_ALL_DATA_LINKS_LABEL}
                                showLessLabel={SHOW_LESS_DATA_LINKS_LABEL}
                                list={dataLinks}
                                renderRow={(item, context) => renderDataLink(item, context)}
                            />
                        }
                    ></SimpleSpec>
                )}

                {hasSloId() ? (
                    <SimpleSpec label={SLO_LABEL} value={renderSloLink()} />
                ) : (
                    !!detector && (
                        <SimpleSpec label={DETECTOR_LABEL} value={renderDetectorLinks()} />
                    )
                )}
            </section>
            {hasReadEventCapability && hasUpdateIncidentCapability && (
                <section {...className('subsection')}>
                    <h4 {...className('subsection-title')}>{MANAGE_ALERT_TITLE}</h4>
                    <div className="btn-container">{renderResolve()}</div>
                </section>
            )}
        </section>
    );

    function hasTipMessage() {
        return service.getTip(event) !== null;
    }

    function getTipMessage() {
        const tip = service.getTip(event);
        if (tip === null) {
            return NO_TIP_MESSAGE;
        }

        return tip;
    }

    function hasRunbookUrl() {
        return service.getRunbookUrl(event) !== null;
    }

    function renderRunbookUrl() {
        const runbookUrl = service.getRunbookUrl(event);
        if (runbookUrl) {
            return (
                <a
                    href={runbookUrl}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {OPEN_RUNBOOK_LABEL}
                </a>
            );
        }

        return NO_RUNBOOK_LINK_MESSAGE;
    }

    function renderDataLink(dataLink, context) {
        const { propertyName, propertyValue } = dataLink;
        const timeRange = { pinnedTime: event.timestamp };
        return (
            <React.Fragment>
                <a
                    {...className('link')}
                    href={service.getDataLinkUrl(dataLink, userOrg)}
                    title={`${propertyName}: ${propertyValue}`}
                >
                    <span>{propertyName}: </span>
                    <span>{getDataLinkValue(propertyValue, 25)}</span>
                </a>
                <CrossLinkReact
                    incomingDimensions={dataLink}
                    selectedTimeRange={timeRange}
                    context={context}
                />
            </React.Fragment>
        );
    }

    function renderInfrastructureLink(immLink) {
        const { name, uiLink, navigatorCode } = immLink;
        return (
            <React.Fragment>
                <a
                    {...className('link')}
                    href={'#' + uiLink}
                    title={name}
                    onClick={() => {
                        trackInfrastructureLinkClick(userAnalytics, navigatorCode);
                    }}
                >
                    <span>{name}</span>
                </a>
            </React.Fragment>
        );
    }

    function getDataLinkValue(value, maxLength) {
        if (value.length <= maxLength) {
            return value;
        }

        return `${value.slice(0, maxLength)}...`;
    }

    function renderDashboardLink(dashboard) {
        const swatchClass = `swatch-small swatch-centered swatch ${dashboard.swatchClass}`;
        const dashboardHref = `#/dashboard/${dashboard.id}`;

        return (
            <React.Fragment>
                <i className={swatchClass} />
                <a {...className('link')} href={dashboardHref}>
                    {dashboard.name}
                </a>
            </React.Fragment>
        );
    }

    function renderDetectorLinks() {
        const detectorUrl = service.getDetectorUrl(detector);
        const detectorAlertsUrl = service.getDetectorAlertsUrl(detector, userOrg);

        return (
            <React.Fragment>
                <div>
                    <a {...className('link')} href={detectorUrl}>
                        {OPEN_DETECTOR_LABEL}
                    </a>
                </div>
                <div>
                    <a {...className('link')} href={detectorAlertsUrl}>
                        {SEE_ALL_ALERTS_LABEL}
                    </a>
                </div>
            </React.Fragment>
        );
    }

    function hasSloId() {
        return service.getSloId(event) !== null;
    }

    function renderSloLink() {
        const sloUrl = service.getSloUrl(event);

        return (
            <a {...className('link')} href={sloUrl}>
                {OPEN_SLO_LABEL}
            </a>
        );
    }

    function resolveAlert() {
        const incidentId = service.getIncidentId(event);

        updateIsAlertResolved(null);
        props.resolveAlert(incidentId).then(() => updateIsAlertResolved(true));
    }

    function renderResolve() {
        const buttonStatusClass = isAlertResolved === null ? 'btn-loading--pending' : '';

        if (isAlertResolved) {
            return (
                <div className="resolved-label">
                    <i className="icon-check" />
                    {RESOLVED_LABEL}
                </div>
            );
        }

        return (
            <button
                className={`btn btn-default btn-loading ${buttonStatusClass}`}
                onClick={() => resolveAlert()}
            >
                {RESOLVE_LABEL}
            </button>
        );
    }

    function shouldShowDashboards() {
        return !!(Array.isArray(dashboards) && dashboards.length);
    }

    function shouldShowAPM2Troubleshoot() {
        return detector && service.isAPM2Enabled();
    }

    function shouldShowRumTagSpotlight() {
        return detector && service.isRumEnabled();
    }

    function shouldShowSyntheticsTroubleshoot() {
        return detector && service.isSyntheticsEnabled();
    }

    function shouldShowIMMInfrastructureTroubleshoot() {
        return detector && service.isIMMInfrastructureEnabled();
    }
}

NextSteps.propTypes = {
    alertEventService: PropTypes.instanceOf(AlertEventModalV2Service).isRequired,
    event: PropTypes.object.isRequired,
    detector: PropTypes.object,
    dashboards: PropTypes.array,
    userOrg: PropTypes.string,
    isAlertActive: PropTypes.bool,
    resolveAlert: PropTypes.func,
    hasCapability: PropTypes.func,
};
