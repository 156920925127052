import { FilterKey } from '@splunk/olly-services';

export default [
    'featureEnabled',
    function (featureEnabled) {
        const isGlobalSearchForNavigatorsEnabled = featureEnabled('globalSearchForNavigators');

        function apmSearchCategories() {
            return [
                {
                    type: 'service',
                    key: FilterKey.APM_SERVICE_NAME,
                    regex: /^(?:apm\s+)?service$/i,
                    enabled: true,
                },
                {
                    type: 'trace',
                    key: FilterKey.APM_TRACE_ID,
                    regex: /^(?:trace(?:\s*id)?)?$/i,
                    enabled: true,
                },
                {
                    type: 'workflow',
                    key: FilterKey.APM_WORKFLOW_NAME,
                    regex: /^(?:business\s+)?workflow$/i,
                    enabled: true,
                },
            ].filter((item) => item.enabled);
        }

        function appSearchCategoriesCombined() {
            return [...apmSearchCategories()];
        }

        function getAppsSearchCategories() {
            const searchCategories = appSearchCategoriesCombined();
            return {
                getKey(inputKey) {
                    const match = searchCategories.find((item) => item.regex.test(inputKey));
                    return match ? match.key : null;
                },
            };
        }

        function getAllowedTypesForCatalogSearch() {
            return {
                Chart: true,
                Dashboard: true,
                Detector: true,
                Dimension: true,
                Property: true,
                Tag: true,
                Team: true,
                ...(isGlobalSearchForNavigatorsEnabled && { Navigator: true }),
            };
        }

        function getAllowedTypesForMetadataSearch() {
            return {
                Property: true,
                Dimension: true,
                Tag: true,
            };
        }

        function getRemainingPrefixes() {
            return ['integration', 'action', 'navigation', 'help'];
        }

        // remove prefixes that simply adding an "s" to pluralize does not work for
        function getRemovedPrefixesForHints() {
            return ['integration', 'action', 'navigation', 'help'];
        }

        // terms that are technically not "prefixes" but function as such
        function getNonPrefixHints() {
            return ['metric'];
        }

        function generateAllowedTypeKeys(types) {
            return Object.keys(types).reduce((result, key) => {
                if (types[key]) {
                    result[key] = true;
                    result[key.toLowerCase()] = true;
                }
                return result;
            }, {});
        }

        function isValidSearchPrefix(key) {
            return (
                generateAllSearchPrefixes().includes(key) || testKeyMatchesRegexAllowedPrefixes(key)
            );
        }

        function testKeyMatchesRegexAllowedPrefixes(key) {
            const appSearchCategories = appSearchCategoriesCombined().filter((item) => {
                return key ? item.regex.test(key) : true;
            });
            return appSearchCategories.length > 0;
        }

        function generateAllSearchPrefixes() {
            const catalogSearchPrefixes = Object.keys(getAllowedTypesForCatalogSearch()).map(
                (prefix) => prefix.toLowerCase()
            );

            const appSearchPrefixes = appSearchCategoriesCombined().map((item) => item.type);

            return [...catalogSearchPrefixes, ...appSearchPrefixes, ...getRemainingPrefixes()];
        }

        function getSuggectedHintPrefixes() {
            const suggestedHints = generateAllSearchPrefixes()
                .filter((prefix) => !getRemovedPrefixesForHints().includes(prefix))
                .concat(getNonPrefixHints());
            return suggestedHints;
        }

        function getSuggestedHint() {
            const suggestedHints = getSuggectedHintPrefixes();
            const suggestedHintIndex = Math.floor(Math.random() * suggestedHints.length);
            return suggestedHints[suggestedHintIndex];
        }

        return {
            getAppsSearchCategories,
            generateAllowedTypeKeys,
            isValidSearchPrefix,
            getAllowedTypesForCatalogSearch,
            getAllowedTypesForMetadataSearch,
            getSuggectedHintPrefixes,
            generateAllSearchPrefixes,
            getSuggestedHint,
        };
    },
];
