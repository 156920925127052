import React, { FC, useEffect, useRef } from 'react';
import {
    useMessageChannel,
    useMessageChannelReducer,
} from '@splunk/olly-services/lib/hooks/useMessageChannel';
import {
    FilterValue,
    DetectorWizardMessages,
    useDetectorWizard,
    CreateNewDetectorFromChartInterrupted,
    DETECTOR_WIZARD_CHANNEL,
} from '@splunk/olly-services/lib/hooks/useDetectorWizard';
import PropTypes from 'prop-types';

type Filters = (FilterValue | FilterValue[])[];

export type CreateDetectorFromChartProps = {
    modalId: string | undefined;
    fallback: (reason: string) => void;
    chartId: string;
    programText: string;
    getFilters: () => Filters;
    resetModalId: () => void;
};

export const DETECTOR_FROM_CHART_MODAL_ID_NAMESPACE = 'detector-from-chart-modal';

const CreateDetectorFromChart: FC<CreateDetectorFromChartProps> = ({
    modalId,
    chartId,
    programText,
    getFilters,
    fallback,
    resetModalId,
}) => {
    const transactionId = useRef<string>();

    const { createNewDetectorFromChart } = useDetectorWizard();
    const [message] = useMessageChannel<DetectorWizardMessages>(DETECTOR_WIZARD_CHANNEL);

    useMessageChannelReducer(message).on(
        CreateNewDetectorFromChartInterrupted,
        ({ transactionId: id, reason }) => {
            if (id === transactionId.current) {
                fallback(reason);
            }
        }
    );

    useEffect(() => {
        if (modalId !== undefined) {
            const id = createNewDetectorFromChart({
                chartId,
                programText,
                filters: getFilters(),
            });
            transactionId.current = id;
            resetModalId();
        }
    }, [chartId, createNewDetectorFromChart, getFilters, modalId, programText, resetModalId]);

    return <a className="sf-menu-item">New detector from chart</a>;
};

CreateDetectorFromChart.propTypes = {
    modalId: PropTypes.string,
    chartId: PropTypes.string.isRequired,
    fallback: PropTypes.func.isRequired,
    resetModalId: PropTypes.func.isRequired,
    getFilters: PropTypes.func.isRequired,
    programText: PropTypes.string.isRequired,
};

export default CreateDetectorFromChart;
