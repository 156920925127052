import { staticQuickActionsDataProvider } from './staticQuickActionsDataProvider';
import { catalogQuickActionsDataProvider } from './catalogQuickActionsDataProvider';
import { defaultDataQuickActionDataProvider } from './defaultDataQuickActionDataProvider';
import { QuickActionsController } from './quickActions';
import promiseUtil from './promiseUtil';
import quickActionsUtil from './quickActionsUtil';
import sortUtil from './sortUtil';
import searchCategoriesUtil from './searchCategoriesUtil';
import { o11yAppsActionsDataProvider } from './o11yAppsActionsDataProvider';
import iconUtils from './iconUtils';

angular
    .module('signalview.quickActions', [])

    .controller('QuickActionsController', QuickActionsController)

    .service('defaultDataQuickActionDataProvider', defaultDataQuickActionDataProvider)
    .service('staticQuickActionsDataProvider', staticQuickActionsDataProvider)
    .service('o11yAppsActionsDataProvider', o11yAppsActionsDataProvider)
    .service('catalogQuickActionsDataProvider', catalogQuickActionsDataProvider)
    .service('promiseUtil', promiseUtil)
    .service('quickActionsUtil', quickActionsUtil)
    .service('sortUtil', sortUtil)
    .service('searchCategoriesUtil', searchCategoriesUtil)
    .service('iconUtils', iconUtils);
