import templateUrl from './maxDelayOverridePicker.tpl.html';
import { convertStringToMS } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';

export default {
    bindings: {
        maxDelayOverride: '<',
        disabled: '<',
        updateMaxDelay: '&',
        labelId: '@?',
    },
    templateUrl,
    controller: [
        '$scope',
        'msToStrFilter',
        function ($scope, msToStrFilter) {
            const $ctrl = this;
            $ctrl.$onInit = $onInit;
            $ctrl.$onChanges = $onChanges;

            function $onInit() {
                $scope.maxDelayDisplay = convertMSToStr($ctrl.maxDelayOverride);
                $scope.updateMaxDelay = $ctrl.updateMaxDelay;
                $scope.disabled = $ctrl.disabled;
            }

            function $onChanges() {
                $scope.maxDelayDisplay = convertMSToStr($ctrl.maxDelayOverride);
            }

            $scope.maxDelayOverrideOptions = [
                {
                    display: 'Default',
                    value: '(use individual chart settings)',
                },
                {
                    display: 'Auto',
                    value: '(as little as possible)',
                },
                {
                    display: '5s',
                    value: '',
                },
                {
                    display: '10s',
                    value: '',
                },
                {
                    display: '30s',
                    value: '',
                },
                {
                    display: '1m',
                    value: '',
                },
                {
                    display: '5m',
                    value: '',
                },
            ];

            function convertMSToStr(ms) {
                switch (ms) {
                    case null:
                        return 'Default';
                    case 0:
                        return 'Auto';
                    default:
                        return msToStrFilter(ms);
                }
            }

            function convertStrToMS(delayString) {
                switch (delayString) {
                    case 'Default':
                        return null;
                    case 'Auto':
                        return 0;
                    default:
                        return convertStringToMS(delayString);
                }
            }

            function emitMessageIfDifferent(maxDelayMs) {
                if (maxDelayMs !== $ctrl.maxDelayOverride) {
                    $scope.$emit('max delay dirty');
                }
            }

            $scope.setMaxDelay = function (userInput) {
                $scope.maxDelayDisplay = userInput;

                if (userInput) {
                    const maxDelayMs = convertStrToMS(userInput);
                    emitMessageIfDifferent(maxDelayMs);

                    $scope.updateMaxDelay({ maxDelayOverride: maxDelayMs });
                }
            };
        },
    ],
};
