import templateUrl from './chartDisplayToo.tpl.html';
import jobStartErrorDetailsTemplateUrl from '../../jobfeedback/jobStartErrorDetails.tpl.html';
import jobStartErrorsAlertTemplateUrl from '../../jobfeedback/jobStartErrorsAlert.tpl.html';
import { CHART_OVERLAY_EVENTS, CHART_OVERLAY_MODES } from './chartOverlay';
import { timestampFormat } from '../../../../app/charting/chartdisplay/tableChart/utils/timestamp';

export const chartDisplayToo = {
    templateUrl,
    transclude: true,
    bindings: {
        chartModel: '<',
        filters: '<',
        hideLegend: '<',
        hideTitle: '<',
        sharedChartState: '<',
        sharedChartConfig: '<',
        openHref: '@',
        filterAlias: '<',
        inEditor: '<',
        disableUrl: '<',
        snapshot: '<',
        isStandalone: '@?',
        widget: '<',
        getFilterOverrides: '<',
        maxDelayOverride: '<',
        builderDisplayMode: '<',
        setLinkUrl: '<',
    },
    controller: [
        'chartUtils',
        'routeParameterService',
        '$scope',
        '$rootScope',
        '$timeout',
        '$element',
        'programTextUtils',
        'chartDisplayUtils',
        'colorAccessibilityService',
        'sourceFilterService',
        'CHART_DISPLAY_EVENTS',
        'featureEnabled',
        'chartSettingsModal',
        'sfxModal',
        'SmokeyStateProvider',
        'themeService',
        'ChartNoDataService',
        'ChartExportService',
        'timeZoneService',
        function (
            chartUtils,
            routeParameterService,
            $scope,
            $rootScope,
            $timeout,
            $element,
            programTextUtils,
            chartDisplayUtils,
            colorAccessibilityService,
            sourceFilterService,
            CHART_DISPLAY_EVENTS,
            featureEnabled,
            chartSettingsModal,
            sfxModal,
            SmokeyStateProvider,
            themeService,
            ChartNoDataService,
            chartExportService,
            timeZoneService
        ) {
            this.jobStartErrorsAlertTemplateUrl = jobStartErrorsAlertTemplateUrl;

            const $ctrl = this;
            let oldPlots = null;
            let currentDataProvider;
            let recomputeKeySetDelay;
            let jobFeedbackMessages = [];
            const smokeyStateProvider = new SmokeyStateProvider(smokeyStateUpdated);

            $ctrl.plotDataGeneration = 0;
            $ctrl.jobInProgress = false;
            $ctrl.numMTS = 0;

            $ctrl.$onDestroy = $onDestroy;
            $ctrl.$onInit = $onInit;
            $ctrl.$doCheck = $doCheck;
            $ctrl.onNewDataProvider = onNewDataProvider;
            $ctrl.useExportToCsv = useExportToCsv();
            $ctrl.onSortChanged = sortChanged;
            $ctrl.onGroupByChanged = groupByChanged;
            $ctrl.onLegendColumnConfigChanged = onLegendColumnConfigChanged;
            $ctrl.onTimestampChanged = hideTimestampChanged;
            $ctrl.onPlotChange = onPlotChange;
            $ctrl.showSparkline = chartDisplayUtils.showSparkline;
            $ctrl.themeKey = themeService.getColorScheme();
            $ctrl.showNoDataMessage = false;
            $ctrl.filters = {};
            $ctrl.replaceonlyFilters = {};
            $ctrl.logsTimeRange = '';
            $ctrl.logsFilters = [];
            $ctrl.logsIndexes = [];

            $ctrl.setLogsTimeRange = function (logsTimeRange) {
                $ctrl.logsTimeRange = logsTimeRange;
            };

            $ctrl.setLogsFilters = function (logsFilters) {
                $ctrl.logsFilters = logsFilters;
            };

            $ctrl.setLogsIndexes = function (logsIndexes) {
                $ctrl.logsIndexes = logsIndexes;
            };

            function getModalParams() {
                return {
                    backingJob: null,
                    viewJob: currentDataProvider ? currentDataProvider.getJobId() : null,
                    chartObj: $ctrl.chartModel,
                    feedback: jobFeedbackMessages,
                    jobAttempts: null,
                    traceId: null,
                    snapshot: $ctrl.snapshot,
                    jobHistory: null,
                    logsTimeRange: $ctrl.logsTimeRange,
                    logsFilters: $ctrl.logsFilters,
                    logsIndexes: $ctrl.logsIndexes,
                };
            }

            function showChartInfo() {
                chartSettingsModal.info(getModalParams());
            }

            $scope.clickChart = function (event) {
                if (event && event.altKey && event.metaKey) {
                    event.preventDefault();
                    event.stopPropagation();
                    showChartInfo();
                }
            };

            $scope.$on('show chart info', showChartInfo);

            function useExportToCsv() {
                let data;
                function exportToCsv(chartName) {
                    const { columns, getPlotColumnDisplayValue, tableData, timestamp } = data;
                    const dataTable = [[...columns.map(({ name }) => name)]];

                    tableData.forEach((dataRow) => {
                        const csvRow = columns.map((column) => {
                            const value = dataRow[column.name];
                            return column.isPlot
                                ? `\"${getPlotColumnDisplayValue(column, value)}\"`
                                : value;
                        });
                        dataTable.push(csvRow);
                    });

                    if (timestamp) {
                        dataTable.push(
                            [],
                            [
                                'Timestamp',
                                timeZoneService.dateWithTimeZone(timestamp, timestampFormat),
                            ]
                        );
                    }

                    chartExportService.downloadAsCSV(dataTable, chartName);
                }

                return function () {
                    return {
                        exportToCsv,
                        setExportData: (exportData) => {
                            data = exportData;
                        },
                    };
                };
            }

            $scope.$on('export current chart', function () {
                const tsidToPlot = {};
                const mdm = currentDataProvider.getMetaDataMap();
                angular.forEach(mdm, (metaData, tsid) => {
                    tsidToPlot[tsid] = chartDisplayUtils.getPlotObject(mdm[tsid], $ctrl.chartModel);
                });

                const { exportToCsv } = $ctrl.useExportToCsv();
                exportToCsv($ctrl.chartModel.sf_chart);
            });

            $scope.$on('builder mode swap', function () {
                if (currentDataProvider) {
                    currentDataProvider.rerun();
                }
            });

            $rootScope.$on('theme update', function () {
                $ctrl.themeKey = themeService.getColorScheme();
            });

            $scope.$on(
                CHART_DISPLAY_EVENTS.CONTEXT_RESIZE,
                _.debounce(() => {
                    $ctrl.chartHeight = $element[0].clientHeight;
                }, 100)
            );

            $scope.$on(
                CHART_DISPLAY_EVENTS.CONTEXT_RESIZE,
                _.debounce(() => {
                    $ctrl.chartWidth = $element[0].clientWidth;
                }, 100)
            );

            function sortChanged(sortPreference) {
                if (sortPreference) {
                    $ctrl.chartModel.sf_uiModel.chartconfig.sortPreference = sortPreference;
                }
            }

            function groupByChanged(groupByFieldName) {
                if (groupByFieldName) {
                    $ctrl.chartModel.sf_uiModel.chartconfig.groupBy = [groupByFieldName];
                }
            }

            function onLegendColumnConfigChanged(legendColumnConfig) {
                if (legendColumnConfig) {
                    $ctrl.chartModel.sf_uiModel.chartconfig.legendColumnConfiguration =
                        legendColumnConfig;
                }
            }

            function onPlotChange(plot) {
                if (plot) {
                    const idx = $ctrl.chartModel.sf_uiModel.allPlots.findIndex(
                        (obj) => obj.uniqueKey === plot.uniqueKey
                    );
                    $ctrl.chartModel.sf_uiModel.allPlots[idx].invisible = !plot.invisible;
                }
            }

            function hideTimestampChanged(val) {
                $ctrl.chartModel.sf_uiModel.chartconfig.hideTimestamp = val;
            }

            function setTimezone(dataProvider) {
                if ($ctrl.chartModel.sf_uiModel.chartconfig.timezone) {
                    dataProvider.setTimezone($ctrl.chartModel.sf_uiModel.chartconfig.timezone);
                }
            }

            function getMaxDelay() {
                return (
                    $ctrl.maxDelayOverride || $ctrl.chartModel.sf_uiModel.chartconfig.maxDelay || 0
                );
            }

            function onNewDataProvider(dataProvider) {
                if (currentDataProvider) {
                    currentDataProvider.unsubscribe(onDataProviderMessage);
                }
                currentDataProvider = dataProvider;

                // Cache dashboard charts (but exclude chartBuilder from caching)
                if (featureEnabled('cacheJobsForCharts') && !$ctrl.inEditor) {
                    currentDataProvider.setUseCache(true);
                }

                currentDataProvider.setFilterAlias($ctrl.filterAlias);
                currentDataProvider.subscribe(onDataProviderMessage);
                currentDataProvider.setImmediate($ctrl.isStandalone);
                if (!$ctrl.snapshot || !$ctrl.snapshot.id) {
                    currentDataProvider.setComputingFor($ctrl.chartModel.sf_id);
                }
                $ctrl.chartDisplayDebouncer = dataProvider.getChartDisplayDebouncer();
                $ctrl.chartDisplayDebouncer.registerListener(angular.noop, function () {
                    $scope.$broadcast(CHART_DISPLAY_EVENTS.RESTART_PROGRESS);
                });

                $ctrl.chartDisplayDebouncer.setEnabled(
                    $ctrl.inEditor && !featureEnabled('disableBigChartDebouncer')
                );
                currentDataProvider.setMaxDelayMs(getMaxDelay());
                setTimezone(currentDataProvider);

                updateFilters();
                regenerateSignalFlow();
                dataProvider.init();
            }

            function coerceValueToList(value) {
                if (value && !angular.isArray(value)) {
                    return [value];
                } else {
                    return value;
                }
            }

            function updateScopeFilters() {
                const filterAliases = $ctrl.filterAlias || [];
                const filterOverrides = chartUtils.getChartOverrides(
                    filterAliases.filter((f) => {
                        return !f.replaceOnly;
                    })
                );
                const replaceOnlyFilterOverrides = chartUtils.getChartOverridesCustom(
                    filterAliases.filter((f) => {
                        return f.replaceOnly;
                    }),
                    []
                );

                $ctrl.filters = filterOverrides
                    .map(function (filt) {
                        return {
                            property: filt.key,
                            propertyValue: filt.value,
                            applyIfExists: filt.applyIfExists,
                            NOT: filt.not,
                        };
                    })
                    .concat(($ctrl.getFilterOverrides ? $ctrl.getFilterOverrides() : []) || []);

                $ctrl.replaceOnlyFilters = replaceOnlyFilterOverrides.map(function (filt) {
                    return {
                        property: filt.key,
                        propertyValue: filt.value,
                        applyIfExists: filt.applyIfExists,
                        NOT: filt.not,
                    };
                });
            }

            function resetShowNoDataMessage() {
                $ctrl.showNoDataMessage = false;
            }

            function updateFilters() {
                updateScopeFilters();
                if (currentDataProvider) {
                    const filterOnlyStr =
                        sourceFilterService.translateSourceFilterObjectsToFilterBlock(
                            $ctrl.replaceOnlyFilters
                        );
                    currentDataProvider.setFilter(
                        sourceFilterService.translateSourceFilterObjectsToFilterBlock($ctrl.filters)
                    );
                    currentDataProvider.setReplaceOnlyFilter(filterOnlyStr);
                }

                const filterAliases = $ctrl.filterAlias || [];
                const filterOverrides = chartUtils.getChartOverrides(
                    filterAliases.filter((f) => {
                        return !f.replaceOnly;
                    })
                );
                const replaceOnlyFilterOverrides = chartUtils.getChartOverridesCustom(
                    filterAliases.filter((f) => {
                        return f.replaceOnly;
                    }),
                    []
                );
                if (smokeyStateProvider) {
                    const filters = filterOverrides
                        .map(function (filt) {
                            return {
                                property: filt.key,
                                values: coerceValueToList(filt.value),
                                not: filt.not,
                            };
                        })
                        .concat(($ctrl.getFilterOverrides ? $ctrl.getFilterOverrides() : []) || []);

                    const replaceOnlyFilters = replaceOnlyFilterOverrides.map(function (filt) {
                        return {
                            property: filt.key,
                            values: coerceValueToList(filt.value),
                            not: filt.not,
                        };
                    });
                    smokeyStateProvider.setFilters(filters);
                    smokeyStateProvider.setReplaceOnlyFilters(replaceOnlyFilters);
                }

                if ($ctrl.showNoDataMessage === true) {
                    resetShowNoDataMessage();
                }

                regenerateSignalFlow();
            }

            function jobMessagesReceived(msgs) {
                jobFeedbackMessages = msgs;
                $ctrl.jobMessageSummary = chartDisplayUtils.jobMessagesReceived(
                    msgs,
                    $ctrl.chartModel
                );
                // someday you'll need identifier here as the 3rd arg. Not yet, since detectors only use graphs.
                $scope.$emit(
                    CHART_DISPLAY_EVENTS.JOB_RESOLUTION_DETERMINED,
                    $ctrl.jobMessageSummary.primaryJobResolution
                );
                $scope.$emit(
                    CHART_DISPLAY_EVENTS.JOB_FETCH_CAPS,
                    $ctrl.jobMessageSummary.jobFetchCaps
                );
                $scope.$emit(
                    CHART_DISPLAY_EVENTS.CHART_WINDOW_MISALIGNED,
                    $ctrl.jobMessageSummary.misalignedResolution
                );

                if ($ctrl.jobMessageSummary) {
                    $ctrl.chartRollupMessage = $ctrl.jobMessageSummary.chartRollupMessage;
                    if (angular.isUndefined($ctrl.chartRollupMessage)) {
                        $ctrl.chartRollupMessage = 'determining rollup...';
                    }
                    $scope.$emit(
                        CHART_DISPLAY_EVENTS.CHART_ROLLUP_DETERMINED,
                        $ctrl.chartRollupMessage
                    );
                }

                if (!$ctrl.isOriginallyV2) {
                    $ctrl.chartDisplayDebouncer.latestJobStats(
                        $ctrl.jobMessageSummary.passThruCount
                    );
                } else {
                    $ctrl.chartDisplayDebouncer.latestJobStats(
                        currentDataProvider.getNumTimeseries()
                    );
                }

                $scope.$emit(CHART_DISPLAY_EVENTS.JOB_FEEDBACK_RECEIVED, msgs);
            }

            const unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                ['sources[]', 'variables[]'],
                updateFilters.bind(this)
            );

            function onDataProviderMessage(msg) {
                if ((msg.type === 'init') !== $ctrl.jobInProgress) {
                    //TODO cant force digests here.  may have to manually update dom visibility
                    $ctrl.jobInProgress = msg.type === 'init';
                    $ctrl.numMTS = 0;
                    $ctrl.jobStartErrors = null;
                }

                if (msg.type === 'feedback') {
                    $scope.$emit(CHART_DISPLAY_EVENTS.JOB_FEEDBACK_RECEIVED, msg.data);

                    msg.data.forEach(function (msg) {
                        if (msg.messageCode === 'JOB_ASSIMILATED_FILTERS') {
                            $scope.$emit(
                                CHART_DISPLAY_EVENTS.REPLACED_FILTER_REPORT,
                                msg.contents.replaceOnlyTermsReplaced
                            );
                        }
                    });

                    jobMessagesReceived(msg.data);
                    recomputeKeysetDebounced();

                    if ($ctrl.chartNoDataService) {
                        $ctrl.chartNoDataService.onFeedback(msg.data);
                    }
                }

                if (msg.type === 'error') {
                    $ctrl.jobStartErrors = {
                        chartId: $ctrl.chartModel.sf_id || $ctrl.snapshot.id || '',
                        chartName: $ctrl.chartModel.sf_chart || '',
                        jobId: currentDataProvider.getJobId(),
                        errors: [msg.data],
                    };
                }
            }

            function $onDestroy() {
                unregisterRouteWatchGroup();
                if (currentDataProvider) {
                    currentDataProvider.destroy();
                }

                if (smokeyStateProvider) {
                    smokeyStateProvider.destroy();
                }
            }

            $scope.$on('disableChartRefresh', function () {
                $ctrl.stopRefresh = true;
            });

            $scope.$on('enableChartRefresh', function () {
                $ctrl.stopRefresh = false;
            });

            $scope.showJobStartErrors = function () {
                sfxModal.open({
                    templateUrl: jobStartErrorDetailsTemplateUrl,
                    controller: [
                        '$scope',
                        'details',
                        'SUPPORT_EMAIL',
                        'PRODUCT_NAME',
                        'featureEnabled',
                        function ($scope, details, SUPPORT_EMAIL, PRODUCT_NAME, featureEnabled) {
                            $scope.details = details;
                            $scope.SUPPORT_EMAIL = SUPPORT_EMAIL;
                            $scope.PRODUCT_NAME = PRODUCT_NAME;
                            $scope.supportSwitch = featureEnabled('supportSwitch');
                        },
                    ],
                    resolve: {
                        details: function () {
                            return $ctrl.jobStartErrors;
                        },
                    },
                });
            };

            //we only need this in editMode, technically
            function $doCheck() {
                if (currentDataProvider) {
                    currentDataProvider.setMaxDelayMs(getMaxDelay());
                    setTimezone(currentDataProvider);
                    currentDataProvider.setSampleSize(
                        chartDisplayUtils.getSampleRate($ctrl.chartModel)
                    );
                }

                if ($ctrl.isOriginallyV2) {
                    //i'd prefer to bind this direct to children.. but this will suffice
                    regenerateSignalFlow();
                } else {
                    const plots = $ctrl.chartModel.sf_uiModel.allPlots;
                    //stringify might be cheaper?
                    if (!oldPlots) {
                        oldPlots = angular.copy(plots);
                    } else {
                        if (!angular.equals(oldPlots, plots)) {
                            $ctrl.plotDataGeneration++;
                            regenerateSignalFlow();
                            oldPlots = angular.copy(plots);
                        }
                    }
                }
            }

            function recomputeKeysetDebounced() {
                $timeout.cancel(recomputeKeySetDelay);
                recomputeKeySetDelay = $timeout(function () {
                    if (!currentDataProvider) {
                        return;
                    }
                    $scope.$emit(CHART_DISPLAY_EVENTS.LEGEND_KEYS_RECOMPUTED, recomputeKeyset());
                }, 1000);
            }

            function recomputeKeyset() {
                const tsidToPlot = {};
                const mdm = currentDataProvider.getMetaDataMap();
                angular.forEach(mdm, (metaData, tsid) => {
                    tsidToPlot[tsid] = chartDisplayUtils.getPlotObject(mdm[tsid], $ctrl.chartModel);
                });
                return chartDisplayUtils.getLegendKeys(mdm, tsidToPlot);
            }

            function reassertModelVersion() {
                if (angular.isDefined($ctrl.chartModel.$isOriginallyV2)) {
                    $ctrl.isOriginallyV2 = $ctrl.chartModel.$isOriginallyV2;
                } else {
                    $ctrl.isOriginallyV2 =
                        $ctrl.chartModel.sf_modelVersion === 2 ||
                        $ctrl.chartModel.sf_flowVersion === 2;
                }
            }

            function $onInit() {
                $ctrl.jobMessageSummary = {};
                $ctrl.isPreview = chartUtils.isPreviewChart($ctrl.chartModel);
                $ctrl.plotColors = colorAccessibilityService.get().getPlotColors();
                $ctrl.chartRollupMessage = 'determining rollup...';

                updateScopeFilters();
                reassertModelVersion();
                regenerateSignalFlow();
                initializeChartNoDataService();
                $scope.$emit(CHART_DISPLAY_EVENTS.CHART_DISPLAY_INITIALIZED);
            }

            function getTransientModel(chartModel) {
                const programTextSource = angular.copy(chartModel);
                if ($ctrl.disableUrl) {
                    return programTextSource;
                }
                chartDisplayUtils.applyUrlStateToModel(programTextSource, $ctrl.filterAlias);
                programTextUtils.refreshProgramText(programTextSource);
                return programTextSource;
            }

            function smokeyStateUpdated(alertState) {
                $scope.$emit('alert state updated', alertState);
                if (alertState) {
                    $ctrl.alertState = alertState;
                } else {
                    $ctrl.alertState = null;
                }
            }

            function initializeChartNoDataService() {
                // Do not show the chart no data message if you are in the editor
                if (
                    !$ctrl.inEditor &&
                    ChartNoDataService.shouldUseChartNoDataService($ctrl.chartModel)
                ) {
                    $ctrl.chartNoDataService = new ChartNoDataService($ctrl.chartModel, () => {
                        $ctrl.showNoDataMessage = true;
                    });
                }
            }

            /* unscoped function definitions */

            function regenerateSignalFlow() {
                if (!$ctrl.stopRefresh) {
                    let signalFlowToStream;
                    let programTextSource;
                    programTextSource = $ctrl.chartModel;

                    if (!$ctrl.isOriginallyV2) {
                        programTextSource = getTransientModel($ctrl.chartModel);
                        signalFlowToStream = programTextUtils.getV2ProgramText(
                            programTextSource.sf_uiModel,
                            !$ctrl.inEditor
                        );
                    } else {
                        signalFlowToStream = $ctrl.chartModel.sf_viewProgramText;
                    }
                    //v2 chart signalflow is handled via $onChange
                    $ctrl.signalFlowToStream = signalFlowToStream;
                    if ($ctrl.sharedChartState) {
                        $ctrl.sharedChartState.lastStreamedSignalflow = signalFlowToStream;
                    }

                    if (currentDataProvider) {
                        currentDataProvider.setSignalFlowText($ctrl.signalFlowToStream);
                    }

                    if (smokeyStateProvider) {
                        smokeyStateProvider.onSignalFlowChanged($ctrl.signalFlowToStream);
                    }
                }
            }

            function addOverlay(overlayMode) {
                if (!$ctrl.overlays) {
                    $ctrl.overlays = {};
                }
                $ctrl.overlays[overlayMode] = true;
            }

            function removeOverlay(overlayMode) {
                if (!$ctrl.overlays) {
                    return;
                }
                delete $ctrl.overlays[overlayMode];
                if (Object.keys($ctrl.overlays).length === 0) {
                    delete $ctrl.overlays;
                }
            }

            $ctrl.shouldShowOverlayArrow = function () {
                return !!$ctrl.overlays && !!$ctrl.overlays[CHART_OVERLAY_MODES.POPOVER];
            };

            function initOverlay(chartWrapperId, attributes, done) {
                // Custom chart component will be listening to this promise
                // so it knows when CHART_OVERLAY_EVENTS.OVERLAY_REMOVED event is triggered for this overlay
                // and the overlayMode is unset for this chart.
                const resolveOnClosed = new Promise(function (onClose) {
                    $scope.$on(
                        CHART_OVERLAY_EVENTS.OVERLAY_REMOVED,
                        function (evt, removedWrapperId, removedOverlayMode) {
                            if (
                                removedWrapperId === chartWrapperId &&
                                removedOverlayMode === attributes.mode
                            ) {
                                removeOverlay(attributes.mode);
                                onClose();
                            }
                        }
                    );
                    // Handle events from chartdisplay charts
                    $rootScope.$on(CHART_DISPLAY_EVENTS.CHART_CLOSE_TOOLTIPS, function () {
                        $rootScope.$broadcast(
                            CHART_OVERLAY_EVENTS.OVERLAY_REMOVE_REQUESTED,
                            chartWrapperId,
                            attributes.mode
                        );
                    });
                });
                addOverlay(attributes.mode);
                $scope.$on(
                    CHART_OVERLAY_EVENTS.OVERLAY_CREATED,
                    function (evt, containerAttributes) {
                        done({
                            domId: containerAttributes.domId,
                            resolveOnClosed,
                            ...containerAttributes,
                        });
                    }
                );
            }

            // Must be provided to the chart wrapper as request-overlay-container
            // if the chart utilizes dashboard overlay feature.
            $ctrl.requestOverlayContainer = (chartWrapperId, attributes) => {
                const resolveOnDomCreated = new Promise(function (resolve) {
                    initOverlay(chartWrapperId, attributes, resolve);
                });
                $rootScope.$broadcast(
                    CHART_OVERLAY_EVENTS.OVERLAY_REQUESTED,
                    $scope,
                    chartWrapperId,
                    attributes,
                    $element[0]
                );
                return resolveOnDomCreated;
            };

            // Must be provided to the chart wrapper as remove-overlay-container
            // if the chart utilizes dashboard overlay feature.
            $ctrl.removeOverlayContainer = function (chartWrapperId, mode) {
                removeOverlay(mode);
                $rootScope.$broadcast(
                    CHART_OVERLAY_EVENTS.OVERLAY_REMOVE_REQUESTED,
                    chartWrapperId,
                    mode
                );
            };
        },
    ],
};
