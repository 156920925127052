export default [
    'crossLinkDataService',
    'CROSS_LINK_EVENTS',
    'crossLinkUtils',
    'routeParameterService',
    'userAnalytics',
    function (
        crossLinkDataService,
        CROSS_LINK_EVENTS,
        crossLinkUtils,
        routeParameterService,
        userAnalytics
    ) {
        return {
            restrict: 'A',
            scope: {
                dimensions: '<?crossLinkAnchorDimensions',
                context: '<?crossLinkAnchorContext',
                propertyName: '<?crossLinkAnchorPropertyName',
                propertyValue: '<?crossLinkAnchorPropertyValue',
            },
            bindToController: true,
            controller: [
                '$scope',
                '$element',
                function ($scope, $element) {
                    const $ctrl = this;
                    const HAS_CROSS_LINK_CLASS = 'has-cross-link';
                    let hasBoundEvents = false;
                    let deregisterRouteWatch;
                    let urlFiltersContext = null;

                    $ctrl.$onChanges = $onChanges;
                    $ctrl.$onDestroy = $onDestroy;
                    $ctrl.$onInit = $onInit;

                    const unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                        ['sources[]', 'variables[]'],
                        function (loc, changed) {
                            if (!_.isEmpty(changed)) {
                                urlFiltersContext =
                                    crossLinkDataService.getContextFromURLOverrides();
                                getCrossLinkAndSetHref();
                            }
                        }
                    );

                    function $onChanges(changesObj) {
                        const { dimensions, propertyName, propertyValue, context } = changesObj;

                        if (dimensions || propertyName || propertyValue) {
                            urlFiltersContext = crossLinkDataService.getContextFromURLOverrides();
                            setDimension();
                            getCrossLinkAndSetHref();
                        }

                        if (context) {
                            getCrossLinkAndSetHref();
                        }
                    }

                    function $onDestroy() {
                        unbindEvents();
                        unregisterRouteWatchGroup();
                    }

                    function $onInit() {
                        $scope.$on(CROSS_LINK_EVENTS.INVALIDATED_CACHE, () => {
                            setDimension();
                            getCrossLinkAndSetHref();
                        });

                        setDimension();
                        getCrossLinkAndSetHref();
                    }

                    function setDimension() {
                        if ($ctrl.dimensions) {
                            $ctrl.dimension = angular.isArray($ctrl.dimensions)
                                ? $ctrl.dimensions[0]
                                : $ctrl.dimensions;
                        } else if ($ctrl.propertyName) {
                            $ctrl.dimension = {
                                propertyName: $ctrl.propertyName,
                                propertyValue: $ctrl.propertyValue,
                            };
                        }
                    }

                    function getCrossLinkAndSetHref() {
                        if ($ctrl.dimension) {
                            const { propertyName, propertyValue } = $ctrl.dimension;

                            const extendedContext = crossLinkDataService.getExtendedContext(
                                $ctrl.dimensions || [$ctrl.dimension],
                                null,
                                urlFiltersContext,
                                $ctrl.context
                            );

                            crossLinkDataService
                                .getInternalLinkByTrigger(
                                    propertyName,
                                    propertyValue,
                                    extendedContext
                                )
                                .then((crossLink) => {
                                    if (crossLink && crossLink.defaultLink) {
                                        $ctrl.crossLink = crossLink;
                                        $ctrl.defaultLink = crossLink.defaultLink;

                                        setHref();
                                        $element.addClass(HAS_CROSS_LINK_CLASS);

                                        bindEvents();
                                    } else {
                                        $element.removeClass(HAS_CROSS_LINK_CLASS);
                                        $element.removeAttr('href');
                                        $element.removeAttr('title');
                                    }
                                });
                        }
                    }

                    function unbindEvents() {
                        if (hasBoundEvents) {
                            $element.off('click');
                            deregisterRouteWatch();

                            hasBoundEvents = false;
                        }
                    }

                    function bindEvents() {
                        if (!hasBoundEvents) {
                            // if cross link has default target, it will need to be updated
                            // when url params change.
                            deregisterRouteWatch = routeParameterService.registerRouteWatchGroup(
                                [
                                    'startTime',
                                    'endTime',
                                    'startTimeUTC',
                                    'endTimeUTC',
                                    'sources[]',
                                    'variables[]',
                                ],
                                () => {
                                    setHref();
                                }
                            );

                            // only attaching track-click here in order to avoid counting
                            // clicks on things without bound cross links.
                            $element.on('click', handleClick);

                            hasBoundEvents = true;
                        }
                    }

                    function handleClick() {
                        userAnalytics.event('click', 'cross-link-anchor');
                    }

                    function setHref() {
                        const href = crossLinkUtils.getRedirectHref(
                            $ctrl.crossLink,
                            $ctrl.dimension.propertyValue,
                            $ctrl.defaultLink
                        );

                        $element.attr('href', href);

                        const title = crossLinkUtils.getCrossLinkTitle($ctrl.defaultLink);
                        $element.attr('title', title);
                    }
                },
            ],
        };
    },
];
