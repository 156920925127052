import updateV1p5InfoTemplateUrl from './update/updateV1p5Info.tpl.html';
import detectorSaveTooltipTemplateUrl from '../tooltips/detectorSaveTooltip.tpl.html';
import detectorSettingsPanelTemplateUrl from './detectorSettingsPanel.tpl.html';
import templateUrl from './edit.tpl.html';
import { ngRoute } from '../../../app/routing/ngRoute';

angular
    .module('signalview.detector')
    .service('getNextDetectorDestination', [
        'locationHistory',
        function (locationHistory) {
            return function (orgId) {
                return function () {
                    const maxHistoryBackTrack = 10;

                    let lastUrl = locationHistory.last();
                    let x;
                    for (
                        x = 1;
                        x < maxHistoryBackTrack && lastUrl && lastUrl.indexOf('/detector/') === 0;
                        x++
                    ) {
                        lastUrl = locationHistory.last(x);
                    }

                    if (x < maxHistoryBackTrack && lastUrl && lastUrl.indexOf('/detector/') !== 0) {
                        return lastUrl;
                    } else {
                        return '/detectors/' + (orgId ? orgId : '');
                    }
                };
            };
        },
    ])
    .controller('DetectorEdit', [
        '$scope',
        'sfxModal',
        'chartbuilderUtil',
        '$timeout',
        'globalNavUpdateService',
        '$rootScope',
        'mutingService',
        'CHART_DISPLAY_EVENTS',
        'detectorUtils',
        'getNextDetectorDestination',
        function (
            $scope,
            sfxModal,
            chartbuilderUtil,
            $timeout,
            globalNavUpdateService,
            $rootScope,
            mutingService,
            CHART_DISPLAY_EVENTS,
            detectorUtils,
            getNextDetectorDestination
        ) {
            const $ctrl = this;

            $ctrl.$onInit = () => {
                const { data, updateTitle } = $ctrl.data;

                $scope.detector = data.detector;
                $scope.detectorSaveTooltipTemplateUrl = detectorSaveTooltipTemplateUrl;
                $scope.detectorSettingsPanelTemplateUrl = detectorSettingsPanelTemplateUrl;

                $scope.deleteModalState = {
                    isOpen: false,
                    onCancel: $timeout(function () {
                        $scope.deleteModalState.isOpen = false;
                    }),
                };

                // lazy migration for show threshold flag
                angular.forEach(
                    $scope.detector.sf_uiModel.rules,
                    detectorUtils.setRuleShowThreshold
                );

                // because features enabled is not initialized in time when landed directly on new detector page
                // we are setting the flow version here
                if (!$scope.detector.sf_id && $scope.detector.sf_signalflowVersion === 1) {
                    $scope.detector.sf_signalflowVersion = 2;
                }

                function initMuting(mutings) {
                    $scope.mutings = mutings || [];
                    $scope.mutingStarted = mutingService.isMutingStarted($scope.mutings);
                    $scope.mutingText = mutingService.getMutingText($scope.mutingStarted);
                }

                initMuting(data.mutings);

                $scope.reloadMutings = () => {
                    return mutingService.getListForDetector($scope.detector.sf_id).then(initMuting);
                };

                $scope.openMutingModal = (muting) => {
                    mutingService.openMutingModal(muting, []).then($scope.reloadMutings);
                };

                $scope.deleteMuting = (muting) => {
                    $scope.deleteModalState.onDelete = function () {
                        $scope.$timeout(function () {
                            $scope.deleteModalState.isOpen = false;
                            mutingService.deleteMuting(muting).then($scope.reloadMutings);
                        }, 0);
                    };

                    $scope.deleteModalState.isOpen = true;
                    $scope.deleteModalState.title = 'Delete Muting Rule';
                    $scope.deleteModalState.description =
                        'You are about to delete this muting rule. Are you sure?';
                    $scope.deleteModalState.callToAction = 'Delete';
                };

                $scope.endMuting = (muting) => {
                    $scope.deleteModalState.onDelete = function () {
                        $scope.$timeout(function () {
                            $scope.deleteModalState.isOpen = false;
                            mutingService.endMuting(muting).then($scope.reloadMutings);
                        }, 0);
                    };

                    $scope.deleteModalState.isOpen = true;
                    $scope.deleteModalState.title = 'Resume Notifications';
                    $scope.deleteModalState.description =
                        'You are about to resume notifications. Are you sure?';
                    $scope.deleteModalState.callToAction = 'Resume';
                };

                $scope.getFormattedMutingDurationAndCreator =
                    mutingService.getFormattedDurationAndCreator;
                $scope.getMutingTooltip = mutingService.getMutingTooltip;

                $scope.$watch('detector.sf_detector', function (v) {
                    updateTitle('Detector - ' + (v || 'Untitled Detector'));
                });

                $scope.getNextDestination = getNextDetectorDestination(
                    $scope.detector.sf_organizationID,
                    $scope.detector.sf_id
                );

                $scope.onUpdate = function () {
                    const uiModel = $scope.detector.sf_uiModel;
                    detectorUtils.updateDetectorValidityState(uiModel.rules, uiModel.allPlots);
                };

                function openMetricsSidebar() {
                    $scope.showMetricsSidebar = true;
                }

                $scope.$on('showMetricsSidebar', openMetricsSidebar);

                function hideMetricsSidebar() {
                    $scope.showMetricsSidebar = false;
                }
                $scope.hideMetricsSidebar = hideMetricsSidebar;

                $scope.$on('signalEditorClosed', function () {
                    $scope.showMetricsSidebar = false;
                });

                // Make chart re-render after sidebar is shown or hidden
                $scope.$watch('showMetricsSidebar', function () {
                    $timeout(function () {
                        $rootScope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                    }, 0);
                });

                $scope.$on('plotSignalsSelected', function (evt, signals) {
                    chartbuilderUtil.addPlotsForSignals($scope.detector.sf_uiModel, signals, true);
                });

                $scope.updateDetectorModelVersion = function () {
                    if ($scope.detector.sf_isLocked) {
                        return;
                    }
                    const modalInstance = sfxModal.open({
                        templateUrl: updateV1p5InfoTemplateUrl,
                        controller: 'DetectorUpdateV1p5Controller',
                        size: 'md',
                        resolve: {
                            detector: function () {
                                return $scope.detector;
                            },
                        },
                    });
                    modalInstance.result.then(function () {
                        ngRoute.reload();
                    });
                };

                $scope.needsUpdate = $scope.detector && !detectorUtils.isFlow2($scope.detector);

                globalNavUpdateService.update({
                    object: $scope.detector,
                });
            };
        },
    ])
    .component('detectorRichFormEditor', {
        templateUrl,
        controller: 'DetectorEdit',
        bindings: {
            data: '<',
        },
    });
