export const defaultDataQuickActionDataProvider = [
    'recentPagesService',
    'favoriteService',
    '$q',
    '_',
    'iconUtils',
    'getLinkForDocument',
    'dashboardV2Service',
    'dashboardUtil',
    'dashboardGroupService',
    '$log',
    function (
        recentPagesService,
        favoriteService,
        $q,
        _,
        iconUtils,
        getLinkForDocument,
        dashboardV2Service,
        dashboardUtil,
        dashboardGroupService,
        $log
    ) {
        function hasDashboardGroupAndConfig(dashboard) {
            return dashboard.groupId && dashboard.configId;
        }

        /**
         * Checks if dashboard entry is of the old favorites and recents formats
         * in which is it just the dashboard id itself and if dashboard from dashboardById map
         * exists with a group id
         */
        function isDashboardWithNoGroupAndConfig(dashboardEntry, dashboardById) {
            return (
                !hasDashboardGroupAndConfig(dashboardEntry) &&
                dashboardById[dashboardEntry.dashboardId] &&
                dashboardById[dashboardEntry.dashboardId].groupId
            );
        }

        function getDashboard(dashboardEntry, iconType, dashboardById, dashboardGroupById) {
            const dashboardResult = dashboardById[dashboardEntry.dashboardId];
            if (!dashboardResult) {
                $log.warn('Could not find dashboard', dashboardEntry.dashboardId);
                return null;
            }

            const dashboardGroupResult =
                dashboardGroupById[dashboardEntry.groupId || dashboardResult.groupId];
            if (!dashboardGroupResult) {
                $log.warn(
                    'Could not find dashboard group',
                    dashboardEntry.groupId || dashboardResult.groupId
                );
                return null;
            }

            if (!hasDashboardGroupAndConfig(dashboardEntry)) {
                return {
                    id: dashboardEntry.dashboardId,
                    sf_type: 'Dashboard',
                    dashboardId: dashboardEntry.dashboardId,
                    name: dashboardResult.name,
                    groupName: dashboardGroupResult.name,
                    iconType: iconType,
                };
            }

            let config;
            if (dashboardGroupResult.dashboardConfigs) {
                config = dashboardGroupResult.dashboardConfigs.find(function (config) {
                    return (
                        config.dashboardId === dashboardEntry.dashboardId &&
                        config.configId === dashboardEntry.configId
                    );
                });
            }
            const name = config && config.nameOverride ? config.nameOverride : dashboardResult.name;

            return {
                dashboardId: dashboardEntry.dashboardId,
                name: name,
                groupId: dashboardEntry.groupId,
                configId: dashboardEntry.configId,
                groupName: dashboardGroupResult.name,
                iconType: iconType,
            };
        }

        /**
         * Return map of dashboard id to dashboard object.
         *
         * Note: dashboard object only has notion of an association to a single group. That group
         *       is the original group it was created in.
         */
        function getDashboardById(dashboardEntries) {
            const dashboardById = {};
            const dashboardIds = dashboardEntries
                .map((dashboardEntry) => dashboardEntry.dashboardId)
                .filter((dashboardId) => dashboardId);

            return dashboardV2Service.getAll(dashboardIds, false).then(function (dashboardResults) {
                dashboardResults.forEach(function (dashboard) {
                    dashboardById[dashboard.id] = dashboard;
                });
                return dashboardById;
            });
        }

        /**
         * dashboardEntries represents entries from favorites and recents dashboard objects.
         *
         * dashboardById maps a dashboard id to its dashboard object - this dashboard object
         * has the key groupId tied to the original dashboard group that that dashboard was created
         * (e.g if a dashboard is originally in G1 but is added to other group G2, dashboard.groupId
         * will represent G1)
         */
        function getDashboardGroupById(dashboardEntries, dashboardById) {
            // We want to fetch group ids of dashboard entries from dashboard objects in dashboardById
            // only if the dashboard entry does not already contain the group id. This filter is to
            // avoid unnecessary search of a group.
            //
            // E.g. Consider a dashboard in group G1 added to group G2 - the new format of recents will
            //      accurately indicate dashboard is associated with G2 even though the dashboard
            //      in dashboardById map will only have idea of the original in G1. Thus, we want to
            //      narrow down our search to include only G2 and not G1.
            const dashboardGroupIdsForDashboardsWithNoGroupAndConfig = dashboardEntries
                .filter((dashboardEntry) =>
                    isDashboardWithNoGroupAndConfig(dashboardEntry, dashboardById)
                )
                .map((dashboardEntry) => dashboardById[dashboardEntry.dashboardId].groupId);

            const dashboardGroupIdsForDashboardsWithGroupAndConfig = dashboardEntries
                .filter(
                    (dashboardEntry) =>
                        !isDashboardWithNoGroupAndConfig(dashboardEntry, dashboardById)
                )
                .map((dashboardEntry) => dashboardEntry.groupId);

            // get all group ids from new format as well as group ids from dashboard result
            const groupIds = _.uniq(
                dashboardGroupIdsForDashboardsWithNoGroupAndConfig.concat(
                    dashboardGroupIdsForDashboardsWithGroupAndConfig
                )
            );

            return dashboardGroupService
                .getDashboardGroups(groupIds)
                .then(function (dashboardGroupResults) {
                    return dashboardGroupResults;
                });
        }

        /**
         * dashboardEntries represents entries from favorites and recents dashboard objects
         *
         * Favorites and recents objects in the new format uniquely identifies them and is effectively
         * in the form:
         *   {
         *     dashboardId,
         *     groupId,
         *     configId
         *   }
         *
         * Favorites and recents objects in old format would simply be { dashboardId }
         */
        function getDashboards(dashboardEntries, iconType) {
            return getDashboardById(dashboardEntries).then(function (dashboardById) {
                return getDashboardGroupById(dashboardEntries, dashboardById).then(function (
                    dashboardGroupById
                ) {
                    const allDashboards = [];
                    dashboardEntries.forEach(function (dashboardEntry) {
                        allDashboards.push(
                            getDashboard(
                                dashboardEntry,
                                iconType,
                                dashboardById,
                                dashboardGroupById
                            )
                        );
                    });
                    return allDashboards;
                });
            });
        }

        function mapDashboardToSearchResult(dashboard) {
            let link;
            if (hasDashboardGroupAndConfig(dashboard)) {
                link = dashboardUtil.getDashboardUrl(
                    dashboard.dashboardId,
                    dashboard.groupId,
                    dashboard.configId
                );
            } else {
                link = getLinkForDocument(dashboard);
            }

            return {
                label: dashboard.name,
                secondary: dashboard.groupName,
                type: 'Dashboard',
                img: iconUtils.getIconURL(dashboard.iconType),
                href: link,
            };
        }

        return function () {
            return $q
                .all({
                    favorite: favoriteService.getFavorites(),
                    recent: recentPagesService.getRecentDashboardEntries(),
                })
                .then(function (result) {
                    return $q
                        .all({
                            favoriteDashboards: getDashboards(result.favorite, 'favorite'),
                            recentDashboards: getDashboards(result.recent, 'recent'),
                        })
                        .then(function (result) {
                            const dashboards = result.recentDashboards
                                .concat(result.favoriteDashboards)
                                .filter((dashboard) => dashboard);
                            return _.uniqBy(dashboards.map(mapDashboardToSearchResult), 'href');
                        });
                });
        };
    },
];
