import { useEffect, useState } from 'react';

import CrosslinkService from './crosslinkService';

// Replicates a combined logic of getCrossLinkAndSetHref and setHref in crossLinkAnchor.js as a custom hook
// so it can be reused in React components
export function useCrosslinkHref(dimensions, context) {
    const [href, setHref] = useState(null);
    const [title, setTitle] = useState(null);
    const crosslinkService = CrosslinkService.useInstance();

    useEffect(() => {
        if (dimensions) {
            const dimension = dimensions[0];
            const extendedContext = crosslinkService
                .getDataService()
                .getExtendedContext(dimensions, null, null, context);
            crosslinkService
                .getDataService()
                .getInternalLinkByTrigger(
                    dimension.propertyName,
                    dimension.propertyValue,
                    extendedContext
                )
                .then((crossLink) => {
                    if (crossLink && crossLink.defaultLink) {
                        const crosslinkUtils = crosslinkService.getUtils();

                        const redirectHref = crosslinkUtils.getRedirectHref(
                            crossLink,
                            dimension.propertyValue,
                            crossLink.defaultLink
                        );
                        setHref(redirectHref);

                        const linkTitle = crosslinkUtils.getCrossLinkTitle(crossLink.defaultLink);
                        setTitle(linkTitle);
                    }
                });
        }
    }, [crosslinkService, dimensions, context]);

    return { href, title };
}
