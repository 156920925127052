import templateUrl from './chartDisplay.tpl.html';
import jobStartErrorDetailsTemplateUrl from '../../jobfeedback/jobStartErrorDetails.tpl.html';
import jobStartErrorsAlertTemplateUrl from '../../jobfeedback/jobStartErrorsAlert.tpl.html';
import { convertMSToString, safeLookup } from '@splunk/olly-utilities/lib/sfUtilities/sfUtilities';
import { SIGNALFX_GREEN } from '../../../common/consts';
import {
    convertToDbTimestamp,
    getProgramArgsForDashboardInTime,
} from '../../utils/programArgsUtils';

import { generateTimeSeriesName } from '@splunk/olly-utilities/lib/Timeseries';

export const chartDisplay = [
    '_',
    '$location',
    '$log',
    '$rootScope',
    '$timeout',
    '$window',
    '$q',
    'sfxModal',
    'alertTypeService',
    'axisLabelFormatter',
    'Chart',
    'chartbuilderUtil',
    'CHART_DISPLAY_EVENTS',
    'ChartDisplayOverlayRenderer',
    'chartDisplayUtils',
    'chartEventDataSource',
    'ChartPanningControl',
    'chartUtils',
    'chartVersionService',
    'colorAccessibilityService',
    'colorByValueService',
    'dashboardVariablesService',
    'detectorPriorityService',
    'documentStateService',
    'dyGraphConfigurationGenerator',
    'dyGraphUtils',
    'featureEnabled',
    'valueFormatter',
    'instrumentationService',
    'signalviewMetrics',
    'modelToStartEnd',
    'murmurHash',
    'plotUtils',
    'programTextUtils',
    'routeParameterService',
    'SAMPLE_CONSTANTS',
    'sessionCache',
    'signalStream',
    'signalStreamPreRunner',
    'sourceFilterService',
    'STATIC_RESOURCE_ROOT',
    'themeService',
    'ThresholdCorrelator',
    'timepickerUtils',
    'timeservice',
    'timeZoneService',
    'uiModelToVisualizationOptionsService',
    'urlOverridesService',
    'userAnalytics',
    'ChartDisplayDebounceService',
    'chartSettingsModal',
    'ChartExportService',
    'chartLoadedEvent',
    'SMOKEY_SEVERITY_LEVELS',
    'mappingService',
    'SmokeyStateProvider',
    'DetectorV2SearchService',
    'ChartNoDataService',
    'chartV2Service',
    function (
        _,
        $location,
        $log,
        $rootScope,
        $timeout,
        $window,
        $q,
        sfxModal,
        alertTypeService,
        axisLabelFormatter,
        Chart,
        chartbuilderUtil,
        CHART_DISPLAY_EVENTS,
        ChartDisplayOverlayRenderer,
        chartDisplayUtils,
        chartEventDataSource,
        ChartPanningControl,
        chartUtils,
        chartVersionService,
        colorAccessibilityService,
        colorByValueService,
        dashboardVariablesService,
        detectorPriorityService,
        documentStateService,
        dyGraphConfigurationGenerator,
        dyGraphUtils,
        featureEnabled,
        valueFormatter,
        instrumentationService,
        signalviewMetrics,
        modelToStartEnd,
        murmurHash,
        plotUtils,
        programTextUtils,
        routeParameterService,
        SAMPLE_CONSTANTS,
        sessionCache,
        signalStream,
        signalStreamPreRunner,
        sourceFilterService,
        STATIC_RESOURCE_ROOT,
        themeService,
        ThresholdCorrelator,
        timepickerUtils,
        timeservice,
        timeZoneService,
        uiModelToVisualizationOptionsService,
        urlOverridesService,
        userAnalytics,
        ChartDisplayDebounceService,
        chartSettingsModal,
        chartExportService,
        chartLoadedEvent,
        SMOKEY_SEVERITY_LEVELS,
        mappingService,
        SmokeyStateProvider,
        DetectorV2SearchService,
        ChartNoDataService,
        chartV2Service
    ) {
        return {
            restrict: 'EA',
            priority: 200,
            scope: {
                isDetectorChart: '=?',
                chartModel: '=',
                legendHelper: '=?',
                disableUrl: '=?',
                onFullLoad: '&',
                onInitialize: '&',
                hideTitle: '@?',
                inEditor: '@?',
                sharedChartState: '=?',
                sharedChartConfig: '=?',
                jobFeedback: '=?',
                jobStartErrors: '=?',
                inView: '&?',
                openHref: '@?',
                noHref: '<',
                hideLegend: '=?',
                filters: '=',
                viewOnly: '=?',
                disableTimeModification: '=?',
                filterAlias: '=?',
                maxDelayOverride: '=?',
                sourceOverrides: '=?',
                legendKeys: '=?',
                disableLegend: '=?',
                snapshot: '=?',
                getFilterOverrides: '=?', // note : this is only applying to v2 to fix a critical infra nav bug
                explainIncidentId: '=?',
                isClearEvent: '=?',
                chartConfigOverride: '=?',
                identifier: '@?',
                isDetectorNative: '=?',
                tzPrefHour: '@?',
                isAlertModalV2Chart: '=?',
                detectorPreviewConfig: '=?',
                legendDataTableClassName: '@?',
                legendEventsClassName: '@?',
                legendDataTableParent: '@?',
                legendEventsParent: '@?',
                chartDisplayDebouncer: '<?',
                passChartAndEventInformation: '<?',
                eventOverlayParams: '<?',
                isStandalone: '@?',
                incidentInfo: '<?',
                allowEventPublishes: '@?',
                hoverTooltipAbsolute: '<?',
                detectorAlertStates: '=?',
                disableLegendDropdown: '<?',
                focusedEvent: '<?',
            },
            templateUrl,
            link: function (scope, elem) {
                const chartContainer = angular.element('.sf-chart-target', elem);
                const hoverLine = angular.element('.hover-line', elem);
                const pinPositioner = angular.element('.chartdisplay-overlay-positioner.pin', elem);
                const snapToPresentMsThreshold = 10000;
                const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;
                const isTurbo = !!featureEnabled('turboButton');
                const parentContainers = ['dashboard', 'v2-chart-editor'];
                const MAXIMUM_MAX_DELAY = 15 * 60 * 1000; // 15 mins
                const THRESHOLD_LINE_WIDTH = 2;
                const SELECTION_MOUSE_BUFFER = 5;
                const MINIMUM_SELECTED_TIME_WIDTH = 40;
                const MINIMUM_SELECTED_TIME_HEIGHT = 30;
                const ONCHART_OVERFLOW_BUFFER = 3;
                const HEX_BLACK = '#000000';
                const DEFAULT_HISTOGRAM_COLOR = '#ea1849';
                const UNKNOWN_SOURCE_COLOR = '#0096d0';
                const PLOT_COLORS = colorAccessibilityService.get().getPlotColors();
                // Avoiding plot letter collisions in fallback mode (event overlay plot letter with offset >> 'ZZZZZZ')
                const EVENT_OVERLAY_OFFSET = 1000000000;

                const MAXIMUM_RENDER_CHECK_INTERVAL = 5000;
                const TIMEOUT_DELAY_MULTIPLIER = 10;
                const CHART_RENDER_DELAY_MULTIPLIER = 3;

                // throttle and then debounce this call as debounce timer setup/teardown is expensive at scale
                const throttledDebouncedKeyRecompute = _.throttle(recomputeKeysetDebounced, 200, {
                    trailing: true,
                });
                const throttledDebouncedJobProcessing = _.throttle(
                    debouncedJobMessagesReceived,
                    200,
                    { trailing: true }
                );
                const smokeyStateProvider = scope.isAlertModalV2Chart
                    ? null
                    : new SmokeyStateProvider(smokeyStateUpdated);

                // For chart loaded event to take screenshot
                // We want to wait for all the defer to resolve
                const chartLoadPromises = {
                    loadOnChartLegendDefer: $q.defer(),
                    loadEventDefer: $q.defer(),
                    loadDataDefer: $q.defer(),
                };

                const features = {
                    disableBigChartDebouncer: featureEnabled('disableBigChartDebouncer'),
                    disableBrowserProtectionMode: featureEnabled('disableBrowserProtectionMode'),
                };

                let tsidToColorCache = {};
                let isOriginallyV2;
                let preTransformedModel;
                // force v1 jobs if it's for an incident Id, unless feature flag to explain is enabled
                let isPreflight = false;
                let preflightAvailableFrom = null;
                let relatedThresholdService = null;
                let positionCache = {};
                let currentJobMidPoint = null;
                let onChartLegendDebounce = null;
                let hoverTooltipTimeout = null;
                let lastPoint = null;
                let overlayRenderer = null;
                let checkDataIntervalId;
                let offScreenLegendLineUpdateTimeout;
                let jobTimer;
                let panningController;
                let panDirection = null;
                let panAxisExtent = null;
                let jobAttempts = [];
                let byValColor;
                let updateOptionQueue = [];
                let renderScoreDebounce = null;
                let alwaysVisibleThresholdTsids = [];
                let preflightNotAvailableTooltipShown = false;
                let isSelectedTimeHovered = false;
                let thresholdTimeout = null;
                let unregisterRouteWatchGroup = null;
                let dragPerformedRecently = false;
                let jobMessageProcessTimeout = null;
                let selectedTimeView;
                let selectedTimeLink;
                let percentTooltip;
                let percentTooltipTextContainer;
                let preflightNotAvailableTooltipTextContainer;
                let mouseDownOnSelectedTime;
                let selectedTimeRange;
                let preflightTimestamp;
                let preflightPercent;
                let pinAfterLoad = null;
                let newDataCheckerKickOff;
                let hasInitialized = false;
                let plotColorPreviouslyOverriden;
                let showThreshold;
                let hasFiredChartLoadsSucceededEvent = false;

                scope.jobStartErrorsAlertTemplateUrl = jobStartErrorsAlertTemplateUrl;
                scope.jobMessageSummary = {};
                scope.receivedMaxDelay = 0;
                scope.dyGraphInstance = null;
                scope.legendDisabled = true;
                scope.jobFeedback = [];
                scope.hideHoverTooltip = true;
                scope.defaultChartTitle = 'Untitled Chart';
                scope.STATIC_RESOURCE_ROOT = STATIC_RESOURCE_ROOT;
                scope.highlightAllowed = false;
                scope.batchLoadCompleted = false;
                scope.chartRollupMessage = 'determining rollup...';

                scope.getChartConfig = getChartConfig;
                scope.clickChart = clickChart;
                scope.legendRowHighlighted = legendRowHighlighted;
                scope.panStart = panStart;
                scope.panStop = panStop;
                scope.panCancel = panCancel;
                scope.setLegendPinByDomClick = setLegendPinByDomClick;
                scope.showFullLegend = showFullLegend;
                scope.setLegendPin = setLegendPin;
                scope.resetLegendPin = resetLegendPin;
                scope.getRenderRatio = getRenderRatio;
                scope.mouseLeave = mouseLeave;
                scope.shouldRender = shouldRender;
                scope.updateDataNow = updateDataNow;
                scope.preventDataUpdates = preventDataUpdates;
                scope.onEnterDebounceArea = onEnterDebounceArea;
                scope.onLeaveDebounceArea = onLeaveDebounceArea;
                scope.showJobStartErrors = showJobStartErrors;
                scope.updateHeatmapPlot = updateHeatmapPlot;
                scope.heatmapContainer = getHeatmapContainer;
                scope.showNoDataMessage = false;
                scope.themeKey = themeService.getColorScheme();
                $rootScope.$on('theme update', function () {
                    scope.themeKey = themeService.getColorScheme();
                });

                initialize();
                function initialize() {
                    $q.all([
                        chartLoadPromises.loadOnChartLegendDefer.promise,
                        chartLoadPromises.loadEventDefer.promise,
                        chartLoadPromises.loadDataDefer.promise,
                    ]).then(() => chartLoadedEvent());

                    reassertModelVersion();
                    initializeChartDisplayDebouncer();
                    preTransformedModel = angular.copy(scope.chartModel);
                    // ensure selected time overlay is only shown for detectors
                    scope.displaySelectedTimeOverlay =
                        !scope.isDetectorNative && scope.chartModel.sf_type === 'Detector';

                    scope.instrumentation = {
                        getInstrumentationMetrics:
                            !scope.chartModel ||
                            (safeLookup(scope, 'chartModel.sf_uiModel.allPlots') || []).length > 1,
                    };
                    if (scope.focusedEvent) {
                        scope.tzPrefHour = timeZoneService
                            .moment(scope.focusedEvent.timestamp)
                            .format('HH:mm:ss');
                    }
                    scope.snapshot = scope.snapshot || {};
                    // if sharedChartState is not provided, then pause and other functionality will be local
                    scope.sharedChartState = scope.sharedChartState || {};
                    // is a preview chart, created on the fly for quick previews such as in catalog, not persistent
                    scope.isPreview = chartUtils.isPreviewChart(scope.chartModel);
                    initializeLoadingState();
                    resetDyGraph();

                    if (scope.chartModel && hasId()) {
                        setCachedModel();
                    }

                    scope.dyGraphInstance = new Dygraph(
                        chartContainer[0],
                        scope.dyGraph.file,
                        getDyGraphConfig()
                    );

                    overlayRenderer = new ChartDisplayOverlayRenderer(scope.dyGraphInstance);
                    angular.element('.sf-ui').on('tooltipMouseMove', nonAngularTooltipMove);
                    angular.element(chartContainer).on('click', chartClickPin);
                    updateEventQueryState();
                    scope.plotUniqueKeyToPlotIndex = getPlotUniqueKeyToPlotIndexMap();
                    updateHasHeatMap();
                    applyUrlState();
                    initializeChartNoDataService();
                    checkInitializationNeeded();

                    initializeEventListeners();
                    initializeWatchers();

                    if ($location.path().match(/chart/)) {
                        signalviewMetrics.endRouteUi('chart');
                    }

                    scope.$emit(CHART_DISPLAY_EVENTS.CHART_DISPLAY_INITIALIZED);
                    scope.$emit(
                        CHART_DISPLAY_EVENTS.INCREMENT_CHART_LOADS_EXPECTED,
                        scope.$id,
                        getId()
                    );

                    if (scope.displaySelectedTimeOverlay) {
                        $timeout(function () {
                            selectedTimeView = angular.element('.chart-selected-time', elem);
                            selectedTimeLink = angular.element('.chart-selected-time-link', elem);
                            percentTooltip = angular.element('.preflight-percent', elem);
                            percentTooltipTextContainer = angular.element('.text', percentTooltip);
                            preflightNotAvailableTooltipTextContainer = angular.element(
                                '.preflight-not-available-container',
                                elem
                            );
                        }, 0);
                    }
                }

                function initializeEventListeners() {
                    scope.$on('disableChartRefresh', () => (scope.stopRefresh = true));
                    scope.$on('enableChartRefresh', () => (scope.stopRefresh = false));
                    scope.$on('export current chart', exportChartAsCsv);
                    scope.$on('export event chart', exportChartEventsAsJson);
                    scope.$on('show chart info', showChartInfo);
                    // Show/hide vertical line at timestamp of event from tile
                    scope.$on('eventTileMouseEnter', onEventTileMouseEnter);
                    scope.$on('eventTileMouseLeave', onEventTileMouseLeave);
                    scope.$on('dashboardMouseUp', (e, payloadEvent) =>
                        graphMouseUp(payloadEvent, null)
                    );
                    scope.$on('chart settings modified', updateAllOptions);
                    scope.$on(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, (e, immediate) =>
                        resize(!immediate)
                    );
                    scope.$on('updateSeriesNames', updatePlotNames);
                    scope.$on('setCachedChartModels', setCachedCharts);
                    scope.$on('preflight update event', updatePreflightEvent);
                    scope.$on('get events and metadata with timestamp', getEventsForChart);
                    scope.$on('$destroy', onDestroy);
                    scope.$on('new global event', refreshEventStreamWithDelay);
                    scope.$on('edit global event', refreshEventStreamWithDelay);
                    scope.$on('delete global event', refreshEventStreamWithDelay);
                    scope.$on('fullscreen reload charts', () =>
                        updateData(scope.chartModel.sf_uiModel)
                    );
                    scope.$on('builder mode swap', () => updateData(scope.chartModel.sf_uiModel));
                    scope.$on('dashboardScrolled', checkInitializationNeeded);
                    // when plot is re-sequenced, plotKeyToInfoMap needs to be updated since the plot key changed
                    scope.$on('update plot expression key', () =>
                        jobMessagesReceived(scope.jobFeedback)
                    );
                    scope.$on('preflight chart update', preflightChartUpdate);
                    scope.$on('updateVariables', onJobRequested);

                    scope.$on('initialize on chart legend', setOnChartLegend);
                    scope.$on(
                        'on chart legend get highest cardinality dimension',
                        function (ev, onChartLegendOptions) {
                            onChartLegendOptions.highestCardinalityDimension =
                                getHighestCardinalityDimension();
                        }
                    );

                    if (scope.displaySelectedTimeOverlay) {
                        scope.$on('window-resized', () =>
                            $timeout(calculateSelectedTimeOverlay, 100)
                        );
                        scope.$on('chart selected time overlay', function (ev, config) {
                            scope.currentSelectedTime = config;
                            calculateSelectedTimeOverlay();
                        });
                    } else {
                        scope.$on(CHART_DISPLAY_EVENTS.LEGEND_TAB_SELECTED, function (ev, tab) {
                            const timestamp = scope.sharedChartState.pinnedTimeStamp || null;
                            scope.setLegendPin(timestamp, true);
                            scope.$broadcast(CHART_DISPLAY_EVENTS.SET_LEGEND_CONTENT, tab);
                        });
                    }

                    if (scope.isDetectorNative) {
                        scope.$on('chart pin after load', onChartPinLoad);
                    } else {
                        scope.$on('chart reinit event streaming', initializeEventStreaming);
                    }
                }

                function initializeWatchers() {
                    scope.$watch('chartModel.sf_uiModel.chartMode', onChartModeUpdate);
                    scope.$watch('sharedChartState.pinnedTimeStamp', onPinnedTimeUpdate);
                    scope.$watch(isPaused, onPausedStateUpdate);
                    scope.$watch(
                        'sharedChartState.mouseHoverTimestamp',
                        onMouseHoverTimestampUpdate
                    );
                    scope.$watch('intermediateDragTime', onDragChartTime);
                    scope.$watch('eventOverlayParams', onEventOverlayUpdate);

                    scope.$watch('chartModel.sf_uiModel.chartconfig.showLegend', function () {
                        $timeout(function () {
                            resize();
                            adjustOnChartLegendOverflow();
                        });
                    });

                    scope.$watch('hasHeatMap', function () {
                        updateModeMixin();
                        updateLegendValues();
                    });

                    scope.$watchGroup(
                        [
                            'legendKeys',
                            'chartModel.sf_uiModel.chartconfig.legendColumnConfiguration',
                        ],
                        onLegendUpdate
                    );

                    scope.$watchGroup(
                        [
                            'eventStreamQuery',
                            'eventProgram.program.programText',
                            'eventProgram.additionalFilters',
                            'eventProgram.additionalReplaceOnlyFilters',
                        ],
                        initializeEventStreaming
                    );

                    initializeRouteWatchers();

                    if (!scope.inEditor) {
                        scope.$watch('maxDelayOverride', function (newVal, oldVal) {
                            if (angular.equals(newVal, oldVal)) {
                                return;
                            }

                            refreshChart();
                        });
                        scope.$watch(
                            'chartModel.sf_uiModel.allPlots',
                            function (nval, oval) {
                                if (!nval || angular.equals(nval, oval)) {
                                    return;
                                }
                                onChartAllPlotsChange(nval, oval);
                                programTextUtils.refreshProgramText(scope.chartModel);
                                updateRenderScore();
                                refreshChart();
                            },
                            true
                        );
                    } else {
                        initializeEditorOnlyWatchers();
                    }
                }

                function initializeEditorOnlyWatchers() {
                    scope.$watch('chartModel.sf_uiModel.chartconfig.colorByMetric', updateColors);
                    scope.$watch('chartModel.sf_uiModel.chartconfig.useKMG2', updateUseKMG2);
                    scope.$watch(
                        'chartModel.sf_uiModel.chartconfig.bucketCount',
                        updateBucketCount
                    );

                    scope.$watch('chartModel.sf_uiModel.chartType', onChartTypeUpdate);

                    scope.$watchGroup(
                        [
                            'chartModel.sf_uiModel.chartconfig.stackedChart',
                            'chartModel.sf_uiModel.chartType',
                        ],
                        updateStackState
                    );

                    scope.$watchGroup(
                        [
                            'chartModel.sf_uiModel.chartconfig.disableThrottle',
                            'chartModel.sf_uiModel.chartconfig.forcedResolution',
                            'chartModel.sf_uiModel.chartconfig.maxDelay',
                            'chartModel.sf_uiModel.chartconfig.timezone',
                            'chartModel.sf_uiModel.chartconfig.updateInterval',
                            'chartModel.sf_uiModel.chartconfig.pointDensity',
                            'chartModel.sf_viewProgramText',
                            'chartModel.sf_uiModel.chartconfig.chartMode',
                            'chartModel.sf_uiModel.chartconfig.absoluteStart',
                            'chartModel.sf_uiModel.chartconfig.absoluteEnd',
                            'chartModel.sf_uiModel.chartconfig.range',
                            'chartModel.sf_uiModel.chartconfig.rangeEnd',
                            'chartConfigOverride.range',
                            'chartConfigOverride.endAt',
                            'chartConfigOverride.absoluteStart',
                            'chartConfigOverride.absoluteEnd',
                            'sourceOverrides',
                            'detectorPreviewConfig.preview',
                        ],
                        function (newValues, oldValues) {
                            showThreshold = shouldShowThreshold();
                            updateChartPreview(newValues, oldValues);
                        }
                    );

                    scope.$watchGroup(
                        [
                            'chartModel.sf_uiModel.chartType',
                            'chartModel.sf_uiModel.chartconfig.colorByMetric',
                            'chartModel.sf_uiModel.chartconfig.colorByValue',
                            'chartModel.sf_uiModel.chartconfig.axisPrecision',
                            'chartModel.sf_uiModel.chartconfig.useKMG2',
                            'chartModel.sf_uiModel.chartconfig.stackedChart',
                            'chartModel.sf_uiModel.chartconfig.includeZero',
                            'chartModel.sf_uiModel.chartconfig.showDots',
                            'chartModel.sf_uiModel.chartconfig.verticalLines',
                        ],
                        function () {
                            updateRenderScore();
                            updateAllOptions();
                            //this is really only needed for axisPrecision...  strange dygraph bug where
                            //the area left of the y axis is no longer cleaned up when the width changes
                            scope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE, true);
                        }
                    );

                    scope.$watch('chartModel.sf_uiModel.chartconfig.eventLines', () => {
                        updateAllOptions();
                        redrawEventHistogram();
                    });

                    scope.$watchCollection(
                        'chartModel.sf_uiModel.chartconfig.colorByValueScale',
                        updateAllOptions
                    );

                    scope.$watch(
                        'chartModel.sf_uiModel.chartconfig.colorByValue',
                        function (newVal) {
                            if (!newVal) {
                                byValColor = null;
                            }
                            updateAllOptions();
                        }
                    );

                    scope.$watch('chartModel.sf_uiModel.allPlots', onChartAllPlotsChange, true);
                    scope.$watch('chartModel.sf_uiModel.allPlots', function () {
                        for (const tsid in scope.dyGraphMaps.tsidToPlot) {
                            delete scope.dyGraphMaps.tsidToPlot[tsid];
                            updateTSIDMap(tsid);
                        }
                    });

                    scope.$watch(
                        'chartModel.sf_uiModel.chartconfig.yAxisConfigurations',
                        function yAxisChanged() {
                            updateAllOptions();
                            resize();
                        },
                        true
                    );

                    scope.$watch('chartModel.sf_uiModel.chartconfig.histogramColor', function () {
                        if (isHistogram()) {
                            updateHistogramDygraphColor();
                        }
                    });
                }

                function initializeRouteWatchers() {
                    // Start watching after route updates due to initializations have finished
                    if (unregisterRouteWatchGroup) {
                        unregisterRouteWatchGroup();
                    }

                    $timeout(
                        function () {
                            const routesToWatch = [
                                'density',
                                'startTime',
                                'endTime',
                                'startTimeUTC',
                                'endTimeUTC',
                                'resolutionAdjustable',
                            ];
                            routesToWatch.push('sources[]');
                            routesToWatch.push('variables[]');

                            unregisterRouteWatchGroup =
                                routeParameterService.registerRouteWatchGroup(
                                    routesToWatch,
                                    function () {
                                        if (!scope.inEditor) {
                                            refetchChart().then(applyUrlState);
                                        } else {
                                            applyUrlState();
                                        }
                                    }
                                );
                        },
                        0,
                        false
                    );
                }

                function initializeChartNoDataService() {
                    // Do not show the chart no data message if you are in the editor
                    if (
                        !scope.inEditor &&
                        ChartNoDataService.shouldUseChartNoDataService(scope.chartModel)
                    ) {
                        scope.chartNoDataService = new ChartNoDataService(scope.chartModel, () => {
                            scope.showNoDataMessage = true;
                        });
                    }
                }

                function onChartTypeUpdate() {
                    updateHasHeatMap();

                    if (isHistogram()) {
                        updateHistogramDygraphColor();
                    } else {
                        clearHistogramDygraphColor();
                    }
                }

                function runJob() {
                    if (!scope.stopRefresh) {
                        refreshChart();
                    }
                }

                function onJobRequested() {
                    resetInternalJobState();
                    scope.chartDisplayDebouncer.jobRequested();
                }

                function beginCountDown() {
                    scope.$broadcast(CHART_DISPLAY_EVENTS.RESTART_PROGRESS);
                }

                function reassertModelVersion() {
                    if (angular.isDefined(scope.chartModel.$isOriginallyV2)) {
                        isOriginallyV2 = scope.chartModel.$isOriginallyV2;
                    } else {
                        isOriginallyV2 =
                            scope.chartModel.sf_modelVersion === 2 ||
                            scope.chartModel.sf_flowVersion === 2;
                    }
                }

                function isDetectorV2() {
                    return (
                        preTransformedModel &&
                        preTransformedModel.sf_detector !== undefined &&
                        isOriginallyV2
                    );
                }

                function initializeChartDisplayDebouncer() {
                    //create private debouncer if none is specified, otherwise defer to parent object including enablement state.
                    if (!scope.chartDisplayDebouncer) {
                        scope.chartDisplayDebouncer = new ChartDisplayDebounceService();
                        scope.chartDisplayDebouncer.setEnabled(
                            !features.disableBigChartDebouncer && scope.inEditor
                        );
                        scope.chartDisplayDebouncer.debounceAllJobs();
                    }

                    scope.chartDisplayDebouncer.registerListener(runJob, beginCountDown);
                }

                function exportChartAsCsv() {
                    if (scope.streamObject) {
                        chartExportService.exportAsCsv(
                            scope.dyGraphMaps.timeStampToTimeSlice,
                            scope.dyGraph.labels,
                            scope.streamObject.metaDataMap,
                            scope.legendKeys,
                            scope.dyGraphMaps.tsidToPlot,
                            scope.chartModel.sf_chart
                        );
                    }
                }

                function exportChartEventsAsJson() {
                    if (scope.chartModel.sf_uiModel.chartMode === 'event' && scope.eventProgram) {
                        const jobRangeParameters =
                            chartDisplayUtils.getJobRangeParametersFromConfig(
                                getChartConfig(scope.chartModel),
                                scope.chartModel.sf_uiModel.chartMode
                            );
                        const from = jobRangeParameters.range;
                        const to = jobRangeParameters.endAt;
                        chartExportService.exportEventAsJson(
                            scope.eventStreamQuery,
                            scope.eventProgram,
                            scope.chartModel.sf_chart,
                            to,
                            from
                        );
                    }
                }

                function getChartConfig(model) {
                    return scope.chartConfigOverride && scope.chartConfigOverride.resolution
                        ? scope.chartConfigOverride
                        : safeLookup(model || scope.chartModel, 'sf_uiModel.chartconfig');
                }

                function getEventQueryRange() {
                    return chartDisplayUtils.getEventQueryRange(
                        getChartConfig(),
                        scope.chartModel.sf_uiModel.chartMode
                    );
                }

                function explainIncidentId() {
                    return scope.explainIncidentId || null;
                }

                function setSelection(selectionSet) {
                    if (!scope.dyGraphInstance) {
                        return;
                    }
                    if (!selectionSet || !selectionSet.length) {
                        selectionSet = alwaysVisibleThresholdTsids;
                    }
                    try {
                        scope.dyGraphInstance.setSelection(false, selectionSet, true);
                    } catch (e) {
                        $log.info(
                            'Attempted to select ' + selectionSet + ' but it likely was not found.'
                        );
                    }
                    underlayCallback();
                }

                function flushUpdateOptionQueue() {
                    if (scope.dyGraphInstance) {
                        updateOptionQueue.forEach(function (opts) {
                            scope.dyGraphInstance.updateOptions.apply(scope.dyGraphInstance, [
                                opts,
                                true,
                            ]);
                        });
                        updateOptionQueue = [];
                    }
                }

                function updateOptions(options, forceSkip) {
                    if (!scope.dyGraphInstance) {
                        updateOptionQueue.push(options);
                        return;
                    }
                    flushUpdateOptionQueue();
                    if (!scope.renderScore || !scope.batchLoadCompleted) {
                        // if we aren't ready to draw because our renderScore isnt ready or we don't believe a majority of
                        // datapoints have arrived, then update the internal options state of dygraph but defer rendering
                        scope.dyGraphInstance.updateOptions(options, true);
                        return;
                    }
                    scope.dyGraphInstance.updateOptions(options, forceSkip);
                    scope.dyGraphInstance.clearSelection();
                    setSelection(scope.lastSelectionSet);
                }

                function isPausedTimeBeforeFirstDataPoint() {
                    if (!scope.pausedTime) {
                        return false;
                    }
                    return scope.pausedTime < getLeftmostTimestamp();
                }

                function getLeftmostTimestamp() {
                    return scope.dyGraph.file[0][0].getTime();
                }

                function getTimestampByFileIndex(index) {
                    return scope.dyGraph.file[index][0].getTime();
                }

                function isGreaterThanPausedTime(timestamp) {
                    return scope.pausedTime && timestamp > scope.pausedTime;
                }

                function findClosestRowIndex(timestamp) {
                    // finds the row that is closest but less than the time at which the
                    // chart was paused. This prevents the value from shifting as new
                    // data arrives.
                    const numRows = scope.dyGraph.file.length;

                    // TODO: verify that this is necessary, and add comment about the
                    // circumstances in which it would be.
                    if (!numRows || isPausedTimeBeforeFirstDataPoint()) {
                        return -1;
                    }

                    if (timestamp <= getLeftmostTimestamp()) {
                        return 0;
                    }

                    for (let i = 0; i < numRows - 1; i++) {
                        const leftTimestamp = getTimestampByFileIndex(i);
                        const rightTimestamp = getTimestampByFileIndex(i + 1);

                        if (isGreaterThanPausedTime(rightTimestamp)) {
                            return i;
                        }

                        if (leftTimestamp < timestamp && timestamp <= rightTimestamp) {
                            const leftDiff = timestamp - leftTimestamp;
                            const rightDiff = rightTimestamp - timestamp;

                            return leftDiff <= rightDiff ? i : i + 1;
                        }
                    }

                    return numRows - 1;
                }

                // Find the timestamp of the data point or event closest to the given timestamp
                function findClosestDataTimestamp(timestamp, rowIdx) {
                    // Check data points
                    let closestTimestamp = timestamp;
                    let smallestDiff = null;
                    if (rowIdx !== -1) {
                        closestTimestamp = scope.dyGraph.file[rowIdx][0].getTime();
                        smallestDiff = Math.abs(timestamp - closestTimestamp);
                    }

                    // Now check against any events, which will be centered in their time
                    // range buckets
                    if (scope.chartEventStore) {
                        const buckets = scope.chartEventStore.getHistogram();
                        if (!buckets.length) {
                            return closestTimestamp;
                        }

                        const prevDiff = Math.abs(timestamp - buckets[0].timeStamp);

                        for (let i = 0; i < buckets.length; i++) {
                            const bucketTimestamp = buckets[i].timeStamp;
                            const diff = Math.abs(timestamp - bucketTimestamp);
                            if (smallestDiff === null) {
                                // First thing on the chart we've found
                                closestTimestamp = bucketTimestamp;
                                smallestDiff = diff;
                                continue;
                            }
                            if (diff > prevDiff) {
                                // Getting further away from the timestamp, don't keep looking
                                return closestTimestamp;
                            }
                            if (
                                diff < smallestDiff &&
                                (!scope.pausedTime || bucketTimestamp < scope.pausedTime)
                            ) {
                                closestTimestamp = bucketTimestamp;
                                smallestDiff = diff;
                            }
                        }
                    }
                    return closestTimestamp;
                }

                function getModalParams() {
                    return {
                        backingJob: null,
                        viewJob: scope.currentJobId,
                        chartObj: scope.isPreview ? null : scope.chartModel,
                        feedback: scope.jobFeedback,
                        jobAttempts: jobAttempts,
                        traceId: scope.lastTraceId,
                        snapshot: scope.snapshot,
                        jobHistory:
                            scope.chartModel && !scope.isPreview
                                ? scope.chartModel.sf_jobIdsHistory
                                : null,
                    };
                }

                function showChartInfo() {
                    chartSettingsModal.info(getModalParams());
                }

                function clickChart(event) {
                    if (event && event.altKey && event.metaKey) {
                        event.preventDefault();
                        event.stopPropagation();
                        showChartInfo();
                    }
                }

                function initializeLoadingState() {
                    scope.datapointReceived = false; //indicates whether or not any data has been received
                    scope.currentJobId = null; //indicates whether or not we recieved an ack from analytics
                    scope.currentTraceId = null;
                    scope.streamStarted = false;
                    scope.legendData = [];
                    jobAttempts = [];
                    scope.$emit(CHART_DISPLAY_EVENTS.REPLACED_FILTER_REPORT, null);
                }

                function getColorComparisonString(tsid) {
                    const metadataFromMap = scope.streamObject.metaDataMap[tsid];
                    if (!metadataFromMap) {
                        return '';
                    }

                    // TODO: investigate whether metadata.raw is the same as what is
                    // stored in scope.streamObject.metaDataMap[tsid]
                    const metadata = getSeriesMetadata(tsid, scope.chartModel);
                    const colorByMetric = !!scope.chartModel.sf_uiModel.chartconfig.colorByMetric;
                    const delimiter = '';
                    const plot = scope.dyGraphMaps.tsidToPlot[tsid];

                    return generateTimeSeriesName(
                        metadata.raw,
                        delimiter,
                        !colorByMetric,
                        colorByMetric,
                        plot
                    );
                }

                /* For incident event modal, use a simpler color selection
                 *  algorithm that can be easily replicated by signalboost-rest
                 *  chart image generation */
                function getIncidentPlotColor(tsid) {
                    const allTsids = Object.keys(scope.dyGraphMaps.tsidToIndex);
                    allTsids.sort((a, b) => a.localeCompare(b));
                    const idx = Math.max(allTsids.indexOf(tsid), 0);
                    return PLOT_COLORS[idx % PLOT_COLORS.length];
                }

                /**
                 * Takes in a tsid and gets its color information
                 * @param tsid
                 *          Time Series Id
                 * @returns { hash(Integer), color(color hex), colorIndex(Index in Color Array) }
                 */
                function getPlotColorInfo(tsid) {
                    let hash,
                        colorIndex = -1,
                        color;

                    const colorOverride = getColorOverrideForTsid(tsid);
                    if (colorOverride) {
                        color = colorAccessibilityService
                            .get()
                            .convertPlotColorToAccessible(colorOverride);
                        colorIndex = PLOT_COLORS.indexOf(color);
                    }

                    if (!color) {
                        const colorComparisonString = getColorComparisonString(tsid);
                        if (
                            colorComparisonString &&
                            !colorComparisonString.startsWith('_SF_PLOT_KEY')
                        ) {
                            hash = Math.abs(murmurHash(colorComparisonString));
                            colorIndex = hash % PLOT_COLORS.length;
                            color = PLOT_COLORS[colorIndex];
                        }
                    }

                    return {
                        hash,
                        colorIndex,
                        color: color || UNKNOWN_SOURCE_COLOR,
                    };
                }

                function getColorForThresholdType(thresholdType) {
                    // TODO: this should use accessible colors
                    if (thresholdType === chartDisplayUtils.FIRE_TYPE) {
                        return getThresholdPriority()
                            ? detectorPriorityService.getColorBySeverity(getThresholdPriority())
                            : '#ea1849';
                    } else if (thresholdType === chartDisplayUtils.CLEAR_TYPE) {
                        return '#59A200';
                    }
                }

                // eventually this will handle all cases
                // currently this will only align severity color with focused event in Alert Modal V2
                // for all other cases, fallback is Critical / red, which is the same as the behavior
                // to date
                function getThresholdPriority() {
                    if (!scope || !scope.focusedEvent || !scope.focusedEvent.metadata) {
                        return null;
                    }

                    return scope.focusedEvent.metadata.sf_priority;
                }

                function getThresholdTypeForTsid(tsid) {
                    const metaData = scope.streamObject.metaDataMap[tsid];

                    return chartDisplayUtils.getThresholdType(
                        metaData,
                        scope.incidentInfo,
                        scope.isDetectorNative
                    );
                }

                function getColorOverrideForTsid(tsid) {
                    return safeLookup(
                        scope,
                        `dyGraphMaps.tsidToPlot.${tsid}.configuration.colorOverride`
                    );
                }

                /**
                 * Takes in a list of tsids and returns plot color info for each tsids collected by the requested color property
                 * @param tsids
                 *          List of Time Series Id
                 * @param collectByProp
                 *          One of ['hash', 'color', 'colorIndex']. Collects tsids on the requested color property.
                 *          Default: 'hash'
                 * @returns [{ hash, color, colorIndex, tsids[] }]
                 *          An array of object for plot color information containing all time series ids with the same
                 *          collectByProp.
                 */
                function getPlotColorsCollectedByKey(tsids, collectByProp) {
                    const plotColorsByProp = tsids.reduce((colorMap, tsid) => {
                        const colorInfo = getPlotColorInfo(tsid);
                        const collectionKey = colorInfo[collectByProp];
                        if (!colorMap[collectionKey]) {
                            colorInfo.tsids = [tsid];
                            colorMap[collectionKey] = colorInfo;
                        } else {
                            colorMap[collectionKey].tsids.push(tsid);
                        }
                        return colorMap;
                    }, {});
                    return Object.values(plotColorsByProp);
                }

                /**
                 * Distributes plot color into their respective buckets in order
                 * @param tsids
                 *          Array of Time Series IDs
                 * @param tsidOverrides
                 *          Array of Time Series IDs with color override
                 * @returns Buckets of respective plotColor info collected from {@link getPlotColorsCollectedByKey}:
                 *           {
                 *              colorBuckets: [[plotColors[]], ...],
                 *              unknownColorBucket: [plotColors[]]
                 *           }
                 */
                function getPlotColorsOrderedBuckets(tsids, tsidOverrides) {
                    const unknownColorBucket = [];
                    const colorBuckets = PLOT_COLORS.map(() => []);

                    const fixedPlotColors = getPlotColorsCollectedByKey(
                        tsidOverrides || [],
                        'colorIndex'
                    );

                    // Filter out plotColors which are cached and do not need a new color assignment
                    const cachedTsids = _.remove(tsids, (tsid) => tsidToColorCache[tsid]);
                    const cachedPlotColors = getPlotColorsCollectedByKey(cachedTsids, 'hash');

                    // Collect all the plots without color assignment by their hashes.
                    // This ensures plots which needs same colors are together
                    const distributablePlotColors = getPlotColorsCollectedByKey(tsids, 'hash');

                    // Sort Time Series by hash value. This guarantees that we distribute the same set of plots across color
                    // buckets with every refresh/reload.
                    cachedPlotColors.sort((a, b) => a.hash - b.hash);
                    distributablePlotColors.sort((a, b) => a.hash - b.hash);

                    // Take out and add unknown color plotColors their own bucket
                    unknownColorBucket.push(
                        ..._.remove(fixedPlotColors, (plotColor) => plotColor.colorIndex === -1)
                    );
                    unknownColorBucket.push(
                        ..._.remove(cachedPlotColors, (plotColor) => plotColor.colorIndex === -1)
                    );
                    unknownColorBucket.push(
                        ..._.remove(
                            distributablePlotColors,
                            (plotColor) => plotColor.colorIndex === -1
                        )
                    );

                    // Add these time series to respective buckets, first goes the fixed assignments and then cached plots
                    // followed by assignments for redistribution.
                    // Doing this order maintains that fixed assignments are already below the threshold of maxPlotPerColor,
                    // and thus will not be moved.
                    // Cached colors will not be moved in case of new time series being encountered but might in case of a new
                    // color override assignment if the color bucket runs of out space because of it.
                    fixedPlotColors.forEach((plotColor) =>
                        colorBuckets[plotColor.colorIndex].push(plotColor)
                    );
                    cachedPlotColors.forEach((plotColor) =>
                        colorBuckets[plotColor.colorIndex].push(plotColor)
                    );
                    distributablePlotColors.forEach((plotColor) =>
                        colorBuckets[plotColor.colorIndex].push(plotColor)
                    );

                    return { colorBuckets, unknownColorBucket };
                }

                /**
                 * @param tsids
                 *          Array of Time Series IDs
                 * @param tsidOverrides
                 *          Array of Time Series IDs with color override
                 * @returns
                 *    A map of tsid to color { tsid: color }
                 */
                function getDistributedColors(tsids, tsidOverrides) {
                    if (_.isEmpty(tsids) && _.isEmpty(tsidOverrides)) {
                        return null;
                    }

                    const distributionStack = [];
                    const numberOfBuckets = PLOT_COLORS.length;
                    const { colorBuckets, unknownColorBucket } = getPlotColorsOrderedBuckets(
                        tsids,
                        tsidOverrides
                    );
                    const maxPlotPerColor = Math.ceil(
                        colorBuckets.reduce(
                            (plotColorSum, bucket) => plotColorSum + bucket.length,
                            0
                        ) / PLOT_COLORS.length
                    );
                    // Find position for the required redistribution, if any
                    const distributionStartIndex = colorBuckets.findIndex(
                        (bucket) => bucket.length > maxPlotPerColor
                    );

                    // Redistribute if necessary
                    if (distributionStartIndex !== -1) {
                        let distributionRunningIndex = distributionStartIndex;

                        do {
                            const bucket = colorBuckets[distributionRunningIndex];
                            if (bucket.length > maxPlotPerColor) {
                                // splice overflow out of bucket (keeping the overridden colors) and add to distributionStack
                                distributionStack.push(bucket.splice(maxPlotPerColor));
                            } else {
                                // Pick up the top plotColors on distribution stack
                                // and push whatever possible into partially filled bucket
                                while (
                                    distributionStack.length &&
                                    bucket.length < maxPlotPerColor
                                ) {
                                    const availableBucketSpace = maxPlotPerColor - bucket.length;
                                    const color = PLOT_COLORS[distributionRunningIndex];
                                    const colorIndex = distributionRunningIndex;
                                    const overflowedPlots = distributionStack.pop();
                                    const plotsToRedistribute = overflowedPlots.slice(
                                        0,
                                        availableBucketSpace
                                    );

                                    bucket.push(
                                        ...plotsToRedistribute.map(({ tsids, hash }) => ({
                                            tsids,
                                            hash,
                                            color,
                                            colorIndex,
                                        }))
                                    );
                                    if (availableBucketSpace < overflowedPlots.length) {
                                        distributionStack.push(
                                            overflowedPlots.slice(availableBucketSpace)
                                        );
                                    }
                                }
                            }

                            distributionRunningIndex =
                                (distributionRunningIndex + 1) % numberOfBuckets;
                        } while (distributionRunningIndex !== distributionStartIndex);
                    }

                    return _.flatten(colorBuckets)
                        .concat(unknownColorBucket)
                        .reduce((tsidToColor, { tsids, color }) => {
                            tsids.forEach((tsid) => (tsidToColor[tsid] = color));
                            return tsidToColor;
                        }, {});
                }

                function getPlotColorsForGraph() {
                    const allTsids = Object.keys(scope.streamObject.metaDataMap);
                    const tsidToColor = {};
                    const colorDistributionList = [];
                    const colorDistributionOverrideList = [];
                    for (const tsid of allTsids) {
                        const thresholdType = getThresholdTypeForTsid(tsid);
                        if (thresholdType) {
                            tsidToColor[tsid] = getColorForThresholdType(thresholdType);
                            continue;
                        }

                        const colorOverride = getColorOverrideForTsid(tsid);
                        if (colorOverride) {
                            const accessibleOverride = colorAccessibilityService
                                .get()
                                .convertPlotColorToAccessible(colorOverride);
                            if (colorAccessibilityService.get().isPlotColor(accessibleOverride)) {
                                // Color override is in our current palette. Thus, gets to vote on distribution.
                                colorDistributionOverrideList.push(tsid);
                            } else {
                                tsidToColor[tsid] = accessibleOverride;
                            }
                            continue;
                        }

                        if (!explainIncidentId()) {
                            colorDistributionList.push(tsid);
                        } else {
                            tsidToColor[tsid] = explainIncidentId()
                                ? getIncidentPlotColor(tsid)
                                : getPlotColorInfo(tsid).color;
                            scope.$emit('PLOT_COLOR_PICKED', {
                                streamLabel: scope.streamObject.metaDataMap[tsid].sf_streamLabel,
                                color: tsidToColor[tsid],
                            });
                        }
                    }

                    return Object.assign(
                        tsidToColor,
                        getDistributedColors(colorDistributionList, colorDistributionOverrideList)
                    );
                }

                function updateColorCache() {
                    if (!scope.newTSIDEncountered) {
                        tsidToColorCache = {};
                    }
                    if (scope.streamObject && scope.streamObject.metaDataMap) {
                        tsidToColorCache = getPlotColorsForGraph();
                    }
                }

                function getColorForGraph(tsid) {
                    if (!tsid) return null;
                    if (
                        !tsidToColorCache[tsid] &&
                        safeLookup(scope, `streamObject.metaDataMap.${tsid}`)
                    ) {
                        updateColorCache();
                    }

                    return tsidToColorCache[tsid] || UNKNOWN_SOURCE_COLOR;
                }

                function getThicknessForGraph(tsid) {
                    const ttype = chartDisplayUtils.getThresholdType(
                        scope.streamObject.metaDataMap[tsid],
                        scope.incidentInfo,
                        scope.isDetectorNative
                    );
                    if (ttype) {
                        if (
                            ttype === chartDisplayUtils.FIRE_TYPE ||
                            ttype === chartDisplayUtils.CLEAR_TYPE
                        ) {
                            return THRESHOLD_LINE_WIDTH;
                        }
                    }
                    return 1;
                }

                function pointHighlightCallback(row, skipDigest) {
                    const customPoints = [];
                    let time = null;
                    if (row > -1 && row < scope.dyGraph.file.length) {
                        time = scope.dyGraph.file[row][0];

                        scope.dyGraph.file[row].forEach(function (d, idx) {
                            if (idx > 0) {
                                const tsid = scope.dyGraph.labels[idx];
                                const targetedPlot = getPlotObject(
                                    scope.streamObject.metaDataMap[tsid],
                                    scope.chartModel
                                );
                                let visibility = true;
                                if (targetedPlot) {
                                    visibility = !targetedPlot.invisible;
                                }
                                if (visibility) {
                                    customPoints.push({
                                        xval: time,
                                        name: tsid,
                                        yval: d,
                                    });
                                }
                            }
                        });
                    }
                    scope.lastHighlightedPoints = customPoints;
                    scope.lastHighlightedTime = time;
                    if (scope.legendDisabled === true) {
                        return;
                    }

                    if (
                        scope.hasHeatMap ||
                        scope.sharedChartState.pinnedTimeStamp ||
                        !scope.hideLegend
                    ) {
                        //no need to update the legend if we're not pinned and showing the legend
                        updateLegendValues();
                    }

                    drawLegendLines();
                    //this is a perf hog, may want to move it out of angular loop as well..
                    //digest is faster but will only update current scope and leave other
                    //scopes "desynchronized"
                    if (!skipDigest) {
                        scope.$digest();
                    }
                }

                function getTransientModel(skipOverrides, includeEvents) {
                    const programTextSource = angular.copy(scope.chartModel);
                    if (scope.disableUrl) {
                        return programTextSource;
                    }
                    if (!skipOverrides) {
                        chartDisplayUtils.applyUrlStateToModel(
                            programTextSource,
                            scope.filterAlias
                        );
                    }

                    // For v2 detectors, an alerts block will be added to the generated signalflow
                    // at the end of the getFullSignalFlowWithEvents function, so we don't want the
                    // detector plot to be included in the signalflow generation process here, to
                    // avoid having a duplicate block.
                    const plotsToExclude = [];
                    if (includeEvents && isDetectorV2()) {
                        const detectorPlotUniqueKey =
                            plotUtils.getDetectorPlot(preTransformedModel)?.uniqueKey;
                        if (detectorPlotUniqueKey || detectorPlotUniqueKey === 0) {
                            plotsToExclude.push(detectorPlotUniqueKey);
                        }
                    }

                    programTextUtils.refreshProgramText(
                        programTextSource,
                        includeEvents
                            ? { includeEvents: includeEvents, plotsToExclude: plotsToExclude }
                            : {}
                    );
                    return programTextSource;
                }

                function getTransientModelWithoutOverrides(includeEvents) {
                    return getTransientModel(true, includeEvents);
                }

                function updateEventQueryState() {
                    assignUniqueKeysToEventPlots();

                    scope.eventStreamQuery = '';
                    scope.eventProgram = getEventProgram();
                }

                function getFilteredVariables(replaceOnly) {
                    const urlFilterAliases =
                        dashboardVariablesService.getVariablesOverrideAsModel() || [];

                    return (scope.filterAlias || [])
                        .filter((f) => !!f.replaceOnly === !!replaceOnly)
                        .map((f) => {
                            const toReturn = angular.copy(f);
                            const varInUrl = urlFilterAliases.find((urlFilterAlias) => {
                                return dashboardVariablesService.checkVariablesAreSame(
                                    urlFilterAlias,
                                    f
                                );
                            });

                            if (varInUrl) {
                                toReturn.value = varInUrl.value;
                            }

                            return toReturn;
                        })
                        .filter((f) => (replaceOnly ? f : f && !_.isEmpty(f.value)));
                }

                function assignUniqueKeysToEventPlots() {
                    if (angular.isArray(scope.overlayEventPlots)) {
                        scope.overlayEventPlots.forEach((overlayEvent) => {
                            overlayEvent.uniqueKey =
                                EVENT_OVERLAY_OFFSET +
                                chartbuilderUtil.getNextUniqueKey(scope.chartModel.sf_uiModel);
                        });
                    }
                }

                function getEventOverlayProgramText() {
                    const overlayPlots = { allPlots: scope.overlayEventPlots };
                    const programTextOptions = {
                        skipPublishInvisible: true,
                        includeEvents: true,
                    };

                    return programTextUtils.getV2ProgramTextWithOptions(
                        overlayPlots,
                        programTextOptions
                    );
                }

                function hasOverlayEventPlots() {
                    return scope.overlayEventPlots && scope.overlayEventPlots.length;
                }

                function getEventProgramTextFromV2() {
                    const allPlots = scope.chartModel.sf_uiModel.allPlots;
                    const overlayEventPlots = scope.overlayEventPlots || [];
                    const uiModelCopy = { allPlots: allPlots.concat(overlayEventPlots) };

                    const programTextOptions = {
                        skipPublishInvisible: true,
                        includeEvents: true,
                    };

                    return programTextUtils.getV2ProgramTextWithOptions(
                        uiModelCopy,
                        programTextOptions
                    );
                }

                function getFullSignalFlowWithEvents() {
                    let fullSignalflowWithEvents;

                    // in neither case do we apply overrides as we expect this to be done via the provided filters
                    if (!isOriginallyV2) {
                        fullSignalflowWithEvents = getEventProgramTextFromV2();
                    } else {
                        fullSignalflowWithEvents =
                            getTransientModelWithoutOverrides(true).sf_viewProgramText;

                        if (hasOverlayEventPlots()) {
                            const eventOverlayProgramText = getEventOverlayProgramText();
                            fullSignalflowWithEvents += `\n${eventOverlayProgramText}`;
                        }
                    }

                    if (isDetectorV2() && preTransformedModel.sf_detector) {
                        fullSignalflowWithEvents +=
                            '\n' +
                            'alerts("' +
                            preTransformedModel.sf_detector.replace(/"/g, '\\"') +
                            '").publish()';
                    }

                    return fullSignalflowWithEvents;
                }

                function getEventProgram() {
                    const convertToVariable = (obj) => ({
                        property: obj.key,
                        values: angular.isArray(obj.value) ? obj.value : [obj.value],
                        not: obj.not,
                    });

                    const eventProgram = {
                        program: {
                            programText: getFullSignalFlowWithEvents(),
                            packageSpecifications: null,
                        },
                        additionalFilters: [],
                        additionalReplaceOnlyFilters: [],
                    };

                    if (scope.disableUrl) {
                        return eventProgram;
                    }

                    const sources = urlOverridesService.getSourceOverride() || [];
                    eventProgram.additionalFilters = chartUtils
                        .getChartOverridesCustom(getFilteredVariables(false), sources)
                        .map(convertToVariable);

                    eventProgram.additionalReplaceOnlyFilters = chartUtils
                        .getChartOverridesCustom(getFilteredVariables(true), [])
                        .map(convertToVariable);

                    if (smokeyStateProvider) {
                        smokeyStateProvider.setFilters(eventProgram.additionalFilters);
                        smokeyStateProvider.setReplaceOnlyFilters(
                            eventProgram.additionalReplaceOnlyFilters
                        );
                    }

                    return eventProgram;
                }

                function updateEvents(newtime) {
                    if (newtime) {
                        const expectedEventTimeStamp = Math.floor(
                            scope.sharedChartState.pinnedTimeStamp || newtime
                        );

                        const decideToFetchEvents =
                            scope.lastEventFetchTimeStamp !== expectedEventTimeStamp;
                        if (decideToFetchEvents) {
                            if (scope.lastEventFetchTimeStamp !== expectedEventTimeStamp) {
                                scope.eventList = null;
                                if (!scope.hideLegend) {
                                    // Make sure this comes from the chart that has the pin
                                    scope.sharedChartState.eventsCount = null;
                                }
                                scope.lastEventFetchTimeStamp = expectedEventTimeStamp;

                                // Get events in time range of the events bucket wherein the
                                // user has clicked
                                const eventsBucket = getNearestEvents(
                                    scope.dyGraphInstance.toDomXCoord(
                                        scope.sharedChartState.verticalLineTimestamp
                                    )
                                );
                                const res = getEventQueryRange();
                                const eventPromise = eventsBucket
                                    ? scope.chartEventStore.getEvents(
                                          eventsBucket.timeStamp,
                                          eventsBucket.timeStamp + res
                                      )
                                    : $q.when([]);
                                eventPromise.then(function (results) {
                                    const labelToPlot = getLabelToEventPlotMap();
                                    if (scope.lastEventFetchTimeStamp === expectedEventTimeStamp) {
                                        scope.eventList = results;
                                        results.forEach(function (event) {
                                            if (labelToPlot[event.___label]) {
                                                const plot = labelToPlot[event.___label];
                                                event.customName = plot.name;
                                                if (
                                                    plot.configuration &&
                                                    plot.configuration.colorOverride
                                                ) {
                                                    event.customColor =
                                                        plot.configuration.colorOverride;
                                                }
                                            }
                                        });
                                        if (!scope.hideLegend) {
                                            scope.sharedChartState.eventsCount = results.length;
                                        }
                                    }
                                });
                            }
                        }
                    } else {
                        //clear events, pin was removed
                        scope.eventList = [];
                        if (!scope.hideLegend) {
                            scope.sharedChartState.eventsCount = null;
                        }
                        scope.lastEventFetchTimeStamp = null;
                    }
                }

                function updateRenderScoreDebounced() {
                    $timeout.cancel(renderScoreDebounce);
                    renderScoreDebounce = $timeout(updateRenderScore, 0, true);
                }

                function handleMetadataMessageInShowThresholdMode(data, tsid) {
                    const metaData = scope.streamObject.metaDataMap[tsid];

                    // do not render thresholds on exploratory view in alert modal v2
                    if (!scope.isDetectorNative && scope.isAlertModalV2Chart) {
                        return;
                    }

                    if (scope.isDetectorNative && !scope.isAlertModalV2Chart) {
                        if (metaData && metaData.sfui_config) {
                            if (typeof metaData.sfui_config === 'string') {
                                metaData.sfui_config = angular.fromJson(metaData.sfui_config);
                            }

                            relatedThresholdService.addThresholdFromUiConfig(
                                data,
                                scope.chartModel.sf_uiModel.allPlots
                            );
                            if (!relatedThresholdService.hasMultiple()) {
                                alwaysVisibleThresholdTsids.push(tsid);
                            } else if (alwaysVisibleThresholdTsids.length) {
                                alwaysVisibleThresholdTsids = [];
                            }
                        } else if (
                            chartDisplayUtils.getThresholdInfo(
                                metaData,
                                scope.incidentInfo,
                                scope.isDetectorNative
                            )
                        ) {
                            relatedThresholdService.addThreshold(data);

                            if (!relatedThresholdService.hasMultiple()) {
                                alwaysVisibleThresholdTsids.push(tsid);
                            } else if (alwaysVisibleThresholdTsids.length) {
                                alwaysVisibleThresholdTsids = [];
                            }
                        }
                    } else if (
                        chartDisplayUtils.getThresholdInfo(
                            metaData,
                            scope.incidentInfo,
                            scope.isDetectorNative
                        )
                    ) {
                        relatedThresholdService.addThreshold(data);
                        if (!relatedThresholdService.hasMultiple() || scope.isAlertModalV2Chart) {
                            alwaysVisibleThresholdTsids.push(tsid);
                        } else if (alwaysVisibleThresholdTsids.length) {
                            alwaysVisibleThresholdTsids = [];
                        }
                    }
                }

                function metadataIsForDetectBlocks(data) {
                    return metadataIsForDetectBlockETS(data) || metadataIsForDetectBlockMTS(data);
                }

                function metadataIsForDetectBlockETS(data) {
                    return !!data.sf_detectLabel;
                }

                function metadataIsForDetectBlockMTS(data) {
                    return (
                        data.sf_detectorDerived &&
                        (!scope.detectorPreviewConfig || !scope.detectorPreviewConfig.preview)
                    );
                }

                function onMetaDataMessage(data, tsid) {
                    if (!scope.chartModel.sf_flowVersion && metadataIsForDetectBlocks(data)) {
                        // we don't need this to render detect block data points
                        return;
                    }

                    updateRenderScoreDebounced();
                    updateTSIDMap(tsid);

                    if (showThreshold) {
                        handleMetadataMessageInShowThresholdMode(data, tsid);
                    }

                    // update the relevant maps when metadata arrives, then issue a
                    // throttled recomputeKeyset call
                    throttledDebouncedKeyRecompute();
                }

                function getPlotForTsid(tsid) {
                    return getPlotObject(scope.streamObject.metaDataMap[tsid], scope.chartModel);
                }

                function updateTSIDMap(tsid) {
                    const targetedPlot = getPlotForTsid(tsid);
                    scope.dyGraphMaps.tsidToPlot[tsid] = targetedPlot;
                }

                function recomputeKeysetDebounced() {
                    $timeout.cancel(scope.recomputeDelay);
                    scope.recomputeDelay = $timeout(function () {
                        if (!scope.streamObject || !scope.streamObject.metaDataMap) {
                            return;
                        }
                        recomputeKeyset();
                        setOnChartLegend();
                        scope.$emit(CHART_DISPLAY_EVENTS.LEGEND_KEYS_RECOMPUTED, scope.legendKeys);
                    }, 1000);
                }

                function recomputeKeyset() {
                    scope.legendKeys = chartDisplayUtils.getLegendKeys(
                        scope.streamObject.metaDataMap,
                        scope.dyGraphMaps.tsidToPlot
                    );
                }

                function getLegendRollupValue(uniqueKey) {
                    const plotInfo = scope.jobMessageSummary.plotKeyToInfoMap[uniqueKey];
                    if (plotInfo && (plotInfo.rollups || plotInfo.sources)) {
                        const { commonRollupType, rollupApplied } = plotInfo;
                        let rollup = '';

                        if (
                            angular.isUndefined(commonRollupType) ||
                            angular.isUndefined(rollupApplied)
                        ) {
                            rollup = 'determining...';
                        } else {
                            if (commonRollupType) {
                                rollup = commonRollupType.displayName.toLowerCase();
                            } else {
                                rollup = 'multiple';
                            }
                            if (!rollupApplied) {
                                rollup += ' - not applied';
                            }
                        }
                        return rollup;
                    }
                }

                function updateLegendValues() {
                    const points = scope.lastHighlightedPoints || [];
                    let pinnedTimeSlice = [];
                    if (
                        scope.sharedChartState.pinnedTimeStamp &&
                        dyGraphUtils.inRange(
                            scope.dyGraphInstance,
                            scope.sharedChartState.pinnedTimeStamp
                        )
                    ) {
                        pinnedTimeSlice =
                            scope.dyGraphMaps.timeStampToTimeSlice[
                                scope.sharedChartState.pinnedTimeStamp
                            ];
                        if (!pinnedTimeSlice) {
                            //this chart may not have data for this timestamp.  time to find the closest...
                            const rowIdx = findClosestRowIndex(
                                scope.sharedChartState.pinnedTimeStamp
                            );
                            if (rowIdx !== -1) {
                                const rowTimestamp = scope.dyGraph.file[rowIdx][0].getTime();
                                pinnedTimeSlice =
                                    scope.dyGraphMaps.timeStampToTimeSlice[rowTimestamp];
                            }
                        }
                        pinnedTimeSlice = pinnedTimeSlice || [];
                    }

                    scope.legendData = [];
                    const keyMap = {};

                    angular.forEach(points, function (point) {
                        const mdata = chartDisplayUtils.getUiConfig(
                            scope.streamObject.metaDataMap[point.name],
                            scope.incidentInfo
                        );
                        //not the greatest way to figure this out...
                        if (mdata.sfui_streamType && mdata.sfui_streamType !== 'signal') {
                            return;
                        }
                        const plotSourceName = getSeriesMetadata(point.name, scope.chartModel);
                        const rollup = getLegendRollupValue(plotSourceName.plot.uniqueKey);

                        plotSourceName.sf_key.forEach(function (key) {
                            if (!keyMap[key]) {
                                keyMap[key] = 0;
                            }
                            keyMap[key]++;
                        });

                        let highT = null;
                        let lowT = null;
                        relatedThresholdService.getCorrelatedTSIDs(mdata).map(function (tsid) {
                            if (
                                chartDisplayUtils.getThresholdInfo(
                                    scope.streamObject.metaDataMap[tsid],
                                    scope.incidentInfo,
                                    scope.isDetectorNative
                                ) > 0
                            ) {
                                highT = valueFormatter.formatValue(
                                    pinnedTimeSlice[scope.dyGraphMaps.tsidToIndex[tsid]],
                                    7,
                                    getUseKMG2()
                                );
                            } else {
                                lowT = valueFormatter.formatValue(
                                    pinnedTimeSlice[scope.dyGraphMaps.tsidToIndex[tsid]],
                                    7,
                                    getUseKMG2()
                                );
                            }
                        });

                        const prefix =
                            safeLookup(plotSourceName, 'plot.configuration.prefix') || '';
                        const suffix =
                            safeLookup(plotSourceName, 'plot.configuration.suffix') || '';
                        const unitType =
                            safeLookup(plotSourceName, 'plot.configuration.unitType') || '';
                        const value =
                            pinnedTimeSlice[scope.dyGraphInstance.indexFromSetName(point.name)];

                        scope.legendData.push({
                            raw: point.yval,
                            value: unitType
                                ? valueFormatter.formatScalingUnit(point.yval, unitType, 7)
                                : valueFormatter.formatValue(point.yval, 7, getUseKMG2()),
                            prefix: prefix,
                            suffix: suffix,
                            tsid: point.name,
                            pinnedRaw:
                                pinnedTimeSlice[
                                    scope.dyGraphInstance.indexFromSetName(point.name)
                                ] || null,
                            pinnedValue: unitType
                                ? valueFormatter.formatScalingUnit(value, unitType, 7)
                                : valueFormatter.formatValue(value, 7, getUseKMG2()),
                            color: scope.dyGraphMaps.tsidToColor[point.name],
                            source: plotSourceName.source,
                            sf_source: plotSourceName.source,
                            metric: plotSourceName.metric,
                            name: chartDisplayUtils.resolveSeriesName(mdata, scope.chartModel),
                            pointMetaData: plotSourceName.raw,
                            highThreshold: highT,
                            lowThreshold: lowT,
                            rollup,
                        });
                    });
                }

                function onEventTileMouseEnter(e, timestamp) {
                    scope.sharedChartState.mouseHoverTimestamp = timestamp;
                    scope.sharedChartState.verticalLineTimestamp = timestamp;
                }

                function onEventTileMouseLeave() {
                    scope.sharedChartState.mouseHoverTimestamp = null;
                    scope.sharedChartState.verticalLineTimestamp = null;
                }

                function drawLegendLines() {
                    const dygraph = scope.dyGraphInstance;
                    if (!dygraph || isNotInViewPort()) {
                        return;
                    }

                    const lineTimestamp = scope.sharedChartState.verticalLineTimestamp;
                    const leftOffsetInPixels = Math.floor(dygraph.toDomXCoord(lineTimestamp));

                    if (isInChartArea(leftOffsetInPixels)) {
                        // if point has ticked off the end of the chart, wipe it.
                        removeLegendLines();
                    } else {
                        positionHoverLine(leftOffsetInPixels);
                        positionHoverTooltip(leftOffsetInPixels);
                    }

                    drawPinLine();
                }

                function drawPinLine() {
                    const dygraph = scope.dyGraphInstance;

                    try {
                        const domCoordX = dygraph.toDomCoords(
                            scope.sharedChartState.pinnedTimeStamp,
                            0
                        )[0];
                        scope.pinnedTimeStampLeftPx = Math.floor(domCoordX);
                    } catch (e) {
                        scope.pinnedTimeStampLeftPx = null;
                        $log.warn('Could not compute xAxis position!');
                    }

                    if (
                        isInChartArea(scope.pinnedTimeStampLeftPx) ||
                        scope.displaySelectedTimeOverlay
                    ) {
                        scope.pinnedTimeStampLeftPx = null;
                    } else {
                        scope.pinnedTimeStampLeftPx += 'px';
                        pinPositioner.css('left', scope.pinnedTimeStampLeftPx);
                    }
                }

                function findFirstTruthyValue(array, fn) {
                    return array.reduce((acc, val) => acc || fn(val), null);
                }

                function getDistanceFromDashboardLeft() {
                    let distanceFromDashboardLeft = positionCache.distanceFromDashboardLeft;

                    if (isNaN(distanceFromDashboardLeft)) {
                        const parentOffset = findFirstTruthyValue(parentContainers, (id) => {
                            return angular.element(elem).parents(id).offset();
                        });

                        const chartOffset = angular.element(elem).offset();
                        distanceFromDashboardLeft = chartOffset.left - (parentOffset?.left || 0);
                        positionCache.distanceFromDashboardLeft = distanceFromDashboardLeft;
                    }

                    return distanceFromDashboardLeft;
                }

                function removeLegendLines() {
                    scope.nearestPoint = null;
                    scope.sharedChartState.mouseHoverTimestamp = null;
                    scope.nearestEvents = null;

                    hideHoverPositioner();
                }

                function hideHoverPositioner() {
                    hoverLine.css('display', 'none');
                }

                function positionHoverLine(leftOffsetInPixels) {
                    hoverLine.css('left', leftOffsetInPixels + 'px');
                    hoverLine.css('display', 'block');
                }

                function positionHoverTooltip(leftOffsetInPixels) {
                    const HOVER_TOOLTIP_OFFSET = 40;
                    const HOVER_TOOLTIP_WIDTH = 310;

                    if (scope.hoverTooltipAbsolute) {
                        scope.hoverTooltipBottom = elem.height() - 10;
                        scope.hoverTooltipLeft = -12;
                    } else {
                        if (
                            leftOffsetInPixels + getDistanceFromDashboardLeft() <
                            HOVER_TOOLTIP_WIDTH + HOVER_TOOLTIP_OFFSET
                        ) {
                            scope.hoverTooltipLeft = `${
                                leftOffsetInPixels + HOVER_TOOLTIP_OFFSET
                            }px`;
                        } else {
                            scope.hoverTooltipLeft = `${
                                leftOffsetInPixels - (HOVER_TOOLTIP_WIDTH + HOVER_TOOLTIP_OFFSET)
                            }px`;
                        }
                    }
                }

                function isInChartArea(offsetInPixels) {
                    const chartWidth = scope.dyGraphInstance.width_;
                    const rightAxisPlot = getVisiblePlots().find((plot) => plot.yAxisIndex === 1);
                    const rightAxisOffset = rightAxisPlot ? 45 : 0;
                    return (
                        !offsetInPixels ||
                        offsetInPixels < 45 || // axis width I guess..
                        offsetInPixels > chartWidth - rightAxisOffset
                    );
                }

                function isNotInViewPort() {
                    return (
                        angular.isDefined(scope.inView) &&
                        angular.isDefined(scope.inView()) &&
                        !scope.inView()
                    );
                }

                function updateDygraphSelectedPoints() {
                    if (!scope.dyGraphInstance || isNotInViewPort()) {
                        return;
                    }
                }

                function updateDragOverlay() {
                    if (isNotInViewPort()) {
                        return;
                    }

                    if (
                        scope.dragStartTime &&
                        scope.intermediateDragTime &&
                        !mouseDownOnSelectedTime
                    ) {
                        overlayRenderer.renderDragOverlay(
                            scope.dragStartTime,
                            scope.intermediateDragTime
                        );
                    }
                }

                function getLabelToEventColorMap() {
                    const labelToPlot = getLabelToEventPlotMap();
                    const labelToColor = {};
                    angular.forEach(labelToPlot, (plot, label) => {
                        if (plot.configuration) {
                            labelToColor[label] = plot.configuration.colorOverride || null;
                        }
                    });
                    return labelToColor;
                }

                function getLabelToEventPlotMap() {
                    const labelToPlot = {};
                    scope.chartModel.sf_uiModel.allPlots
                        .filter((plot) => {
                            return plot.type === 'event';
                        })
                        .forEach(function (plot) {
                            const key =
                                plot._originalLabel ||
                                plotUtils.getLetterFromUniqueKey(plot.uniqueKey);
                            if (!labelToPlot[key]) {
                                labelToPlot[key] = plot;
                            } else {
                                $log.warn(
                                    'Encountered a duplicate plot label (' +
                                        key +
                                        '), styling may be mismatched!'
                                );
                            }
                        });
                    return labelToPlot;
                }

                function redrawEventHistogram() {
                    const dygraph = scope.dyGraphInstance;

                    if (getChartMode() !== 'graph' || !dygraph || isNotInViewPort()) {
                        return;
                    }

                    if (scope.chartEventStore) {
                        const chartOptionsDrawEventLines = safeLookup(
                            scope,
                            'chartModel.sf_uiModel.chartconfig.eventLines'
                        );
                        const labelToColor = getLabelToEventColorMap();
                        overlayRenderer.drawEvents(
                            scope.chartEventStore.getHistogram(),
                            labelToColor,
                            chartOptionsDrawEventLines,
                            scope.currentEventsBucket,
                            scope.eventOverlayColors,
                            scope.eventOverlayParams
                        );
                    }

                    if (isPreflight) {
                        let histogram = [];
                        if (scope.chartEventStore) {
                            histogram = scope.chartEventStore.getHistogram();
                        }

                        const boxes = [];
                        // buffer the start with an interval since we start filling in when things are in the bucket
                        if (preflightTimestamp) {
                            let preflightProgressRenderTime = preflightTimestamp;
                            if (histogram.length) {
                                preflightProgressRenderTime = Math.max(
                                    preflightProgressRenderTime,
                                    histogram[histogram.length - 1].timeStamp +
                                        scope.chartEventStore.resolution
                                );
                            }
                            boxes.push({
                                start: preflightProgressRenderTime,
                                end: null,
                                color: '#659CB7',
                            });

                            if (preflightPercent > 95) {
                                percentTooltip.hide();
                            } else {
                                const leftPercent =
                                    dygraph.toDomCoords(preflightProgressRenderTime, 0, 0)[0] + 1;
                                const area = dygraph.getArea();
                                if (leftPercent > area.x + area.w) {
                                    percentTooltip.hide();
                                } else {
                                    percentTooltipTextContainer.text(preflightPercent + '%');
                                    percentTooltip.show();
                                    percentTooltip.css('left', leftPercent + 'px');
                                }
                            }
                        }
                        // buffer the end time with an interval so that it doesn't cover the event in the interval
                        if (preflightAvailableFrom) {
                            let preflightAvailableFromTimeStamp = preflightAvailableFrom;
                            if (histogram.length) {
                                preflightAvailableFromTimeStamp = Math.min(
                                    preflightAvailableFromTimeStamp,
                                    histogram[0].timeStamp - scope.chartEventStore.resolution
                                );
                            }
                            boxes.push({
                                start: null,
                                end: preflightAvailableFromTimeStamp,
                                color: '#BA7988',
                            });
                            const area = dygraph.getArea();
                            const endX = Math.min(
                                area.x + area.w,
                                dygraph.toDomCoords(preflightAvailableFromTimeStamp, 0, 0)[0]
                            );
                            const startX = area.x;
                            const pos = parseInt((startX + endX) / 2);
                            preflightNotAvailableTooltipTextContainer.css('left', pos + 'px');
                        }
                        overlayRenderer.drawEventBarBoxes(boxes);
                    }
                }

                function underlayCallback() {
                    if (getChartMode() !== 'graph' || !scope.dyGraphInstance || isNotInViewPort()) {
                        return;
                    }

                    // as our requirements get more complex I think we're going to have
                    // to move to something more custom.
                    // regardless, restore to the previous state, clip to above and below the x axis,
                    // draw events as necessary, clip to just the chartable area, then restore back.
                    // this leaves future canvas invocations able to draw across all legal areas(above and below the x axis)

                    const ctx = scope.dyGraphInstance.canvas_ctx_;
                    ctx.restore();

                    //begin draws for anything in the graph area and below the y axis
                    dyGraphUtils.setClip(ctx, scope.dyGraphInstance, true);

                    if (scope.chartEventStore) {
                        redrawEventHistogram();
                    }

                    ctx.save();
                    //begin draws for anything within the plottable area(inside both axes)
                    dyGraphUtils.setClip(ctx, scope.dyGraphInstance, false);

                    let verticalLines = safeLookup(
                        scope,
                        'chartModel.sf_uiModel.chartconfig.verticalLines'
                    );

                    if (
                        scope.chartConfigOverride &&
                        Array.isArray(scope.chartConfigOverride.verticalLines)
                    ) {
                        verticalLines = scope.chartConfigOverride.verticalLines;
                    }

                    updateDragOverlay();

                    if (verticalLines) {
                        overlayRenderer.drawVerticalLines(verticalLines);
                    }

                    const yConfigs = safeLookup(
                        scope,
                        'chartModel.sf_uiModel.chartconfig.yAxisConfigurations'
                    );
                    overlayRenderer.drawWatermarks(yConfigs);

                    overlayRenderer.drawPoint(
                        lastPoint,
                        lastPoint ? scope.dyGraphMaps.tsidToColor[lastPoint.seriesName] : null
                    );
                    if (scope.pinnedPoint) {
                        overlayRenderer.drawPoint(
                            scope.pinnedPoint,
                            scope.dyGraphMaps.tsidToColor[scope.pinnedPoint.seriesName],
                            8,
                            HEX_BLACK
                        );
                    }
                    ctx.restore();
                }

                function zoomCallback(x1, x2) {
                    if (
                        chartDisplayUtils.isTimeSliceMode(scope.chartModel.sf_uiModel) ||
                        x1 === x2
                    ) {
                        return;
                    }

                    //no querying the future
                    x2 = Math.min(x2, Date.now());

                    if (x2 - x1 < 5000) {
                        //minimum fetch range 5 seconds?  no idea what to do here....
                        x1 = x2 - 5000;
                    }

                    scope.$emit(CHART_DISPLAY_EVENTS.CHART_TIME_RANGE_SELECTED, x1, x2);
                }

                function legendRowHighlighted(a) {
                    if (
                        !chartDisplayUtils.getThresholdType(
                            scope.streamObject.metaDataMap[a],
                            scope.incidentInfo,
                            scope.isDetectorNative
                        )
                    ) {
                        updateHighlightedSeries(a);
                    } else {
                        scope.dyGraphInstance.clearSelection();
                        scope.lastSelectionSet = null;
                    }
                }

                function getChartMode() {
                    return safeLookup(scope.chartModel, 'sf_uiModel.chartMode') || 'graph';
                }

                function updateModeMixin() {
                    const cfg = dyGraphConfigurationGenerator.updateModeMixins(scope.chartModel);
                    scope.legendDisabled = !cfg.legend;
                    scope.dyGraphModeMixin = cfg.config;
                }

                function setSeriesPointDraw(point) {
                    lastPoint = point || null;
                }

                function isMouseOverSelectedTime(event) {
                    if (!selectedTimeRange) {
                        return false;
                    }
                    const eventX = event.offsetX || event.layerX;
                    const eventY = event.offsetY;
                    return (
                        selectedTimeRange.start - SELECTION_MOUSE_BUFFER < eventX &&
                        selectedTimeRange.end + SELECTION_MOUSE_BUFFER > eventX &&
                        selectedTimeRange.top - SELECTION_MOUSE_BUFFER < eventY &&
                        selectedTimeRange.bottom + SELECTION_MOUSE_BUFFER > eventY
                    );
                }

                function graphMouseDown(event) {
                    // no dragging allowed when we have resolution override
                    if (scope.isDetectorNative || explainIncidentId()) return;

                    scope.dragPerformed = false;
                    scope.intermediateDragTime = null;
                    //firefox doesn't have offsetX.
                    scope.dragStartWallTime = Date.now();
                    scope.dragStartTime = scope.dyGraphInstance.toDataXCoord(
                        event.offsetX || event.layerX
                    );

                    if (isMouseOverSelectedTime(event)) {
                        mouseDownOnSelectedTime = true;
                        selectedTimeLink.addClass('detector-dragging');
                        selectedTimeView.addClass('detector-dragging');
                        chartContainer.addClass('detector-dragging');
                    }
                }

                function isPaused() {
                    return (
                        scope.sharedChartState.pinnedTimeStamp ||
                        scope.dragPerformed ||
                        scope.isPanning
                    );
                }

                function getRenderingWallTime() {
                    if (scope.sharedChartState.pinnedTimeStamp) {
                        return scope.legendPinWallTime;
                    } else {
                        return timeservice.getServerTime();
                    }
                }

                function getPanDurationHoldRange(heldMilliseconds) {
                    const originalRanges = getPanRange();
                    const delt = originalRanges[1] - originalRanges[0];
                    const numSeconds = heldMilliseconds / 1000;
                    return (
                        (((Math.pow(1 + numSeconds, 3) / 3 + 30 * numSeconds) * delt) / 100) *
                        (panDirection === 'left' ? -1 : 1)
                    );
                }

                function panStart(direction) {
                    if (scope.disableTimeModification) {
                        return;
                    }
                    if (!panningController) {
                        panningController = new ChartPanningControl({
                            onUpdate: updatePanningView,
                            onPanComplete: selectPanningView,
                        });
                    }
                    panAxisExtent = scope.dyGraphInstance.yAxisRange(0);
                    panDirection = direction;
                    angular.element('.chart-panning-time-overlay', elem).addClass('visible');
                    scope.sharedChartState.mouseHoverTimestamp = null;
                    scope.legendLatestTimestamp = null;
                    scope.isPanning = true;
                    panningController.start();
                }

                function panStop() {
                    scope.isPanning = false;
                    if (panningController) {
                        panningController.stop();
                    }
                }

                function panCancel() {
                    scope.isPanning = false;
                    if (panningController && panningController.isPanning()) {
                        panningController.cancel();
                        updatePanningView(0);
                    }
                    angular.element('.chart-panning-time-overlay', elem).removeClass('visible');
                }

                function updatePanningView(elapsedMs) {
                    const stamps = getPanRange();
                    const delt = getPanDurationHoldRange(elapsedMs);
                    stamps[0] += delt;
                    stamps[1] += delt;
                    truncatePanningRanges(stamps);

                    const timestampToDisplay = panDirection === 'left' ? stamps[0] : stamps[1];
                    if (timeZoneService.moment() - timestampToDisplay <= snapToPresentMsThreshold) {
                        angular.element('.chart-panning-time-overlay', elem).text('Now');
                    } else {
                        angular
                            .element('.chart-panning-time-overlay', elem)
                            .text(timeZoneService.moment(stamps[0]).format('MM/DD/YYYY HH:mm:ss'));
                    }
                    updateOptions({
                        axes: {
                            y: {
                                valueRange: panAxisExtent,
                            },
                        },
                        dateWindow: stamps,
                    });
                }

                function truncatePanningRanges(ranges) {
                    let delt = 0;
                    if (ranges[1] > Date.now()) {
                        delt = Date.now() - ranges[1];
                    } else if (ranges[0] < Date.now() - ONE_YEAR) {
                        delt = Date.now() - ONE_YEAR - ranges[0];
                    }
                    ranges[0] += delt;
                    ranges[1] += delt;
                }

                function selectPanningView(elapsedMs) {
                    const stamps = getPanRange();
                    const delt = getPanDurationHoldRange(elapsedMs);

                    stamps[0] += delt;
                    stamps[1] += delt;

                    truncatePanningRanges(stamps);

                    updateOptions(
                        {
                            dateWindow: stamps,
                        },
                        true
                    );

                    const eventName = scope.isDetectorNative
                        ? CHART_DISPLAY_EVENTS.DETECTOR_NATIVE_RANGE_SELECTED
                        : CHART_DISPLAY_EVENTS.CHART_TIME_RANGE_SELECTED;

                    if (Date.now() - stamps[1] < snapToPresentMsThreshold) {
                        scope.$emit(eventName);
                    } else {
                        scope.$emit(eventName, stamps[0], stamps[1]);
                        angular
                            .element('.chart-panning-time-overlay', elem)
                            .removeClass('visible')
                            .text('');
                    }
                }

                function getPanRange(event) {
                    let start, end;
                    const timenow = new Date().getTime();
                    const chartconfig = getChartConfig();
                    if (!chartDisplayUtils.isAbsoluteTimeFromConfig(chartconfig)) {
                        start =
                            timenow +
                            chartDisplayUtils.getFetchDurationFromConfig(false, chartconfig);
                        end =
                            timenow +
                            chartDisplayUtils.getEndDurationFromConfig(false, chartconfig);
                    } else {
                        start = chartDisplayUtils.getFetchDurationFromConfig(true, chartconfig);
                        end = chartDisplayUtils.getEndDurationFromConfig(true, chartconfig);
                    }

                    let delta = event
                        ? scope.dyGraphInstance.toDataXCoord(event.offsetX || event.layerX) -
                          scope.dyGraphInstance.toDataXCoord(scope.timeRangeDrag.startOffset)
                        : 0;

                    if (end - delta > timenow) {
                        delta = end - timenow;
                    }

                    return [start - delta, end - delta];
                }

                function updateHighlightedSeries(tsid) {
                    const thresholds =
                        relatedThresholdService.getCorrelatedTSIDs(
                            scope.streamObject.metaDataMap[tsid]
                        ) || [];
                    const selectionArr = [tsid].concat(thresholds);
                    const plot = scope.dyGraphMaps.tsidToPlot[tsid];

                    if (plot) {
                        if (!plot.yAxisIndex) {
                            chartContainer.addClass('sf-y1-hovered');
                            chartContainer.removeClass('sf-y2-hovered');
                        } else {
                            chartContainer.removeClass('sf-y1-hovered');
                            chartContainer.addClass('sf-y2-hovered');
                        }
                    } else {
                        chartContainer.removeClass('sf-y1-hovered sf-y2-hovered');
                    }

                    if (scope.pinnedPoint) {
                        selectionArr.push(scope.pinnedPoint.seriesName);
                    }
                    if (scope.lastSelectionSet && tsid !== scope.lastSelectionSet[0]) {
                        //highlight the new tsid hovered, plus the old thresholds
                        setSelection([tsid].concat(scope.lastSelectionSet.slice(1)));
                    }
                    //inequality for null vs arr, or array being different
                    if (
                        (scope.lastSelectionSet && !selectionArr) ||
                        (!scope.lastSelectionSet && selectionArr) ||
                        (scope.lastSelectionSet &&
                            selectionArr &&
                            scope.lastSelectionSet.join() !== selectionArr.join())
                    ) {
                        checkVisiblityAndUpdate(selectionArr);
                    } else {
                        scope.lastSelectionSet = selectionArr;
                    }
                }

                function hoverTooltipClear() {
                    scope.nearestPoint = null;
                    scope.nearestEvents = null;
                }

                // Look for nearest bucket around a pinnable location, within half a
                // resolution interval before and after
                function getNearestEvents(xCoord) {
                    if (scope.chartEventStore) {
                        const res = getEventQueryRange();
                        const buckets = scope.chartEventStore.getHistogram();
                        for (let i = buckets.length - 1; i >= 0; i--) {
                            const timestamp = buckets[i].timeStamp;
                            const bucketStartXCoord = scope.dyGraphInstance.toDomXCoord(
                                timestamp - Math.ceil(res / 2)
                            );
                            const bucketEndXCoord = scope.dyGraphInstance.toDomXCoord(
                                timestamp + Math.floor(res / 2)
                            );
                            if (xCoord >= bucketStartXCoord && xCoord <= bucketEndXCoord) {
                                scope.currentEventsBucket = buckets[i];
                                return buckets[i];
                            }
                        }
                    }
                    scope.currentEventsBucket = null;
                }

                function getNearestPoint(event) {
                    return dyGraphUtils.findClosestPointOnTimeSlice(
                        event.offsetX || event.layerX,
                        event.offsetY || event.layerY,
                        scope.dyGraphInstance,
                        !!scope.dyGraph.stackedGraph,
                        scope.thresholdTSIDs
                    );
                }

                function showPreflightNotAvailableTooltip(show) {
                    if (show) {
                        if (!preflightNotAvailableTooltipShown) {
                            preflightNotAvailableTooltipShown = true;
                            preflightNotAvailableTooltipTextContainer.show();
                        }
                    } else {
                        if (preflightNotAvailableTooltipShown) {
                            preflightNotAvailableTooltipShown = false;
                            preflightNotAvailableTooltipTextContainer.hide();
                        }
                    }
                }

                function processAggregations(aggregations, buckets) {
                    buckets.forEach((priority) => {
                        if (!aggregations[priority.name]) {
                            aggregations[priority.name] = {};
                        }
                        priority.aggregations.forEach((state) => {
                            if (state.count > 0) {
                                if (alertTypeService.isClearingEvent(state.name)) {
                                    aggregations[priority.name].cleared = state.count;
                                } else {
                                    aggregations[priority.name].triggered = state.count;
                                }
                            }
                        });
                    });
                }

                function getRollupDetails(tsid) {
                    if (
                        !(
                            scope.dyGraphMaps &&
                            scope.dyGraphMaps.tsidToPlot &&
                            scope.jobMessageSummary &&
                            scope.jobMessageSummary.plotKeyToInfoMap
                        )
                    ) {
                        return null;
                    }

                    const tsidToPlot = scope.dyGraphMaps.tsidToPlot;
                    // it is possible the tsid is no longer in view when dragging the yellow selector
                    // in detector view and the chart is loading.
                    const tsidInfo = tsidToPlot[tsid];
                    if (!tsidInfo) {
                        return null;
                    }
                    const jobSummary = scope.jobMessageSummary;
                    const plotKeyToInfoMap = jobSummary.plotKeyToInfoMap;
                    const { uniqueKey } = tsidInfo;
                    const plotInfo = plotKeyToInfoMap[uniqueKey];
                    const rollupDetails = {};

                    if (jobSummary) {
                        rollupDetails.resolution = convertMSToString(
                            jobSummary.primaryJobResolution
                        );
                    }

                    // If rollup info is available
                    if (plotInfo && (plotInfo.rollups || plotInfo.sources)) {
                        rollupDetails.rollupMessage = chartDisplayUtils.getRollupMessage(
                            plotInfo.rollupApplied,
                            plotInfo.commonRollupType
                        );
                    }
                    return rollupDetails;
                }

                function graphMouseMove(event) {
                    scope.sharedChartState.verticalLineTimestamp =
                        scope.dyGraphInstance.toDataXCoord(event.offsetX || event.layerX);
                    if (scope.dragStartTime && !scope.disableTimeModification) {
                        scope.dragPerformed = true;
                        scope.intermediateDragTime = scope.sharedChartState.verticalLineTimestamp;
                    }

                    // Only show vertical bar for current timestamp at mouse position if
                    // there is no pinned timestamp, or there is one and this is the
                    // selected chart
                    if (!scope.sharedChartState.pinnedTimeStamp || !scope.hideLegend) {
                        // Use timestamp of the closest data point
                        const timestamp = scope.sharedChartState.verticalLineTimestamp;
                        const rowIdx = findClosestRowIndex(timestamp);
                        scope.sharedChartState.mouseHoverTimestamp = findClosestDataTimestamp(
                            timestamp,
                            rowIdx
                        );
                    }

                    if (scope.displaySelectedTimeOverlay) {
                        if (isMouseOverSelectedTime(event)) {
                            if (!isSelectedTimeHovered) {
                                chartContainer.addClass('hover');
                                isSelectedTimeHovered = true;
                            }
                        } else {
                            if (isSelectedTimeHovered) {
                                chartContainer.removeClass('hover');
                                isSelectedTimeHovered = false;
                            }
                        }
                    }

                    showPreflightNotAvailableTooltip(
                        // preflight available from time is there
                        preflightAvailableFrom &&
                            // the mouse is on the left of available time (basically hovering the red box)
                            scope.sharedChartState.verticalLineTimestamp < preflightAvailableFrom &&
                            // the mouse is under the x axis
                            event.offsetY >
                                scope.dyGraphInstance.toDomYCoord(
                                    scope.dyGraphInstance.yAxisRange()[0]
                                )
                    );

                    angular.element('.sf-ui').trigger('tooltipMouseMove');
                    $timeout.cancel(hoverTooltipTimeout);

                    const previousEventsBucket = scope.currentEventsBucket;
                    const eventsBucket = getNearestEvents(event.offsetX);
                    if (
                        (previousEventsBucket && !eventsBucket) ||
                        (!previousEventsBucket && eventsBucket) ||
                        (previousEventsBucket &&
                            eventsBucket &&
                            eventsBucket.timeStamp !== previousEventsBucket.timeStamp)
                    ) {
                        // Update highlighted/unhighlighted event marker
                        redrawEventHistogram();
                    }

                    // Show event tooltip if hovering over an events marker, which would
                    // be below the bottom x-axis
                    const candidateMarker =
                        eventsBucket &&
                        event.offsetY >
                            scope.dyGraphInstance.toDomYCoord(
                                scope.dyGraphInstance.yAxisRange()[0]
                            );
                    const candidatePoint = getNearestPoint(event);

                    if (!candidateMarker && !candidatePoint) {
                        // Wipe relevant values
                        hoverTooltipTimeout = $timeout(hoverTooltipClear, 1000);
                        return;
                    }

                    let needDigest = false; // Keep track so we don't do it multiple times

                    // Moved away from event marker, clear the hover tooltip
                    if (
                        !candidateMarker ||
                        (scope.nearestEvents &&
                            eventsBucket.timeStamp !== scope.nearestEvents.timestampInMs)
                    ) {
                        scope.nearestEvents = null;
                        needDigest = true;
                    }

                    // Show hover tooltip with event info if mousing over an event
                    // marker different than the one last seen, sorted by descending
                    // priority/severity
                    if (
                        candidateMarker &&
                        eventsBucket.aggregations &&
                        eventsBucket.aggregations.length
                    ) {
                        const aggregations = {};
                        const customEvents = [];
                        let customEventColor;
                        const labelToPlot = getLabelToEventPlotMap();

                        if (eventsBucket._composition) {
                            angular.forEach(eventsBucket._composition, function (agg, label) {
                                if (agg.aggregations && agg.aggregations.length) {
                                    angular.forEach(agg.aggregations, function (subagg) {
                                        if (subagg.aggregations && subagg.aggregations.length) {
                                            processAggregations(aggregations, subagg.aggregations);
                                            // is alert
                                        } else {
                                            const aggPlot = labelToPlot[label];
                                            if (aggPlot) {
                                                if (aggPlot.configuration) {
                                                    customEventColor =
                                                        aggPlot.configuration.colorOverride;
                                                }
                                            } else if (scope.eventOverlayColors) {
                                                customEventColor =
                                                    scope.eventOverlayColors[subagg.name];
                                            }
                                            customEvents.push({
                                                customCount: subagg.count,
                                                customEventName: aggPlot
                                                    ? aggPlot.name
                                                    : subagg.name,
                                                customEventColor: customEventColor,
                                            });
                                        }
                                    });
                                }
                            });
                        } else {
                            $log.error(
                                'Deprecated call to legacy event bucket display processing!'
                            );
                        }
                        const sortedPriorities = Object.keys(aggregations).sort(function sortNums(
                            a,
                            b
                        ) {
                            return parseInt(b) - parseInt(a);
                        });
                        const priorityCounts = sortedPriorities.map(function getSeverityCount(
                            priority
                        ) {
                            return {
                                severity:
                                    detectorPriorityService.getDisplayNameBySeverity(priority),
                                swatchClass:
                                    detectorPriorityService.getAlertClassBySeverity(priority),
                                triggered: aggregations[priority].triggered,
                                cleared: aggregations[priority].cleared,
                            };
                        });
                        // List custom events last
                        if (customEvents.length) {
                            customEvents.forEach((customEvent) => {
                                priorityCounts.push({
                                    severity: 'Custom',
                                    eventType: customEvent.customEventName,
                                    customEventColor: customEvent.customEventColor,
                                    swatchClass:
                                        detectorPriorityService.getSwatchClassBySeverity('0'),
                                    count: customEvent.customCount,
                                });
                            });
                        }

                        const res = getEventQueryRange();
                        const timeRange = timeZoneService.getCondensedTimeRange(
                            eventsBucket.timeStamp,
                            eventsBucket.timeStamp + res
                        );
                        scope.nearestEvents = {
                            timestampInMs: eventsBucket.timeStamp,
                            startTimestamp: timeRange.start,
                            endTimestamp: timeRange.end,
                            priorityCounts: priorityCounts,
                        };
                        needDigest = true;
                    }

                    if (scope.pinnedPoint) {
                        overlayRenderer.drawPoint(
                            scope.pinnedPoint,
                            scope.dyGraphMaps.tsidToColor[scope.pinnedPoint.seriesName],
                            8,
                            HEX_BLACK
                        );
                    }

                    // Get info for nearest data point
                    if (candidatePoint) {
                        // Show hover tooltip with point info if mousing near one different
                        // than the last one seen
                        const candidateTsid = candidatePoint.seriesName;
                        const candidateTimestamp = chartbuilderUtil.getLegendTimeStampString(
                            candidatePoint.point.xval
                        );
                        if (
                            !scope.nearestPoint ||
                            candidateTsid !== scope.nearestPoint.tsid ||
                            candidateTimestamp !== scope.nearestPoint.timestamp
                        ) {
                            scope.hoveredTsid = candidatePoint.seriesName;
                            //if a suitable point has been found, then set up the necessary data
                            //to show it in the graph tooltip

                            scope.pointPositionBelow = candidatePoint.point.canvasy < 80;
                            const relatedTSIDs = relatedThresholdService.getCorrelatedTSIDs(
                                scope.streamObject.metaDataMap[candidatePoint.seriesName]
                            );
                            let above = null;
                            let below = null;
                            relatedTSIDs.forEach(function (relatedTsid) {
                                const val =
                                    scope.dyGraph.file[candidatePoint.row][
                                        scope.dyGraphMaps.tsidToIndex[relatedTsid]
                                    ];
                                const tinfo = chartDisplayUtils.getThresholdInfo(
                                    scope.streamObject.metaDataMap[relatedTsid],
                                    scope.incidentInfo,
                                    scope.isDetectorNative
                                );
                                if (tinfo > 0) {
                                    above = val;
                                } else if (tinfo < 0) {
                                    below = val;
                                } else {
                                    $log.error('Correlated a signal to a signal.');
                                }
                            });

                            const seriesMetadata = getSeriesMetadata(
                                candidatePoint.seriesName,
                                scope.chartModel
                            );
                            const prefix =
                                safeLookup(seriesMetadata, 'plot.configuration.prefix') || '';
                            const suffix =
                                safeLookup(seriesMetadata, 'plot.configuration.suffix') || '';

                            const { configuration } =
                                scope.dyGraphMaps.tsidToPlot[scope.hoveredTsid] ||
                                chartDisplayUtils.getSyntheticPlot();
                            const unit = (configuration || {}).unitType;

                            scope.nearestPoint = {
                                tsid: candidateTsid,
                                value: unit
                                    ? valueFormatter.formatScalingUnit(
                                          candidatePoint.point.yval,
                                          unit,
                                          7
                                      )
                                    : valueFormatter.formatValue(
                                          candidatePoint.point.yval,
                                          7,
                                          getUseKMG2()
                                      ),
                                prefix: prefix,
                                suffix: suffix,
                                dimensions: chartDisplayUtils.resolveSeriesName(
                                    scope.streamObject.metaDataMap[candidatePoint.seriesName],
                                    scope.chartModel,
                                    true
                                ),
                                metric: seriesMetadata.metric || '',
                                color: scope.dyGraphMaps.tsidToColor[candidatePoint.seriesName],
                                timestamp: candidateTimestamp,
                                thresholdAbove:
                                    above || typeof above === 'number'
                                        ? valueFormatter.formatValue(above, 7, getUseKMG2())
                                        : null,
                                thresholdBelow:
                                    below || typeof below === 'number'
                                        ? valueFormatter.formatValue(below, 7, getUseKMG2())
                                        : null,
                                thresholdColorClass:
                                    detectorPriorityService.getSwatchClassBySeverity(
                                        getThresholdPriority() || 1000
                                    ),
                                rollup: getRollupDetails(scope.hoveredTsid),
                            };

                            setSeriesPointDraw(candidatePoint);

                            updateHighlightedSeries(candidatePoint.seriesName);

                            const nearestRow = candidatePoint.row;
                            pointHighlightCallback(nearestRow, true);
                            needDigest = true;
                        }
                    }

                    if (needDigest) {
                        scope.$digest();
                    }
                }

                function checkVisiblityAndUpdate(selectionArr) {
                    window.clearTimeout(thresholdTimeout);
                    if (relatedThresholdService.hasMultiple() && scope.lastSelectionSet) {
                        thresholdTimeout = window.setTimeout(function () {
                            scope.lastSelectionSet = selectionArr;
                            setSelection(scope.lastSelectionSet);
                            const r = updatePlotVisibility(
                                scope.renderScore ? scope.getRenderRatio() : 1
                            );
                            if (r) {
                                updateOptions({
                                    visibility: scope.dyGraph.visibility,
                                });
                            }
                        }, 200);
                    } else {
                        scope.lastSelectionSet = selectionArr;
                        setSelection(scope.lastSelectionSet);
                        const r = updatePlotVisibility(
                            scope.renderScore ? scope.getRenderRatio() : 1
                        );
                        if (r) {
                            updateOptions({
                                visibility: scope.dyGraph.visibility,
                            });
                        }
                    }
                }

                function dragPerformedTimeout() {
                    dragPerformedRecently = false;
                }

                function graphMouseUp(event, g) {
                    if (scope.dragPerformed) {
                        let offsetFactor = 0;
                        if (!g) {
                            //this seems to be off by about 5% of the range...
                            offsetFactor = angular
                                .element(elem)
                                .find('.sf-chart-target')
                                .offset().left;
                        }

                        const startTime = scope.dragStartTime;
                        const endTime = scope.dyGraphInstance.toDataXCoord(
                            (event.offsetX || event.layerX) - offsetFactor
                        );
                        const pixelDelta = Math.abs(
                            scope.dyGraphInstance.toDomXCoord(endTime) -
                                scope.dyGraphInstance.toDomXCoord(startTime)
                        );

                        if (mouseDownOnSelectedTime) {
                            // this handles dragging the orange detail view box in 1.5 detectors
                            // needs to do this after apply so all the clear stuff is done and we redo it.
                            if (g) {
                                scope.$apply();
                            }
                            const overlayTime = calculateOverlayTime();
                            mouseDownOnSelectedTime = false;
                            selectedTimeLink.removeClass('detector-dragging');
                            selectedTimeView.removeClass('detector-dragging');
                            chartContainer.removeClass('detector-dragging');
                            scope.$emit(
                                CHART_DISPLAY_EVENTS.CHART_CLICK_PIN,
                                Math.floor(
                                    overlayTime.start + (overlayTime.end - overlayTime.start) / 2
                                )
                            );
                        } else {
                            if (Date.now() - scope.dragStartWallTime > 200 || pixelDelta > 10) {
                                //if we have had enough time between mousedown and up, or moved enough pixels, zoom
                                zoomCallback(
                                    Math.min(scope.dragStartTime, endTime),
                                    Math.max(scope.dragStartTime, endTime)
                                );
                            } else {
                                //otherwise, pin
                                scope.setLegendPin(endTime);
                            }
                        }
                        Dygraph.cancelEvent(event);

                        // Tell chart not to set legend pin when user clicked on the chart
                        // as part of zooming
                        dragPerformedRecently = true;
                        $timeout(dragPerformedTimeout, 0, false);
                    }

                    scope.intermediateDragTime = null;
                    scope.dragPerformed = false;
                    scope.dragStartTime = null;
                    scope.dragStartWallTime = null;
                }

                // Show legend when clicking on chart display
                function setLegendPinByDomClick() {
                    if (scope.sharedChartState.mouseHoverTimestamp) {
                        scope.setLegendPin(scope.sharedChartState.mouseHoverTimestamp);
                        if (scope.sharedChartState.currentTabId) {
                            scope.$emit(CHART_DISPLAY_EVENTS.SELECT_TAB, 'data');
                        } else {
                            scope.$broadcast(CHART_DISPLAY_EVENTS.SET_LEGEND_CONTENT, 'data');
                        }
                    }
                }

                function showFullLegend() {
                    showLegendInDashboard();
                    const rowIdx = scope.dyGraph.file.length - 1;
                    pointHighlightCallback(rowIdx, true);
                    if (scope.sharedChartState.currentTabId) {
                        scope.$emit(CHART_DISPLAY_EVENTS.SELECT_TAB, 'data');
                    } else {
                        scope.$broadcast(CHART_DISPLAY_EVENTS.SET_LEGEND_CONTENT, 'data');
                    }
                }

                function showLegendInDashboard() {
                    scope.nearestPoint = null;
                    // Close any other open legend
                    $rootScope.$broadcast(CHART_DISPLAY_EVENTS.CHART_CLOSE_TOOLTIPS, scope);
                    const gridsterElem = elem.parents('.gridster-item');
                    const hasKubeWidgetClass = elem.hasClass('kube-page-widget-chart');
                    // Disable moving and resizing the chart, which causes issue with the
                    // sizing and positioning of the legendaxisLabelFormatter
                    gridsterElem
                        .find('.gridster-item-resizable-handler, .chart-drag-handle')
                        .hide();
                    // Highlight selected chart and its legend, if there is a selected
                    // chart (vs. single chart in chart builder page)
                    angular
                        .element('.gridster-chart-always-on-top')
                        .removeClass('gridster-chart-always-on-top');
                    gridsterElem.addClass('gridster-chart-always-on-top');
                    if (gridsterElem.length || hasKubeWidgetClass) {
                        elem.addClass('chart-selected');
                        angular.element('.chart-legend').addClass('selected');
                    }
                    scope.hideLegend = false;
                }

                function setLegendPin(timestamp, skipDigest) {
                    // Prevent pinning timestamp in non-graph charts, which isn't useful
                    if (
                        scope.chartModel.sf_uiModel.chartMode !== 'graph' ||
                        (timestamp && scope.sharedChartState.pinnedTimeStamp === timestamp) ||
                        scope.disableLegend
                    ) {
                        return;
                    }

                    showLegendInDashboard();
                    recomputeKeyset();

                    let rowIdx;
                    if (timestamp) {
                        rowIdx = findClosestRowIndex(timestamp);
                        scope.sharedChartState.pinnedTimeStamp = findClosestDataTimestamp(
                            timestamp,
                            rowIdx
                        );
                    } else {
                        if (scope.sharedChartState.mouseHoverTimestamp) {
                            rowIdx = findClosestRowIndex(
                                scope.sharedChartState.mouseHoverTimestamp
                            );
                        } else {
                            // Use most recent timestamp
                            rowIdx = scope.dyGraph.file.length - 1;
                            if (rowIdx !== -1) {
                                scope.legendLatestTimestamp =
                                    scope.dyGraph.file[rowIdx][0].getTime();
                            }
                        }
                    }
                    pointHighlightCallback(rowIdx, skipDigest);
                    updateEvents(timestamp);
                    updateLegendValues();
                    userAnalytics.event('chart', 'chart-legend-opened');
                }

                function hasId() {
                    return scope.chartModel.sf_id || scope.snapshot.id;
                }

                // hasId actually returns the id, but the name doesn't suggest that so creating one with a proper name
                function getId() {
                    return hasId();
                }

                function updateOriginalChartRanges() {
                    if (angular.isUndefined(scope.origStartTime) && scope.chartModel && hasId()) {
                        scope.origStartTime = chartDisplayUtils.getFetchDurationFromConfig(
                            true,
                            getChartConfig()
                        );
                    }
                    if (angular.isUndefined(scope.origEndTime) && scope.chartModel && hasId()) {
                        scope.origEndTime = chartDisplayUtils.getEndDurationFromConfig(
                            true,
                            getChartConfig()
                        );
                    }
                }

                function resetLegendPin() {
                    if (scope.hideLegend) {
                        // Legend already hidden
                        return;
                    }

                    scope.legendLatestTimestamp = null;
                    scope.$emit(CHART_DISPLAY_EVENTS.RESET_LEGEND_PIN);
                    if (
                        scope.sharedChartState.currentTabId !== 'data' &&
                        scope.sharedChartState.currentTabId !== 'events'
                    ) {
                        // Only hide legend if another type of tab is active
                        scope.hideLegend = true;
                    } else if (scope.sharedChartState.pinnedTimeStamp) {
                        // Only go to previous tab when unpinning chart
                        scope.$emit(CHART_DISPLAY_EVENTS.SELECT_PREVIOUS_TAB);
                    }
                    scope.sharedChartState.pinnedTimeStamp = null;
                    scope.pinnedPoint = null;
                    scope.legendPinWallTime = null;
                    // Close any other open legend
                    $rootScope.$broadcast(CHART_DISPLAY_EVENTS.CHART_CLOSE_TOOLTIPS, scope);
                    // Un-highlight previously selected chart
                    angular
                        .element('.gridster-chart-always-on-top')
                        .removeClass('gridster-chart-always-on-top');
                    angular.element('.chart-selected').removeClass('chart-selected');
                    // Re-enable moving and resizing the chart
                    elem.parents('.gridster-item')
                        .find('.gridster-item-resizable-handler, .chart-drag-handle')
                        .show();
                    updateEvents(null);
                    updateLegendValues();
                }

                function updateHistogramDygraphColor() {
                    updateOptions({
                        color: scope.chartModel.sf_uiModel.chartconfig.histogramColor,
                    });
                }

                function clearHistogramDygraphColor() {
                    updateOptions({ color: null });
                }

                function isHistogram() {
                    return (
                        scope.chartModel.sf_uiModel.chartMode === 'graph' &&
                        scope.chartModel.sf_uiModel.chartType === 'heatmap'
                    );
                }

                function getDyGraphConfig() {
                    const config = {
                        drawPoints: false,
                        showRoller: false,
                        connectSeparatedPoints: true,
                        //note that stackedGraphNaNFill affects the data sent to the RENDERER
                        stackedGraphNaNFill: 'none',
                        gridLineColor: themeService.dark ? '#999' : '#cccccc',
                        rightGap: 0,
                        showLabelsOnHighlight: false,
                        hideOverlayOnMouseOut: false,
                        axes: scope.dyGraph.axes,
                        labels: scope.dyGraph.labels,
                        labelsKMB: !scope.dyGraph.useKMG2,
                        labelsKMG2: scope.dyGraph.useKMG2,
                        interactionModel: {
                            mousedown: graphMouseDown,
                            mousemove: graphMouseMove,
                            mouseup: graphMouseUp,
                            click: angular.noop,
                        },
                        drawCallback: function () {
                            underlayCallback();
                        },
                        highlightCallback: function (a) {
                            const candidatePoint = getNearestPoint(a);
                            setSeriesPointDraw(candidatePoint);
                            underlayCallback();
                        },
                        fillAlpha: 0.4,
                    };

                    if (isHistogram()) {
                        config.color =
                            scope.chartModel.sf_uiModel.chartconfig.histogramColor ||
                            DEFAULT_HISTOGRAM_COLOR;
                    }

                    return config;
                }

                function updatePlotNames() {
                    angular.forEach(scope.dyGraphMaps.tsidToIndex, function (idx, tsid) {
                        scope.dyGraph.labels[idx] = tsid;
                    });
                    return false;
                }

                function updateColorIfValid(idx) {
                    const firstVisible = idx || findFirstVisibleTSID();
                    if (
                        firstVisible &&
                        scope.chartModel.sf_uiModel.chartconfig.colorByValue &&
                        scope.chartModel.sf_uiModel.chartconfig.colorByValueScale &&
                        scope.dyGraph.file[scope.dyGraph.file.length - 1]
                    ) {
                        const val = scope.dyGraph.file[scope.dyGraph.file.length - 1][firstVisible];
                        byValColor = colorByValueService.getColorForValue(
                            scope.chartModel.sf_uiModel.chartconfig.colorByValueScale,
                            val
                        );
                    }
                }

                function applyFullDygraphModel() {
                    const configuration = angular.extend(
                        scope.dyGraph,
                        scope.dyGraph.axesLabels,
                        scope.dyGraphModeMixin
                    );
                    //convert out of legacy drawAxis spec

                    configuration.axes = configuration.axes || {};
                    configuration.axes.y = configuration.axes.y || {};
                    configuration.axes.x = configuration.axes.x || {};

                    if (angular.isDefined(configuration.drawYAxis)) {
                        configuration.axes.y.drawAxis = configuration.drawYAxis;
                        delete configuration.drawYAxis;
                    }

                    if (angular.isDefined(configuration.drawXAxis)) {
                        configuration.axes.x.xRangePad = 10;
                        configuration.axes.x.drawAxis = configuration.drawXAxis;
                        delete configuration.drawXAxis;
                    }

                    if (angular.isDefined(configuration.drawXGrid)) {
                        configuration.axes.x.drawGrid = configuration.drawXGrid;
                        delete configuration.drawXGrid;
                    }

                    if (angular.isDefined(configuration.drawYGrid)) {
                        configuration.axes.y.drawGrid = configuration.drawYGrid;
                        delete configuration.drawYGrid;
                    }

                    if (scope.datapointReceived && scope.dyGraphInstance) {
                        updateOptions(configuration);
                    }
                }

                function updateStackState() {
                    const type = scope.chartModel.sf_uiModel.chartType;
                    scope.dyGraph.stackedGraph =
                        scope.chartModel.sf_uiModel.chartconfig.stackedChart &&
                        !(type === 'line' || type === 'heatmap');
                }

                function updateIncludeZero() {
                    scope.dyGraph.includeZero = scope.chartModel.sf_uiModel.chartconfig.includeZero;
                }

                function updateShowLegendState() {
                    scope.dyGraph.showLegend = scope.chartModel.sf_uiModel.chartconfig.showLegend;
                    scope.dyGraph.dimensionInLegend =
                        scope.chartModel.sf_uiModel.chartconfig.dimensionInLegend;
                    scope.dyGraph.dimensionKeys =
                        scope.chartModel.sf_uiModel.chartconfig.dimensionKeys;
                }

                function getUseKMG2() {
                    return scope.chartModel.sf_uiModel.chartconfig.useKMG2;
                }

                function updateUseKMG2() {
                    scope.dyGraph.labelsKMB = !getUseKMG2();
                    scope.dyGraph.labelsKMG2 = getUseKMG2();
                }

                function updateBucketCount() {
                    scope.dyGraph.bucketCount = scope.chartModel.sf_uiModel.chartconfig.bucketCount;
                }

                function getRenderRatio() {
                    return Math.min(
                        (SAMPLE_CONSTANTS.MAXIMUM_SAMPLE_RATE - 1) / scope.renderScore,
                        1
                    );
                }

                function publishRenderStats() {
                    const renderRatio = scope.getRenderRatio();
                    if (isNaN(renderRatio)) {
                        return;
                    }

                    const isThrottleCandidateAndDataReady =
                        chartDisplayUtils.isRenderThrottledChartMode(
                            scope.chartModel.sf_uiModel.chartMode
                        ) &&
                        scope.streamObject &&
                        Object.keys(scope.streamObject.metaDataMap).length > 0;

                    if (
                        preTransformedModel.sf_flowVersion === 2 &&
                        isThrottleCandidateAndDataReady &&
                        renderRatio < 1
                    ) {
                        scope.renderStats = {
                            rendered: Math.ceil(
                                Object.keys(scope.streamObject.metaDataMap).length * renderRatio
                            ),
                            total:
                                scope.jobMessageSummary.passThruCount +
                                scope.jobMessageSummary.throttleCount,
                        };
                    } else if (renderRatio < 1 && isThrottleCandidateAndDataReady) {
                        scope.renderStats = chartDisplayUtils.getRenderThrottleStats(
                            scope.chartModel.sf_uiModel,
                            scope.jobMessageSummary.plotKeyToInfoMap,
                            scope.chartModel.sf_uiModel.chartconfig.disableThrottle
                                ? SAMPLE_CONSTANTS.MAXIMUM_SAMPLE_RATE
                                : SAMPLE_CONSTANTS.DEFAULT_SAMPLE_RATE,
                            renderRatio
                        );
                    } else {
                        scope.renderStats = null;
                    }
                    scope.$emit(CHART_DISPLAY_EVENTS.RENDER_STATS, scope.renderStats);
                }

                function shouldShowThreshold() {
                    const previewRules = safeLookup(scope.detectorPreviewConfig, 'preview.rules');
                    if (
                        (previewRules &&
                            previewRules.length &&
                            (previewRules[0].showThreshold || isDetectorV2())) ||
                        explainIncidentId()
                    ) {
                        return true;
                    }
                    return false;
                }

                function updatePlotVisibility(displayRatio) {
                    let needRender = false;
                    const sel = scope.lastSelectionSet;
                    let numVisible = 0;

                    angular.forEach(scope.dyGraphMaps.tsidToIndex, function (seriesIndex, tsid) {
                        const metaData = scope.streamObject.metaDataMap[tsid];
                        const targetedPlot = getPlotObject(metaData, scope.chartModel);
                        let visibility = true;

                        if (targetedPlot) {
                            visibility = !targetedPlot.invisible;
                        }
                        //if by plot configuration setting, this MTS should have been visible, hide it based on the % of plots we wish to hide
                        //due to displayRatio

                        const isThreshold = chartDisplayUtils.getThresholdType(
                            metaData,
                            scope.incidentInfo,
                            scope.isDetectorNative
                        );

                        if (!isThreshold) {
                            visibility =
                                visibility && (murmurHash(tsid) % 100) / 100 < displayRatio;
                        } else {
                            visibility = sel ? sel.indexOf(tsid) !== -1 : false;
                        }

                        if (showThreshold && alwaysVisibleThresholdTsids.indexOf(tsid) > -1) {
                            visibility = true;
                        }

                        if (visibility) {
                            numVisible++;
                        }
                        if (scope.dyGraph.visibility[seriesIndex - 1] !== visibility) {
                            needRender = true;
                            scope.dyGraph.visibility[seriesIndex - 1] = visibility;
                        }
                    });
                    scope.highlightAllowed = numVisible > 1;
                    return needRender;
                }

                function updateTicksForPrecision() {
                    const hasTicks = safeLookup(scope, 'dyGraphInstance.axes_.0.ticks.length');
                    if (hasTicks) {
                        scope.dynamicPrecision =
                            dyGraphConfigurationGenerator.updatePrecisionOptions(
                                scope.dyGraphInstance,
                                scope.dyGraph,
                                scope.chartModel,
                                scope.dynamicPrecision
                            );
                    }
                }

                function updateAllOptions() {
                    updateStackState();
                    updateIncludeZero();
                    updateShowLegendState();
                    updateColorIfValid(scope.dyGraphMaps.dataIndex);
                    updateColors();
                    updateLineThickness();
                    updateThresholdingMetadata();
                    publishRenderStats();
                    dyGraphConfigurationGenerator.updateAllOptions(
                        scope.chartModel,
                        scope.dyGraph,
                        scope.dyGraphMaps,
                        getPlotObject,
                        chartDisplayUtils.resolveSeriesName,
                        scope.streamObject,
                        scope.renderScore ? scope.getRenderRatio() : 1
                    );
                    updateTicksForPrecision();
                    updatePlotVisibility(scope.renderScore ? scope.getRenderRatio() : 1);
                    updateModeMixin();
                    //TODO : this probably needs to be split into three different calls to full refresh each mode's constituents
                    const mode = getChartMode();

                    if (mode === 'graph') {
                        applyFullDygraphModel();
                    }
                }

                function resetDyGraph() {
                    relatedThresholdService = new ThresholdCorrelator();
                    alwaysVisibleThresholdTsids = [];
                    updateOptionQueue = [];
                    scope.thresholdTSIDs = {};
                    scope.newTSIDEncountered = false; // this flag asserts whether or not we need to update all TSID related properties at once
                    scope.lastHighlightedPoints = [];
                    scope.lastHighlightedTime = null;
                    scope.latestPointsRenderedTime = null; //the last timestamp of points we rendered
                    scope.latestPointsRenderedCount = null; //the number of points in that timestamp
                    scope.latestPointsRenderedAxisAdvance = null; //the last x-axis extent timestamp we rendered
                    scope.seenThresholdsMap = {};
                    scope.dyGraph = {
                        labels: ['Timestamp'],
                        useKMG2: false,
                        graph: null,
                        axes: {
                            x: {
                                axisLabelFormatter: axisLabelFormatter,
                                axisLineColor: '#7d7d7d',
                                axisLineWidth: 1,
                                axisTickSize: 5,
                            },
                            y: {
                                axisLineWidth: 0,
                                axisLineColor: 'transparent',
                            },
                            axisLabelColor: '#999999',
                            axisLabelFontSize: 12,
                        },
                        file: [],
                        visibility: [],
                        colors: [],
                        strokeWidth: [],
                        thresholdingInfo: [],
                        series: {},
                        axesLabels: {},
                        yRangePad: 4,
                    };

                    scope.dyGraphMaps = {
                        tsidToIndex: {},
                        timeStampToTimeSlice: {},
                        tsidToPlot: {}, //maps TSIDs to sf_uiModel.allPlots[x]
                        tsidToColor: {}, // used to build tooltip.
                        allPlotsIndexToInsertIndex: [],
                    };

                    const maxPlotKey =
                        scope.chartModel && scope.chartModel.sf_uiModel.allPlots.length
                            ? scope.chartModel.sf_uiModel.allPlots.length
                            : 0;
                    for (let x = 0; x <= maxPlotKey; x++) {
                        scope.dyGraphMaps.allPlotsIndexToInsertIndex[x] = 1;
                    }

                    scope.legendData = [];

                    if (!scope.hideLegend && !pinAfterLoad) {
                        scope.resetLegendPin();
                    }

                    setSelection([]);
                }

                function addFilters(chart) {
                    if (!scope.filters) return chart;

                    const wrappedChart = Chart.create(chart);
                    scope.filters.forEach(function (filter) {
                        const key = filter.split(':')[0];
                        const value = filter.substr(key.length + 1);
                        wrappedChart.filter(key, value);
                    });

                    return wrappedChart.config();
                }

                function setCachedModel() {
                    scope.cachedModel = angular.copy(scope.chartModel);
                }

                function resetShowNoDataMessage() {
                    scope.showNoDataMessage = false;
                }

                function refetchChart() {
                    if (!hasId()) return $q.when(null);
                    let signalBoostSource;
                    if (scope.cachedModel) {
                        signalBoostSource = $q.when(angular.copy(scope.cachedModel));
                    } else {
                        if (scope.chartModel.sf_type === 'Detector') {
                            const endPoint = DetectorV2SearchService.get;
                            signalBoostSource = endPoint(scope.chartModel.sf_id).get();
                        } else {
                            signalBoostSource = chartV2Service.get(scope.chartModel.sf_id);
                        }
                    }

                    if (scope.showNoDataMessage === true) {
                        resetShowNoDataMessage();
                    }

                    return signalBoostSource.then(
                        function (chart) {
                            addFilters(chart);
                            scope.chartModel = chart;
                            return scope.chartModel;
                        },
                        function () {
                            $log.log('Failed refetching chart.');
                            return scope.chartModel;
                        }
                    );
                }

                function applyUrlState() {
                    if (scope.disableUrl) {
                        return;
                    }

                    chartDisplayUtils.updateGlobalTimeRange(
                        scope.chartModel,
                        timepickerUtils.getChartConfigURLTimeParameters()
                    );

                    updateOriginalChartRanges();

                    const t = urlOverridesService.getGlobalTimePicker();
                    if (t) {
                        scope.$emit(CHART_DISPLAY_EVENTS.REQUEST_INIT_TIME_PICKER, t);
                    }

                    if (!hasInitialized) {
                        return;
                    }
                    onJobRequested();
                }

                function mouseLeave(evt) {
                    setSeriesPointDraw();
                    if (scope.dragStartTime) {
                        scope.dragStartTime = null;
                        scope.dragStartWallTime = null;
                        scope.intermediateDragTime = null;
                        scope.dragPerformed = false;
                        mouseDownOnSelectedTime = false;
                    }
                    hoverTooltipClear();
                    scope.currentEventsBucket = null;
                    scope.dyGraphInstance.clearSelection();
                    scope.lastSelectionSet = null;

                    chartContainer.removeClass('sf-y1-hovered');
                    chartContainer.removeClass('sf-y2-hovered');

                    checkVisiblityAndUpdate();
                    showPreflightNotAvailableTooltip(false);
                    evt.preventDefault();
                    evt.stopPropagation();
                }

                function debouncedJobMessagesReceived(msgs) {
                    $timeout.cancel(jobMessageProcessTimeout);
                    jobMessageProcessTimeout = $timeout(function jobMessagesAsync() {
                        jobMessagesReceived(msgs);
                    }, 1000);
                }

                function jobMessagesReceived(msgs) {
                    scope.jobMessageSummary = chartDisplayUtils.jobMessagesReceived(
                        msgs,
                        scope.chartModel
                    );
                    updateRenderScore();
                    scope.$emit(
                        CHART_DISPLAY_EVENTS.JOB_RESOLUTION_DETERMINED,
                        scope.jobMessageSummary.primaryJobResolution,
                        scope.identifier
                    );
                    scope.$emit(
                        CHART_DISPLAY_EVENTS.JOB_FETCH_CAPS,
                        scope.jobMessageSummary.jobFetchCaps
                    );
                    scope.$emit(
                        CHART_DISPLAY_EVENTS.CHART_WINDOW_MISALIGNED,
                        scope.jobMessageSummary.misalignedResolution,
                        scope.identifier
                    );

                    if (scope.jobMessageSummary) {
                        scope.chartRollupMessage = scope.jobMessageSummary.chartRollupMessage;
                        if (angular.isUndefined(scope.chartRollupMessage)) {
                            scope.chartRollupMessage = 'determining rollup...';
                        }
                        scope.$emit(
                            CHART_DISPLAY_EVENTS.CHART_ROLLUP_DETERMINED,
                            scope.chartRollupMessage,
                            scope.identifier
                        );
                    }

                    dyGraphUtils.setInstanceProperty(
                        scope.dyGraphInstance,
                        'pointTickMs',
                        scope.jobMessageSummary.primaryJobResolution
                    );
                    if (!isOriginallyV2) {
                        scope.chartDisplayDebouncer.latestJobStats(
                            scope.jobMessageSummary.passThruCount
                        );
                    } else if (scope.streamObject && scope.streamObject.metaDataMap) {
                        scope.chartDisplayDebouncer.latestJobStats(
                            Object.keys(scope.streamObject.metaDataMap).length
                        );
                    }
                    publishRenderStats();

                    scope.$emit(CHART_DISPLAY_EVENTS.JOB_FEEDBACK_RECEIVED, msgs, scope.identifier);
                }

                function resize(throttle) {
                    if (throttle) {
                        //wait for animation to complete, then size
                        scope.resizeThrottle = $timeout(resize, 1000);
                        return;
                    }
                    if (
                        scope.dyGraphInstance &&
                        scope.chartModel.sf_uiModel.chartMode === 'graph'
                    ) {
                        scope.dyGraphInstance.resize();
                        //not happy about having to call private methods, however
                        //resize logic we need for redraws wont fire if the sizes havent changed
                        scope.dyGraphInstance.resizeElements_();
                        try {
                            scope.dyGraphInstance.predraw_();
                        } catch (e) {
                            $log.warn(
                                'Failed to predraw likely due to transitionary inter-job state!'
                            );
                        }
                        overlayRenderer.recomputeCanvasExtents();
                        adjustOnChartLegendOverflow();
                    }
                    positionCache = {};
                    if (scope.displaySelectedTimeOverlay) {
                        calculateSelectedTimeOverlay();
                    }
                }

                function calculateOverlayTime() {
                    let overlayStart, overlayEnd;
                    if (scope.currentSelectedTime.absoluteStart) {
                        overlayStart = scope.currentSelectedTime.absoluteStart;
                        overlayEnd = scope.currentSelectedTime.absoluteEnd;
                    } else {
                        const now = Date.now();
                        overlayStart = now - scope.currentSelectedTime.range;
                        overlayEnd = now - scope.currentSelectedTime.rangeEnd;
                    }

                    if (mouseDownOnSelectedTime && scope.intermediateDragTime) {
                        const timeShifted = Math.floor(
                            scope.intermediateDragTime - scope.dragStartTime
                        );
                        overlayStart += timeShifted;
                        overlayEnd += timeShifted;
                    }
                    return {
                        start: overlayStart,
                        end: overlayEnd,
                    };
                }

                function calculateSelectedTimeOverlay() {
                    if (!scope.currentSelectedTime) {
                        return;
                    }
                    const dygraph = scope.dyGraphInstance;
                    const dyGraphArea = dygraph.getArea();

                    const tr = getRenderTimeRange();
                    const overlayRange = calculateOverlayTime();

                    const resolutionWidth =
                        (dyGraphArea.w * scope.jobMessageSummary.primaryJobResolution) /
                        (tr[1] - tr[0]);
                    const halfResolution = Math.ceil(resolutionWidth / 2);

                    let xstarts = Math.floor(
                        dygraph.toDomCoords(Math.max(overlayRange.start, tr[0]), 0, 0)[0]
                    );
                    let xends = Math.floor(
                        dygraph.toDomCoords(Math.min(overlayRange.end, tr[1]), 0, 0)[0]
                    );
                    // if the range is smaller than minimum size, use the minimum size
                    if (xends - xstarts < MINIMUM_SELECTED_TIME_WIDTH) {
                        const halfWidth = Math.ceil(MINIMUM_SELECTED_TIME_WIDTH / 2);
                        const midPoint = Math.ceil((xends + xstarts) / 2);
                        xstarts = midPoint - halfWidth;
                        xends = xstarts + MINIMUM_SELECTED_TIME_WIDTH;
                    }

                    const overlayWidth = xends - xstarts;
                    if (xends > dyGraphArea.x + dyGraphArea.w) {
                        xends = dyGraphArea.x + dyGraphArea.w;
                        xstarts = xends - overlayWidth;
                    }

                    // buffer by a resolution
                    const topBottom = dyGraphUtils.findMaxMinForDomRange(
                        xstarts - halfResolution,
                        xends + halfResolution,
                        scope.dyGraphInstance
                    );
                    if (!topBottom) {
                        return;
                    }

                    if (topBottom.bottom - topBottom.top < MINIMUM_SELECTED_TIME_HEIGHT) {
                        const half = Math.floor(MINIMUM_SELECTED_TIME_HEIGHT / 2);
                        topBottom.top = Math.max(0, topBottom.top - half);
                        topBottom.bottom = topBottom.bottom + half;
                    }
                    if (topBottom.bottom > dyGraphArea.y + dyGraphArea.h) {
                        topBottom.bottom = dyGraphArea.y + dyGraphArea.h;
                    }

                    const left = Math.min(
                        Math.max(xstarts, dyGraphArea.x),
                        dyGraphArea.x + dyGraphArea.w - overlayWidth
                    );

                    selectedTimeRange = {
                        start: left,
                        end: left + overlayWidth,
                        top: topBottom.top,
                        bottom: topBottom.bottom,
                    };
                    // if left is less than possible area, chart is still initing so we are ignoring it.
                    if (left >= dyGraphArea.x) {
                        selectedTimeView.show();
                        selectedTimeView.css('left', left + 'px');
                        selectedTimeView.css('width', overlayWidth + 'px');
                        if (topBottom) {
                            selectedTimeView.css('top', topBottom.top - 5 + 'px');
                            selectedTimeView.css(
                                'height',
                                topBottom.bottom - topBottom.top + 5 + 'px'
                            );
                        } else {
                            selectedTimeView.css('top', null);
                            selectedTimeView.css('height', null);
                        }

                        const linkLeft = left + overlayWidth / 2 - 3;
                        const width = dyGraphArea.x + dyGraphArea.w - linkLeft + 40;
                        selectedTimeLink.show();
                        selectedTimeLink.css('left', linkLeft + 2 + 'px');
                        selectedTimeLink.css('width', Math.ceil(width + 1) + 'px');
                        if (topBottom) {
                            selectedTimeLink.css('height', Math.ceil(topBottom.top + 6) + 'px');
                        } else {
                            selectedTimeLink.css('height', null);
                        }
                    }
                }

                function getV2Model(model) {
                    const v2Model = angular.copy(preTransformedModel);
                    v2Model.name = model.sf_chart;
                    v2Model.description = model.sf_description;
                    v2Model.programText = model.sf_viewProgramText;
                    v2Model.options = uiModelToVisualizationOptionsService(
                        model.sf_uiModel
                    ).serialize();
                    return v2Model;
                }

                function setCachedCharts() {
                    if (!hasId()) {
                        return;
                    }
                    const models = sessionCache.getId('cachedCharts');
                    const currentModel = scope.cachedModel || scope.chartModel;
                    if (chartVersionService.getVersion(preTransformedModel) === 2) {
                        models.push(getV2Model(currentModel));
                    } else {
                        models.push(currentModel);
                    }
                    sessionCache.setId('cachedCharts', models);
                }

                function updatePreflightEvent(
                    ev,
                    eventAggregatedData,
                    preflightProgressTimestamp,
                    percent
                ) {
                    if (!scope.isDetectorNative) {
                        preflightTimestamp = preflightProgressTimestamp;
                        preflightPercent = percent;
                        if (eventAggregatedData.length) {
                            eventAggregatedData.forEach(function (agg) {
                                scope.chartEventStore.addEventHistogram(
                                    agg.timestamp,
                                    agg.is,
                                    agg.priority,
                                    agg.count
                                );
                            });
                        }
                        redrawEventHistogram();
                    }
                }

                function getEventsForChart() {
                    if (
                        scope.isDetectorNative &&
                        scope.chartEventStore &&
                        scope.passChartAndEventInformation
                    ) {
                        const eventPromise = scope.chartEventStore.getEvents(0, Date.now());
                        eventPromise.then(function (results) {
                            const information = {
                                metadata: scope.streamObject.metaDataMap,
                            };
                            if (results.length) {
                                information.events = results.find(
                                    (result) => result.properties.is === 'anomalous'
                                );
                            }
                            scope.passChartAndEventInformation(information);
                        });
                    }
                }

                function shouldRender() {
                    return (
                        !chartDisplayUtils.isRenderThrottledChartMode(
                            scope.chartModel.sf_uiModel.chartMode
                        ) ||
                        (scope.renderScore !== null &&
                            scope.renderScore < SAMPLE_CONSTANTS.MAXIMUM_SAMPLE_RATE)
                    );
                }

                function updateRenderScore() {
                    if (scope.streamObject) {
                        if (features.disableBrowserProtectionMode) {
                            scope.renderScore = 1;
                            return;
                        }
                        // we're basically trying to detect v2 charts/detectors here
                        if (
                            preTransformedModel.sf_modelVersion !== 2 &&
                            !preTransformedModel.$isOriginallyV2
                        ) {
                            scope.renderScore = chartDisplayUtils.calculateRenderScore(
                                scope.chartModel.sf_uiModel,
                                scope.jobMessageSummary.plotKeyToInfoMap,
                                scope.chartModel.sf_uiModel.chartconfig.disableThrottle
                                    ? SAMPLE_CONSTANTS.MAXIMUM_SAMPLE_RATE
                                    : SAMPLE_CONSTANTS.DEFAULT_SAMPLE_RATE
                            );
                        } else {
                            scope.renderScore = chartDisplayUtils.calculateRenderScoreV2(
                                scope.chartModel.sf_uiModel,
                                scope.streamObject.metaDataMap
                            );
                        }
                    }
                }

                function refreshChart() {
                    if (scope.chartDisplayDebouncer.isWaiting()) {
                        return;
                    }

                    // right now we're using the nuclear option to refresh dygraph
                    // we do this because dygraph requires certain fields to be updated in parallel
                    // label length, data lengtth, etc.
                    // optimize this later if we need marginally better perf

                    scope.sharedChartState.pinnedTimeStamp = null;
                    scope.sharedChartState.mouseHoverTimestamp = null;
                    scope.sharedChartState.verticalLineTimestamp = null;
                    scope.legendLatestTimestamp = null;
                    resetDyGraph();

                    //keep track of the original range that this chart has data for, in order
                    //to determine when to drop out of persisted data lookups
                    updateOriginalChartRanges();

                    updateEventQueryState();
                    scope.$emit(CHART_DISPLAY_EVENTS.RENDER_STATS, null);
                    scope.$emit(CHART_DISPLAY_EVENTS.JOB_FETCH_CAPS, null);

                    // apply overrides if they exist, on a valid chart model
                    const mode = scope.chartModel.sf_uiModel.chartMode;
                    if (mode !== 'event') {
                        updateData(scope.chartModel.sf_uiModel);
                    } else if (scope.streamObject) {
                        //if there's a job to stop, stop it
                        scope.streamObject.stopStream(
                            getJobStopReasonPrefix() + ', mode switch to non-MTS display'
                        );
                    }

                    initializeEventStreaming();
                    if (scope.chartModel.sf_uiModel.allPlots.filter((p) => !p.transient).length) {
                        updateAllOptions();
                    } else {
                        // clear all dygraph data when there's no plots selected
                        updateOptions({
                            file: [],
                        });
                    }

                    if (smokeyStateProvider) {
                        // We use the transient model here because the event program includes
                        // event overlay events, which we don't want considered when pulling
                        // related detector alert states.
                        const programText = getTransientModelWithoutOverrides().sf_viewProgramText;
                        smokeyStateProvider.onSignalFlowChanged(programText);
                    }
                }

                function onEventOverlayUpdate(overlayParams) {
                    if (!overlayParams) {
                        return;
                    }

                    scope.overlayEventPlots = overlayParams.eventPlots;
                    scope.eventOverlayColors = angular.copy(overlayParams.eventOverlayColors);
                    updateEventColor(false);
                    const mode = getChartMode();
                    if (mode === 'graph') {
                        applyFullDygraphModel();
                    }
                    updateEventQueryState();
                    scope.resetLegendPin();
                }

                function clearAlertStateIndicators() {
                    // Clear out previous classes which may have been added
                    let severityClasses = SMOKEY_SEVERITY_LEVELS.slice(0);
                    severityClasses = severityClasses.map((severityLevel) =>
                        severityLevel.toLowerCase()
                    );

                    const chartLegendElement = elem.find('.chart-legend');
                    const gridsterElem = elem.parents('.gridster-item');
                    chartLegendElement.removeClass(severityClasses.join(' '));
                    gridsterElem.removeClass(
                        severityClasses.map((c) => 'alert-state-' + c).join(' ')
                    );
                }

                function setAlertStateIndicators(alertState) {
                    if (!alertState) return;

                    const chartLegendElement = elem.find('.chart-legend');
                    const gridsterElem = elem.parents('.gridster-item');
                    const alertStateSwatchClassName = alertState.toLowerCase();
                    chartLegendElement.addClass(alertStateSwatchClassName);
                    gridsterElem.addClass('alert-state-' + alertStateSwatchClassName);
                }

                function smokeyStateUpdated(alertObj) {
                    if (alertObj.alertState) {
                        // Clear out previous classes which may have been added
                        clearAlertStateIndicators();
                    }
                    scope.activeAlerts = alertObj.activeAlerts;
                    scope.alertState = alertObj.alertState;
                    scope.showAlertTicker =
                        alertObj && alertObj.alertState && alertObj.alertState !== 'Normal';

                    scope.$emit('alert state updated', alertObj);

                    // Apply new classes
                    setAlertStateIndicators(scope.alertState);
                }

                function initializeEventStreaming() {
                    if (scope.chartEventStore) {
                        scope.chartEventStore.cancel();
                    }

                    scope.eventFetchTimestamp = null;
                    scope.dyGraphMaps.timeStampToEvents = {};

                    const startEnd = modelToStartEnd(getChartConfig());

                    const res = getEventQueryRange();
                    let eventDataSourceVersion = null;
                    if ((isPreflight && scope.isDetectorNative) || explainIncidentId()) {
                        eventDataSourceVersion = 2;
                    } else if (isPreflight) {
                        eventDataSourceVersion = 3;
                    }

                    scope.chartEventStore = chartEventDataSource.initialize(
                        eventDataSourceVersion,
                        {
                            query: scope.eventStreamQuery,
                            programToQuery: scope.eventProgram,
                            start: startEnd.start,
                            end: startEnd.end,
                            res: res,
                            getETSMetadata: function () {
                                return scope.streamObject.etsMetaDataMap;
                            },
                            getModel: function () {
                                return scope.chartModel;
                            },
                            onNewData: function () {
                                redrawEventHistogram();
                                chartLoadPromises.loadEventDefer.resolve();
                            },
                            getRules: function () {
                                return scope.detectorPreviewConfig &&
                                    scope.detectorPreviewConfig.preview
                                    ? scope.detectorPreviewConfig.preview.rules
                                    : scope.chartModel.sf_uiModel.rules;
                            },
                        }
                    );
                    if (!scope.chartEventStore.streaming) {
                        chartLoadPromises.loadEventDefer.resolve();
                    }
                    if (isPreflight && !scope.isDetectorNative) {
                        const obj = {};
                        scope.$emit(CHART_DISPLAY_EVENTS.CHART_EVENT_STREAM_INIT, obj);
                        preflightTimestamp = obj.latestDataTimestamp;
                        preflightAvailableFrom = obj.preflightAvailableFrom;
                        preflightPercent = obj.percent;

                        (obj.eventAggregatedDataAccumulated || []).forEach(function (agg) {
                            scope.chartEventStore.addEventHistogram(
                                agg.timestamp,
                                agg.is,
                                agg.priority,
                                agg.count
                            );
                        });
                    }
                    redrawEventHistogram();
                }

                function findFirstVisibleTSID() {
                    let firstVisiblePlotKey = null;
                    let targetPlotIndex = null;
                    //fix me for v2

                    if (scope.chartModel.sf_flowVersion === 2) {
                        return 1;
                    }

                    scope.chartModel.sf_uiModel.allPlots.some(function (plot) {
                        if (
                            !plot.invisible &&
                            (plot.type === 'ratio' || plot.type === 'plot') &&
                            !plot.transient
                        ) {
                            firstVisiblePlotKey = plot.uniqueKey;
                            return true;
                        }
                    });

                    if (firstVisiblePlotKey !== null) {
                        angular.forEach(scope.dyGraphMaps.tsidToPlot, function (plot, tsid) {
                            if (plot && plot.uniqueKey === firstVisiblePlotKey) {
                                if (!isNaN(scope.dyGraphMaps.tsidToIndex[tsid])) {
                                    // it appears that tsidToIndex can be missing at times
                                    // likely because no data arrived for a particular timeseries
                                    // scan to find something we CAN show
                                    targetPlotIndex = scope.dyGraphMaps.tsidToIndex[tsid];
                                }
                            }
                        });
                    }

                    return targetPlotIndex;
                }

                function updateColors() {
                    let color;
                    updateColorCache();
                    for (let i = 1; i < scope.dyGraph.labels.length; i++) {
                        color = getColorForGraph(scope.dyGraph.labels[i]);
                        scope.dyGraph.colors[i - 1] = byValColor || color;
                        scope.dyGraphMaps.tsidToColor[scope.dyGraph.labels[i]] =
                            byValColor || color;
                    }
                    updateEventColor(true);
                }

                function updateEventColor(initialize) {
                    const eventColors = {};
                    scope.chartModel.sf_uiModel.allPlots.forEach((plot) => {
                        if (plot.type === 'event') {
                            const data =
                                plot.seriesData.eventQuery || plot.seriesData.detectorQuery;
                            const eventColor = (plot.configuration || {}).colorOverride;
                            if (data && eventColor) {
                                eventColors[data] = eventColor;
                            }
                        }
                    });

                    if (scope.overlayEventPlots && scope.overlayEventPlots.length) {
                        // if event is overlaid, use the overlay colors
                        scope.eventOverlayColors = _.assignWith(
                            scope.eventOverlayColors,
                            eventColors,
                            (objValue, srcValue) => (_.isUndefined(objValue) ? srcValue : objValue)
                        );
                    } else {
                        scope.eventOverlayColors = _.assign(scope.eventOverlayColors, eventColors);
                    }

                    if (initialize && !_.isEmpty(eventColors)) {
                        initializeEventStreaming();
                    }
                }

                function updateLineThickness() {
                    let px;
                    for (let i = 1; i < scope.dyGraph.labels.length; i++) {
                        px = getThicknessForGraph(scope.dyGraph.labels[i]);
                        scope.dyGraph.strokeWidth[i - 1] = px;
                    }
                }

                function updateThresholdingMetadata() {
                    for (let i = 1; i < scope.dyGraph.labels.length; i++) {
                        scope.dyGraph.thresholdingInfo[i - 1] = chartDisplayUtils.getThresholdInfo(
                            scope.streamObject.metaDataMap[scope.dyGraph.labels[i]],
                            scope.incidentInfo,
                            scope.isDetectorNative
                        );
                    }
                }

                function getPlotObject(data, chart) {
                    if (data && (data.sf_streamLabel || isOriginallyV2)) {
                        const cachedPlot = scope.dyGraphMaps.tsidToPlot[data.tsid];
                        if (cachedPlot) {
                            return cachedPlot;
                        }
                    }

                    return chartDisplayUtils.getPlotObject(data, chart, isOriginallyV2);
                }

                function getSeriesMetadata(tsid, chart) {
                    const metadata = scope.streamObject.metaDataMap[tsid];
                    if (!metadata) {
                        return {};
                    }
                    return chartDisplayUtils.getSeriesMetadataFromPlot(
                        metadata,
                        chartDisplayUtils.getPlotObject(metadata, chart, isOriginallyV2)
                    );
                }

                function insertTimeSeries(data, targetedPlot, color, visibility) {
                    let indexForTargetedPlot = -1;
                    if (scope.chartModel.sf_uiModel.allPlots && scope.plotUniqueKeyToPlotIndex) {
                        indexForTargetedPlot =
                            scope.plotUniqueKeyToPlotIndex[targetedPlot.uniqueKey];
                    }

                    const insertIndex =
                        scope.dyGraphMaps.allPlotsIndexToInsertIndex[indexForTargetedPlot] || 1;
                    const metaData = chartDisplayUtils.getUiConfig(
                        scope.streamObject.metaDataMap[data.tsid],
                        scope.incidentInfo
                    );
                    if (metaData.sfui_streamType && metaData.sfui_streamType !== 'signal') {
                        scope.thresholdTSIDs[data.tsid] = true;
                    }

                    // optimize for the condition under which the new timeseries is effectively an array push.
                    // splice/unshift are linear whereas push is amortized constant.  ideally we would be able to
                    // bulk insert to alleviate some of the performance issues here.
                    if (
                        insertIndex === scope.dyGraph.labels.length &&
                        featureEnabled('adaptiveChartRendering')
                    ) {
                        scope.dyGraph.labels.push(data.tsid);
                        angular.forEach(scope.dyGraphMaps.tsidToIndex, function (arrIdx, tsid) {
                            if (arrIdx >= insertIndex) {
                                scope.dyGraphMaps.tsidToIndex[tsid]++;
                            }
                        });

                        scope.dyGraph.colors.push(color);
                        scope.dyGraph.strokeWidth.push(5);
                        scope.dyGraph.thresholdingInfo.push(null);
                        scope.dyGraph.visibility.push(visibility);
                        scope.dyGraphMaps.tsidToIndex[data.tsid] = insertIndex;
                        angular.forEach(scope.dyGraph.file, function (dataArr) {
                            dataArr.push(null);
                        });
                    } else {
                        scope.dyGraph.labels.splice(insertIndex, 0, data.tsid);
                        angular.forEach(scope.dyGraphMaps.tsidToIndex, function (arrIdx, tsid) {
                            if (arrIdx >= insertIndex) {
                                scope.dyGraphMaps.tsidToIndex[tsid]++;
                            }
                        });
                        scope.dyGraph.colors.splice(insertIndex - 1, 0, color);
                        scope.dyGraph.strokeWidth.splice(insertIndex - 1, 0, 5);
                        scope.dyGraph.thresholdingInfo.splice(insertIndex - 1, 0, null);
                        scope.dyGraph.visibility.splice(insertIndex - 1, 0, visibility);
                        scope.dyGraphMaps.tsidToIndex[data.tsid] = insertIndex;
                        angular.forEach(scope.dyGraph.file, function (dataArr) {
                            dataArr.splice(insertIndex, 0, null);
                        });
                    }

                    for (
                        let x = indexForTargetedPlot;
                        x < scope.dyGraphMaps.allPlotsIndexToInsertIndex.length;
                        x++
                    ) {
                        scope.dyGraphMaps.allPlotsIndexToInsertIndex[x]++;
                    }
                }

                function getJobStopReasonPrefix() {
                    let prefix = 'chart display';
                    if (scope.chartModel && scope.chartModel.sf_chart) {
                        prefix = 'chart display - ' + scope.chartModel.sf_chart;
                    } else if (scope.chartModel && scope.chartModel.sf_detector) {
                        prefix = 'detector display - ' + scope.chartModel.sf_detector;
                    }
                    return prefix;
                }

                function onChartPinLoad(ev, timeToPin, isEvent) {
                    pinAfterLoad = {
                        time: timeToPin,
                        isEvent: isEvent,
                    };
                }

                function adjustOnChartLegendOverflow() {
                    if (
                        scope.onChartLegend &&
                        (scope.onChartLegend.onChartLegendY1.length !== 0 ||
                            scope.onChartLegend.onChartLegendY2.length !== 0)
                    ) {
                        const $leftLegendContainer = elem.find('.on-chart-legend-split-left');
                        let leftOverflow;
                        if ($leftLegendContainer[0]) {
                            leftOverflow =
                                $leftLegendContainer[0].scrollWidth >
                                $leftLegendContainer.width() + ONCHART_OVERFLOW_BUFFER;
                        }

                        const $rightLegendContainer = elem.find('.on-chart-legend-split-right');
                        let rightWidth = 0;
                        if ($rightLegendContainer[0]) {
                            rightWidth = $rightLegendContainer[0].scrollWidth;
                        }

                        const rightLegendExists = rightWidth !== 0;
                        let rightOverflow;
                        if (rightLegendExists) {
                            rightOverflow =
                                rightWidth >
                                $rightLegendContainer.width() + ONCHART_OVERFLOW_BUFFER;
                        }
                        scope.onChartLegend.showMoreLegend = leftOverflow || rightOverflow;
                        scope.onChartLegend.showLeftLegendBorder =
                            leftOverflow && rightLegendExists;
                    }
                }

                function onDestroy() {
                    if (scope.streamObject) {
                        if (jobTimer) {
                            jobTimer.abort();
                        }
                        scope.streamObject.stopStream(
                            getJobStopReasonPrefix() + ', navigated away'
                        );
                        clearInterval(scope.renderInterval);
                    }

                    if (scope.chartEventStore) {
                        scope.chartEventStore.cancel();
                    }

                    if (scope.dyGraphInstance && scope.dyGraphInstance.destroy) {
                        scope.dyGraphInstance.destroy();
                        scope.dyGraphInstance = null;
                    }

                    throttledDebouncedKeyRecompute.cancel();
                    $timeout.cancel(jobMessageProcessTimeout);
                    angular.element('.sf-ui').off('tooltipMouseMove', nonAngularTooltipMove);
                    angular.element(chartContainer).off('click', chartClickPin);
                    $window.clearTimeout(renderTimeoutId);
                    $window.clearInterval(checkDataIntervalId);
                    $timeout.cancel(scope.jobMessagePromise);
                    if (unregisterRouteWatchGroup) {
                        unregisterRouteWatchGroup();
                    }
                    if (smokeyStateProvider) {
                        smokeyStateProvider.destroy();
                    }
                }

                function onJobError(jobId, errorType, error) {
                    if (errorType !== 'jobStart') return;
                    scope.jobStartErrors = {
                        chartId: scope.chartModel.sf_id || scope.snapshot.id || '',
                        chartName: scope.chartModel.sf_chart || '',
                        jobId: jobId,
                        errors: [error],
                    };

                    userAnalytics.event('job-start', 'error');
                }

                function updateDataNow() {
                    scope.chartDisplayDebouncer.unsuspend();
                }

                function preventDataUpdates() {
                    scope.chartDisplayDebouncer.suspend();
                }

                function onEnterDebounceArea() {
                    scope.chartDisplayDebouncer.pause();
                }

                function onLeaveDebounceArea() {
                    scope.chartDisplayDebouncer.unpause();
                }

                function resetInternalJobState() {
                    scope.jobStartErrors = null;
                    if (scope.instrumentation.getInstrumentationMetrics) {
                        scope.instrumentation.expectingData = true;
                        scope.instrumentation.expectingBackfill = true;
                        scope.instrumentation.getInstrumentationMetrics = false;
                    } else {
                        scope.instrumentation.expectingData = false;
                        scope.instrumentation.expectingBackfill = false;
                    }
                    scope.jobMessageSummary = {};
                    throttledDebouncedKeyRecompute.cancel();
                    resetDyGraph();
                    $timeout.cancel(jobMessageProcessTimeout);
                }

                function updateData(uimodel) {
                    if (!programTextUtils.areAllExpressionsValid(uimodel.allPlots)) {
                        scope.datapointReceived = false;
                        onJobError('Not started, an invalid expression was found.', 'jobStart', {
                            error: ['An invalid expression was found.'],
                        });
                        $log.warn('Refused to run a job with invalid expressions.');
                        return;
                    }

                    if (!chartbuilderUtil.areAllRegExValid(uimodel.allPlots)) {
                        scope.datapointReceived = false;
                        onJobError(
                            'Not started, an invalid regular expression was found.',
                            'jobStart',
                            {
                                error: ['An invalid regular expression was found.'],
                            }
                        );
                        $log.warn('Refused to run a job with invalid regular expressions.');
                        return;
                    }

                    function initializeDataFetch() {
                        initializeLoadingState();
                        if (scope.onInitialize) scope.onInitialize();

                        jobTimer = instrumentationService.getInstrumentationTimer(
                            'ui.jobrequest',
                            ['streamId', 'streamStart', 'streamData'],
                            5000
                        );
                        jobTimer.init();
                        const incidentId = explainIncidentId();
                        let basisModel = scope.chartModel;
                        if (!incidentId) {
                            basisModel = getTransientModel();
                        }
                        const jobRangeParameters =
                            chartDisplayUtils.getJobRangeParametersFromConfig(
                                getChartConfig(basisModel),
                                basisModel.sf_uiModel.chartMode
                            );
                        const isTimeSliceMode = chartDisplayUtils.isTimeSliceMode(
                            scope.chartModel.sf_uiModel
                        );
                        if (isTimeSliceMode) {
                            currentJobMidPoint = 0;
                        } else {
                            currentJobMidPoint =
                                Date.now() -
                                Math.abs(jobRangeParameters.endAt) -
                                Math.abs(jobRangeParameters.endAt - jobRangeParameters.range) * 0.5;
                        }

                        const jobstart = Date.now();

                        function checkBackfillComplete(timeStampMs) {
                            // if we're expecting to instrument backfill(ie this is the first fetch of the
                            // current load chain), and the latest timestamp is reasonably close to "now"
                            // based on the job's expected absolute end or the start time less 5 times the
                            // resolution(jitter, etc) then we can consider the data fully loaded
                            return (
                                !incidentId &&
                                scope.instrumentation.expectingBackfill &&
                                timeStampMs >
                                    (jobRangeParameters.endAt
                                        ? jobstart + jobRangeParameters.endAt
                                        : jobstart) -
                                        jobRangeParameters.resolution * 5
                            );
                        }

                        function tsidRendersPoints(tsid) {
                            const metadataObj = scope.streamObject.metaDataMap[tsid];
                            if (!metadataObj) {
                                return true;
                            }

                            if (metadataObj.sf_detectLabel) {
                                // metadata for detect blocks ETS, we don't need this to
                                // render tsid data points
                                return false;
                            }

                            // V1 detectors
                            if (
                                !scope.chartModel.sf_flowVersion &&
                                metadataObj.sf_detectorDerived
                            ) {
                                // Detail view
                                if (
                                    !scope.detectorPreviewConfig ||
                                    !scope.detectorPreviewConfig.preview
                                ) {
                                    // metadata for detect blocks MTS, we don't need this to
                                    // render tsid data points
                                    return false;
                                }
                                if (metadataObj.sfui_streamType === 'threshold') {
                                    return true;
                                }
                                // don't render side publishes
                                if (!metadataObj.sfui_config) {
                                    return false;
                                }
                            }
                            // V2 detectors
                            else if (scope.chartModel.sf_modelVersion === 2) {
                                if (!scope.detectorPreviewConfig) {
                                    if (metadataObj.sf_detectorDerived) {
                                        return false;
                                    }
                                } else {
                                    if (
                                        metadataObj.sf_detectorDerived &&
                                        !metadataObj.sfui_config
                                    ) {
                                        return false;
                                    }
                                }
                            }
                            return true;
                        }

                        function getLatestDyGraphFile() {
                            return scope.dyGraph.file[scope.dyGraph.file.length - 1];
                        }

                        function isDatapointOutOfOrder(timestampMs) {
                            const latestFile = getLatestDyGraphFile();
                            if (!latestFile) {
                                return false;
                            }

                            const isLate = timestampMs < latestFile[0].getTime();

                            if (isLate) {
                                $log.warn(
                                    'Out of order datapoint by ' +
                                        (timestampMs - latestFile[0].getTime()) / 1000 +
                                        ' seconds '
                                );
                            }

                            return isLate;
                        }

                        function createEmptyDataSlice(timestampMs) {
                            const newDataSlice = [new Date(timestampMs)];

                            for (let i = 1; i < scope.dyGraph.labels.length; i++) {
                                newDataSlice.push(null);
                            }

                            return newDataSlice;
                        }

                        function insertLateDyGraphFile(dataslice) {
                            const timeStampMs = dataslice[0].getTime();
                            let latestIndex = scope.dyGraph.file.length - 1;

                            while (
                                latestIndex > -1 &&
                                timeStampMs < scope.dyGraph.file[latestIndex][0].getTime()
                            ) {
                                latestIndex--;
                            }

                            $log.warn(
                                'moved datapoint ' +
                                    (scope.dyGraph.file.length - latestIndex) +
                                    ' slices backward'
                            );

                            scope.dyGraph.file.splice(latestIndex + 1, 0, dataslice);
                        }

                        function recordNewDatapoint(data) {
                            const { tsid, timestamp, value } = data;

                            if (checkBackfillComplete(timestamp)) {
                                jobTimer.report('fullload');
                                scope.$emit(CHART_DISPLAY_EVENTS.INSTRUMENTATION_FULL_LOAD);
                                scope.instrumentation.expectingBackfill = false;
                                updateAllOptions();
                                if (scope.onFullLoad) scope.onFullLoad();
                            }

                            if (!scope.dyGraphMaps.timeStampToTimeSlice[timestamp]) {
                                // assume its on the end.
                                const newDataSlice = createEmptyDataSlice(timestamp);
                                const tsidIndex = scope.dyGraphMaps.tsidToIndex[tsid];

                                newDataSlice[tsidIndex] = value;
                                scope.dyGraphMaps.timeStampToTimeSlice[timestamp] = newDataSlice;

                                if (isDatapointOutOfOrder(timestamp)) {
                                    //traverse backwards to find an appropriate location to insert...
                                    insertLateDyGraphFile(newDataSlice);
                                } else {
                                    scope.dyGraph.file.push(newDataSlice);
                                }
                            } else {
                                const dataSlice = scope.dyGraphMaps.timeStampToTimeSlice[timestamp];
                                if (
                                    angular.isUndefined(
                                        dataSlice[scope.dyGraphMaps.tsidToIndex[tsid]]
                                    )
                                ) {
                                    $log.error(
                                        'Could not find an appropriate index to place data from ' +
                                            tsid
                                    );
                                } else {
                                    dataSlice[scope.dyGraphMaps.tsidToIndex[tsid]] = value;
                                }
                            }
                        }

                        scope.batchLoadCompleted = false;
                        const jobOpts = {
                            callback(data) {
                                const metadataObj = scope.streamObject.metaDataMap[data.tsid];

                                if (!tsidRendersPoints(data.tsid)) {
                                    return;
                                }

                                const targetedPlot = getPlotObject(metadataObj, scope.chartModel);
                                let seriesVisibility = true;

                                if (targetedPlot) {
                                    // add clear threshold cond
                                    seriesVisibility = !targetedPlot.invisible;
                                }

                                if (!scope.dyGraphMaps.tsidToIndex[data.tsid]) {
                                    const color = getColorForGraph(data.tsid);
                                    scope.dyGraphMaps.tsidToColor[data.tsid] = color;
                                    insertTimeSeries(data, targetedPlot, color, seriesVisibility);
                                    scope.newTSIDEncountered = true;
                                }

                                recordNewDatapoint(data);
                            },
                            eventCallback(evt) {
                                if (isPreflight && scope.isDetectorNative) {
                                    evt.wasSimulated = true;
                                }
                                if (
                                    (isPreflight && scope.isDetectorNative) ||
                                    explainIncidentId()
                                ) {
                                    // only added simulated event for preflight
                                    scope.chartEventStore.addEvent(evt);
                                }
                            },
                            streamRequestedCallback(message) {
                                if (message.traceId) {
                                    scope.currentTraceId = message.traceId;
                                }
                            },
                            streamStartCallback(jobId) {
                                jobTimer.report('streamId');

                                if (scope.currentJobId) {
                                    if (jobAttempts.length >= 3) {
                                        jobAttempts = [jobAttempts[1], jobAttempts[2]];
                                    }
                                    jobAttempts.push(scope.currentJobId);
                                }
                                scope.currentJobId = jobId;

                                jobTimer.report('streamStart');
                                scope.streamStarted = true;
                                scope.jobStartErrors = null;
                                resetDyGraph();
                                scope.datapointReceived = false;
                                scope.$emit(CHART_DISPLAY_EVENTS.DERIVED_STREAM_INITIATED, jobId);

                                if (!scope.currentJobId) {
                                    $log.error('ERROR : somehow got streamStart before streamId');
                                }

                                scope.datapointReceived = true;
                                initializeNewDataChecker();
                                jobTimer.report('streamData');
                            },
                            onFeedback(msgs) {
                                // shallow copy the array to trigger watchers (and not require watchers to be deep watchers)
                                scope.jobFeedback = msgs.slice(0);
                                msgs.forEach((m) => {
                                    if (m.messageCode === 'JOB_ASSIMILATED_FILTERS') {
                                        scope.$emit(
                                            CHART_DISPLAY_EVENTS.REPLACED_FILTER_REPORT,
                                            m.contents.replaceOnlyTermsReplaced
                                        );
                                    }
                                });
                                throttledDebouncedJobProcessing(msgs);
                                if (scope.chartNoDataService) {
                                    scope.chartNoDataService.onFeedback(msgs);
                                }
                            },
                            metaDataUpdated: onMetaDataMessage,
                            onStreamError: onJobError,
                            streamStopCallback: function () {
                                scope.$apply(function () {
                                    renderImmediate(true);
                                });
                                chartLoadPromises.loadDataDefer.resolve();
                            },
                            immediate: scope.isStandalone,
                            programArgs: getProgramArgsForDashboardInTime(
                                scope?.chartModel?.sf_uiModel?.chartconfig
                            ),
                        };

                        if (featureEnabled('cacheJobsForCharts')) {
                            // Cache dashboard charts; exclude alert/detector charts & chartBuilder from caching
                            if (
                                !scope.isDetectorChart &&
                                !scope.isDetectorNative &&
                                !scope.inEditor
                            ) {
                                jobOpts.useCache = true;
                            }
                        }

                        if (incidentId) {
                            jobOpts.incidentId = incidentId;
                            jobOpts.boundaryType = scope.isClearEvent ? 'end' : 'start';
                            if (scope.incidentInfo && scope.incidentInfo.selectedIdentifiers) {
                                jobOpts.selectedIdentifiers =
                                    scope.incidentInfo.selectedIdentifiers;
                            }

                            angular.extend(jobOpts, {
                                resolution: jobRangeParameters.resolution,
                                historyrange: jobRangeParameters.range,
                                stopTime: jobRangeParameters.endAt,
                            });
                        } else {
                            populateJobProgramOpts(basisModel, jobOpts, jobRangeParameters);
                            if (!jobOpts.signalFlowText) {
                                scope.batchLoadCompleted = true;
                                return;
                            }
                        }

                        // if we're in timeslice mode, and "auto" was chosen, then add a fallback resolution of 5m to handle
                        // intermittent or otherwise indeterminate resolutions
                        if (jobRangeParameters.fallbackResolutionMs) {
                            jobOpts.fallbackResolutionMs = 300000;
                        }

                        const preRun = signalStreamPreRunner.fetch(jobOpts);
                        if (preRun) {
                            preRun.setJobOpts(jobOpts);
                        }

                        const dataStreamingPromise = preRun || signalStream.stream(jobOpts);

                        if (preRun) {
                            // if we're connecting to an existing prerun job, then we are ready to flush as it is likely the messages
                            // have piled up.  we MUST assign streamobject before resuming, or lookups on the streamObject as a result
                            // of messages coming back will fail(metaDataMap)
                            scope.streamObject = preRun;
                            dataStreamingPromise.resumeStreaming();
                        } else {
                            // if we are running a job ad hoc, then suspend streaming until chart initialization completes.  this will
                            // give some time for messages to accrue.
                            scope.streamObject = dataStreamingPromise;
                            scope.streamObject.suspendStreaming();
                        }
                        $timeout(
                            function initDyGraph() {
                                scope.streamObject.resumeStreaming();
                                flushUpdateOptionQueue();
                            },
                            0,
                            false
                        );

                        scope.resolution = dataStreamingPromise.resolution;
                        scope.legendHelper = dataStreamingPromise.uniqueKeyToTSIDMap;
                    }

                    function populateJobProgramOpts(chart, jobOpts, jobRangeParameters) {
                        let signalFlowToStream = null;

                        const programTextSource = chart;
                        const disableAllEventPublishes = !scope.allowEventPublishes;
                        const throttleValue = chartDisplayUtils.getSampleRate(chart);

                        if (!chart.sf_flowVersion) {
                            if (
                                scope.isDetectorNative &&
                                scope.detectorPreviewConfig &&
                                scope.detectorPreviewConfig.preview
                            ) {
                                signalFlowToStream =
                                    programTextUtils.getV2ProgramTextPublishRuleAnnotateThreshold(
                                        programTextSource.sf_uiModel.allPlots,
                                        scope.detectorPreviewConfig.preview.rules
                                    );
                            } else {
                                signalFlowToStream = programTextUtils.getV2ProgramText(
                                    programTextSource.sf_uiModel,
                                    scope.viewOnly,
                                    false,
                                    [],
                                    false,
                                    false,
                                    true
                                );
                            }
                            scope.sharedChartState.signalflow2Text = signalFlowToStream;
                        } else {
                            if (
                                !isDetectorV2() &&
                                programTextSource.sf_uiModel.allPlots.filter(
                                    (p) =>
                                        !p.transient &&
                                        (p.type === 'plot' || p.type === 'ratio') &&
                                        !p.invisible
                                ).length === 0
                            ) {
                                return;
                            }
                            signalFlowToStream = programTextSource.sf_viewProgramText;
                        }

                        if (!signalFlowToStream) {
                            return;
                        }

                        scope.sharedChartState.lastStreamedSignalflow = signalFlowToStream;

                        let maxDelay;
                        if (
                            scope.maxDelayOverride === null ||
                            scope.maxDelayOverride === undefined
                        ) {
                            // This indicates 'No override', so we defer to the chart settings
                            maxDelay =
                                parseInt(
                                    safeLookup(scope, 'chartModel.sf_uiModel.chartconfig.maxDelay'),
                                    10
                                ) || null;
                        } else {
                            maxDelay = scope.maxDelayOverride;
                        }

                        if (scope.isDetectorChart) {
                            angular.extend(jobOpts, { usedByDetectorUI: true });
                        }

                        angular.extend(jobOpts, {
                            signalFlowText: signalFlowToStream,
                            resolution: jobRangeParameters.resolution,
                            historyrange: jobRangeParameters.range,
                            stopTime: jobRangeParameters.endAt,
                            resolutionAdjustable: urlOverridesService.getResolutionAdjustable(),
                            maxDelayMs: maxDelay,
                            offsetByMaxDelay: true,
                            disableAllEventPublishes: disableAllEventPublishes,
                        });

                        if (!scope.snapshot || !scope.snapshot.id) {
                            angular.extend(jobOpts, {
                                computingFor: chart.sf_id,
                                traceContext: { chartId: chart.sf_id },
                            });
                        }

                        // If we have chosen only a single threshold to display - filter down to that one only
                        if (
                            isDetectorV2() &&
                            (safeLookup(scope, 'detectorPreviewConfig.isPreflight') ||
                                isPreflight) &&
                            safeLookup(scope, 'detectorPreviewConfig.detectLabel')
                        ) {
                            angular.extend(jobOpts, {
                                enabledDetectLabels: [scope.detectorPreviewConfig.detectLabel],
                            });
                        }

                        if (throttleValue) {
                            jobOpts.sampleSize = throttleValue;
                        }

                        const timezone = safeLookup(
                            scope,
                            'chartModel.sf_uiModel.chartconfig.timezone'
                        );
                        if (timezone) {
                            jobOpts.timezone = timezone;
                        }

                        let filters = [];
                        let replaceOnlyFilters = [];

                        if (!scope.disableUrl) {
                            // If not disabled, also coalesce sourcesOverrides and variableOverrides from the URL (Regular charts)
                            const chartVersion = chartVersionService.getVersion(chart || {});
                            if (chartVersion === 2) {
                                const filterAliases = scope.filterAlias || [];
                                filters = chartUtils
                                    .getChartOverrides(filterAliases.filter((f) => !f.replaceOnly))
                                    .map((filt) => {
                                        return {
                                            property: filt.key,
                                            propertyValue: filt.value,
                                            applyIfExists: filt.applyIfExists,
                                            NOT: filt.not,
                                        };
                                    })
                                    .concat(
                                        (scope.getFilterOverrides
                                            ? scope.getFilterOverrides()
                                            : []) || []
                                    );

                                replaceOnlyFilters = chartUtils
                                    .getChartOverridesCustom(
                                        filterAliases.filter((f) => f.replaceOnly),
                                        []
                                    )
                                    .map(function (filt) {
                                        return {
                                            property: filt.key,
                                            propertyValue: filt.value,
                                            applyIfExists: filt.applyIfExists,
                                            NOT: filt.not,
                                        };
                                    });
                            } else {
                                // If URL updates are disabled, only use overrides passed into the chart.
                                filters = (scope.filterAlias || []).filter((f) => !f.replaceOnly);
                                replaceOnlyFilters = (scope.filterAlias || []).filter(
                                    (f) => f.replaceOnly
                                );
                            }
                        }

                        jobOpts.filter =
                            sourceFilterService.translateSourceFilterObjectsToFilterBlock(filters);
                        jobOpts.replaceOnlyFilter =
                            sourceFilterService.translateSourceFilterObjectsToFilterBlock(
                                replaceOnlyFilters
                            );
                    }

                    if (uimodel && uimodel.allPlots) {
                        const chartModelToStream = null;
                        if (scope.streamObject) {
                            if (jobTimer) {
                                jobTimer.abort();
                            }
                            $q.when(scope.streamObject).then(function (so) {
                                so.stopStream(
                                    getJobStopReasonPrefix() +
                                        ', analytics change requires a new ui-view job.'
                                );
                            });
                        }

                        angular.forEach(scope.legendHelper, function (val, key) {
                            delete scope.legendHelper[key];
                        });
                        scope.streamObject = null;

                        //start the stream requests and lazily set up the dygraph options
                        initializeDataFetch(chartModelToStream);
                    }
                }

                function getExpirationTimeStamp(end, isTimeSliceMode) {
                    if (isTimeSliceMode) {
                        // clear out anything beyond 12 points in the past if we know the resolution, or an hour, with some buffer
                        // for the maxdelay
                        return (
                            end -
                            12 * (scope.jobMessageSummary.primaryJobResolution || 60 * 60 * 1000) -
                            MAXIMUM_MAX_DELAY
                        );
                    } else {
                        // clear out anything beyond the start render area, with some buffer for the resolution(offscreen datapoints) and max delay
                        const renderRange = getRenderTimeRange();
                        return (
                            renderRange[0] -
                            Math.abs(renderRange[1] - renderRange[0]) * 1.2 -
                            2 * (scope.jobMessageSummary.primaryJobResolution || 1000) -
                            (scope.jobMessageSummary.receivedMaxDelay || 1000)
                        );
                    }
                }

                function renderImmediate(skipDigest) {
                    $window.clearInterval(checkDataIntervalId);
                    scope.batchLoadCompleted = true;
                    resize();
                    jobMessagesReceived(scope.jobFeedback);
                    doRender(true, skipDigest);

                    //TODO :there are situations where this resize is unnecessary, detect and skip as its quite expensive

                    if (pinAfterLoad) {
                        scope.sharedChartState.verticalLineTimestamp = pinAfterLoad.time;
                        scope.setLegendPin(pinAfterLoad.time, skipDigest);
                        switchToLegendTab(pinAfterLoad.isEvent);
                        pinAfterLoad = null;
                    }
                }

                function initializeNewDataChecker() {
                    newDataCheckerKickOff = Date.now();
                    scope.batchLoadCompleted = false;

                    $window.clearInterval(checkDataIntervalId);
                    checkDataIntervalId = $window.setInterval(function () {
                        if (Date.now() - newDataCheckerKickOff > 2000) {
                            renderImmediate();
                            $log.warn(
                                'Waited over 2s for data batches to become infrequent.  Timing out!'
                            );
                            return;
                        }
                        if (
                            !scope.batchLoadCompleted &&
                            scope.streamObject.getLatestTimeStamp() > currentJobMidPoint
                        ) {
                            renderImmediate();
                        }
                    }, 100);
                }

                function setOnChartLegend() {
                    $timeout.cancel(onChartLegendDebounce);
                    const onChartLegendY1 = [];
                    const onChartLegendY2 = [];
                    scope.onChartLegend = {};
                    if (scope.chartModel.sf_uiModel.chartconfig.dimensionInLegend) {
                        if (scope.streamObject) {
                            let aliasPromise;
                            if (featureEnabled('mappingService')) {
                                // We only fetch property aliases because when we show the metric as the dimension we just use the plot
                                // name. There is no lookup that has the potential to miss because of aliases.
                                aliasPromise = mappingService.getPropertyAliases(
                                    scope.chartModel.sf_uiModel.chartconfig.dimensionInLegend
                                );
                            } else {
                                // If we are not using the mappingService, we can treat this as though there are no aliases for the
                                // selected dimension
                                aliasPromise = $q.when([]);
                            }

                            aliasPromise
                                .then((aliases) => {
                                    angular.forEach(
                                        scope.streamObject.metaDataMap,
                                        function (metadata, key) {
                                            if (scope.dyGraphMaps) {
                                                const plot = scope.dyGraphMaps.tsidToPlot[key];
                                                if (plot && !plot.invisible) {
                                                    const yaxis = plot.yAxisIndex;

                                                    // In this order, check for:
                                                    // 1. A value keyed on the selected dimension
                                                    // 2. A value keyed on an alias of the selected dimension (if multiple we will take the first)
                                                    const getFirstValidAlias = () =>
                                                        aliases.find((alias) => !!metadata[alias]);
                                                    let dimensionValue =
                                                        metadata[
                                                            scope.chartModel.sf_uiModel.chartconfig
                                                                .dimensionInLegend
                                                        ] ||
                                                        metadata[getFirstValidAlias()] ||
                                                        null;

                                                    if (
                                                        scope.chartModel.sf_uiModel.chartconfig
                                                            .dimensionInLegend === 'sf_metric'
                                                    ) {
                                                        dimensionValue = plot.name;
                                                    }
                                                    if (!dimensionValue) {
                                                        dimensionValue = '-';
                                                    }
                                                    const onChartLegendData = {
                                                        dimension: dimensionValue,
                                                        color: getColorForGraph(key),
                                                    };
                                                    if (yaxis === 0) {
                                                        onChartLegendY1.push(onChartLegendData);
                                                    } else {
                                                        onChartLegendY2.push(onChartLegendData);
                                                    }
                                                }
                                            }
                                        }
                                    );
                                })
                                .then(() => {
                                    onChartLegendY1.sort(sortByDimension);
                                    onChartLegendY2.sort(sortByDimension);
                                });
                        }
                    }

                    scope.onChartLegend.onChartLegendY1 = onChartLegendY1;
                    scope.onChartLegend.onChartLegendY2 = onChartLegendY2;

                    onChartLegendDebounce = $timeout(function () {
                        resize();
                        adjustOnChartLegendOverflow();
                        chartLoadPromises.loadOnChartLegendDefer.resolve();
                    }, 300);
                }

                function getHighestCardinalityDimension() {
                    if (scope.streamObject) {
                        return chartDisplayUtils.getHighestCardinalityDimension(
                            scope.legendKeys,
                            scope.streamObject.metaDataMap
                        );
                    }

                    return '';
                }

                function sortByDimension(first, second) {
                    if (first.dimension === second.dimension) {
                        return 0;
                    }

                    if (first.dimension === '-' || second.dimension === '-') {
                        return 1;
                    }

                    if (first.dimension < second.dimension) {
                        return -1;
                    }

                    if (first.dimension > second.dimension) {
                        return 1;
                    }

                    return 0;
                }

                function getRenderTimeRange() {
                    const timenow = getRenderingWallTime();
                    const isTimeSliceMode = chartDisplayUtils.isTimeSliceMode(
                        scope.chartModel.sf_uiModel
                    );
                    let start = null;
                    let end = null;
                    const chartconfig = getChartConfig();
                    const isAbsoluteTime = chartDisplayUtils.isAbsoluteTimeFromConfig(chartconfig);

                    if (!isAbsoluteTime) {
                        start =
                            timenow +
                            chartDisplayUtils.getFetchDurationFromConfig(false, chartconfig);
                        end =
                            timenow +
                            chartDisplayUtils.getEndDurationFromConfig(false, chartconfig);
                    } else {
                        start = chartDisplayUtils.getFetchDurationFromConfig(true, chartconfig);
                        end = chartDisplayUtils.getEndDurationFromConfig(true, chartconfig);
                    }

                    if (isTimeSliceMode && scope.streamObject) {
                        start = end - scope.streamObject.resolution * 10;
                    }

                    return [start, end];
                }

                function updateTime(forceSkip) {
                    const tr = getRenderTimeRange();
                    updateOptions(
                        {
                            dateWindow: [tr[0], tr[1]],
                        },
                        forceSkip
                    );
                }

                function doRender(full, skipDigest) {
                    if (scope.chartDisplayDebouncer.isWaiting()) {
                        return;
                    }
                    //full == true means update all non-timestamp/value data.
                    const tr = getRenderTimeRange();
                    let start = tr[0];
                    const end = tr[1];
                    const isTimeSliceMode = chartDisplayUtils.isTimeSliceMode(
                        scope.chartModel.sf_uiModel
                    );

                    if (isTimeSliceMode && scope.dyGraph.file.length > 1) {
                        // in some cases (AWS Cloudwatch) the best resolution we can run at
                        // is nowhere close to the resolution we want to refresh at
                        // so we have to guess what resolution is available by the timestamps
                        // and adjust the sparkline range accordingly.
                        const firstTs = scope.dyGraph.file[0][0].getTime();
                        const secondTs = scope.dyGraph.file[1][0].getTime();
                        const naiveResolution = secondTs - firstTs;
                        if (naiveResolution !== scope.streamObject.resolution) {
                            start = end - naiveResolution * 10;
                        }
                        if (scope.chartModel.sf_uiModel.chartconfig.colorByValue) {
                            updateAllOptions();
                        }
                    }

                    if (scope.displaySelectedTimeOverlay) {
                        calculateSelectedTimeOverlay();
                    }

                    if (
                        dyGraphConfigurationGenerator.axesContainDuplicateLabels(
                            scope.dyGraphInstance
                        )
                    ) {
                        updateAllOptions();
                    }

                    if (full) {
                        let updatedAll = false;
                        if (scope.newTSIDEncountered) {
                            //FIXME this occassionally throws an error even with seemingly valid data
                            updateTime(true);
                            updateAllOptions();
                            scope.newTSIDEncountered = false;
                            updatedAll = true;
                        }

                        scope.dyGraphMaps.dataIndex = findFirstVisibleTSID();
                        const chartMode = safeLookup(scope, 'chartModel.sf_uiModel.chartMode');

                        //we need to try to render EVEN if there is no data, to clear out old data
                        if (!updatedAll && (chartMode === 'graph' || !chartMode)) {
                            updateOptions({
                                file: scope.dyGraph.file,
                                labels: scope.dyGraph.labels,
                                dateWindow: [start, end],
                            });
                        }

                        drawLegendLines();
                    } else {
                        updateOptions({
                            dateWindow: [start, end],
                        });
                    }

                    // Update legend to show most recent values if there's no pinned or
                    // hover timestamp
                    if (
                        scope.sharedChartState.currentTabId === 'data' &&
                        !scope.sharedChartState.pinnedTimeStamp &&
                        !scope.sharedChartState.mouseHoverTimestamp
                    ) {
                        const rowIdx = scope.dyGraph.file.length - 1;
                        if (rowIdx !== -1) {
                            scope.legendLatestTimestamp = scope.dyGraph.file[rowIdx][0].getTime();
                        }
                        pointHighlightCallback(rowIdx, skipDigest);
                        updateLegendValues();
                    }

                    if (
                        scope.latestPointsRenderedTime > currentJobMidPoint &&
                        !hasFiredChartLoadsSucceededEvent
                    ) {
                        hasFiredChartLoadsSucceededEvent = true;
                        scope.$emit(
                            CHART_DISPLAY_EVENTS.INCREMENT_CHART_LOADS_SUCCEEDED,
                            scope.$id,
                            { traceId: scope.currentTraceId }
                        );
                    }
                }

                function periodicRenderCheck() {
                    const timeoutLag = Date.now() - lastAsyncRequestTime - lastAsyncDelay;
                    if (
                        !documentStateService.isDocumentVisible() ||
                        scope.isPanning ||
                        !scope.batchLoadCompleted
                    ) {
                        scheduleAsyncRender(timeoutLag);
                        return;
                    }
                    let timenow = timeservice.getServerTime();
                    if (scope.pausedTime) {
                        timenow = timeservice.getServerTimeFromLocal(scope.pausedTime);
                    }
                    if (!isTurbo) {
                        timenow = timenow - (timenow % 1000);
                    }
                    const iteration = 0;
                    if (!scope.datapointReceived) {
                        // in theory we have a "leak" if we pause given that we dont clean up old points/events
                        // however we need those points since its paused....
                        scheduleAsyncRender(timeoutLag);
                        return;
                    }

                    const isTimeSliceMode = chartDisplayUtils.isTimeSliceMode(
                        scope.chartModel.sf_uiModel
                    );
                    let start = null;
                    let end = null;
                    const chartconfig = getChartConfig();
                    const isAbsoluteTime = chartDisplayUtils.isAbsoluteTimeFromConfig(chartconfig);

                    if (!isAbsoluteTime) {
                        start =
                            timenow +
                            chartDisplayUtils.getFetchDurationFromConfig(false, chartconfig);
                        end =
                            timenow +
                            chartDisplayUtils.getEndDurationFromConfig(false, chartconfig);
                    } else {
                        start = chartDisplayUtils.getFetchDurationFromConfig(true, chartconfig);
                        end = chartDisplayUtils.getEndDurationFromConfig(true, chartconfig);
                    }

                    if (isPreflight && !scope.isDetectorNative) {
                        // preflighting, broadcast the total alert count
                        const count = scope.chartEventStore
                            .getHistogram()
                            .filter(function (bucket) {
                                return bucket.timeStamp >= start && bucket.timeStamp <= end;
                            })
                            .reduce(function (val, bucket) {
                                const priorities = bucket.aggregations[0].aggregations;
                                for (let i = 0; i < priorities.length; i++) {
                                    for (let j = 0; j < priorities[i].aggregations.length; j++) {
                                        if (
                                            !alertTypeService.isClearingEvent(
                                                priorities[i].aggregations[j].name
                                            )
                                        ) {
                                            val += priorities[i].aggregations[j].count;
                                        }
                                    }
                                }
                                return val;
                            }, 0);
                        scope.$emit(CHART_DISPLAY_EVENTS.CHART_DISPLAY_WINDOW_EVENTS, count);
                    }

                    if (isTimeSliceMode && scope.streamObject) {
                        start = end - scope.streamObject.resolution * 10;
                    }

                    if (scope.chartEventStore) {
                        //clean up old events
                        scope.chartEventStore.expire(start);
                    }

                    let pixelRenderThreshold = 3;
                    let latestDataTimeStamp = null;
                    let latestDataCount = null;
                    let latestAxisAdvanceTimeStamp = null;
                    const dygraphInstance = scope.dyGraphInstance;

                    if (scope.dyGraph && scope.dyGraph.file && scope.dyGraph.file.length > 0) {
                        latestDataTimeStamp =
                            scope.dyGraph.file[scope.dyGraph.file.length - 1][0].getTime();
                        latestDataCount = scope.dyGraph.file[scope.dyGraph.file.length - 1].filter(
                            function (v) {
                                return !isNaN(v);
                            }
                        ).length;
                        latestAxisAdvanceTimeStamp = timenow;
                    }

                    //clean up old datapoints
                    if (!isPaused()) {
                        let spliceCount = 0;
                        let t;
                        //FIXME v2 doesn't send back job messages
                        const expirationTimeStamp = getExpirationTimeStamp(end, isTimeSliceMode);
                        //store up to 120%+maxdelay range of datapoints
                        while (
                            scope.dyGraph.file.length > 0 &&
                            spliceCount < scope.dyGraph.file.length &&
                            scope.dyGraph.file[spliceCount][0].getTime() < expirationTimeStamp
                        ) {
                            t = scope.dyGraph.file[0][0].getTime();
                            delete scope.dyGraphMaps.timeStampToTimeSlice[t];
                            spliceCount++;
                        }
                        scope.dyGraph.file.splice(0, spliceCount);
                    }

                    if (
                        scope.streamObject &&
                        scope.streamObject.resolution > 5000 &&
                        iteration !== 0
                    ) {
                        scheduleAsyncRender(timeoutLag);
                        return;
                    }

                    if (isNotInViewPort()) {
                        scheduleAsyncRender(timeoutLag);
                        return;
                    }

                    //set the pixelRenderThreshold to the REQUESTED resolution of the chart represented in pixels
                    if (scope.dyGraph && scope.dyGraph.file && scope.dyGraph.file.length > 0) {
                        latestDataTimeStamp =
                            scope.dyGraph.file[scope.dyGraph.file.length - 1][0].getTime();
                        latestDataCount = scope.dyGraph.file[scope.dyGraph.file.length - 1].filter(
                            function (v) {
                                return !isNaN(v);
                            }
                        ).length;
                        latestAxisAdvanceTimeStamp = timenow;
                        if (
                            dygraphInstance &&
                            scope.streamObject &&
                            scope.streamObject.resolution
                        ) {
                            pixelRenderThreshold = Math.max(
                                pixelRenderThreshold,
                                dygraphInstance.toDomXCoord(
                                    timenow + scope.streamObject.resolution
                                ) - dygraphInstance.toDomXCoord(timenow)
                            );
                        }
                    }

                    if (isPaused()) {
                        pixelRenderThreshold = pixelRenderThreshold * 5;
                    }

                    if (scope.renderScore) {
                        // multiply the pixelRenderThreshold by a renderScore/200, to a maximum of 5 and a minimum of 1
                        pixelRenderThreshold *= Math.min(
                            5,
                            Math.max(
                                1,
                                Math.min(SAMPLE_CONSTANTS.MAXIMUM_SAMPLE_RATE, scope.renderScore) /
                                    200
                            )
                        );
                    }
                    if (
                        (dygraphInstance &&
                            ((isTurbo &&
                                !isTimeSliceMode &&
                                scope.chartModel.sf_uiModel.chartconfig.range &&
                                Math.abs(scope.chartModel.sf_uiModel.chartconfig.range) <=
                                    20 * 60 * 1000) ||
                                !scope.latestPointsRenderedTime ||
                                (isTimeSliceMode &&
                                    scope.streamObject.getLatestTimeStamp() >
                                        scope.latestPointsRenderedTime) ||
                                latestDataTimeStamp - scope.latestPointsRenderedTime > 0 ||
                                dygraphInstance.toDomXCoord(latestDataTimeStamp) -
                                    dygraphInstance.toDomXCoord(scope.latestPointsRenderedTime) >
                                    pixelRenderThreshold ||
                                dygraphInstance.toDomXCoord(latestAxisAdvanceTimeStamp) -
                                    dygraphInstance.toDomXCoord(
                                        scope.latestPointsRenderedAxisAdvance
                                    ) >
                                    pixelRenderThreshold)) ||
                        latestDataCount > scope.latestPointsRenderedCount
                    ) {
                        const current = Date.now();
                        doRender(iteration === 0, false);
                        lastDrawTime = Date.now() - current;
                        // update our "rendered to" point.  it is either the latest data in the dataset known or the latest
                        // timestamp seen from streamObject's empty batches
                        scope.latestPointsRenderedTime =
                            latestDataTimeStamp || scope.streamObject.getLatestTimeStamp();
                        scope.latestPointsRenderedCount = latestDataCount;
                        scope.latestPointsRenderedAxisAdvance = latestAxisAdvanceTimeStamp;
                    }
                    scheduleAsyncRender(timeoutLag);
                }

                let renderTimeoutId;
                let lastAsyncRequestTime = 0;
                let lastAsyncDelay = 0;
                let lastDrawTime = 0;

                function scheduleAsyncRender(timeoutLag) {
                    const nominalRenderRate = isTurbo ? 33 : 1000;
                    let newDelay = nominalRenderRate;
                    if (featureEnabled('adaptiveChartRendering')) {
                        timeoutLag = timeoutLag || 0;
                        lastAsyncRequestTime = Date.now();
                        let drawPadding = 0;
                        if (lastDrawTime > 100) {
                            drawPadding = lastDrawTime * CHART_RENDER_DELAY_MULTIPLIER;
                        }
                        newDelay = Math.min(
                            MAXIMUM_RENDER_CHECK_INTERVAL,
                            timeoutLag * TIMEOUT_DELAY_MULTIPLIER + nominalRenderRate + drawPadding
                        );
                        lastAsyncDelay = newDelay;
                    }
                    renderTimeoutId = $window.setTimeout(periodicRenderCheck, newDelay);
                }

                scheduleAsyncRender();

                function refreshEventStreamWithDelay(evt, data) {
                    const timestamp = data ? data.timestamp : 0;
                    if (timestamp) {
                        let start =
                            scope.chartModel.sf_uiModel.chartconfig.absoluteStart ||
                            scope.chartModel.sf_uiModel.chartconfig.range;
                        let end =
                            scope.chartModel.sf_uiModel.chartconfig.absoluteEnd ||
                            scope.chartModel.sf_uiModel.chartconfig.rangeEnd;
                        start = convertToDbTimestamp(start);
                        end = convertToDbTimestamp(end);
                        if (timestamp < start || timestamp > end) {
                            return;
                        }
                    }
                    $timeout(function eventStreamingInitialize() {
                        initializeEventStreaming();
                    }, 2000);
                }

                function showJobStartErrors() {
                    sfxModal.open({
                        templateUrl: jobStartErrorDetailsTemplateUrl,
                        controller: [
                            '$scope',
                            'details',
                            'SUPPORT_EMAIL',
                            'PRODUCT_NAME',
                            function ($scope, details, SUPPORT_EMAIL, PRODUCT_NAME) {
                                $scope.details = details;
                                $scope.SUPPORT_EMAIL = SUPPORT_EMAIL;
                                $scope.PRODUCT_NAME = PRODUCT_NAME;
                            },
                        ],
                        resolve: {
                            details: function () {
                                return scope.jobStartErrors;
                            },
                        },
                    });
                }

                function updateHeatmapPlot() {
                    const uiModel = scope.chartModel.sf_uiModel || {};
                    const chartMode = uiModel.chartMode;
                    const chartConfig = uiModel.chartconfig || {};
                    const plots = uiModel.allPlots || [];
                    plotUtils.updatePlotVisibility(chartMode, plots);

                    if (
                        !scope.sharedChartConfig ||
                        !Object.keys(scope.sharedChartConfig.heatmapPlotConfig).length
                    ) {
                        if (!chartConfig.groupBy) {
                            chartConfig.groupBy = [];
                        }
                        scope.sharedChartConfig = scope.sharedChartConfig || {};
                        scope.sharedChartConfig.heatmapPlotConfig =
                            scope.sharedChartConfig.heatmapPlotConfig || {};
                        if (!chartConfig.colorByValueScale) {
                            chartConfig.colorByValueScale = [];
                        }
                        if (!chartConfig.heatmapColorOverride) {
                            chartConfig.heatmapColorOverride = SIGNALFX_GREEN;
                        }
                        if (!chartConfig.heatmapColorRange) {
                            chartConfig.heatmapColorRange = {};
                        }
                        if (!chartConfig.heatmapSortBy) {
                            chartConfig.heatmapSortBy = { asc: true, value: {} };
                        }
                        if (!angular.isDefined(chartConfig.heatmapUnitsPerRow)) {
                            chartConfig.heatmapUnitsPerRow = 0;
                        }
                        if (!angular.isDefined(chartConfig.heatmapAutoGradients)) {
                            chartConfig.heatmapAutoGradients = 5;
                        }
                        if (!angular.isDefined(chartConfig.heatmapUseValueAsColor)) {
                            chartConfig.heatmapUseValueAsColor = false;
                        }
                        if (!angular.isDefined(chartConfig.hideTimestamp)) {
                            chartConfig.hideTimestamp = false;
                        }
                    }

                    scope.sharedChartConfig.heatmapPlotConfig.visibilities = Object.keys(
                        scope.dyGraphMaps.tsidToPlot
                    ).filter(function (tsid) {
                        return !scope.dyGraphMaps.tsidToPlot[tsid].invisible;
                    });
                }

                function getHeatmapContainer() {
                    return elem && elem.find('.heatmap-chart-display');
                }

                function preflightChartUpdate(evt, preflight) {
                    isPreflight = preflight;
                    if (!preflight && percentTooltip) {
                        percentTooltip.hide();
                    }
                    onJobRequested();
                }

                function onPausedStateUpdate(isPaused, wasPaused) {
                    if (isPaused && wasPaused) {
                        return;
                    }

                    if (isPaused && !wasPaused) {
                        scope.pausedTime = Date.now();
                    } else {
                        scope.pausedTime = null;
                    }
                }

                function onChartModeUpdate(newval, oldval) {
                    if (!newval || newval === oldval) {
                        return;
                    }
                    if (isOriginallyV2) {
                        scope.chartModel.sf_uiModel.allPlots.forEach((p) => {
                            p.invisible = false;
                        });
                    }
                    scope.sharedChartConfig = scope.sharedChartConfig || {};
                    scope.sharedChartConfig.heatmapPlotConfig = {};
                    scope.jobStartErrors = null;
                    updateEventQueryState();
                    updateRenderScore();
                    onJobRequested();
                }

                function onPinnedTimeUpdate(newval, oldval) {
                    if (!oldval) {
                        //if we're starting a new pin, record the pin wall time for every chart
                        scope.legendPinWallTime = timeservice.getServerTime();
                    }
                    drawLegendLines();
                    let ts = newval;
                    if (!dyGraphUtils.inRange(scope.dyGraphInstance, ts)) {
                        ts = null;
                    }
                    updateEvents(ts);
                }

                function checkInitializationNeeded() {
                    const v = isNotInViewPort();
                    if (!v && !scope.inEditor) {
                        // can check this flag for editor
                        if (!hasInitialized) {
                            hasInitialized = true;
                            onJobRequested();
                        } else {
                            updateAllOptions();
                        }
                    }
                }

                function onMouseHoverTimestampUpdate() {
                    $window.clearTimeout(offScreenLegendLineUpdateTimeout);
                    if (!isNotInViewPort()) {
                        drawLegendLines();
                        updateDygraphSelectedPoints();
                    } else {
                        offScreenLegendLineUpdateTimeout = $window.setTimeout(function () {
                            drawLegendLines();
                            updateDygraphSelectedPoints();
                        }, 1000);
                    }
                }

                function onDragChartTime(value) {
                    if (value === undefined) return;

                    updateDragOverlay();
                    applyFullDygraphModel();

                    if (mouseDownOnSelectedTime) {
                        calculateSelectedTimeOverlay();
                    }
                }

                function onLegendUpdate(params) {
                    if (params[0]) {
                        const columnConfig = params[1] || [];
                        scope.filteredLegendKeys =
                            chartDisplayUtils.mergeJobKeysAndColumnConfiguration(
                                scope.legendKeys,
                                columnConfig
                            );
                        doRender(true, true);
                    }
                }

                function nonAngularTooltipMove() {
                    drawLegendLines();
                }

                function chartClickPin(event) {
                    if (dragPerformedRecently) {
                        return;
                    }

                    const target = angular.element(event.target);
                    const closestChartTarget = target.closest('.sf-chart-target');
                    const offsetX =
                        target.offset().left +
                        (event.offsetX || event.layerX) -
                        closestChartTarget.offset().left;
                    const coordX = scope.dyGraphInstance.toDataXCoord(offsetX);

                    const eventsBucket = getNearestEvents(event.offsetX);
                    const isEventClicked =
                        eventsBucket &&
                        event.offsetY >
                            scope.dyGraphInstance.toDomYCoord(
                                scope.dyGraphInstance.yAxisRange()[0]
                            );
                    if (scope.displaySelectedTimeOverlay) {
                        const timeNormalized = coordX - (coordX % 1000);
                        if (isEventClicked) {
                            scope.chartEventStore
                                .getClosestEventTime(timeNormalized)
                                .then(function (time) {
                                    scope.$emit(
                                        CHART_DISPLAY_EVENTS.CHART_CLICK_PIN,
                                        time,
                                        isEventClicked
                                    );
                                });
                        } else {
                            scope.$emit(
                                CHART_DISPLAY_EVENTS.CHART_CLICK_PIN,
                                timeNormalized,
                                isEventClicked
                            );
                        }
                    } else {
                        scope.setLegendPin(coordX, false);
                        switchToLegendTab(isEventClicked);
                    }
                }

                function switchToLegendTab(isEvent) {
                    if (isEvent) {
                        if (scope.sharedChartState.currentTabId) {
                            scope.$emit(CHART_DISPLAY_EVENTS.SELECT_TAB, 'events');
                        } else {
                            scope.$broadcast(CHART_DISPLAY_EVENTS.SET_LEGEND_CONTENT, 'event');
                        }
                    } else {
                        if (scope.sharedChartState.currentTabId) {
                            scope.$emit(CHART_DISPLAY_EVENTS.SELECT_TAB, 'data');
                        } else {
                            scope.$broadcast(CHART_DISPLAY_EVENTS.SET_LEGEND_CONTENT, 'data');
                        }
                    }
                }

                function onChartAllPlotsChange() {
                    updateRenderScore();
                    scope.dyGraphMaps.dataIndex = findFirstVisibleTSID();
                    updateAllOptions();
                    // force a refire of jobFeedback watchers... this is kind of a hack?
                    jobMessagesReceived(scope.jobFeedback);
                    scope.jobFeedback = scope.jobFeedback.concat([]);
                    updateEventQueryState();
                    scope.plotUniqueKeyToPlotIndex = getPlotUniqueKeyToPlotIndexMap();

                    const plotColorOverridden = scope.chartModel.sf_uiModel.allPlots.some(
                        (elem) => elem.configuration && elem.configuration.colorOverride
                    );
                    if (plotColorOverridden || plotColorPreviouslyOverriden) {
                        setOnChartLegend();
                    }
                    plotColorPreviouslyOverriden = plotColorOverridden;
                }

                function getPlotUniqueKeyToPlotIndexMap() {
                    let i = 0;
                    const plotUniqueKeyToPlotIndex = {};
                    scope.chartModel.sf_uiModel.allPlots.forEach(function (plot) {
                        plotUniqueKeyToPlotIndex[plot.uniqueKey] = i;
                        i++;
                    });

                    return plotUniqueKeyToPlotIndex;
                }

                function updateHasHeatMap() {
                    scope.hasHeatMap = chartbuilderUtil.hasVisualization(
                        scope.chartModel.sf_uiModel,
                        'heatmap'
                    );
                }

                function updateChartPreview(newValues, oldValues) {
                    // we currently depend on newValues === oldValues watch to start jobs
                    if (!angular.equals(newValues, oldValues)) {
                        // sometimes watch triggers when a value moves from undefined to ''
                        // such as pointDensity - this should not trigger a new job
                        // such as on save (we always start a new view job upon save and redirect) wasteful
                        // this fixes it
                        const nvals = angular.copy(newValues);
                        const ovals = angular.copy(oldValues);
                        nvals.forEach(function (v, i) {
                            if (v === undefined) nvals[i] = '';
                        });
                        ovals.forEach(function (v, i) {
                            if (v === undefined) ovals[i] = '';
                        });
                        if (angular.equals(nvals, ovals)) {
                            return;
                        }
                    }
                    scope.$emit('chartPreviewUpdated');
                    onJobRequested();
                }

                function getVisiblePlots() {
                    return scope.chartModel.sf_uiModel.allPlots.filter(
                        (plot) => !plot.transient && !plot.invisible
                    );
                }
            },
        };
    },
];
