angular.module('chartbuilderUtil').service('plotSignalSuggestionService', [
    '$q',
    'signalboost',
    'chartbuilderUtil',
    'programTextUtils',
    'currentUser',
    function ($q, signalboost, chartbuilderUtil, programTextUtils, currentUser) {
        return {
            getSignalSuggestions,
        };

        function getSyntheticResults(q, transient, allPlots, plot, chartMode) {
            const isTransient = !!transient;
            const allRequests = [];
            if (
                plot.type === 'ratio' &&
                !programTextUtils.isExpressionInvalid(q, allPlots, plot.uniqueKey)
            ) {
                allRequests.push($q.when([{ type: 'expression', value: q }]));
            }
            const isPlotType = plot.type === 'plot';
            const isEventType = plot.type === 'event';
            const isEventChart = chartMode === 'event';
            if ((isPlotType || isTransient) && !isEventChart) {
                allRequests.push(chartbuilderUtil.getGlobResults(q));
            }
            if (isEventType || isEventChart || isTransient) {
                allRequests.push(chartbuilderUtil.getEventGlobResults(q));
            }
            return $q.all(allRequests).then(function (resps) {
                let results = [];
                resps.forEach(function (r) {
                    results = results.concat(r);
                });
                return results;
            });
        }

        function getSuggestValues(q, plot) {
            const propSet = [];
            if (plot.seriesData.eventQuery) {
                propSet.push('sf_eventType');
            } else if (plot.seriesData.detectorQuery) {
                propSet.push('sf_detector');
            } else if (plot.seriesData.metric) {
                propSet.push('sf_metric');
            }
            // use signalboost basicSearch at some point to get filtering
            return currentUser.orgId().then(function (orgId) {
                return signalboost.autosuggest
                    .all()
                    .all('suggest')
                    .all('values')
                    .customGET('', {
                        partialInput: q,
                        limit: 100,
                        property: propSet,
                        organizationId: orgId,
                    })
                    .catch(function () {
                        return [];
                    });
            });
        }

        function getSignalSuggestions(q, transient, allPlots, plot, chartMode) {
            const syntheticResults = getSyntheticResults(q, transient, allPlots, plot, chartMode);
            const values = getSuggestValues(q, plot);

            return $q.all([values, syntheticResults]).then(function (resps) {
                const result = resps[0];
                let rs = resps[1];
                const metrics = result.sf_metric;
                const ets = result.sf_eventType;
                const detectorWithId = result.sf_detector_sf_id;
                const detectors =
                    chartMode === 'eventOverlay' && detectorWithId
                        ? detectorWithId
                        : result.sf_detector;

                if (plot.seriesData.regExStyle) {
                    rs = rs.sort(function (a, b) {
                        return b.regExStyle === 'plain' ? -1 : 1;
                    });
                }

                if (chartMode !== 'event' && chartMode !== 'eventOverlay') {
                    angular.forEach(metrics, function (itm) {
                        rs.push({ type: 'metric', value: itm });
                    });
                }

                angular.forEach(ets, function (itm) {
                    rs.push({ type: 'eventTimeSeries', value: itm });
                });

                angular.forEach(detectors, function (itm) {
                    rs.push({ type: 'detectorEvents', value: itm });
                });

                return rs;
            });
        }
    },
]);
