import { FilterKey, FilterItemType, Domain } from '@splunk/olly-services';
import { truncateTextMiddle } from '@splunk/olly-common/utils/StringUtils';

const O11yAppSuffixes = {
    [FilterKey.APM_SERVICE_NAME]: 'APM service',
    [FilterKey.APM_TRACE_ID]: 'APM trace',
    [FilterKey.APM_WORKFLOW_NAME]: 'APM business workflow',
};

export const o11yAppsActionsDataProvider = [
    'metadataMatchingStore',
    'featureEnabled',
    'searchCategoriesUtil',
    'callToActionGenerationStore',
    'iconUtils',
    function (
        metadataMatchingStore,
        featureEnabled,
        searchCategoriesUtil,
        callToActionGenerationStore,
        iconUtils
    ) {
        function getSearchPrefixKey(key) {
            const appsSearchCategories = searchCategoriesUtil.getAppsSearchCategories();
            const searchPrefix = appsSearchCategories.getKey(key);
            return searchPrefix;
        }

        function generateFilterItem(key, value, type = FilterItemType.INCLUDE) {
            return {
                type: key === FilterKey.APM_TRACE_ID ? FilterItemType.EQUAL : type,
                key,
                value,
            };
        }

        function createFilterSetForKey(key, value) {
            let filterSet = [];

            if (!key) {
                filterSet = createFilterSetForAllKeys(value);
            } else {
                const appsSearchCategories = searchCategoriesUtil.getAppsSearchCategories();
                const filterKey = appsSearchCategories.getKey(key);
                filterSet = filterKey ? [generateFilterItem(filterKey, value)] : [];

                const isValidPrefix = searchCategoriesUtil.isValidSearchPrefix(key);
                if (!isValidPrefix) {
                    value = `${key}:${value}`;
                    filterSet = createFilterSetForAllKeys(value);
                }
            }

            return filterSet;
        }

        function createFilterSetForAllKeys(value) {
            const filterKeys = Object.values(FilterKey);
            return filterKeys.map((key) => generateFilterItem(key, value));
        }

        function getCallToActionTitle(callToAction) {
            let title = callToAction.title;
            if (callToAction.type === FilterKey.APM_WORKFLOW_NAME) {
                title = truncateTextMiddle(title, 35);
            }
            return title;
        }

        function getCallToActions(matcherResult) {
            const callToActions = callToActionGenerationStore.generateCallToActions(matcherResult);
            return callToActions.map((callToAction) => ({
                label: getCallToActionTitle(callToAction),
                href: callToAction.url,
                type: O11yAppSuffixes[callToAction.type],
                domain: callToAction.domain,
                img: iconUtils.getIconURL(callToAction.type),
                secondary: callToAction.hasOwnProperty('secondaryTitle')
                    ? callToAction.secondaryTitle
                    : '',
            }));
        }
        function performFindMatches(filterSet, domain, queryReporter) {
            const matcherPromises = domain
                ? metadataMatchingStore.findMatchesByDomain(filterSet, domain, queryReporter)
                : metadataMatchingStore.findMatches(filterSet, queryReporter);
            return matcherPromises.map((matcherResultPromise) =>
                matcherResultPromise.then((matcherResult) => {
                    if (matcherResult) {
                        return getCallToActions(matcherResult);
                    }
                    return [];
                })
            );
        }

        function createFilterSetAndFindMatches(key, value, queryReporter, domain) {
            const filterSet = key
                ? createFilterSetForKey(key, value)
                : createFilterSetForAllKeys(value);
            return filterSet.length ? performFindMatches(filterSet, domain, queryReporter) : [];
        }

        function globalSearchO11yAppSearch(key, value, queryReporter) {
            let globalSearchO11yAppPromise;
            if (!featureEnabled('globalSearchReactified')) {
                globalSearchO11yAppPromise = Promise.all(
                    createFilterSetAndFindMatches(key, value, queryReporter, Domain.APM)
                );
            } else {
                globalSearchO11yAppPromise = Promise.all(
                    createFilterSetAndFindMatches(key, value, queryReporter)
                );
            }
            return globalSearchO11yAppPromise.then(function (resultList) {
                return resultList.flat();
            });
        }

        function getEnabledSearchTypes(key) {
            if (key) {
                const filterKey = getSearchPrefixKey(key);
                if (filterKey === FilterKey.APM_SERVICE_NAME) {
                    return ['apmServiceMatcher'];
                } else if (filterKey === FilterKey.APM_WORKFLOW_NAME) {
                    return ['apmWorkflowMatcher'];
                } else if (filterKey === FilterKey.APM_TRACE_ID) {
                    return ['apmTraceMatcher'];
                }
            }
            return ['apmServiceMatcher', 'apmWorkflowMatcher', 'apmTraceMatcher'];
        }

        return {
            globalSearchO11yAppSearch,
            getEnabledSearchTypes,
            generateFilterItem,
            createFilterSetForKey,
        };
    },
];
