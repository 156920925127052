import { buildUserQueryReporter } from '@splunk/olly-tracing/UserQueryReporter';

export const QuickActionsController = [
    '$scope',
    '$timeout',
    '$q',
    '$uibModalInstance',
    'imageService',
    'staticQuickActionsDataProvider',
    'catalogQuickActionsDataProvider',
    'defaultDataQuickActionDataProvider',
    'o11yAppsActionsDataProvider',
    'quickActionsUtil',
    'userAnalytics',
    'promiseUtil',
    'sortUtil',
    'featureEnabled',
    'searchCategoriesUtil',
    'metadataMatchingStore',
    'callToActionGenerationStore',
    function (
        $scope,
        $timeout,
        $q,
        $uibModalInstance,
        imageService,
        staticQuickActionsDataProvider,
        catalogQuickActionsDataProvider,
        defaultDataQuickActionDataProvider,
        o11yAppsActionsDataProvider,
        quickActionsUtil,
        userAnalytics,
        promiseUtil,
        sortUtil,
        featureEnabled,
        searchCategoriesUtil
    ) {
        $scope.searchText = '';
        $scope.itemClass = 'quick-action-result';
        $scope.containerClass = 'quick-action-results';
        $scope.suggestedObject = searchCategoriesUtil.getSuggestedHint();
        $scope.getLinkToSelf = quickActionsUtil.getLinkToSelf;
        $scope.reportResultClick = function (type, index) {
            userAnalytics.event('Top_Nav/Global_Search', 'Click', index + 1, type);
        };
        const queryReporter = buildUserQueryReporter({
            queryServiceName: 'globalSearch',
            timeoutMs: 120 * 1000,
        });

        function parseSearchText(searchText, delimiter = ':') {
            const colonIndex = searchText.indexOf(delimiter);
            return colonIndex > 0
                ? [searchText.slice(0, colonIndex).trim(), searchText.slice(colonIndex + 1).trim()]
                : [undefined, searchText.trim()];
        }

        let searchPromise = {};
        function performSearch() {
            $scope.loading = true;
            const [key, value] = parseSearchText($scope.searchText);

            // search event
            userAnalytics.event('Top_Nav/Global_Search', 'Search', value.length, key ? key : null);

            // start the query but delegate to the individual providers which sections should be added to the query,
            // since that could be driven by feature flags or could be changed as new features are added.
            queryReporter.startQuery([
                ...staticQuickActionsDataProvider.getEnabledSearchTypes(),
                ...catalogQuickActionsDataProvider.getEnabledSearchTypes(),
                ...o11yAppsActionsDataProvider.getEnabledSearchTypes(key),
            ]);

            return $q.all({
                catalog: catalogQuickActionsDataProvider.globalSearch(key, value, queryReporter),
                o11yAppsActions: o11yAppsActionsDataProvider.globalSearchO11yAppSearch(
                    key,
                    value,
                    queryReporter
                ),
                staticActions: staticQuickActionsDataProvider(key, value, queryReporter),
            });
        }

        function processSearchResults(response) {
            const items = response.staticActions
                .concat(response.catalog)
                .concat(response.o11yAppsActions)
                .map(function (item) {
                    if (!item.img) {
                        item.img = imageService.getServiceIconURL('');
                    }
                    return item;
                });

            sortUtil.reorganizeBuckets(items);

            $scope.result = {
                items: items,
                searchText: $scope.searchText,
            };
            userAnalytics.event('Top_Nav/Global_Search', 'Presented', items.length || 0, null);
            tryToSelectFirst();
        }

        function setDefaultResults(results = []) {
            $scope.result = { items: results, searchText: '' };
            tryToSelectFirst();
        }

        let defaultResults;
        function processDefaultResults(response) {
            defaultResults = response;
            setDefaultResults(response);
        }

        function setNotLoading() {
            $scope.loading = false;
        }

        function emptySearchPromise() {
            searchPromise = {};
        }

        function searchCleanup() {
            setNotLoading();
            emptySearchPromise();
        }

        function tryToSelectFirst() {
            if ($scope.result.items.length > 0) {
                $scope.selectedIndex = 0;
            } else {
                $scope.selectedIndex = -1;
            }
        }

        $scope.mouseEnter = function (index) {
            $scope.selectedIndex = index;
        };

        function keyPressedAnalytics(keyPressed) {
            const [, value] = parseSearchText($scope.searchText);
            userAnalytics.event('Top_Nav/Global_Search', keyPressed, value.length, null);
        }

        function scrollToItem() {
            quickActionsUtil.tryToScrollToItem(
                $scope.selectedIndex,
                $scope.itemClass,
                $scope.containerClass
            );
        }

        function onKeyDown(event) {
            if (!$scope.result || !$scope.result.items || !$scope.result.items.length) {
                $scope.selectedIndex = -1;
                return;
            }
            switch (event.keyCode) {
                case 38: //up
                    keyPressedAnalytics('key-up');
                    $scope.selectedIndex -= 1;
                    if ($scope.selectedIndex < 0) {
                        $scope.selectedIndex = $scope.result.items.length - 1;
                    }
                    scrollToItem();
                    break;
                case 40: //down
                    keyPressedAnalytics('key-down');
                    $scope.selectedIndex += 1;
                    if ($scope.selectedIndex >= $scope.result.items.length) {
                        $scope.selectedIndex = 0;
                    }
                    scrollToItem();
                    break;
                case 13: {
                    //enter
                    keyPressedAnalytics('key-enter');
                    quickActionsUtil.navigateToItem($scope.result.items[$scope.selectedIndex]);
                    $scope.$close();
                    break;
                }
                default:
                    return;
            }
            event.stopPropagation();
            event.preventDefault();
            $scope.$digest();
        }

        const documentElem = angular.element(document);
        documentElem.bind('keydown', onKeyDown);

        let debounceTextType;
        $scope.$watch('searchText', function () {
            $timeout.cancel(debounceTextType);
            debounceTextType = $timeout(function () {
                if (searchPromise.destroy) {
                    searchPromise.destroy();
                }

                if ($scope.searchText) {
                    searchPromise = promiseUtil.destroyablePromise(performSearch(), setNotLoading);
                    searchPromise.then(processSearchResults).finally(searchCleanup);
                } else if (defaultResults) {
                    setDefaultResults();
                } else {
                    searchPromise = promiseUtil.destroyablePromise(
                        defaultDataQuickActionDataProvider()
                    );
                    searchPromise.then(processDefaultResults).finally(emptySearchPromise);
                }
            }, 400);
        });

        $uibModalInstance.result.finally(function () {
            // clean up the listener
            documentElem.unbind('keydown', onKeyDown);
        });
    },
];
