import templateUrl from './metricsSearchBox.tpl.html';

export default [
    function () {
        return {
            restrict: 'E',
            scope: {
                searchText: '=',
                addFilter: '<',
                updateAndSearchMetrics: '<',
                inputClass: '<',
                isActiveMetricsOnly: '<',
                placeholder: '@',
                skipAutoResize: '<',
            },
            templateUrl,
            controller: [
                '$q',
                '$scope',
                'metricsCatalogSearchUtil',
                'promiseGenerationManager',
                function ($q, $scope, metricsCatalogSearchUtil, promiseGenerationManager) {
                    let searchContext = null;
                    let metricGroupAccepted = false;
                    const FILTER_TYPE = metricsCatalogSearchUtil.FILTER_TYPE;
                    const metricGroupSuggestionsFetch = promiseGenerationManager(
                        metricsCatalogSearchUtil.fetchMetricGroupSuggestions
                    );
                    const propertyValueSuggestionsFetch = promiseGenerationManager(
                        metricsCatalogSearchUtil.fetchSuggestions
                    );

                    $scope.filterSelected = filterSelected;
                    $scope.typeaheadDropdownOnChange = typeaheadDropdownOnChange;

                    $scope.$watch('searchText', (nVal, oldVal) => {
                        if (nVal === oldVal) {
                            return;
                        }
                        // Count the # of search terms
                        const match = nVal.match(/[^\s]+/g);
                        $scope.$emit('metric finder search terms', match ? match.length : 0);
                    });

                    // Typeahead dropdown will trigger when you have typed
                    // <property>:<optional partialInput> and you are editing after the colon
                    // where delimiter is the first colon to the left of the cursor
                    // property is space separated token before cursor.
                    // partial input is everything between delimiter and cursor.
                    function typeaheadDropdownOnChange(searchText) {
                        //when last character is a space never suggest anything in the dropdown
                        if (searchText.substr(-1) === ' ') {
                            return $q.when([]);
                        }
                        searchContext = getSearchContext(searchText);
                        if (searchContext === null) {
                            return $q.when([]);
                        }

                        if (searchContext.filterType === FILTER_TYPE.PROPERTY_VALUE) {
                            const partialInput = searchContext.partialInput;
                            return propertyValueSuggestionsFetch(
                                searchContext.property,
                                partialInput,
                                $scope.isActiveMetricsOnly
                            );
                        }

                        if (searchContext.filterType === FILTER_TYPE.METRIC) {
                            // if metric group was just selected, try to find future results by appending a '.'
                            if (
                                metricGroupAccepted &&
                                searchContext.partialInput.substr(-1) !==
                                    FILTER_TYPE.METRIC.delimiter
                            ) {
                                searchContext.partialInput += FILTER_TYPE.METRIC.delimiter;
                                searchContext.fullMetricText = searchText.substring(
                                    searchContext.propertyStartIndex,
                                    searchContext.cursorIndex
                                );
                                metricGroupAccepted = false;
                            } else {
                                searchContext.fullMetricText = searchText.substring(
                                    searchContext.propertyStartIndex,
                                    searchContext.delimIndex
                                );
                            }

                            return metricGroupSuggestionsFetch(
                                searchContext.partialInput,
                                $scope.isActiveMetricsOnly
                            );
                        }
                        //return empty when no filter type selected.
                        return $q.when([]);
                    }

                    function getSearchContext(searchString) {
                        const inputElement = document.getElementsByClassName($scope.inputClass)[0];
                        const cursorIndex = inputElement.selectionStart;
                        const spaceIndex = findLastIndexOf(searchString, ' ', 0, cursorIndex);
                        const propertyStartIndex = spaceIndex + 1;
                        const context = {};
                        const propertyDelimIndex = findLastIndexOf(
                            searchString,
                            FILTER_TYPE.PROPERTY_VALUE.delimiter,
                            spaceIndex,
                            cursorIndex
                        );
                        if (propertyDelimIndex > 0) {
                            context.filterType = FILTER_TYPE.PROPERTY_VALUE;
                            context.propertyStartIndex = propertyStartIndex;
                            context.property = searchString.substring(
                                propertyStartIndex,
                                propertyDelimIndex
                            );
                            context.cursorIndex = cursorIndex;
                            context.partialInput = searchString.substring(
                                propertyDelimIndex + 1,
                                cursorIndex
                            );
                            return context;
                        }

                        const metricDelimIndex = findLastIndexOf(
                            searchString,
                            FILTER_TYPE.METRIC.delimiter,
                            spaceIndex,
                            cursorIndex
                        );
                        if (metricDelimIndex > 0) {
                            context.filterType = FILTER_TYPE.METRIC;
                            context.fullMetricText = '';
                            context.propertyStartIndex = propertyStartIndex;
                            context.delimIndex = metricDelimIndex;
                            context.cursorIndex = cursorIndex;
                            context.partialInput = searchString.substring(
                                propertyStartIndex,
                                cursorIndex
                            );
                            return context;
                        }

                        return null;
                    }

                    function findLastIndexOf(searchString, target, start, exclusiveEnd) {
                        let i = exclusiveEnd;
                        while (start < i) {
                            i--;
                            if (searchString.charAt(i) === target) {
                                return i;
                            }
                        }
                        return -1;
                    }

                    function filterSelected(value) {
                        if (searchContext.filterType === FILTER_TYPE.PROPERTY_VALUE) {
                            const newFilter =
                                searchContext.property +
                                FILTER_TYPE.PROPERTY_VALUE.delimiter +
                                value;
                            $scope.addFilter(newFilter);
                            const beforeText = $scope.searchText.slice(
                                0,
                                searchContext.propertyStartIndex
                            );
                            const afterText = $scope.searchText.slice(searchContext.cursorIndex);
                            const searchText = beforeText + afterText;
                            $scope.updateAndSearchMetrics(searchText);
                        }

                        if (searchContext.filterType === FILTER_TYPE.METRIC) {
                            const beforeText = $scope.searchText.slice(
                                0,
                                searchContext.propertyStartIndex
                            );
                            const afterText = $scope.searchText.slice(searchContext.cursorIndex);
                            $scope.searchText =
                                beforeText + searchContext.fullMetricText + value + afterText;
                            // set that a value was accepted
                            metricGroupAccepted = true;
                        }
                        // clear search context
                        searchContext = null;
                    }
                },
            ],
        };
    },
];
