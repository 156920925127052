import PropTypes from 'prop-types';
import React, { useState, useEffect, useReducer } from 'react';
import BEMHelper from 'react-bem-helper';
import SavingStatus from './SavingStatus.js';
import InformationCheckbox from './InformationCheckbox.js';
import InformationDurationInput from './InformationDurationInput.js';
import OrganizationPreferenceService from './OrganizationPreferenceService';
import UserAnalyticsService from '../../../../legacy/app/userAnalytics/UserAnalyticsService';
import { AngularInjector } from '../../../../common/AngularUtils';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { ngRoute } from '../../../../app/routing/ngRoute';

const ORGANIZATION_SETTINGS_TITLE = 'General Organization Settings';

//buttons
const APPLY_CHANGES = 'Apply';
const CANCEL = 'Cancel';

//headings and labels
const TEAM_MANAGEMENT_INFORMATION = [
    'Enable enhanced team security by restricting users from joining any team.',
    'Team managers and administrators can add and remove users from a team.',
];
const TEAM_CHECKBOX_LABEL = 'Restrict Team Access';

const ALERT_AUTO_CLEAR_LABEL = 'Auto-Clear Alerts';
const ALERT_AUTO_CLEAR_INFORMATION = [
    'Clear active alerts by default if metric time series has not responded for the given duration.',
];

const className = new BEMHelper('organization-preferences');

function ChangeButtons({ onCancelClick, onApplyChangesClick, isValid }) {
    return (
        <div {...className('apply-change-button')}>
            <div {...className('button-spacing')}>
                <button onClick={onCancelClick} className="btn btn-default">
                    {CANCEL}
                </button>
            </div>
            <div {...className('button-spacing')}>
                <button
                    onClick={onApplyChangesClick}
                    className={'btn btn-primary'}
                    disabled={!isValid}
                >
                    {APPLY_CHANGES}
                </button>
            </div>
        </div>
    );
}

ChangeButtons.propTypes = {
    onCancelClick: PropTypes.func,
    onApplyChangesClick: PropTypes.func,
    isValid: PropTypes.bool,
};

export default function OrganizationPreferences({ featureEnabled, editable }) {
    const [restrictTeamManagement, setRestrictTeamManagement] = useState(null);
    const [alertAutoClearDuration, setAlertAutoClearDuration] = useState(null);
    const [isValid, setIsValid] = useState(true);
    const [isCancelled, cancel] = useReducer((c) => c + 1, 0);
    const [isLoading, setIsLoading] = useState(true);
    const [initialTeamManagementSetting, setInitialTeamManagementSetting] = useState(null);
    const [initialAlertAutoClearDuration, setInitialAlertAutoClearDuration] = useState(null);
    const [orgPreferenceId, setOrgPreferenceId] = useState(null);
    const [syncState, setSyncState] = useState(null);
    const rbacUtilsService = AngularInjector.instantiate('rbacUtilsService');

    const orgPreferenceService = OrganizationPreferenceService.useInstance();
    const userAnalyticsService = UserAnalyticsService.useInstance();

    const isUpdated = () => {
        if (
            restrictTeamManagement !== initialTeamManagementSetting ||
            alertAutoClearDuration !== initialAlertAutoClearDuration
        ) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (featureEnabled('newRbacExperience')) {
            rbacUtilsService.checkCapabilityAndRedirect(Capability.READ_GENERAL_SETTINGS);
        } else if (!editable) {
            ngRoute.history?.replace('/NotFound');
        }
    }, [editable, featureEnabled, rbacUtilsService]);

    useEffect(() => {
        const fetchOrgData = async () => {
            const organizationSettings = await orgPreferenceService.get();

            setOrgPreferenceId(organizationSettings.sf_id);
            setInitialTeamManagementSetting(!!organizationSettings.sf_restrictTeamManagement);
            setRestrictTeamManagement(!!organizationSettings.sf_restrictTeamManagement);
            if (organizationSettings.sf_alertAutoClearDuration) {
                setInitialAlertAutoClearDuration(organizationSettings.sf_alertAutoClearDuration);
                setAlertAutoClearDuration(organizationSettings.sf_alertAutoClearDuration);
            }
            setIsLoading(false);
        };

        fetchOrgData();
    }, [orgPreferenceService]);

    function handleSubmit() {
        setSyncState('APPLYING');

        const patch = {};
        if (restrictTeamManagement !== initialTeamManagementSetting) {
            patch.restrictTeamManagement = restrictTeamManagement;
        }
        if (alertAutoClearDuration !== initialAlertAutoClearDuration) {
            patch.alertAutoClearDuration = alertAutoClearDuration;
        }

        return orgPreferenceService
            .update(orgPreferenceId, patch)
            .then(() => {
                setSyncState('COMPLETED');
                const eventAction = restrictTeamManagement
                    ? 'team-controls-toggle-on'
                    : 'team-controls-toggle-off';
                userAnalyticsService.event('general organization settings', eventAction);
                setInitialTeamManagementSetting(restrictTeamManagement);
                setInitialAlertAutoClearDuration(alertAutoClearDuration);
            })
            .catch(() => {
                setSyncState('FAILED');
            })
            .finally(() => {
                setTimeout(function () {
                    setSyncState(null);
                }, 1000);
            });
    }

    function cancelChanges() {
        setRestrictTeamManagement(initialTeamManagementSetting);
        setAlertAutoClearDuration(initialAlertAutoClearDuration);
        cancel();
    }

    function onRestrictTeamManagementChanges() {
        setRestrictTeamManagement(!restrictTeamManagement);
    }

    return (
        <section {...className()}>
            {isLoading && <div className="loading-shroud"></div>}
            {!isLoading && (
                <div>
                    <SavingStatus status={syncState} />
                    <h4 {...className('page-title')}>{ORGANIZATION_SETTINGS_TITLE}</h4>
                    {featureEnabled('writepermissions') && (
                        <InformationCheckbox
                            label={TEAM_CHECKBOX_LABEL}
                            isSelected={restrictTeamManagement}
                            onCheckboxClick={onRestrictTeamManagementChanges}
                            additionalInfo={TEAM_MANAGEMENT_INFORMATION}
                        />
                    )}
                    <InformationDurationInput
                        key={isCancelled}
                        label={ALERT_AUTO_CLEAR_LABEL}
                        value={alertAutoClearDuration}
                        onChange={(duration, isValidDuration) => {
                            setIsValid(isValidDuration);
                            setAlertAutoClearDuration(duration);
                        }}
                        additionalInfo={ALERT_AUTO_CLEAR_INFORMATION}
                    />
                    {isUpdated() && (
                        <ChangeButtons
                            isValid={isValid}
                            onApplyChangesClick={handleSubmit}
                            onCancelClick={cancelChanges}
                        />
                    )}
                </div>
            )}
        </section>
    );
}

OrganizationPreferences.propTypes = {
    featureEnabled: PropTypes.func,
    currentUser: PropTypes.func,
    editable: PropTypes.bool,
};
