import MockSignalFlowClient from '../../../../app/mock/mockSignalFlowClient';

angular.module('signalflowv2').service('ComputationManager', [
    'signalFlowV2StreamerArtifacts',
    '$log',
    'auth',
    'featureEnabled',
    'SIGNALFLOW_V2_API_URL',
    'sdContentCreationAuth',
    '$rootScope',
    function (
        signalFlowV2StreamerArtifacts,
        $log,
        auth,
        featureEnabled,
        SIGNALFLOW_V2_API_URL,
        sdContentCreationAuth,
        $rootScope
    ) {
        let signalFlowClient = null;
        const wsUrl = SIGNALFLOW_V2_API_URL;

        function getV2Token() {
            const token = auth.authToken();
            $log.debug('Using V2 token for Signalflow V2 ', token);
            return token;
        }

        function getSignalFlowClient() {
            // FIXME: this could fail if the token expires and the promise continues to return
            // the expired token.  need code that understands auth failures and recreates a token
            // once a valid v1 token exists
            if (signalFlowClient) {
                return signalFlowClient;
            }
            if (featureEnabled('mockComputations')) {
                signalFlowClient = new MockSignalFlowClient();
            } else {
                signalFlowClient = signalFlowV2StreamerArtifacts(getV2Token(), {
                    signalflowEndpoint: wsUrl,
                });
            }
            return signalFlowClient;
        }

        function streamComputation(params, isPreflight, callback, errback) {
            params.compress = false;
            //FIXME: this could leak promises if the client never returns, no timeouts.  we'll end up piling on jobstarts
            let passMessages = true;
            let streamObject = null;
            if (params.incidentId) {
                streamObject = getSignalFlowClient().explain(params);
            } else if (isPreflight) {
                streamObject = getSignalFlowClient().preflight(params);
            } else {
                streamObject = getSignalFlowClient().execute(params);
            }
            streamObject.stream(function (err, cb) {
                if (cb) {
                    if (passMessages) {
                        callback(cb);
                    }
                } else {
                    errback(err);
                }
            });

            function stopJob(computation) {
                computation.close();
            }
            return {
                stop: function () {
                    passMessages = false;
                    stopJob(streamObject);
                },
            };
        }

        function reset() {
            if (signalFlowClient) {
                signalFlowClient.disconnect();
            }
            signalFlowClient = null;
        }

        $rootScope.$on('migrated services initialized', function () {
            auth.onAuth(reset); // subsequent request will get new token
            auth.onDeAuth(reset);
        });
        return {
            streamComputation: streamComputation,
            reset: reset,
        };
    },
]);
