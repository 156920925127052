const MAX_RECENTLY_VIEWED_DASHBOARDS = 5;
const MAX_LAST_VISITED_DASHBOARDS = 100;
const ERROR_TYPES = {
    MISMATCHED_ORG: 'MISMATCHED_ORG',
    INVALID_RECENT_ENTRY: 'INVALID_RECENT_ENTRY',
};

export function removeSnapshotsFromList(recentlyViewedDashboards) {
    return _.filter(recentlyViewedDashboards, function (d) {
        return !d.snapshotId;
    });
}

export function addSnapshotToList(snapshot, recentlyViewedDashboards = []) {
    const rv = [...recentlyViewedDashboards];
    const updatedRecentlyViewedDashboards = removeSnapshotsFromList(rv);
    updatedRecentlyViewedDashboards.unshift(snapshot);
    return updatedRecentlyViewedDashboards.slice(0, MAX_RECENTLY_VIEWED_DASHBOARDS);
}

angular
    .module('signalview.recentPages', ['signalboost', 'signalview.page'])
    /**
     * Service to get dashboards that the current user recently visited.
     *
     * Recently visited dashboards are stored on the user preferences object under
     * the 'sf_recentlyViewedDashboards' key as an array of dashboard IDs that is
     * sorted from most recently visited to least recently visited.
     *
     * The most recent dashboard visited for a particular page is stored on the
     * user preferences object under 'sf_lastViewedDashboard' as a mapping from
     * page ID to dashboard ID.
     */
    .service('recentPagesService', [
        '$log',
        '$q',
        '_',
        'currentUser',
        'featureEnabled',
        function ($log, $q, _, currentUser, featureEnabled) {
            // This will eventually return a list of objects of the format
            // { groupId, dashboardId, overrideConfigId, nameOverride }
            //
            // For now, we should be able to handle when the recentlyViewed are only ids
            function getRecentDashboardIds() {
                return currentUser
                    .orgPreferences()
                    .then((prefs) => {
                        return prefs.sf_recentlyViewedDashboards || [];
                    })
                    .catch((e) => {
                        $log.error('Unable to load user org preferences', e);
                        return [];
                    });
            }

            /**
             * Sets a dashboard to the most recently viewed dashboard for the current
             * user.
             *
             * @param {string} orgId - The ID of the org that the dashboard belongs to.
             * @param {string} groupId - The ID of the page that the dashboard belongs
             *                          to.
             * @param {string} dashboardId - The ID of the dashboard that should be
             *                               considered the most recently visited.
             * @param {string} configId
             * @param {string} nameOverride
             * @returns {Object} The updated user preferences object.
             */
            // TODO(trevor): This is where the format of storing dashboard preferences can be changed
            function setDashboardVisited(orgId, { groupId, dashboardId, configId, nameOverride }) {
                return currentUser.orgPreferences().then(
                    function (prefs) {
                        verifyPrefsMatchOrg(prefs, orgId);
                        validateRecentEntry({ groupId, dashboardId, configId });
                        prefs.sf_lastViewedDashboard = updateLastViewedDashboards(prefs, {
                            groupId,
                            dashboardId,
                            configId,
                        });
                        prefs.sf_recentlyViewedDashboards = updateRecentlyViewedDashboards(prefs, {
                            dashboardId,
                            groupId,
                            configId,
                            nameOverride,
                        });
                        return currentUser.updateOrgPreferences(prefs);
                    },
                    function (reason) {
                        if (reason.type === ERROR_TYPES.MISMATCHED_ORG) {
                            $log.error(
                                'Failed finding user prefs to set last viewed dashboard',
                                dashboardId,
                                reason.msg
                            );
                        } else if (reason.type === ERROR_TYPES.INVALID_RECENT_ENTRY) {
                            $log.error(reason.msg);
                        }
                    }
                );
            }

            function setSnapshotVisited(orgId, snapshot) {
                return currentUser.orgPreferences().then(
                    function (prefs) {
                        verifyPrefsMatchOrg(prefs, orgId);
                        prefs.sf_recentlyViewedDashboards = addSnapshotToList(
                            snapshot,
                            prefs.sf_recentlyViewedDashboards
                        );
                        return currentUser.updateOrgPreferences(prefs);
                    },
                    function (e) {
                        $log.error(
                            'Failed finding user prefs to set last viewed dashboard',
                            snapshot.snapshotId,
                            e
                        );
                    }
                );
            }

            function verifyPrefsMatchOrg(prefs, orgId) {
                if (prefs.sf_organizationID !== orgId) {
                    return $q.reject({
                        type: ERROR_TYPES.MISMATCHED_ORG,
                        msg: `prefs org id "${prefs.sf_organizationID}" is different than given org id ${orgId}`,
                    });
                }
            }

            function validateRecentEntry({ groupId, dashboardId, configId }) {
                if (
                    !groupId ||
                    !dashboardId ||
                    // for access control entitlement valid dashboard entry could be without configId set
                    !(configId || featureEnabled('accessControl'))
                ) {
                    return $q.reject({
                        type: ERROR_TYPES.INVALID_RECENT_ENTRY,
                        msg: `invalid parameters for recent entry groupId:${groupId}, dashboardId:${dashboardId}, configId:${configId}`,
                    });
                }
            }

            function updateLastViewedDashboards(prefs, { groupId, dashboardId, configId }) {
                let sf_lastViewedDashboard = prefs.sf_lastViewedDashboard || {};
                const keepingKeys = _.keys(sf_lastViewedDashboard).slice(
                    0,
                    MAX_LAST_VISITED_DASHBOARDS
                );
                sf_lastViewedDashboard = _.pick(sf_lastViewedDashboard, keepingKeys);
                sf_lastViewedDashboard[groupId] = { dashboardId, configId };
                return sf_lastViewedDashboard;
            }

            /** @param dashboardPageObj - { groupId, dashboardId, configId, nameOverride }  */
            function updateRecentlyViewedDashboards(prefs, dashboardPageObj) {
                const rv = angular.copy(prefs.sf_recentlyViewedDashboards) || [];
                rv.unshift(dashboardPageObj);
                const recentlyViewedDashboards = removeDuplicates(rv).slice(
                    0,
                    MAX_RECENTLY_VIEWED_DASHBOARDS
                );
                return recentlyViewedDashboards;
            }

            function removeDuplicates(recentlyViewedDashboards) {
                const seen = new Set();
                const uniqueRecentViews = [];

                let key;
                recentlyViewedDashboards.forEach((dash) => {
                    key = dash.configId || dash.dashboardId || dash.snapshotId || dash;

                    if (!seen.has(key)) {
                        uniqueRecentViews.push(dash);
                        seen.add(key);
                    }
                });

                return uniqueRecentViews;
            }

            function removeAllRecentEntriesOfDashboard(dashboardId) {
                return currentUser.orgPreferences().then((prefs) => {
                    const recentlyViewedDashboards =
                        angular.copy(prefs.sf_recentlyViewedDashboards) || [];
                    prefs.sf_recentlyViewedDashboards = recentlyViewedDashboards.filter(
                        (dashboard) => dashboard.dashboardId !== dashboardId
                    );
                    prefs.sf_lastViewedDashboard = removeLastViewedDashboardEntries(
                        prefs.sf_lastViewedDashboard,
                        'dashboardId',
                        dashboardId
                    );
                    return currentUser.updateOrgPreferences(prefs);
                });
            }

            function removeSpecificRecentEntryOfDashboard(configId) {
                return currentUser.orgPreferences().then((prefs) => {
                    const recentlyViewedDashboards =
                        angular.copy(prefs.sf_recentlyViewedDashboards) || [];
                    prefs.sf_recentlyViewedDashboards = recentlyViewedDashboards.filter(
                        (dashboard) => dashboard.configId !== configId
                    );
                    prefs.sf_lastViewedDashboard = removeLastViewedDashboardEntries(
                        prefs.sf_lastViewedDashboard,
                        'configId',
                        configId
                    );
                    return currentUser.updateOrgPreferences(prefs);
                });
            }

            /**
             * Removes all entries in the given lastViewedDashboards object with the given value for the
             * given key
             *
             * @param {Object} lastViewedDashboards A lastViewedDashboards object from user preferences
             * @param {string} keyString Either 'dashboardId' or 'configId'
             * @param {string} value The id by which to select objects for deletion
             *
             * @returns {Object} The modified lastViewedDashboards object
             */
            function removeLastViewedDashboardEntries(lastViewedDashboards, keyString, value) {
                const keys = Object.keys(lastViewedDashboards);

                keys.forEach((groupId) => {
                    const entry = lastViewedDashboards[groupId];
                    if (entry[keyString] === value) {
                        delete lastViewedDashboards[groupId];
                    }
                });

                return lastViewedDashboards;
            }

            /**
             * Maps recents to itself if its an object or coerces it to an object with key dashboardId
             */
            function getRecentDashboardEntries() {
                return getRecentDashboardIds().then(function (recents) {
                    return recents.map(function (recent) {
                        // check if recent is still in old format so just string id
                        if (typeof recent === 'string') {
                            return {
                                dashboardId: recent,
                            };
                        }
                        return recent;
                    });
                });
            }

            return {
                getRecentDashboardIds,
                removeAllRecentEntriesOfDashboard,
                removeSpecificRecentEntryOfDashboard,
                setDashboardVisited,
                removeSnapshotsFromList,
                addSnapshotToList,
                setSnapshotVisited,
                getRecentDashboardEntries,
            };
        },
    ]);
