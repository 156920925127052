import templateUrl from './heatmapGroupBy.tpl.html';

angular.module('signalview.heatmap').directive('heatmapGroupBy', [
    'getDataFacets',
    '$q',
    'HEATMAP_PROPERTY_DENYLIST',
    function (getDataFacets, $q, HEATMAP_PROPERTY_DENYLIST) {
        return {
            restrict: 'E',
            scope: {
                heatmap: '=',
            },
            templateUrl,
            link: {
                post: function ($scope) {
                    function loadGroupBy() {
                        $scope.groupBy = $scope.heatmap.groupBy();
                    }

                    $scope.heatmap.on('groupBy updated', loadGroupBy);
                    loadGroupBy();

                    $scope.$watch('groupBy', function (newVal, oldVal) {
                        if (!angular.equals(newVal, oldVal)) {
                            $scope.heatmap.selection(null);
                            $scope.heatmap.groupBy($scope.groupBy);
                            $scope.heatmap.groupBySelection(null);
                        }
                    });

                    $scope.$on('groupBy selector highlighted', function ($event, groupBy) {
                        // Disable updating group bys on hover when the heatmap is very large
                        // for UI performance reasons.
                        if (!$scope.heatmap.isVeryLarge()) {
                            $scope.heatmap.groupBy(groupBy);
                        }
                    });

                    $scope.getSuggestions = function () {
                        const nodes = $scope.heatmap.getNodes();
                        const suggestions = getDataFacets(nodes, HEATMAP_PROPERTY_DENYLIST);
                        return $q.when(suggestions);
                    };

                    let previousSelection;
                    let previousGroupBy;
                    $scope.$on('groupBy selector visible', function () {
                        previousSelection = $scope.heatmap.selection();
                        previousGroupBy = $scope.heatmap.groupBy();
                        $scope.heatmap.selection(null);
                    });

                    $scope.$on('groupBy selector hidden', function () {
                        if (angular.equals(previousGroupBy, $scope.groupBy)) {
                            $scope.heatmap.selection(previousSelection);
                        }
                    });
                },
            },
        };
    },
]);
