import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';

const DEFAULT_LIST_LENGTH = 3;
const DEFAULT_SHOW_LESS_LABEL = 'Show less';
const DEFAULT_SHOW_ALL_LABEL = 'Show all';

const className = BEMHelper('show-more-list');

export default function ShowMoreList(props) {
    const initialLength = props.initialLength || DEFAULT_LIST_LENGTH;

    const showLessLabel = props.showLessLabel || DEFAULT_SHOW_LESS_LABEL;
    const showAllLabel = props.showAllLabel || DEFAULT_SHOW_ALL_LABEL;

    const [isExpanded, setIsExpanded] = useState(false);

    const isCollapsible = props.list.length > initialLength;
    const label = isExpanded ? showLessLabel : showAllLabel;

    const context = useMemo(() => {
        const context = {};
        for (const item of props.list) {
            context[item.propertyName] = item.propertyValue;
        }
        return context;
    }, [props.list]);

    return (
        <React.Fragment>
            <ul>
                {props.list.map((value, index) => (
                    <li key={index} className={getItemClass(index)}>
                        {props.renderRow(value, context)}
                    </li>
                ))}
            </ul>
            {isCollapsible && (
                <button
                    {...className('link', [], ['sf-clickable'])}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {label}
                </button>
            )}
        </React.Fragment>
    );

    function getItemClass(index) {
        return index >= initialLength && !isExpanded ? 'hide' : '';
    }
}

ShowMoreList.propTypes = {
    list: PropTypes.array.isRequired,
    renderRow: PropTypes.func.isRequired,
    showAllLabel: PropTypes.string,
    showLessLabel: PropTypes.string,
    initialLength: PropTypes.number,
};
