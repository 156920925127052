/* eslint-disable @typescript-eslint/no-unused-vars */
import { useUserStore } from '@splunk//olly-services/lib/services/UserV2Service/UserStoreProvider';
import { useSignalViewMetricsStore } from '@splunk/olly-imm/build/metrics/SignalViewMetricsStoreProvider';
import { useSignalboostBaseService } from '@splunk/olly-imm/build/services/signalboost/SignalboostBaseService/SignalboostBaseProvider';
import {
    useHTTPService,
    useMetadataMatchingStore,
    useCallToActionGenerationStore,
} from '@splunk/olly-services';
import { useAuthStore } from '@splunk/olly-services/lib/services/Auth/AuthStoreProvider';
import * as awsFilterTranslationService from '@splunk/olly-services/lib/services/AWSFilterTranslationService/AWSFilterTranslationService';
import { useColorAccessibilityStore } from '@splunk/olly-services/lib/services/ColorAccessibility/ColorAccessibilityProvider';
import { useCredentialV2Store } from '@splunk/olly-services/lib/services/CredentialV2/CredentialV2StoreProvider';
import { useCurrentUserStore } from '@splunk/olly-services/lib/services/CurrentUser/CurrentUserStoreProvider';
import { useFeatureFlags } from '@splunk/olly-services/lib/services/FeatureFlag/FeatureFlagStoreProvider';
import { useGlobalNavUpdateStore } from '@splunk/olly-services/lib/services/GlobalNav/GlobalNavUpdateStoreProvider';
import { useLoginStore } from '@splunk/olly-services/lib/services/LoginStore/LoginStoreProvider';
import { useOrganizationStore } from '@splunk/olly-services/lib/services/Organization/OrganizationStoreProvider';
import { useOverQuotaNotificationStore } from '@splunk/olly-services/lib/services/OverQuotaNotification/OverQuotaNotificationStoreProvider';
import { usePubSubStore } from '@splunk/olly-services/lib/services/PubSubService/PubSubProvider';
import { useSalesforceLiveAgentServiceStore } from '@splunk/olly-services/lib/services/SalesforceLiveAgentChat/SalesforceLiveAgentServiceProvider';
import { useSessionCacheStore } from '@splunk/olly-services/lib/services/SessionCache/SessionCacheProvider';
import { useUserAnalytics } from '@splunk/olly-services/lib/services/UserAnalytics/UserAnalyticsProvider';
import { useSessionStore } from '@splunk/olly-services/lib/Session/SessionServiceProvider';
import { useSplunkIntegrations } from '@splunk/olly-services/lib/services/SplunkIntegrations/SplunkIntegrationsProvider';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AngularInjector } from '../../common/AngularUtils';
import { SharedClipboard } from '../Index';
import { useRelatedContent } from '../relatedContentService/RelatedContentServiceProvider';
import { reactNgBridge } from './MigratedServiceProxies';
import { FeatureFlags, SignalviewFeatureFlag } from '../../common/util/FeatureFlags';

/**
 * Syncs initialized stateful services with reactNgBridge.
 * This links the external services such as those coming
 * from @splunk/olly-services with angular dependency injection.
 */
export const NgInjectMigratedServices: FC<{ onInit?: () => void }> = ({ onInit, children }) => {
    const [initialized, setInit] = useState<boolean>(false);
    const [broadcasted, setBroadcast] = useState<boolean>(false);
    const [org, setOrg] = useState<{ id: string; created: number }>({ id: '', created: 0 });

    const organizationStore = useOrganizationStore();
    const { relatedContentService } = useRelatedContent();
    const { integrations } = useSplunkIntegrations();
    const { initialized: featureInitialized, featureFlags, featureStore } = useFeatureFlags();

    reactNgBridge.setProxy('auth', useAuthStore());
    reactNgBridge.setProxy('awsFilterTranslationService', awsFilterTranslationService);
    reactNgBridge.setProxy('clipboard', useContext(SharedClipboard));
    reactNgBridge.setProxy('colorAccessibilityService', useColorAccessibilityStore());
    reactNgBridge.setProxy('currentUser', useCurrentUserStore());
    reactNgBridge.setProxy('featureFlags', featureFlags);
    reactNgBridge.setProxy('globalNavUpdateService', useGlobalNavUpdateStore());
    reactNgBridge.setProxy('httpClient', useHTTPService());
    reactNgBridge.setProxy('loginService', useLoginStore());
    reactNgBridge.setProxy('migratedCredentialV2Service', useCredentialV2Store());
    reactNgBridge.setProxy('migratedSignalboost', useSignalboostBaseService());
    reactNgBridge.setProxy('organizationService', useOrganizationStore());
    reactNgBridge.setProxy('overQuotaNotificationService', useOverQuotaNotificationStore());
    reactNgBridge.setProxy('relatedContentService', relatedContentService);
    reactNgBridge.setProxy('integrations', { integrations: integrations });
    reactNgBridge.setProxy('salesforceLiveAgentService', useSalesforceLiveAgentServiceStore());
    reactNgBridge.setProxy('sessionCache', useSessionCacheStore());
    reactNgBridge.setProxy('sessionService', useSessionStore());
    reactNgBridge.setProxy('signalviewMetrics', useSignalViewMetricsStore());
    reactNgBridge.setProxy('uiFeatures', featureStore);
    reactNgBridge.setProxy('userAnalytics', useUserAnalytics());
    reactNgBridge.setProxy('userV2Service', useUserStore());
    reactNgBridge.setProxy('metadataMatchingStore', useMetadataMatchingStore());
    reactNgBridge.setProxy('callToActionGenerationStore', useCallToActionGenerationStore());

    const $rootScope = AngularInjector.useInjectedClass<angular.IRootScopeService>('$rootScope');
    const PubSubStore = usePubSubStore();

    useEffect(() => {
        organizationStore.get().then((org) => {
            setOrg({ id: org.id, created: org.created });
        });
    }, [organizationStore]);

    useEffect(() => {
        // Save original broadcast function to use later
        const extendBroadcast = $rootScope.$broadcast;

        $rootScope.$broadcast = function (...args: any): any {
            const [event, ...payload] = args;
            const payloadSize = payload.length;
            const skipRelay = !!(payload && payload[payloadSize - 1] === 'skipRelay');
            const rootscopePayload = skipRelay ? payload.slice(0, payloadSize - 1) : payload;
            if (!skipRelay) {
                const pubsubPayload =
                    rootscopePayload.length === 1 ? rootscopePayload[0] : rootscopePayload;
                PubSubStore.publish(event, pubsubPayload, true);
            }
            return extendBroadcast.apply(this, args);
        };

        // Listen to all events on PubSubStore and relay onto $rootScope
        return PubSubStore.subscribe('*', function (event: string, payload: any) {
            // skip relay onto PubSubStore to avoid cyclic trigger
            const eventPayload = Array.isArray(payload)
                ? [...payload, 'skipRelay']
                : [payload, 'skipRelay'];
            $rootScope.$broadcast(event, ...eventPayload);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // the event will only be broadcasted when all the migrated-services get initialized
        const shouldBroadcastForInitialization =
            !broadcasted && initialized && reactNgBridge.areProxiesInitialized();

        if (shouldBroadcastForInitialization) {
            $rootScope.$broadcast('migrated services initialized');
            setBroadcast(true);
        }

        if (featureInitialized && org?.id) {
            $rootScope.$broadcast('featureStore initialized', org);
        }
        setInit(true);
    }, [$rootScope, broadcasted, initialized, featureInitialized, org]);

    useEffect(() => {
        if (initialized && onInit) {
            onInit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized]);

    return <>{initialized && children}</>;
};

const ngMigratedServiceModule = window.angular.module('sfx.migratedServices', []);

ngMigratedServiceModule
    .constant(...reactNgBridge.ngProxy('auth'))
    .constant(...reactNgBridge.ngProxy('awsFilterTranslationService'))
    .constant(...reactNgBridge.ngProxy('clipboard'))
    .constant(...reactNgBridge.ngProxy('colorAccessibilityService'))
    .constant(...reactNgBridge.ngProxy('currentUser'))
    .constant(...reactNgBridge.ngProxy('featureFlags'))
    .constant(...reactNgBridge.ngProxy('globalNavUpdateService'))
    .constant(...reactNgBridge.ngProxy('httpClient'))
    .constant(...reactNgBridge.ngProxy('loginService'))
    .constant(...reactNgBridge.ngProxy('migratedCredentialV2Service'))
    .constant(...reactNgBridge.ngProxy('migratedSignalboost'))
    .constant(...reactNgBridge.ngProxy('organizationService'))
    .constant(...reactNgBridge.ngProxy('overQuotaNotificationService'))
    .constant(...reactNgBridge.ngProxy('relatedContentService'))
    .constant(...reactNgBridge.ngProxy('integrations'))
    .constant(...reactNgBridge.ngProxy('salesforceLiveAgentService'))
    .constant(...reactNgBridge.ngProxy('sessionCache'))
    .constant(...reactNgBridge.ngProxy('sessionService'))
    .constant(...reactNgBridge.ngProxy('signalviewMetrics'))
    .constant(...reactNgBridge.ngProxy('uiFeatures'))
    .constant(...reactNgBridge.ngProxy('userAnalytics'))
    .constant(...reactNgBridge.ngProxy('userV2Service'))
    .constant(...reactNgBridge.ngProxy('metadataMatchingStore'))
    .constant(...reactNgBridge.ngProxy('callToActionGenerationStore'));

ngMigratedServiceModule.service('featureEnabled', [
    'featureFlags',
    function (featureFlags: FeatureFlags): (feature: SignalviewFeatureFlag) => boolean {
        return (feature: SignalviewFeatureFlag): boolean => featureFlags[feature] || false;
    },
]);
