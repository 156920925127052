import templateUrl from './hostBasedSubscription.tpl.html';
import { ENTERPRISE_PRODUCT, MONTH_PERIOD, STANDARD_PRODUCT } from './orgSubscriptionService.js';
import { ngRoute } from '../../../../app/routing/ngRoute';
import { supportService } from '@splunk/olly-services/lib';

export default {
    bindings: {
        subscriptionSvc: '=',
    },
    templateUrl,
    controller: [
        '$log',
        '$window',
        'ACCOUNT_SOURCES',
        'MARKETPLACE_PRODUCT_LINK',
        'SALES_NUMBER',
        'orgSubscriptionService',
        'organizationService',
        'subscriptionTypeUtil',
        function (
            $log,
            $window,
            ACCOUNT_SOURCES,
            MARKETPLACE_PRODUCT_LINK,
            SALES_NUMBER,
            orgSubscriptionService,
            organizationService,
            subscriptionTypeUtil
        ) {
            const ctrl = this;

            ctrl.ENTERPRISE_PRODUCT = ENTERPRISE_PRODUCT;
            ctrl.MONTH_PERIOD = MONTH_PERIOD;
            ctrl.MARKETPLACE_PRODUCT_LINK = MARKETPLACE_PRODUCT_LINK;
            ctrl.SALES_NUMBER = SALES_NUMBER;
            ctrl.STANDARD_PRODUCT = STANDARD_PRODUCT;

            ctrl.$onInit = $onInit;
            ctrl.syncOrg = syncOrg;

            function $onInit() {
                ctrl.isSupportOrg = supportService.getSupportOrg();
                getAccount();

                organizationService.get().then((org) => {
                    ctrl.org = org;

                    ctrl.isTrial = org.accountType === 'TRIAL';
                    ctrl.isAmazon = org.accountSource === ACCOUNT_SOURCES.AWS_MARKET_PLACE;
                    ctrl.mtsBasedOrg = subscriptionTypeUtil.isCustomMts(
                        org.accountSubscriptionType
                    );
                    ctrl.hostBasedOrg = subscriptionTypeUtil.isHostBased(
                        org.accountSubscriptionType
                    );
                });
            }

            function syncOrg() {
                ctrl.syncing = true;
                organizationService.get().then((org) => {
                    orgSubscriptionService
                        .syncOrg(org.accountKey)
                        .then(function () {
                            ngRoute.reload();
                        })
                        .catch(function (e) {
                            $window.alert('Failed to sync org');
                            $log.error('Failed to sync org ', e);
                            ctrl.syncing = false;
                        });
                });
            }

            function getAccount() {
                ctrl.account = null;
                ctrl.fetching = true;

                ctrl.subscriptionSvc.getAccount().then(
                    (acct) => {
                        ctrl.fetching = false;
                        ctrl.account = acct;
                        if (!acct) {
                            return;
                        }

                        if (
                            ctrl.account.upcomingSubscription &&
                            ctrl.account.upcomingSubscription.length
                        ) {
                            ctrl.upcoming = ctrl.account.upcomingSubscription[0];
                        }
                    },
                    function () {
                        ctrl.fetching = false;
                    }
                );
            }
        },
    ],
};
