export default [
    '_',
    '$q',
    'CROSS_LINK_TYPES',
    'crossLinkUtils',
    'crossLinkService',
    'featureEnabled',
    function (_, $q, CROSS_LINK_TYPES, crossLinkUtils, crossLinkService, featureEnabled) {
        const artificialIdPrefix = 'SYNTHETIC_ID:';
        const navigatorDataLinks = featureEnabled('navigatorDataLinks');
        function attachDefinition(target, definition) {
            Object.defineProperty(target, 'definition', {
                value: definition,
                enumerable: false,
                configurable: true,
            });

            return target;
        }

        function typeError(allowedTypes) {
            throw new Error(
                'Target link type should conform to allowed types: ' + [...allowedTypes]
            );
        }

        function isSameDefinition(defA, defB) {
            return (
                (defA.propertyName || null) === (defB.propertyName || null) &&
                (defA.propertyValue || null) === (defB.propertyValue || null) &&
                (defA.contextId || null) === (defB.contextId || null)
            );
        }

        function update(destination, ignore = [], ...extensions) {
            extensions = _.extend({}, ...extensions);
            ignore.forEach((key) => {
                delete extensions[key];
            });
            ignore = new Set(ignore);

            _.keys(destination).forEach((key) => {
                if ((!extensions.hasOwnProperty(key) || !extensions[key]) && !ignore.has(key)) {
                    delete destination[key];
                }
            });

            _.each(extensions, (value, key) => {
                if (value) {
                    destination[key] = value;
                }
            });

            return destination;
        }

        /* Returns a list of targets which can directly be manipulated and consumed by saveTargets(),
         * Supports crossLinks as well as integration objects */
        function crossLinkUpdatableTargets(links, allowedTypes) {
            this.updatableTargets = [];
            this.nextArtificialId = 0;
            this.linkTypes = new Set(allowedTypes);

            this.links = {};
            this.definitions = {};
            links.forEach((link) => (this.links[link.id] = link));

            const linkCopy = angular.copy(links);

            linkCopy.forEach((link) => {
                this.definitions[link.id] = link;
                if (link.targets) {
                    link.targets.forEach((target) => this.addAsUpdatableTarget(link, target));
                }
            });
        }

        crossLinkUpdatableTargets.prototype.filterNavigatorTargets = function () {
            return this.updatableTargets.filter(
                (target) => target.type !== CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK
            );
        };

        crossLinkUpdatableTargets.prototype.getNewDefinition = function (
            target,
            contextId = null,
            propertyName = '',
            propertyValue = ''
        ) {
            return {
                propertyName,
                propertyValue,
                id: `${artificialIdPrefix}${++this.nextArtificialId}`,
                contextId: contextId,
                targets: [target],
            };
        };

        crossLinkUpdatableTargets.prototype.isValidTarget = function (target) {
            return (
                target.type in CROSS_LINK_TYPES &&
                (_.isEmpty(this.linkTypes) || this.linkTypes.has(CROSS_LINK_TYPES[target.type]))
            );
        };

        crossLinkUpdatableTargets.prototype.addAsUpdatableTarget = function (link, target) {
            if (this.isValidTarget(target)) {
                this.updatableTargets.push(target);
            }

            attachDefinition(target, link);
        };

        crossLinkUpdatableTargets.prototype.addNewUpdatableTarget = function (
            target,
            contextId = null,
            propertyName = '',
            propertyValue = ''
        ) {
            if (!this.isValidTarget(target)) {
                typeError(this.linkTypes);
            }

            attachDefinition(
                target,
                this.getNewDefinition(target, contextId, propertyName, propertyValue)
            );
            target._isNew = true;

            this.updatableTargets.unshift(target);
            return target;
        };

        crossLinkUpdatableTargets.prototype.isNewTarget = function (target) {
            return (
                target.definition.id.startsWith(artificialIdPrefix) ||
                (this.definitions[target.definition.id] &&
                    !this.definitions[target.definition.id].targets.includes(target))
            );
        };

        crossLinkUpdatableTargets.prototype.cleanTarget = function (target) {
            // Keep original target object (for angular), clean it out and add updates
            _.keys(target).forEach((key) => {
                if (!key.startsWith('$') && key !== 'type') {
                    delete target[key];
                }
            });

            return target;
        };

        crossLinkUpdatableTargets.prototype.getTargets = function () {
            this.updatableTargets = navigatorDataLinks
                ? this.updatableTargets
                : this.filterNavigatorTargets();
            return this.updatableTargets;
        };

        crossLinkUpdatableTargets.prototype.sortTargets = function (order) {
            this.updatableTargets = _.sortBy(
                this.updatableTargets,
                order.map((prop) => (target) => _.get(target, prop, ''))
            );
        };

        crossLinkUpdatableTargets.prototype.addOrUpdateLink = function (target, updates) {
            const targetUpdate =
                target._isNew ||
                !_.isEqual(
                    crossLinkUtils.cleanObject(target),
                    crossLinkUtils.cleanObject(updates.target)
                );
            const definitionUpdate = !isSameDefinition(target.definition, updates.definition);

            if (definitionUpdate) {
                return this.updateTargetDefinition(
                    target,
                    updates.definition,
                    targetUpdate ? updates.target : null
                );
            } else if (targetUpdate) {
                return this.addOrUpdateTarget(target, updates.target);
            }
            return $q.when();
        };

        crossLinkUpdatableTargets.prototype.updateTargetDefinition = function (
            target,
            newDefinition,
            newTarget
        ) {
            if (this.updatableTargets.includes(target)) {
                if (!isSameDefinition(target.definition, newDefinition)) {
                    if (this.isNewTarget(target)) {
                        const targets = target.definition.targets;
                        update(target.definition, ['targets'], newDefinition);
                        target.definition.targets = targets;
                        return this.addOrUpdateTarget(target, newTarget);
                    } else {
                        return this.deleteTarget(target, true).then(() => {
                            attachDefinition(
                                target,
                                this.getNewDefinition(
                                    target,
                                    newDefinition.contextId,
                                    newDefinition.propertyName,
                                    newDefinition.propertyValue
                                )
                            );

                            return this.addOrUpdateTarget(target, newTarget);
                        });
                    }
                }

                return $q.when();
            } else {
                throw new Error('Target not part of updatable targets');
            }
        };

        crossLinkUpdatableTargets.prototype.deleteTarget = function (target, keepInList) {
            if (this.updatableTargets.includes(target)) {
                if (this.isNewTarget(target)) {
                    _.pull(this.updatableTargets, target);
                    return $q.when();
                } else {
                    const linkId = target.definition.id;
                    if (target.definition.targets.length === 1) {
                        // There is only one target, Delete the whole link
                        return crossLinkService.delete(linkId).then(() => {
                            if (!keepInList) {
                                _.pull(this.updatableTargets, target);
                            }
                            delete this.links[linkId];
                            delete this.definitions[linkId];
                        });
                    } else {
                        // Link has multiple targets, remove relevant target and update the link
                        const linkCopy = angular.copy(target.definition);
                        _.pullAt(linkCopy.targets, target.definition.targets.indexOf(target));

                        return crossLinkService
                            .update(crossLinkUtils.cleanCrossLink(linkCopy))
                            .then((updatedLink) => {
                                this.links[linkId].targets = updatedLink.targets;
                                _.pull(target.definition.targets, target);
                                if (!keepInList) {
                                    _.pull(this.updatableTargets, target);
                                }
                            });
                    }
                }
            } else {
                throw new Error('Target not part of updatable targets');
            }
        };

        crossLinkUpdatableTargets.prototype.addOrUpdateTarget = function (target, newTarget) {
            if (!this.updatableTargets.includes(target)) {
                throw new Error('Target does not exists');
            }

            let isNew = this.isNewTarget(target);

            let existingDefinition = target.definition;

            // If is new find out if a definition exist
            if (isNew) {
                const preExist = _.find(this.definitions, (definition) =>
                    isSameDefinition(definition, target.definition)
                );

                if (preExist) {
                    isNew = false;
                    existingDefinition = preExist;
                }
            }

            const newLink = angular.copy(existingDefinition);
            const updateTarget = newTarget || target;
            const targetIndex = existingDefinition.targets.indexOf(target);
            if (targetIndex === -1) {
                if (updateTarget.isDefault && newLink.targets.some((target) => target.isDefault)) {
                    // Default already exists
                    updateTarget.isDefault = false;
                }

                newLink.targets.push(updateTarget);
            } else {
                newLink.targets[targetIndex] = updateTarget;
            }

            target._transient = true;

            if (isNew) {
                return crossLinkService
                    .create(crossLinkUtils.cleanCrossLink(newLink))
                    .then((updatedLink) => {
                        const replaceIndex = newLink.targets.indexOf(updateTarget);
                        this.cleanTarget(target);
                        update(target, [], updateTarget, updatedLink.targets[replaceIndex]);
                        attachDefinition(target, updatedLink);
                        target.definition.targets[replaceIndex] = target;

                        delete target._isNew;
                        this.links[updatedLink.id] = updatedLink;
                        this.definitions[updatedLink.id] = target.definition;
                        target._transient = true;
                        return target;
                    });
            } else {
                return crossLinkService
                    .update(crossLinkUtils.cleanCrossLink(newLink))
                    .then((updatedLink) => {
                        const replaceIndex = newLink.targets.indexOf(updateTarget);
                        this.cleanTarget(target);
                        update(target, [], updateTarget, updatedLink.targets[replaceIndex]);
                        if (targetIndex === -1) {
                            attachDefinition(target, existingDefinition);
                            existingDefinition.targets.push(target);
                        } else {
                            target.definition.targets[replaceIndex] = target;
                        }

                        delete target._isNew;
                        this.links[updatedLink.id] = updatedLink;
                        target._transient = true;
                        return target;
                    });
            }
        };

        crossLinkUpdatableTargets.prototype.setDefault = function (target) {
            let promise = null;
            if (
                CROSS_LINK_TYPES.INTERNAL_LINK === target.type ||
                CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK === target.type
            ) {
                let originalDefaultTarget = null;
                target.definition.targets.forEach((target) => {
                    if (target.isDefault) {
                        target.isDefault = false;
                        originalDefaultTarget = target;
                    }
                });
                target.isDefault = true;
                promise = this.addOrUpdateTarget(target).catch((errorResponse) => {
                    // Failure, Revert!
                    originalDefaultTarget.definition.targets.forEach((target) => {
                        if (target.isDefault) {
                            target.isDefault = false;
                        }
                    });
                    originalDefaultTarget.isDefault = true;
                    return errorResponse;
                });
            }
            return $q.when(promise);
        };

        return crossLinkUpdatableTargets;
    },
];
