angular
    .module('signalview.input')
    /**
     * Usage:
     *   input that need to be auto resized.
     *
     *   Specify min-width and max-width CSS style to provide boundaries.
     */
    .directive('autoResizeInput', [
        '$timeout',
        '$q',
        function ($timeout, $q) {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, element, attributes, ngModel) {
                    const mirrorModel = angular.element('<span class="input-mirror"></span>');
                    angular.element('.sf-ui').first().append(mirrorModel);

                    angular.forEach(
                        [
                            'fontFamily',
                            'fontSize',
                            'fontWeight',
                            'fontStyle',
                            'letterSpacing',
                            'textTransform',
                            'wordSpacing',
                            'textIndent',
                            'boxSizing',
                            'borderRightWidth',
                            'borderLeftWidth',
                            'borderLeftStyle',
                            'borderRightStyle',
                            'paddingLeft',
                            'paddingRight',
                            'marginLeft',
                            'marginRight',
                            'minWidth',
                            'maxWidth',
                        ],
                        function (value) {
                            mirrorModel.css(value, element.css(value));
                        }
                    );

                    const updateMirrorModel = function () {
                        mirrorModel.text(ngModel.$viewValue || element.attr('placeholder') || '');
                        const contentWidth = mirrorModel.innerWidth();
                        element.css('width', contentWidth + 10);
                    };
                    $timeout(updateMirrorModel, 0, false);

                    scope.$watch(
                        function () {
                            return ngModel.$viewValue;
                        },
                        () => {
                            $q.resolve().then(updateMirrorModel());
                        }
                    );

                    scope.$on('$destroy', function () {
                        // get rid of mirror element when we destroy this directive.
                        mirrorModel.remove();
                    });
                },
            };
        },
    ]);
