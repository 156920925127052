import { RelatedContentService } from '@splunk/olly-common/RelatedContent/useRelatedContent';
import { ValueOf } from '@splunk/olly-common/utils/typeUtils';
import { SignalViewMetricsStore } from '@splunk/olly-imm/build/metrics/SignalViewMetricsStore';
import { Signalboost } from '@splunk/olly-imm/build/services/signalboost/SignalboostBaseService/SignalboostBaseProvider';
import {
    AWSFilterTranslationService,
    InstrumentedServiceClient,
    SplunkIntegration,
    MetadataMatchingStore,
    CallToActionGenerationStore,
} from '@splunk/olly-services';
import { AuthStore } from '@splunk/olly-services/lib/services/Auth/AuthStore';
import { Clipboard } from '@splunk/olly-services/lib/services/Clipboard';
import { ColorAccessibilityStore } from '@splunk/olly-services/lib/services/ColorAccessibility/ColorAccessibilityStore';
import { CredentialV2Store } from '@splunk/olly-services/lib/services/CredentialV2/CredentialV2Store';
import { CurrentUserStore } from '@splunk/olly-services/lib/services/CurrentUser/CurrentUserStore';
import { FeatureFlagStore } from '@splunk/olly-services/lib/services/FeatureFlag/FeatureFlagStore';
import { GlobalNavUpdateStore } from '@splunk/olly-services/lib/services/GlobalNav/GlobalNavUpdateStore';
import { LoginStore } from '@splunk/olly-services/lib/services/LoginStore/LoginStore';
import { OrganizationStore } from '@splunk/olly-services/lib/services/Organization/OrganizationStore';
import { OverQuotaNotificationStore } from '@splunk/olly-services/lib/services/OverQuotaNotification/OverQuotaNotificationStore';
import { SalesforceLiveAgentService } from '@splunk/olly-services/lib/services/SalesforceLiveAgentChat/salesforceLiveAgentService';
import { SessionCacheStore } from '@splunk/olly-services/lib/services/SessionCache/SessionCacheStore';
import { UserAnalytics } from '@splunk/olly-services/lib/services/UserAnalytics/UserAnalytics';
import { UserStore } from '@splunk/olly-services/lib/services/UserV2Service/UserStore';
import { SessionStore } from '@splunk/olly-services/lib/Session/SessionStore';
import debug from 'debug';
import { debounce, intersection, isEmpty, isFunction } from 'lodash';
import { AngularInjector } from '../../common/AngularUtils';

const serviceDebugger = debug('signalview').extend('MigratgedServiceProxies');
const warn = serviceDebugger.extend('warning');

export interface ProxyServiceContainer {
    (...args: any[]): any;

    auth?: ReturnType<AuthStore>;
    awsFilterTranslationService?: AWSFilterTranslationService;
    clipboard?: Clipboard;
    colorAccessibilityService?: ReturnType<ColorAccessibilityStore>;
    currentUser?: ReturnType<CurrentUserStore>;
    featureFlags?: Record<string, boolean>;
    globalNavUpdateService?: ReturnType<GlobalNavUpdateStore>;
    httpClient?: ReturnType<InstrumentedServiceClient>;
    loginService?: ReturnType<LoginStore>;
    migratedCredentialV2Service?: ReturnType<CredentialV2Store>;
    migratedSignalboost?: Signalboost;
    organizationService?: ReturnType<OrganizationStore>;
    overQuotaNotificationService?: ReturnType<OverQuotaNotificationStore>;
    relatedContentService?: RelatedContentService;
    integrations?: { integrations: SplunkIntegration[] };
    salesforceLiveAgentService?: ReturnType<SalesforceLiveAgentService>;
    sessionCache?: SessionCacheStore;
    sessionService?: ReturnType<SessionStore>;
    signalviewMetrics?: SignalViewMetricsStore;
    uiFeatures?: FeatureFlagStore;
    userAnalytics?: ReturnType<UserAnalytics>;
    userV2Service?: ReturnType<UserStore>;
    metadataMatchingStore?: MetadataMatchingStore;
    callToActionGenerationStore?: CallToActionGenerationStore;
}

type Callback = () => void;
export type OnChangeUnSubscriber = Callback;
export type OnChangeSubscriber = (subscriber: Callback) => OnChangeUnSubscriber;
export type OnChangeHandler = {
    onChange: OnChangeSubscriber;
    onChangeSubscribers: Array<() => void>;
};

export type KeyOfProxies = NonNullable<keyof ProxyServiceContainer>;

type ProxyWithChangeNotifier<T extends KeyOfProxies> = {
    service: ProxyServiceContainer[T];
    onChange: OnChangeSubscriber;
};

export type ProxiesWithChangeNotifier = {
    [P in keyof ProxyServiceContainer]: ProxyWithChangeNotifier<P>;
} & {
    (...args: any[]): any;
};

export interface NgInjectableClass {
    new (...args: any): any;

    $inject: Array<string>;
}

function isClassNgInjectable(Constructor: NgInjectableClass): Constructor is NgInjectableClass {
    return Array.isArray(Constructor.$inject) && Constructor.$inject.length === Constructor.length;
}

/**
 * Provides Necessary Mechanisms to re-inject React services that are migrated from Angular
 * within this app or to inject ES6 based pure services which still have to directly pull
 * in dependencies from Angular.
 *
 * It also provides React's useMemo analogue, ngReactMemo which memoizes
 * partially migrated ES6 based services.
 */
class ReactNgBridge {
    private changeHandlerCount = 0;
    private proxiesCount = 0;
    private onChangeHandlers: Partial<Record<keyof ProxyServiceContainer, OnChangeHandler>> = {};
    private initializationSubscribers: Callback[] = [];

    /**
     * This Container stores the instances of Migrated React service still being used in Angular.
     * These instances are then Proxied into the Angular Framework,
     * so that existing setup can still make use of the migrated services.
     *
     * Proxy acts as a pointer to latest instance of React services from within
     * the Angular DI.
     *
     * NOTE: Using function as a container, so that when a Proxy is applied to it,
     * it takes into account that both cases below are valid:
     *   1. <service>[<property>],
     *   2. <service>(<arguments>)
     */
    private proxyServiceContainer: ProxyServiceContainer = () => {};

    /**
     * Broadcast once the very first time all proxies are initialized.
     * Listener generally hooks into letting Angular start rendering.
     */
    private broadcastOnceIfInitialized(): void {
        if (this.areProxiesInitialized()) {
            this.initializationSubscribers.forEach((callback) => callback());
            this.initializationSubscribers = [];
        }
    }

    /**
     * Registers subscribers for listening to proxies initialized event.
     */
    public onInitialize(subscriber: Callback): void {
        if (this.areProxiesInitialized()) {
            subscriber();
        } else {
            this.initializationSubscribers.push(subscriber);
        }
    }

    public areProxiesInitialized(): boolean {
        return this.proxiesCount === this.changeHandlerCount && this.proxiesCount > 0;
    }

    /**
     * Gets the React service without any bridge attached
     */
    public getServiceRaw<T extends keyof ProxyServiceContainer>(
        serviceName: T
    ): ProxyServiceContainer[T] {
        return this.proxyServiceContainer[serviceName];
    }

    /**
     * Updates React service with the bridge.
     *
     * If the service instance is different what is already linked, all the
     * subscribers listening on updates to service will also be triggered.
     *
     */
    public setProxy<T extends keyof ProxyServiceContainer>(
        serviceName: T,
        value: ProxyServiceContainer[T]
    ): void {
        if (
            this.proxyServiceContainer[serviceName] !== value &&
            (!isEmpty(value) || isFunction(value))
        ) {
            if (this.proxyServiceContainer[serviceName] === undefined) {
                this.proxiesCount += 1;
            }

            this.proxyServiceContainer[serviceName] = value;

            this.broadcastOnceIfInitialized();
            this.onChangeHandlers[serviceName]?.onChangeSubscribers.forEach((subscriber) =>
                subscriber()
            );
        }
    }

    /**
     * Creates a Proxy to the original service contained inside serviceContainer.
     * The service is free to update anytime and the proxy will take care of always
     * referring to the latest service in serviceContainer.
     *
     * __NOTE:__
     * Proxy also attaches onChange(callback) to each proxied service.
     * The callback will be triggered whenever the instance of the service is
     * updated with the bridge. This allows for Angular injected services to tap
     * into React render life cycle.
     *
     * __CAUTION:__
     * This solves the problem of updating stateful services being used
     * within angular Dependency Injection but are modified outside it.
     * As such, it creates a blackbox anti-pattern in terms of testing and
     * should be limited in its usage.
     */
    public ngProxy<T extends keyof ProxiesWithChangeNotifier>(
        serviceName: T
    ): [T, ProxiesWithChangeNotifier[T]] {
        const proxyServiceContainer = this.proxyServiceContainer;
        const onChangeHandlers = this.onChangeHandlers;

        if (!proxyServiceContainer[serviceName]) {
            const onChangeSubscribers: Array<() => void> = [];
            const onChange: OnChangeSubscriber = (subscriber) => {
                onChangeSubscribers.push(subscriber);
                return (): void => {
                    const index = onChangeSubscribers.indexOf(subscriber);
                    onChangeSubscribers.splice(index, 1);
                };
            };

            onChangeHandlers[serviceName] = { onChange, onChangeSubscribers };
            this.changeHandlerCount += 1;
        }

        return [
            serviceName,
            new Proxy(this.proxyServiceContainer, {
                get(
                    target,
                    name
                ): ValueOf<ProxyServiceContainer[T]> | OnChangeSubscriber | undefined {
                    if (name === 'onChange' && onChangeHandlers[serviceName]) {
                        return onChangeHandlers[serviceName]?.onChange;
                    }

                    const service = target[serviceName] as any;
                    if (!service) {
                        throw new Error(`Proxy Not Found: ${serviceName}`);
                    }
                    return service[name];
                },
                set: (target, prop, value): boolean => {
                    warn(
                        'Possible Error: Keeping Object Updates Pure is crucial for React lifecycle events!'
                    );

                    const service = target[serviceName] as any;
                    if (!service) {
                        throw new Error(`Proxy Not Found: ${serviceName}`);
                    }

                    service[prop] = value;
                    return true;
                },
                apply(target, thisArg, argumentsList): any {
                    const func = target[serviceName] as (...args: any) => any;
                    return func.apply(func, argumentsList);
                },
            }) as unknown as ProxiesWithChangeNotifier[T],
        ];
    }

    /**
     * React's useMemo analogue, which memoizes partially migrated services.
     * If a ES6 based pure but stateful (generates and saves an internal state) service
     * has not fully migrated yet, i.e., it still has dependencies from within
     * the Angular DI along with react services. Chances are it needs to directly
     * inject into Angular. It likely will also need to update it's internal
     * state whenever the React services updates.
     * Injecting the service wrapped with ngReactMemo essentially memoizes the service,
     * and re-instantiates it whenever the supplied dependencies (migrated-only) updates.
     *
     * @NOTE It is important that you use ngModule.constant()
     * to inject the memoized service into Angular Framework.
     *
     * @example
     * // Examples of Partially Migrated service
     *
     * // Class based constructor
     * class PartiallyMigratedConstructor {
     *     // Angular style dependency array
     *     static $inject = ['dep1', 'dep2'];
     *     constructor(dep1: dep1Type, dep2: dep2Type) { }
     * }
     *
     * // Function Based Constructor
     * function PartiallyMigratedConstructor { }
     * PartiallyMigratedConstructor
     *     .$inject = ['dep1', 'dep2']; // Angular style dependency array
     *
     * // How to wrap with "ngReactMemo":
     * ngModule.constant('service-name',
     *     ngReactMemo(PartiallyMigratedConstructor, <migratedDeps>));
     */
    public ngReactMemo = <T extends NgInjectableClass>(
        Constructor: T,
        migratedNgInjectedDeps?: Array<string>
    ): T => {
        const onChangeHandlers = this.onChangeHandlers;
        const areProxiesInitialized = this.areProxiesInitialized.bind(this);

        if (!isClassNgInjectable(Constructor)) {
            throw Error(
                `Class is not injectable in AngularJs framework. Constructor Parameters and static property $inject don't match!`
            );
        }

        let service: T = {} as T;
        let instantiated = false;

        const instantiate = (): void => {
            if (!areProxiesInitialized()) {
                throw Error('Proxies Not Initialized. Unable to Instantiate service');
            }

            instantiated = true;
            service = AngularInjector.instantiate(Constructor);
        };

        // Re-instantiate only if the service was already asked for
        // by the application framework
        const onDepsUpdate = debounce(() => {
            if (instantiated) {
                instantiate();
            }
        });

        // Setup listeners for React service updates
        this.onInitialize(() => {
            const allMigratedServices = Object.keys(onChangeHandlers);

            const migratedDeps = intersection(
                allMigratedServices,
                migratedNgInjectedDeps || Constructor.$inject
            ) as Array<keyof ProxyServiceContainer>;

            migratedDeps.forEach((dependency) => {
                onChangeHandlers[dependency]?.onChange(onDepsUpdate);
            });
        });

        return new Proxy(service, {
            get: (_, prop): T[keyof T] | undefined => {
                if (!instantiated) {
                    instantiate();
                }

                return service[prop as keyof T] || Constructor[prop as keyof T];
            },
            set: (_, prop, value): boolean => {
                warn(
                    'Possible Error: Keeping Object Updates Pure is crucial for React lifecycle events!'
                );

                service[prop as keyof T] = value;
                return true;
            },
        });
    };
}

export const reactNgBridge = new ReactNgBridge();
