import templateUrl from './crossLinkCondition.tpl.html';

export default {
    templateUrl,
    bindings: {
        condition: '=',
        hasError: '<',
        triggerTypes: '<',
        onAddNew: '<',
        onRemove: '&',
        shouldEditableFormFieldForRbac: '<',
    },
    controller: [
        '$scope',
        '$timeout',
        '$element',
        '$log',
        'dimensionService',
        function ($scope, $timeout, $element, $log, dimensionService) {
            const $ctrl = this;
            const trigger = {};
            let suggestionGenerationNumber = 0;

            $ctrl.$onInit = function () {
                trigger.name = $ctrl.condition.property || '';
                trigger.value = $ctrl.condition.values[0] || '';
                trigger.input = $scope.getTrigger();

                $scope.trigger = trigger;
                $scope.editingTrigger = !trigger.name && !trigger.value;
                $scope.inputName = `condition-${$scope.$parent.$index}`;
                $scope.suggestions = [];
                $scope.triggerType = getTriggerType();
                $scope.triggerTypes = $ctrl.triggerTypes;

                if ($scope.editingTrigger) {
                    $scope.editTrigger();
                }
            };

            $scope.editTrigger = function (deletePrevious) {
                setEditingTrigger(true);
                focusTriggerInput();

                if (deletePrevious) {
                    $scope.trigger.input = '';
                } else {
                    const property = $scope.getTrigger();
                    $scope.trigger.input = property;
                    if (
                        $scope.triggerType === $ctrl.triggerTypes.PAIR &&
                        property &&
                        !property.includes(':')
                    ) {
                        $scope.trigger.input += ':';
                    }
                }
                [trigger.name, trigger.value = ''] = $scope.trigger.input.split(':');
                $scope.updateSuggestions();
            };

            $scope.exitEdit = function () {
                if ($scope.editingTrigger) {
                    const splitInput = $scope.trigger.input.split(':');
                    const name = splitInput[0];
                    const value = splitInput.slice(1).join(':');

                    trigger.name = name || '';
                    trigger.value = value || '';

                    setEditingTrigger($scope.triggerType !== getTriggerType() || !trigger.name);
                    updateParentProp();
                }
            };

            $scope.getTrigger = function () {
                return trigger.value ? `${trigger.name}:${trigger.value}` : trigger.name;
            };

            $scope.onAddClick = function () {
                $ctrl.onAddNew();
            };

            $scope.onRemoveClick = function () {
                $ctrl.onRemove({});
                markFormDirty();
            };

            $scope.onSuggestionSelect = function (item) {
                const splitInput = $scope.trigger.input.split(':');

                if ($scope.triggerType === $ctrl.triggerTypes.PAIR && splitInput.length === 2) {
                    $scope.trigger.input = $scope.trigger.input.replace(/:.*/, `:${item.value}`);

                    trigger.name = splitInput[0];
                    trigger.value = item.value;
                } else if (
                    $scope.triggerType === $ctrl.triggerTypes.ANY_VALUE &&
                    splitInput.length > 0
                ) {
                    trigger.name = splitInput.length === 1 ? item.value : splitInput[0];
                    trigger.value = '';
                } else {
                    $scope.trigger.input = `${item.value}:`;
                    $scope.updateSuggestions();
                    return;
                }

                setEditingTrigger(false);
                $scope.showDropdown = false;
                $scope.suggestions = [];
                updateParentProp();
            };

            $scope.setTriggerType = function (selectedType) {
                if ($scope.triggerType !== selectedType) {
                    $scope.triggerType = selectedType;
                    markFormDirty();
                }

                $scope.exitEdit();

                if (selectedType === $ctrl.triggerTypes.PAIR) {
                    if (!trigger.value) {
                        $scope.editTrigger(false);
                    }
                } else if (selectedType === $ctrl.triggerTypes.ANY_VALUE) {
                    trigger.value = '';

                    if (!trigger.name) {
                        $scope.editTrigger(false);
                    }
                }
                $scope.showTriggerTypeDD = false;
                updateParentProp();
            };

            $scope.updateSuggestions = function () {
                if (!$scope.loadingSuggestions) {
                    const splitInput = $scope.trigger.input.split(':');
                    const searchProperties = splitInput.length === 1;
                    const type = searchProperties ? 'property' : 'value';
                    $scope.suggestions = [];

                    if (
                        splitInput.length > 2 ||
                        (type === 'value' && $scope.triggerType !== $ctrl.triggerTypes.PAIR)
                    ) {
                        $scope.showDropdown = false;
                        return;
                    }

                    const propertyName = splitInput[0];
                    const propertyValue = splitInput.slice(1).join(':');
                    const partialInput = searchProperties ? propertyName : propertyValue;

                    suggestionGenerationNumber++;
                    const localGeneration = suggestionGenerationNumber;

                    $scope.loadingSuggestions = true;

                    return getSuggestions(type, partialInput, propertyName)
                        .then(
                            (suggestions) => {
                                if (localGeneration === suggestionGenerationNumber) {
                                    $scope.suggestions = [];

                                    const literalInput = searchProperties
                                        ? propertyName
                                        : propertyValue;

                                    if (literalInput && !suggestions.includes(literalInput)) {
                                        $scope.suggestions.push({
                                            displayName: literalInput,
                                            value: literalInput,
                                        });
                                    }

                                    suggestions.forEach((s) =>
                                        $scope.suggestions.push({ displayName: s, value: s })
                                    );

                                    $scope.showDropdown = $scope.editingTrigger;
                                    $scope.highlighted = $scope.suggestions[0];
                                }
                            },
                            () => {
                                $log.info('SignalFlow suggest failed');
                            }
                        )
                        .finally(() => {
                            $scope.loadingSuggestions = false;
                        });
                }
            };

            function focusTriggerInput() {
                return $timeout(() => {
                    $element.find(`input[name="${$scope.inputName}"]`).focus();
                });
            }

            function getSuggestions(type, partialInput, property, limit) {
                if (type === 'value') {
                    return dimensionService.getPropertyValueSuggest({
                        property: property,
                        partialInput: partialInput || '',
                        limit,
                    });
                } else {
                    return dimensionService.getPropertyNameSuggest({
                        partialInput: partialInput || '',
                        limit,
                    });
                }
            }

            function getTriggerType() {
                return trigger.value ? $ctrl.triggerTypes.PAIR : $ctrl.triggerTypes.ANY_VALUE;
            }

            function markFormDirty() {
                if ($scope.$parent.crossLinkForm) {
                    $scope.$parent.crossLinkForm.$setDirty();
                }
            }

            function setEditingTrigger(editing) {
                $scope.editingTrigger = editing;
                markFormDirty();
            }

            function updateParentProp() {
                $ctrl.condition.property = trigger.name;
                if (trigger.value === '') {
                    // if 'value' is empty, remove it from 'values' array
                    $ctrl.condition.values.splice(0, 1);
                } else {
                    $ctrl.condition.values[0] = trigger.value;
                }
                $ctrl.condition.editingTrigger = $scope.editingTrigger;
            }
        },
    ],
};
