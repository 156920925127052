import fixPlotExpressionKeyTooltipTemplateUrl from '../../charting/chartbuilder/util/fixPlotExpressionKeyTooltip.tpl.html';
import chartNavTemplateUrl from './chartV2Nav.tpl.html';
import { ngRoute } from '../../../../app/routing/ngRoute';
import { Dashboards as sitemap } from '../../../../app/routing/Sitemaps';

// "Closable" means the pages with a "Close" button that sends the user to the previous page.
// It needs to be identified to avoid the infinite loop scenario described in the wiki page
// (https://signalfuse.atlassian.net/wiki/spaces/RAP/pages/2639790110/Special+cases+in+routing#Issue%3A-RAP-1056)

const CLOSABLE_URL_PATTERNS = [/^\/detector\//, /^\/chart\//];

function isClosableUrl(url) {
    for (let i = 0; i < CLOSABLE_URL_PATTERNS.length; i++) {
        if (url.match(CLOSABLE_URL_PATTERNS[i])) {
            return true;
        }
    }
    return false;
}

export const chartNav = {
    bindings: {
        data: '<',
        setCustomContent: '<',
        setHierarchicalNav: '<',
    },
    templateUrl: chartNavTemplateUrl,
    controller: [
        '$q',
        '$scope',
        '$rootScope',
        'urlOverridesService',
        'routeParameterService',
        '$location',
        '$window',
        '$timeout',
        'title',
        'v2ChartService',
        'sourceFilterService',
        'sharingService',
        'timeToRange',
        'chartUtils',
        'featureEnabled',
        'chartbuilderUtil',
        'CHART_DISPLAY_EVENTS',
        'fixPlotExpressionKey',
        'programTextUtils',
        'showSignalFlowModal',
        'sfxModal',
        'snapshotEditsService',
        'dashboardUtil',
        'permissionsChecker',
        'setUrlParamsForSnapshot',
        'timepickerUtils',
        'defaultTextNoteMarkdown',
        'globalNavUpdateService',
        'calendarWindowUtil',
        'SYSTEM_USER_ID',
        'CALENDAR_MESSAGE',
        'dashboardMirrorService',
        'locationHistory',
        'dashboardVariableUtils',
        'dashboardChartServiceAPM2',
        'dashboardChartServiceRUM',
        'dashboardChartServiceProfiling',
        'SPLUNK_APM_PRODUCT_NAME',
        'SPLUNK_PROFILING_PRODUCT_NAME',
        'SPLUNK_RUM_PRODUCT_NAME',
        'dashboardGroupService',
        'dashboardV2Service',
        function (
            $q,
            $scope,
            $rootScope,
            urlOverridesService,
            routeParameterService,
            $location,
            $window,
            $timeout,
            title,
            v2ChartService,
            sourceFilterService,
            sharingService,
            timeToRange,
            chartUtils,
            featureEnabled,
            chartbuilderUtil,
            CHART_DISPLAY_EVENTS,
            fixPlotExpressionKey,
            programTextUtils,
            showSignalFlowModal,
            sfxModal,
            snapshotEditsService,
            dashboardUtil,
            permissionsChecker,
            setUrlParamsForSnapshot,
            timepickerUtils,
            defaultTextNoteMarkdown,
            globalNavUpdateService,
            calendarWindowUtil,
            SYSTEM_USER_ID,
            CALENDAR_MESSAGE,
            dashboardMirrorService,
            locationHistory,
            dashboardVariableUtils,
            dashboardChartServiceAPM2,
            dashboardChartServiceRUM,
            dashboardChartServiceProfiling,
            SPLUNK_APM_PRODUCT_NAME,
            SPLUNK_PROFILING_PRODUCT_NAME,
            SPLUNK_RUM_PRODUCT_NAME,
            dashboardGroupService,
            dashboardV2Service
        ) {
            const $ctrl = this;
            let data;

            $ctrl.$onInit = initialize;
            $ctrl.$onDestroy = onDestroy;

            Object.defineProperty($scope, 'isDashboardSnapshot', {
                get: () =>
                    !!data.snapshot?.id &&
                    (data.snapshot?.type === 'Dashboard' ||
                        (data.snapshot?.type === 'Chart' && data.dashboard?.id === undefined)),
            });

            Object.defineProperty($scope, 'isReadOnly', {
                get: () => isSystemDashboard() || $scope.hasWritePermission === false,
            });

            $scope.passedUtils = {};
            $scope.passedUtils.removePlot = $scope.removePlot = function (plot) {
                chartbuilderUtil.removePlot(plot, $scope.model.sf_uiModel.allPlots);
            };

            $scope.passedUtils.movePlotToTop = function (plot) {
                chartbuilderUtil.movePlotToTop(plot, $scope.model.sf_uiModel.allPlots);
            };

            $scope.passedUtils.movePlotToBottom = function (plot) {
                chartbuilderUtil.movePlotToBottom(plot, $scope.model.sf_uiModel.allPlots);
            };

            $scope.passedUtils.addYAxis = $scope.addYAxis = function (idx) {
                chartbuilderUtil.addYAxis(
                    idx,
                    $scope.model.sf_uiModel.chartconfig.yAxisConfigurations
                );
            };

            $scope.passedUtils.clonePlotConstruct = $scope.clonePlotConstruct = function (itm) {
                chartbuilderUtil.clonePlotConstruct(
                    itm,
                    $scope.model.sf_uiModel,
                    $scope.model.sf_id
                );
            };

            $scope.passedUtils.addPlotNoModal = $scope.addPlotNoModal = function () {
                chartbuilderUtil.addNewPlot($scope.model.sf_uiModel);
            };

            $scope.hideMetricsSidebar = hideMetricsSidebar;
            $scope.sourceFilters = [];
            $scope.showFilterOptions = true;
            $scope.goToDashboard = goToDashboard;
            $scope.sharedState = { editMode: true };
            $scope.sharedConfig = { heatmapPlotConfig: {} };
            $scope.close = close;
            $scope.share = share;
            $scope.isValidChart = isValidChart;
            $scope.chartRollupMessage = 'determining rollup...';
            $scope.deleteModalState = {
                isOpen: false,
                onCancel: function () {
                    $timeout(function () {
                        $scope.deleteModalState.isOpen = false;
                    }, 0);
                },
            };

            $scope.$on('showMetricsSidebar', openMetricsSidebar);
            $scope.$on('hideMetricsSidebar', hideMetricsSidebar);
            $scope.$on('defaultTimeChanged', function (evt, timeobj) {
                if (!$scope.overriddenChartconfig) {
                    $scope.overriddenChartconfig = {};
                }
                const timeRange = timeToRange(timeobj);
                angular.extend($scope.overriddenChartconfig, timeRange);
            });

            $scope.$on(CHART_DISPLAY_EVENTS.REQUEST_INIT_TIME_PICKER, function (ev, obj) {
                $scope.$broadcast('initializeTimePicker', obj);
            });

            $scope.$on('React:$routeUpdate', function () {
                applyTimePickerUrlParams();
            });

            $scope.$on(CHART_DISPLAY_EVENTS.CHART_TIME_RANGE_SELECTED, function (ev, start, end) {
                $scope.$broadcast('setNewGlobalTimeRange', start, end);
            });

            $scope.$on('timePickerChanged', function (ev, timeobj, context) {
                if (context === 'local') {
                    // Default time for the chart has changed
                    const timeRange = timeToRange(timeobj);
                    angular.extend($scope.overriddenChartconfig, timeRange);
                    const timepicker = urlOverridesService.getGlobalTimePicker();
                    if (!timepicker) {
                        // No overrides time set. Update the chart.
                        angular.extend($scope.model.sf_uiModel.chartconfig, timeRange);
                    }
                } else {
                    $scope.$broadcast('setGlobalTimeRanges', timeobj);
                }
            });

            $scope.$on(
                CHART_DISPLAY_EVENTS.JOB_RESOLUTION_DETERMINED,
                function (event, resolution) {
                    $scope.resolution = resolution;
                }
            );

            $scope.$on(
                CHART_DISPLAY_EVENTS.CHART_WINDOW_MISALIGNED,
                function (event, chartWindowMisaligned) {
                    $scope.misalignedResolution = chartWindowMisaligned;
                }
            );

            $scope.$on(
                CHART_DISPLAY_EVENTS.CHART_ROLLUP_DETERMINED,
                function (event, chartRollupMessage) {
                    $scope.chartRollupMessage = chartRollupMessage;
                }
            );

            $scope.$on(
                'plot alt mode trigger',
                function (evt, altModeDisplayedKey, altModeOn = false) {
                    $scope.altMode = altModeOn;
                    $scope.$broadcast('plot alt mode apply', altModeDisplayedKey, $scope.altMode);
                }
            );

            $scope.$on('setCalendarWindowCycleLength', function (evt, cycleName) {
                // current chart time is either default time or global override time
                const currentChartTime = chartUtils.getChartTimeConfig(
                    $scope.model.sf_uiModel.chartconfig
                );
                const optimizedWindowObj = calendarWindowUtil.getOptimizedWindowObject(
                    cycleName,
                    currentChartTime
                );

                if (!optimizedWindowObj) {
                    return;
                }

                storeOptimizedUndoCache(optimizedWindowObj);
                setOptimizedCalendarWindow(optimizedWindowObj);

                // Send message stating timezone was optimized for current cycle
                $scope.$broadcast('calendarWindowOptimized', cycleName);
            });

            $scope.$on('toggleCalendarWindowOptimization', function (evt, optimizeMessage) {
                if (optimizeMessage === CALENDAR_MESSAGE.OPTIMIZE) {
                    setOptimizedCalendarWindow($scope.timeObj.optimized);
                } else {
                    if ($scope.timeObj.local) {
                        const localTime = $scope.timeObj.local;
                        const timepickerObj = timepickerUtils.chartConfigToTimePickerObj(localTime);
                        $scope.$broadcast('initializeTimePicker', timepickerObj, 'local');
                    }

                    if ($scope.timeObj.global) {
                        const globalTime = $scope.timeObj.global;
                        urlOverridesService.setGlobalTimePicker(
                            globalTime.start,
                            globalTime.end,
                            globalTime.relative
                        );
                        const timepicker = urlOverridesService.getGlobalTimePicker();
                        $scope.$broadcast('initializeTimePicker', timepicker);
                    }
                }
            });

            // Make chart re-render after sidebar is shown or hidden
            $scope.$watch('showMetricsSidebar', function () {
                $timeout(function () {
                    $rootScope.$broadcast(CHART_DISPLAY_EVENTS.CONTEXT_RESIZE);
                }, 0);
            });

            const unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                [
                    'sources[]',
                    'variables[]',
                    'density',
                    'startTime',
                    'endTime',
                    'startTimeUTC',
                    'endTimeUTC',
                    'resolutionAdjustable',
                ],
                applySourceOverrides
            );

            function onDestroy() {
                unregisterRouteWatchGroup();
                $ctrl.setHierarchicalNav();
            }

            async function setHNav() {
                const { chart, snapshot } = $ctrl.data;

                if (snapshot) {
                    const { chart } = snapshot.payload;
                    if (chart && chart.name) {
                        $ctrl.setCustomContent(sitemap.name, {
                            id: sitemap.IDs.newChart,
                            label: snapshot.payload.chart.name,
                        });
                    }
                    $ctrl.setHierarchicalNav(sitemap.name, sitemap.IDs.newChart);
                } else if (ngRoute.route.pathname === '/chart/v2/new') {
                    $ctrl.setHierarchicalNav(sitemap.name, sitemap.IDs.newChart);
                } else {
                    let { dashboard } = $ctrl.data;
                    if (!dashboard) {
                        const { groupId, configId } = ngRoute.params;
                        const groupOfUnknownVersion = await dashboardGroupService.get(groupId);
                        const group = dashboardGroupService.convertToV2(groupOfUnknownVersion);
                        const { dashboardId } = group.dashboardConfigs.find(
                            (config) => config.configId === configId
                        );
                        dashboard = await dashboardV2Service.get(dashboardId).catch(() => null);
                    }

                    $ctrl.setCustomContent(sitemap.name, {
                        id: sitemap.IDs.dashboardGroup,
                        label: dashboard.groupName,
                    });
                    $ctrl.setCustomContent(sitemap.name, {
                        id: sitemap.IDs.dashboardName,
                        label: dashboard.name,
                        path: `#/dashboard/${dashboard.id}`,
                    });
                    $ctrl.setCustomContent(sitemap.name, {
                        id: sitemap.IDs.chartName,
                        label: chart.name,
                        path: `#${ngRoute.route.url}`,
                    });
                    $ctrl.setHierarchicalNav(sitemap.name, sitemap.IDs.chartName);
                }
            }

            function initialize() {
                data = $ctrl.data;

                setHNav();
                const defaultSignalflow = ngRoute.params.signalflow || '';

                let chart = data.chart;
                if (!chart) {
                    chart = v2ChartService.getEmptyV2Chart(ngRoute.params.mode);
                    if (ngRoute.params.mode === 'text') {
                        chart.options.setMarkdownText(defaultTextNoteMarkdown);
                    }
                    if (defaultSignalflow) {
                        chart.programText = defaultSignalflow;
                    }
                }

                const targetDashboard = getTargetDashboard();
                $scope.isChartForDashboard = !!targetDashboard;
                $scope.isCloning = !!ngRoute.params.fromChart || !!data.cloneFromSnapshot;
                $scope.showSaveAsOnly = (!targetDashboard && !chart.id) || $scope.isCloning;

                permissionsChecker
                    .hasDashboardWriteAccess(data.dashboard, $scope.isDashboardSnapshot)
                    .then(function (hasWritePermission) {
                        $scope.hasWritePermission = hasWritePermission;
                    });

                $scope.canSaveToParent = canSaveToParent;

                $scope.chart = chart;
                $scope.dashboard = data.dashboard;
                $scope.dashboardGroup = data.dashboardGroup;
                $scope.configId = data.configId;
                $scope.config = null;

                if (data.dashboardGroup && data.dashboardGroup.dashboardConfigs) {
                    $scope.config = dashboardUtil.getConfig(
                        data.dashboardGroup.dashboardConfigs,
                        $scope.configId
                    );
                }

                $scope.groupId = data.groupId;
                $scope.snapshot = data.snapshot || {};
                $scope.altMode = false;

                if ($scope.dashboard) {
                    const dashboardState = {
                        isInDashboardChart: true,
                        dashboardID: $scope.dashboard.id,
                        readOnly: $scope.isReadOnly,
                    };

                    globalNavUpdateService.update({
                        dashboardState: dashboardState,
                    });
                }

                if ($scope.snapshot.id) {
                    globalNavUpdateService.update({
                        snapshot: $scope.snapshot,
                    });
                }

                setUrlParamsForSnapshot($scope.snapshot, $scope.dashboard);

                if (data.dashboard && data.dashboard.filters) {
                    const variables = data.dashboard.filters.variables;
                    if (variables) {
                        // if we have a mirror config, apply it on top of our variables.
                        if (
                            $scope.config &&
                            $scope.config.filtersOverride &&
                            $scope.config.filtersOverride.variables
                        ) {
                            $scope.filterAlias = dashboardVariableUtils.mergeMirrorVariables(
                                variables,
                                $scope.config.filtersOverride.variables
                            );
                        } else {
                            $scope.filterAlias = variables;
                        }
                    }
                    if (data.dashboard.filters.time) {
                        $scope.initRange =
                            timepickerUtils.getChartConfigParametersFromURLTimeObject(
                                data.dashboard.filters.time
                            );
                    }
                }

                if (!$scope.initRange) {
                    $scope.initRange = {};
                    if ($scope.chart && $scope.chart.options && $scope.chart.options.time) {
                        const time = $scope.chart.options.time;
                        if (time.type === 'relative') {
                            $scope.initRange.range = time.range;
                        } else if (time.type === 'absolute') {
                            $scope.initRange.absoluteStart = time.start;
                            $scope.initRange.absoluteEnd = time.end;
                        }
                    }
                }

                $scope.dashboardDisplayTitle = getDashboardDisplayTitle(
                    $scope.dashboard,
                    $scope.dashboardGroup
                );

                if (!chart || !chart.name) {
                    title.updateTitle('New chart');
                } else {
                    title.updateTitle('Chart - ' + chart.name);
                }

                $scope.disableTimePickerUrl = !!$scope.$dismiss;
                if (!$scope.disableTimePickerUrl) {
                    const sourceOverride = urlOverridesService.getSourceOverride() || [];
                    $scope.sourceFilters = sourceFilterService.getSourceFilters(sourceOverride);
                }

                let skipDirtyOnce = true;
                $timeout(function () {
                    const dirtyWatcher = $scope.$watch(
                        'model',
                        function () {
                            if (skipDirtyOnce) {
                                skipDirtyOnce = false;
                                return;
                            }
                            $scope.modified = true;
                            dirtyWatcher();
                        },
                        true
                    );
                }, 0);

                generateChartMenuItems();
            }

            // target dashboard is used to indicate a snapshot to which the chart will
            // be saved. (i.e. this chart does not exist on its own)
            function getTargetDashboard() {
                return ngRoute.params.toDashboard || null;
            }

            function isSystemDashboard() {
                return !!(data.dashboard && data.dashboard.creator === SYSTEM_USER_ID);
            }

            function canSaveToParent() {
                return isValidChart() && !$scope.isReadOnly;
            }

            function openMetricsSidebar() {
                $scope.showMetricsSidebar = true;
            }

            function hideMetricsSidebar() {
                $scope.showMetricsSidebar = false;
            }

            function getDashboardDisplayTitle(dashboard, dashboardGroup) {
                let title = '';

                if (dashboard && dashboard.name) {
                    if (dashboardGroup && dashboardGroup.name) {
                        title = dashboardGroup.name + ': ';
                    }
                    title += dashboard.name;
                } else if (isSnapshot()) {
                    title = 'Unsaved dashboard';
                }

                return title;
            }

            function goToDashboard() {
                unregisterRouteWatchGroup();

                if ($scope.dashboard && $scope.dashboard.id) {
                    const dashboardViewsParams = {
                        groupId: $scope.groupId,
                        configId: $scope.configId,
                    };
                    dashboardUtil.addDashboardSearchParams(dashboardViewsParams);
                    $location.path('/dashboard/' + $scope.dashboard.id);
                } else if (
                    $scope.snapshot &&
                    $scope.snapshot.id &&
                    $scope.snapshot.type === 'Dashboard'
                ) {
                    $location.path('/temp/dashboard/' + $scope.snapshot.id);
                }
            }

            function close() {
                // Skip "closable" urls when trying to get the last url from the history
                // because the "closable" page will eventually send the user back to this page
                // when the user clicks on "Close" button from the page, which will eventually
                // result in the infinite loop of the two pages with "Close" button.
                // More detail about the issue can be found in this wiki page
                // (https://signalfuse.atlassian.net/wiki/spaces/RAP/pages/2639790110/Special+cases+in+routing#Issue%3A-RAP-1056)
                let lastPos = 1;
                while (true) {
                    const tempLastUrl = locationHistory.last(lastPos);
                    if (!tempLastUrl || !isClosableUrl(tempLastUrl)) {
                        break;
                    }
                    lastPos += 1;
                }

                const lastUrl = locationHistory.last(lastPos);
                if (lastUrl) {
                    $location.url(lastUrl);
                } else if ($window.history.length > 1) {
                    $window.history.back();
                } else {
                    $location.path('/dashboards');
                }
            }

            function applySourceOverrides() {
                const sourceOverride = urlOverridesService.getSourceOverride() || [];
                $scope.sourceFilters = sourceFilterService.getSourceFilters(sourceOverride);
            }

            function applyTimePickerUrlParams() {
                const timepicker = urlOverridesService.getGlobalTimePicker();
                if (timepicker) {
                    $scope.$broadcast('initializeTimePicker', timepicker);
                } else {
                    // Re-apply default time
                    if ($scope.overriddenChartconfig) {
                        angular.extend(
                            $scope.model.sf_uiModel.chartconfig,
                            chartUtils.getChartTimeConfig($scope.overriddenChartconfig)
                        );
                    }
                    $scope.$broadcast('resetTimePicker');
                    $scope.$broadcast('resetGlobalTimeRanges');
                }
            }

            function share() {
                $scope.prepareSave().then(function () {
                    sharingService.shareChart($scope.chart, data.dashboard, data.snapshot);
                });
            }

            function isValidChart() {
                // this could probably be much more exhaustive
                return (
                    $scope.model &&
                    (($scope.model.sf_uiModel.chartMode !== 'text' &&
                        $scope.model.sf_viewProgramText) ||
                        ($scope.model.sf_uiModel.chartMode === 'text' &&
                            $scope.model.sf_uiModel.markdownText))
                );
            }

            function isSnapshot() {
                return $scope.snapshot && $scope.snapshot.id;
            }

            function isChartIdentifiable() {
                return $scope.chart.id || isSnapshot() || ngRoute.params.fromChart;
            }

            function isChartIdentifiableNotInClone() {
                return (
                    isChartIdentifiable() &&
                    !$scope.isCloning &&
                    $scope.model.sf_uiModel.chartMode !== 'text'
                );
            }

            function showChartInfo() {
                $scope.$broadcast('show chart info');
            }

            function fixPlotKeys() {
                fixPlotExpressionKey($scope.model.sf_uiModel.allPlots);
                $scope.model.sf_uiModel.currentUniqueKey =
                    $scope.model.sf_uiModel.allPlots.length + 1;
                $scope.$broadcast('update plot expression key');
            }

            function exportAsCsv() {
                $scope.$broadcast('export current chart');
            }

            function exportEventAsJson() {
                $scope.$broadcast('export event chart');
            }

            function deleteFromSnapshot() {
                const chart = angular.copy($scope.model);
                const dashboard = angular.copy($scope.dashboard);
                const charts = $scope.snapshot.payload.charts;

                for (let i = 0; i < charts.length; i++) {
                    if (chart.sf_chartIndex === charts[i].sf_chartIndex) {
                        dashboard.charts.splice(i, 1);
                        charts.splice(i, 1);
                    }
                }

                return snapshotEditsService
                    .saveDashboardEdits(dashboard, charts, false, $scope.snapshot)
                    .then(function (saved) {
                        $scope.snapshot.id = saved.id;
                        $location.path('/temp/dashboard/' + $scope.snapshot.id);
                    });
            }

            function getDeleteChartDescription(name) {
                const description =
                    'You are about to permanently delete the chart ' +
                    (name || 'Untitled chart') +
                    '.';
                if (featureEnabled('dashboardViews')) {
                    return dashboardMirrorService
                        .getDashboardAppearanceCount($scope.dashboard.id)
                        .then(function (result) {
                            if (result.numMirrors > 1) {
                                const groupOrGroups = result.numGroups === 1 ? 'group' : 'groups';
                                return `This chart will be permanently deleted and no longer visible in any mirror
                of this dashboard. This dashboard has ${result.numMirrors} mirrors in
                ${result.numGroups} ${groupOrGroups}.`;
                            } else {
                                return description;
                            }
                        });
                }
                return $q.when(description);
            }

            function handleDelete() {
                $scope.deleteModalState.isOpen = false;

                const spinner = sfxModal.open({
                    template: '<div><i class="busy-spinner-light"></i></div>',
                    windowClass: 'full-screen-busy-spinner',
                    backdrop: 'static',
                    keyboard: false,
                });

                let deletePromise;
                if ($scope.snapshot.id) {
                    deletePromise = deleteFromSnapshot().finally(spinner.close);
                } else {
                    deletePromise = dashboardUtil
                        .deleteChartFromDashboard($scope.dashboard, $scope.model)
                        .then(function () {
                            $location.url('/dashboard/' + $scope.dashboard.id);
                        });
                }

                deletePromise.finally(spinner.close);
            }

            function deleteChart() {
                if (!$scope.hasDeleteChartCapability) {
                    return;
                }
                $scope.deleteModalState.onDelete = function () {
                    $timeout(handleDelete, 0);
                };

                $scope.deleteModalState.title = 'Delete Chart';
                $scope.deleteModalState.isOpen = true;
                $scope.deleteModalState.isLoading = true;

                getDeleteChartDescription($scope.model.sf_chart).then((description) => {
                    $timeout(function () {
                        $scope.deleteModalState.isLoading = false;
                        $scope.deleteModalState.description = description;
                    }, 0);
                });
            }

            function isSavedChart() {
                return $scope.chart && $scope.chart.id;
            }

            function isChartFromSharedDashboard() {
                return isSnapshot() && $scope.snapshot.chartId;
            }

            function isChartFromDashboard() {
                return isSavedChart() || isChartFromSharedDashboard();
            }

            function isChartFromDashboardNotInClone() {
                return isChartFromDashboard() && !$scope.isCloning;
            }

            function showDeleteChartOption() {
                return (
                    $scope.hasWritePermission &&
                    isChartFromDashboardNotInClone() &&
                    $scope.hasDeleteChartCapability
                );
            }

            function generateChartMenuItems() {
                const saveMenuOption = {
                    trackClick: 'chart-menu-v2-save-chart',
                    title: 'Save',
                    label: 'Save',
                    action: () => $scope.save(false),
                    show: () =>
                        $scope.isDirty && canSaveToParent() && $scope.hasCreateChartCapability,
                };

                const saveAsMenuOption = {
                    label: 'Save as...',
                    trackClick: 'chart-menu-v2-saveas',
                    action: () => $scope.saveAs(true),
                    show: () => $scope.allowSave() && $scope.hasCreateChartCapability,
                };

                const shareMenuOption = {
                    label: 'Share',
                    trackClick: 'chart-menu-v2-share',
                    action: $scope.share,
                    enabled: isValidChart,
                    show: () => $scope.hasCreateShareableSnapshotCapability,
                };

                const chartInfoOption = {
                    trackClick: 'chart-menu-v2-info',
                    title: 'Chart info',
                    action: showChartInfo,
                    label: 'Info',
                    enabled: isChartIdentifiableNotInClone,
                };

                const fixPlotKeysOption = {
                    trackClick: 'chart-menu-v2-re-sequence-plots',
                    title: 'Resequence plots',
                    action: fixPlotKeys,
                    label: 'Resequence plots',
                    enabled: () =>
                        $scope.model.sf_uiModel.chartMode !== 'text' &&
                        programTextUtils.areAllExpressionsValid($scope.model.sf_uiModel.allPlots),
                    tooltipTemplate: fixPlotExpressionKeyTooltipTemplateUrl,
                };

                const exportAsCsvOption = {
                    trackClick: 'chart-menu-v2-export-as-csv',
                    action: exportAsCsv,
                    label: 'Export chart as CSV',
                    show: () =>
                        ($scope.model.sf_uiModel.chartMode === 'graph' ||
                            $scope.model.sf_uiModel.chartMode === 'table') &&
                        ($scope.model.sf_uiModel.allPlots || []).length > 1,
                };

                const exportEventAsJsonOption = {
                    trackClick: 'chart-menu-event-export-as-json',
                    action: exportEventAsJson,
                    label: 'Export events as JSON',
                    show: () => $scope.model.sf_uiModel.chartMode === 'event',
                };

                const showSignalFlowOption = {
                    trackClick: 'chart-menu-v2-show-signalflow',
                    title: 'Show SignalFlow',
                    action: function showSignalFlow() {
                        showSignalFlowModal($scope.chart.programText);
                    },
                    label: 'Show SignalFlow',
                    enabled: () => !!$scope.chart.programText,
                };

                const downloadChartOption = {
                    trackClick: 'chart-menu-v2-download-chart',
                    title: 'Download chart as image',
                    label: 'Download as image',
                    action: () => $scope.$broadcast('download chart as image'),
                    show: () => $scope.model.sf_uiModel.chartMode !== 'text',
                    enabled: isValidChart,
                };

                const TROUBLESHOOT_WINDOW = `Troubleshoot from this time window (${SPLUNK_APM_PRODUCT_NAME})`;
                const troubleshootOption = {
                    trackClick: 'chart-menu-v2-troubleshoot',
                    title: TROUBLESHOOT_WINDOW,
                    label: TROUBLESHOOT_WINDOW,
                    show: () => featureEnabled('apm2'),
                    action: () => {
                        const params = dashboardChartServiceAPM2.getTroubleshootIndexParams(
                            $scope.chart,
                            $scope.filterAlias
                        );
                        $location.url(`/apm/troubleshooting?${params}`);
                    },
                };

                const TROUBLESHOOT_IN_RUM = `Troubleshoot from this time window (${SPLUNK_RUM_PRODUCT_NAME})`;
                const troubleshootRUMOption = {
                    trackClick: 'chart-menu-v2-RUM',
                    title: TROUBLESHOOT_IN_RUM,
                    label: TROUBLESHOOT_IN_RUM,
                    show: () =>
                        $scope.model.sf_uiModel.chartMode !== 'text' && featureEnabled('rum'),
                    action: () => {
                        const params = dashboardChartServiceRUM.getRUMIndexParams(
                            $scope.chart,
                            $scope.filterAlias
                        );
                        $location.url(`/rum/tagspotlight?${params}`);
                    },
                };

                const TROUBLESHOOT_IN_PROFILING = `Troubleshoot from this time window (${SPLUNK_PROFILING_PRODUCT_NAME})`;
                const troubleshootProfilingOption = {
                    trackClick: 'chart-menu-v2-profiling',
                    title: TROUBLESHOOT_IN_PROFILING,
                    label: TROUBLESHOOT_IN_PROFILING,
                    show: () =>
                        $scope.model.sf_uiModel.chartMode === 'graph' &&
                        featureEnabled('profilingUIFeatures') &&
                        featureEnabled('profilingMemoryUI'),
                    action: () => {
                        const params = dashboardChartServiceProfiling.getProfilingIndexParams(
                            $scope.chart,
                            $scope.filterAlias
                        );
                        $location.url(`/profiling?${params}`);
                    },
                };

                const deleteChartOption = {
                    trackClick: 'chart-menu-v2-delete-chart',
                    title: 'Delete chart',
                    label: 'Delete',
                    warnLink: true,
                    action: deleteChart,
                    show: showDeleteChartOption,
                };

                $scope.chartMenuItem = [
                    saveMenuOption,
                    saveAsMenuOption,
                    shareMenuOption,
                    chartInfoOption,
                    fixPlotKeysOption,
                    exportAsCsvOption,
                    exportEventAsJsonOption,
                    { separator: true },
                    showSignalFlowOption,
                    downloadChartOption,
                    troubleshootOption,
                    troubleshootRUMOption,
                    troubleshootProfilingOption,
                    {
                        separator: true,
                        show: showDeleteChartOption,
                    },
                    deleteChartOption,
                ];
            }

            function setOptimizedCalendarWindow(time) {
                $scope.model.sf_uiModel.chartconfig.range = time.optimalRangeInMs;
                // Optimize the current chart window
                $scope.$broadcast('setTimePicker', time.optimalRange, 'local');
                // Clear the global chart time override
                $scope.$broadcast('clearTimePicker');
            }

            function storeOptimizedUndoCache(optimizedTimeObj) {
                // obtain copy of previous local and default chart times
                const globalTime = urlOverridesService.getGlobalTimePicker();
                const chartDefaultTime = angular.copy($scope.overriddenChartconfig);
                $scope.timeObj = {
                    local: chartDefaultTime,
                    global: globalTime,
                    optimized: optimizedTimeObj,
                };
            }
        },
    ],
};
