angular.module('signalview.variables').service('dashboardVariableSuggestUtils', [
    '$q',
    '$log',
    'signalboost',
    'currentUser',
    'sourceFilterService',
    'chartbuilderUtil',
    'chartUtils',
    'dashboardVariablesService',
    function (
        $q,
        $log,
        signalboost,
        currentUser,
        sourceFilterService,
        chartbuilderUtil,
        chartUtils,
        dashboardVariablesService
    ) {
        const svc = {
            suggest,
            suggestNoSynthetic,
            getRequiredVariables,
        };

        function getMatchingPreferredSuggestions(valuePartial, preferred) {
            if (!valuePartial) {
                return preferred.slice(0);
            }
            const preferredMatches = [];
            preferred.forEach(function (preferredValue) {
                if (preferredValue.includes(valuePartial)) {
                    preferredMatches.push(preferredValue);
                }
            });
            return preferredMatches;
        }

        function removeSyntheticResults(res) {
            return res.filter((r) => !r.isSynthetic);
        }

        /**
         * suggest values or properties based on parameters
         * {
         *   charts: chart json,
         *   suggestType: value or properties,
         *   preferredSuggestions: suggestions to push to top of suggestions list
         *   variables : the current variable definitions to consider
         *   allowWildCards: whether to allow wild cards
         * }
         */
        function suggest(suggestParams) {
            const isValueSuggest = suggestParams.suggestType === 'value';
            const charts = suggestParams.charts;
            const params = suggestParams.queryParams || {};
            const preferredSuggestions = isValueSuggest
                ? getMatchingPreferredSuggestions(
                      suggestParams.partialInput,
                      suggestParams.preferredSuggestions
                  )
                : null;
            const allowWildCards = suggestParams.allowWildCards;
            const restricted = !!suggestParams.restricted;
            const baseQueryInfo = chartUtils.getChartSignalFlow(charts);

            params.limit = params.limit || 50;

            const optionalFilters = [];
            const filterArray = [];

            if (suggestParams.variables) {
                const materializedUrlVariables =
                    dashboardVariablesService.applyVariableOverridesToVariableModel(
                        angular.copy(suggestParams.variables)
                    );
                materializedUrlVariables.forEach(function (variable) {
                    if (variable.value && variable.property !== suggestParams.property) {
                        if (variable.replaceOnly) {
                            optionalFilters.push({
                                property: variable.property,
                                values: angular.isArray(variable.value)
                                    ? variable.value
                                    : [variable.value],
                            });
                        } else if (variable.value.length > 0) {
                            // array length > 0 or string value > 0
                            filterArray.push({
                                property: variable.property,
                                values: angular.isArray(variable.value)
                                    ? variable.value
                                    : [variable.value],
                            });
                        }
                    }
                });
            }

            let inp;
            if (suggestParams.property) {
                inp = suggestParams.property + ':' + (suggestParams.partialInput || '');
            } else {
                inp = suggestParams.partialInput || '';
            }

            function mapResults(res) {
                return res.map((r) => {
                    // typeaheadDropDown expects objects with value
                    // dashboard variable filter results have propertyValue
                    // dashboard variable configuration results have propertyType
                    r.value = r.propertyValue || r.property;
                    return r;
                });
            }

            const query = chartbuilderUtil.getSuggestionsFromSignalFlow(
                baseQueryInfo,
                params.limit,
                inp,
                filterArray,
                optionalFilters,
                preferredSuggestions,
                restricted,
                null,
                suggestParams.start,
                suggestParams.end
            );
            return query.then(function (respObject) {
                let results = mapResults(respObject);
                if (restricted) {
                    results = removeSyntheticResults(results);
                }
                if (
                    isValueSuggest &&
                    allowWildCards &&
                    params.partialInput &&
                    params.partialInput.match(/\*/)
                ) {
                    results.unshift(params.partialInput);
                }
                return results;
            });
        }

        function suggestNoSynthetic(suggestParams) {
            return suggest(suggestParams).then((results) => {
                results = removeSyntheticResults(results);
                return results.map((res) => res.value);
            });
        }

        function getRequiredVariables(dashboard, charts) {
            const variables = (dashboard.filters || {}).variables || [];
            const filters = [];
            variables.forEach(function (variable) {
                if (variable.required) {
                    if (variable.value) {
                        filters.push(
                            variable.property +
                                ':' +
                                sourceFilterService.generateTermsForQuery(variable.value)
                        );
                    } else {
                        filters.push('_exists_:' + variable.property);
                    }
                }
            });

            if (!filters.length) {
                return $q.when([]);
            }

            const allCharts = {};
            if (charts) {
                charts.forEach(function (c) {
                    const chartId = c.sf_id || c.id;
                    allCharts[chartId] = c;
                });
            }

            let query = chartUtils.getChartFilterQuery(allCharts);
            const filtersQ = filters.join(' AND ');
            if (filtersQ) {
                if (query) {
                    query += ' AND (' + filtersQ + ')';
                } else {
                    query = '(' + filtersQ + ')';
                }
            }
            if (query) {
                query += ' AND ';
            }
            if (dashboard.discoveryOptions) {
                query += '(' + dashboard.discoveryOptions.query + ') AND ';
            }
            return currentUser
                .orgId()
                .then(function (orgId) {
                    query += 'sf_organizationID:' + orgId;
                    return signalboost.metrictimeseries.basicSearch(
                        query,
                        false,
                        0,
                        1,
                        'sf_createdOnMs',
                        true
                    );
                })
                .then(function (result) {
                    if (!result || !result.length) {
                        return [];
                    } else {
                        const value = result[0];
                        return variables.map(function (variable) {
                            return (
                                variable.alias +
                                '=' +
                                variable.property +
                                ':' +
                                sourceFilterService.flattenPropertyValue(value[variable.property])
                            );
                        });
                    }
                })
                .catch(function (e) {
                    $log.error('Failed fetching required variables for ', dashboard, charts, e);
                    return [];
                });
        }

        return svc;
    },
]);
