import catalogTemplateUrl from './catalog.tpl.html';
import { DataManagement as sitemap } from '../../../app/routing/Sitemaps';
import { ngRoute } from '../../../app/routing/ngRoute';
import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';

export const catalog = {
    templateUrl: catalogTemplateUrl,
    bindings: {
        setHierarchicalNav: '<',
    },
    controller: [
        '$scope',
        'sidebarService',
        'catalogDataService',
        'hasCapability',
        function ($scope, sidebarService, catalogDataService, hasCapability) {
            $scope.sidebarService = sidebarService;
            sidebarService.showSidebar = true;
            $scope.dataService = catalogDataService;
            // This is used to share context between the right side and the left side
            // of the catalog (catalog sidebar and catalog preview)
            $scope.sharableObject = {};

            const $ctrl = this;
            $ctrl.loading = true;

            $ctrl.$onInit = () => {
                checkCapability();
                $ctrl.setHierarchicalNav(sitemap.name, sitemap.IDs.metricMetadata);
            };

            $ctrl.$onDestroy = () => {
                $ctrl.setHierarchicalNav();
            };

            function checkCapability() {
                hasCapability(Capability.READ_METRIC).then((hasReadMetricMetadata) => {
                    if (!hasReadMetricMetadata) {
                        ngRoute?.history?.replace('/forbidden');
                    } else {
                        $ctrl.loading = false;
                    }
                });
            }
        },
    ],
};
