import templateUrl from '../quickActions/quickActions.tpl.html';

export default [
    'sfxModal',
    function (sfxModal) {
        let open = false;
        return {
            openModal,
        };

        // Used to open the global search modal
        function openModal() {
            if (open) {
                return;
            }

            open = true;

            sfxModal
                .open({
                    templateUrl,
                    controller: 'QuickActionsController',
                    windowClass: 'quick-actions',
                })
                .result.finally(function () {
                    console.log('closing quick action');
                    open = false;
                });
        }
    },
];
