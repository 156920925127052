import { supportService } from '@splunk/olly-services/lib';
angular
    .module('signalview.dashboardUtil', [
        'signalview',
        'signalboost',
        'signalview.chart',
        'chartbuilderUtil',
    ])

    .service('dashboardUtil', [
        '$q',
        '$log',
        '$window',
        '$timeout',
        '$rootScope',
        '$location',
        'chartbuilderUtil',
        'sessionCache',
        'urlOverridesService',
        'shareableSnapshotService',
        'currentUser',
        'newDashboardService',
        'chartVersionService',
        'v2ChartAPIWrapper',
        'dashboardV2Service',
        'dashboardV2Util',
        'config',
        'dashboardGroupService',
        'featureEnabled',
        'sourceFilterService',
        'dashboardVariablesService',
        'urlOverridesService',
        'timepickerUtils',
        'chartV2Service',
        function (
            $q,
            $log,
            $window,
            $timeout,
            $rootScope,
            $location,
            chartbuilderUtil,
            sessionCache,
            urlParams,
            shareableSnapshotService,
            currentUser,
            newDashboardService,
            chartVersionService,
            v2ChartAPIWrapper,
            dashboardV2Service,
            dashboardV2Util,
            config,
            dashboardGroupService,
            featureEnabled,
            sourceFilterService,
            dashboardVariablesService,
            urlOverridesService,
            timepickerUtils,
            chartV2Service
        ) {
            const SYNTH_ORG_ID = 'SYNTH_ORG_ID';

            /* service api */
            return {
                cleanDashboard,
                cleanChart,
                createBlankWorkspace,
                createDashboardFromData,
                createNewDashboard,
                createNewDashboardVariableOverride,
                deleteChartFromDashboard,
                getAllChartModels,
                getDashboardGroupsForDashboard,
                getAllDashboardConfigs,
                getCachedChartModels,
                getChartSnapshotPayload,
                getConfig,
                getConfigForDashboard,
                getCustomAndUserFavoriteKey,
                getDashboardData,
                getDashboardSnapshotPayload,
                getDashboardSearchParamsString,
                getDashboardUrl,
                addDashboardSearchParams,
                getSavedConfigFilterOverrides,
                getSavedDashboardFilters,
                getSavedFilters,
                getSavedFiltersAndIgnoreVariables,
                mergeVariablesWithOverrides,
                reserializeData,
                resetFilterOverrides,
                setFilterOverrides,
                getCurrentFiltersFromURL,
                makeDashboardReadOnly,
                makeDashboardEditable,
                addChartsFromModels,
                scrollToFirstChart,
                getDashboardUrlFilterState,
                getTimePickerParamsString,
                getTimeSpanForAllCharts,
            };

            // Public function definitions

            function getCachedChartModels() {
                sessionCache.clearId('cachedCharts');
                sessionCache.setId('cachedCharts', []);
                $rootScope.$broadcast('setCachedChartModels');
                const models = sessionCache.getId('cachedCharts');
                sessionCache.clearId('cachedCharts');
                return models;
            }

            function getAllChartModels(savedCharts) {
                // returns cached chart models, accounts for charts that haven't been loaded
                // yet (below the fold optimization)
                // note : i believe merging this will restore deleted charts, however they wont be
                // part of the dashboard model so it should be "okay"
                const mergedCharts = {};
                const charts = getCachedChartModels();
                charts.forEach(function (c) {
                    const id = c.id || c.sf_id;
                    mergedCharts[id] = c;
                });

                angular.forEach(savedCharts, function (chart, id) {
                    mergedCharts[id] = chart;
                });

                return Object.keys(mergedCharts).map(function (k) {
                    return mergedCharts[k];
                });
            }

            function cleanDashboard(dashboard, newname, keepOrgId, ignoreSavedFilters) {
                const isSuperPowers = config('superpower.unreleasedFeatures');
                const d = {};
                d.name = newname || dashboard.name || '';
                d.description = dashboard.description || '';
                d.charts = dashboard.charts || [];
                d.filters = {};
                d.chartDensity = dashboard.chartDensity;
                d.maxDelayOverride =
                    'maxDelayOverride' in dashboard ? dashboard.maxDelayOverride : null;

                if (dashboard.authorizedWriters) {
                    d.authorizedWriters = dashboard.authorizedWriters;
                }

                if (dashboard.eventOverlays) {
                    d.eventOverlays = dashboard.eventOverlays;
                }

                if (dashboard.selectedEventOverlays) {
                    d.selectedEventOverlays = dashboard.selectedEventOverlays;
                }

                if (dashboard.filters && dashboard.filters.variables) {
                    d.filters.variables = dashboard.filters.variables;
                }

                if (!ignoreSavedFilters) {
                    d.filters = getOverridesAsSavedFilters((d.filters || {}).variables || []);
                    d.selectedEventOverlays = urlParams.getSelectedEventOverlays();
                }
                if (isSuperPowers && dashboard.discoveryOptions) {
                    d.discoveryOptions = dashboard.discoveryOptions;
                }
                return d;
            }

            function cleanChart(chart, keepOrgId) {
                const c = {};
                if (chartVersionService.getVersion(chart) === 2) {
                    c.options = angular.copy(chart.options || {});
                    c.name = chart.name || '';
                    c.description = chart.description || '';
                    c.programText = chart.programText;
                    c.sloId = chart.sloId;
                } else {
                    c.sf_type = 'Chart';
                    c.sf_chart = chart.sf_chart || '';
                    c.sf_description = chart.sf_description || '';
                    c.sf_uiModel = angular.copy(chart.sf_uiModel) || { allPlots: [] };
                    c.sf_uiModel.relatedDetectors = [];
                    angular.forEach(c.sf_uiModel.allPlots, function (ap, index) {
                        if (c.sf_uiModel.allPlots[index].seriesData) {
                            delete c.sf_uiModel.allPlots[index].seriesData.metricId;
                        }
                    });
                    if (keepOrgId) {
                        c.sf_organizationID = chart.sf_organizationID;
                    }
                }

                if (chart.sf_flowVersion) {
                    c.sf_flowVersion = chart.sf_flowVersion;
                }
                if (chart.sf_modelVersion) {
                    c.sf_modelVersion = chart.sf_modelVersion;
                }
                c.sf_chartIndex = chart.sf_chartIndex;
                return c;
            }

            function getDashboardData(
                dashboard,
                newname,
                chartsList,
                keepOrgId,
                ignoreSavedFilters
            ) {
                const d = cleanDashboard(dashboard, newname, keepOrgId, ignoreSavedFilters);
                const synthIds = {};

                function getSynthId(id) {
                    if (synthIds[id]) {
                        return synthIds[id];
                    }
                    synthIds[id] = 'SYNTH_CHART_ID_' + Object.keys(synthIds).length;
                    return synthIds[id];
                }

                const data = {
                    dashboard: d,
                    charts: {},
                    orgId: SYNTH_ORG_ID,
                };

                // get all referenced chartIds, and ensure that we didnt miss any due to model mismatch
                // by checking the lengths match post filtration.
                const referencedCharts = d.charts
                    .map(function (itm) {
                        return itm.chartId;
                    })
                    .filter((x) => !!x);
                const foundReferencedIds = referencedCharts.length === d.charts.length;

                const chartids = {};

                let chartsPromise;
                if (chartsList) {
                    chartsPromise = $q.when(chartsList);
                } else {
                    throw new Error('No fromMemory or chartsList defined');
                }

                return $q
                    .all({
                        charts: chartsPromise,
                        orgId: currentUser.orgId(),
                    })
                    .then((results) => {
                        const charts = results.charts;
                        const orgId = results.orgId;

                        angular.forEach(charts, function (chart, index) {
                            if (chart) {
                                const chartId = chart.sf_id || chart.id || orgId + '' + index;
                                if (foundReferencedIds && !referencedCharts.includes(chartId)) {
                                    $log.info(
                                        'Skipped chart ' +
                                            chartId +
                                            ' because it is not referenced by the dashboard'
                                    );
                                    return;
                                }
                                chartids[chartId] = getSynthId(chartId);
                                const c = cleanChart(chart, keepOrgId);
                                c.id = chartId;
                                data.charts[chartids[chartId]] = c;
                            }
                        });

                        return jsonify(data, chartids, keepOrgId ? null : orgId);
                    });
            }

            function createDashboardFromSource(sourceDashboard) {
                const dashboardToCreate = {
                    name: sourceDashboard.name || '',
                    description: sourceDashboard.description || '',
                    filters: {},
                    authorizedWriters: sourceDashboard.authorizedWriters || {},
                };

                // TODO(trevor): Refactor once groupIds are fully deprecated
                if (sourceDashboard.groupId) {
                    dashboardToCreate.groupId = sourceDashboard.groupId;
                }

                if (sourceDashboard.filters) {
                    dashboardToCreate.filters = getFiltersFromSourceDashboard(sourceDashboard);
                }

                if (sourceDashboard.eventOverlays) {
                    dashboardToCreate.eventOverlays = sourceDashboard.eventOverlays;
                }

                if (sourceDashboard.selectedEventOverlays) {
                    dashboardToCreate.selectedEventOverlays = sourceDashboard.selectedEventOverlays;
                }

                const isSuperPowers = config('superpower.unreleasedFeatures');
                if (isSuperPowers && sourceDashboard.discoveryOptions) {
                    dashboardToCreate.discoveryOptions = sourceDashboard.discoveryOptions;
                }

                if (sourceDashboard.charts) {
                    dashboardToCreate.charts = sourceDashboard.charts;
                }

                if (sourceDashboard.permissions) {
                    dashboardToCreate.permissions = sourceDashboard.permissions;
                }

                return dashboardV2Service.create(dashboardToCreate);
            }

            function createDashboardFromData(jsonData, groupId, permissions) {
                const data = angular.fromJson(jsonData);
                const sourceDashboard = data.dashboard;
                const chartIdToIndex = {};
                sourceDashboard.charts.forEach(function (chart, idx) {
                    chartIdToIndex[chart.chartId] = idx;
                });

                // TODO(trevor): Remove after views migration
                sourceDashboard.groupId = groupId || sourceDashboard.groupId;

                //in order to save these charts to the dashboard, we need the charts to have the same index as the dashboard
                const sourceCharts = Object.keys(data.charts)
                    .map(function (chartId) {
                        return data.charts[chartId];
                    })
                    .filter((chart) => chartIdToIndex[chart.id] !== undefined)
                    .sort(function (chart1, chart2) {
                        return chartIdToIndex[chart1.id] - chartIdToIndex[chart2.id];
                    });
                return createChartsFromSource(sourceCharts, data.orgId)
                    .then(function (charts) {
                        const dashboardToCreate = angular.copy(sourceDashboard);
                        charts.forEach(function (chart, index) {
                            if (dashboardToCreate.charts[index]) {
                                dashboardToCreate.charts[index].chartId = chart.sf_id || chart.id;
                            }
                        });
                        if (permissions) {
                            dashboardToCreate.permissions = permissions;
                        }
                        return createDashboardFromSource(dashboardToCreate);
                    })
                    .then(function (dashboard) {
                        return dashboard.id;
                    });
            }

            function getDashboardSnapshotPayload(dashboard, allCharts, ignoreSavedFilters) {
                if (!allCharts) {
                    const errorMessage = 'Charts not passed in to snapshot payload';
                    $log.error(errorMessage);
                    return $q.reject(errorMessage);
                }

                return getDashboardData(dashboard, '', allCharts, true, ignoreSavedFilters).then(
                    function (toReturn) {
                        toReturn = angular.fromJson(toReturn);
                        delete toReturn.orgId;
                        const charts = angular.copy(Object.keys(toReturn.charts));
                        toReturn.charts = charts.map(function (k) {
                            return toReturn.charts[k];
                        });
                        return toReturn;
                    }
                );
            }

            function getChartSnapshotPayload(chart, dashboard) {
                const data = {};
                let c = cleanChart(angular.copy(chart), true);
                if (chartVersionService.getVersion(chart) === 2) {
                    // a passthrough filter may be preferable here, but for now delete unwanted tracking crap
                    delete c.id;
                    delete c.sf_modelVersion;
                    delete c.sf_chartIndex;
                } else {
                    delete c.sf_id;
                    c.sf_type = 'Chart';
                }
                const synthId = {};
                synthId[chart.sf_id] = 'SYNTH_CHART_ID_0';
                c = jsonify(c, synthId);
                data.chart = angular.fromJson(c);
                if (dashboard) {
                    data.dashboard = cleanDashboard(dashboard, dashboard.name, true, false);
                } else {
                    data.savedFilters = getOverridesAsSavedFilters();
                }
                return $q.when(data);
            }

            function getOverridesAsSavedFilters(variablesDefinition) {
                const savedFilters = getDashboardUrlFilterState();
                savedFilters.variables = dashboardVariablesService.getVariablesOverrideAsModel(
                    savedFilters.variables
                );

                if (variablesDefinition && variablesDefinition.length) {
                    if (!savedFilters.variables) {
                        savedFilters.variables = [];
                    }

                    variablesDefinition.forEach((dashboardVarDef) => {
                        // Find (if any) url overrides for dashboard variables.
                        const urlOverrideVarDef = savedFilters.variables.find((urlVarDef) => {
                            return urlVarDef.property === dashboardVarDef.property;
                        });

                        if (!urlOverrideVarDef) {
                            // If there is no url override for the dashboard variable defined,
                            // apply it to the saved filters since it's currently being applied
                            // as a filter even though it's not present in the url.
                            savedFilters.variables.push(dashboardVarDef);
                        } else {
                            // If there is a url override present for a dashboard variable, copy
                            // the dashboard variable settings for the existing property (since
                            // the data in the URL is only a subset of the config) but retain
                            // the value of the URL override.
                            const index = savedFilters.variables.indexOf(urlOverrideVarDef);
                            savedFilters.variables[index] = angular.copy(dashboardVarDef);
                            savedFilters.variables[index].value = urlOverrideVarDef.value;
                        }
                    });
                }
                return savedFilters;
            }

            function getDashboardUrlFilterState() {
                return {
                    sources: urlOverridesService.getSourceFilterOverrideList(),
                    variables: dashboardVariablesService.getVariablesOverride(),
                    time: urlOverridesService.getGlobalTimePicker(),
                    density: urlOverridesService.getPointDensity(),
                };
            }

            function setFilterOverrides(savedFilters, currentFilters, sdVariableValues) {
                let dirty = false;

                if (!currentFilters.sources) {
                    if (savedFilters.sources && savedFilters.sources.length) {
                        urlParams.setSourceFilterOverrideListFromLegacy(savedFilters.sources);
                        dirty = true;
                    } else {
                        urlParams.clearSourceOverride();
                    }
                }

                if (!currentFilters.time && savedFilters.time) {
                    const { start, end, relative } = savedFilters.time;
                    urlParams.setGlobalTimePicker(start, end, relative);
                    dirty = true;
                }

                if (!currentFilters.density && savedFilters.density) {
                    urlParams.setPointDensity(savedFilters.density);
                    dirty = true;
                }

                // variables take the saved defaults independently of filters/time/density.
                if ((savedFilters.variables || sdVariableValues) && currentFilters.variables) {
                    // Combines current filters along with any required sd variable filters
                    const combinedCurrentFilters = currentFilters.variables.slice(0);
                    if (sdVariableValues) {
                        // Adds any sdVariables that aren't defined in the current filters to
                        // the combined model
                        sdVariableValues
                            .filter((variable) => {
                                return combinedCurrentFilters.some(
                                    (curVar) => curVar.alias === variable
                                );
                            })
                            .forEach((variable) => combinedCurrentFilters.push(variable));
                    }

                    dashboardVariablesService.setVariablesOverride(
                        combinedCurrentFilters || savedFilters.variables
                    );
                    dirty = true;
                } else {
                    dashboardVariablesService.clearVariablesOverride();
                }

                return dirty;
            }

            // reset url state to the saved state of the dashboard being displayed.
            function resetFilterOverrides(savedFilters) {
                urlParams.clearSourceOverride();
                urlParams.clearPointDensity();
                urlParams.clearTimePicker();
                dashboardVariablesService.clearVariablesOverride();

                return setFilterOverrides(savedFilters, {});
            }

            function getSavedFiltersAndIgnoreVariables(dashboard, config) {
                const result = getSavedFilters(dashboard, config);
                result.variables = null;
                return result;
            }

            function getSavedFilters(dashboard, config) {
                const dashboardFilters = getSavedDashboardFilters(dashboard);
                if (dashboardFilters.sources) {
                    dashboardFilters.sources = dashboardFilters.sources.map(applyFilterMetadata);
                }

                if (!featureEnabled('dashboardViews')) return dashboardFilters;

                const configFilters = getSavedConfigFilterOverrides(config);
                if (configFilters.sources) {
                    configFilters.sources = configFilters.sources.map(applyFilterMetadata);
                }
                const hasConfigOverrides = ['sources', 'time', 'density', 'variables'].some(
                    (prop) => configFilters[prop] !== null
                );
                const dashboardVariables = dashboardFilters.variables || [];
                const configVariables = configFilters.variables || [];

                let result;
                if (hasConfigOverrides) {
                    result = configFilters;

                    // If null, use dashboard filters. Want to apply empty override if sources is empty
                    // string or empty array.
                    if (result.sources === null) {
                        result.sources = angular.copy(dashboardFilters.sources);
                    }
                    result.variables = mergeVariablesWithOverrides(
                        dashboardVariables,
                        configVariables
                    );
                } else {
                    result = dashboardFilters;
                    result.variables = getCleanedDashboardVariables(dashboardVariables);
                }

                result.density = dashboardFilters.density;
                result.time = dashboardFilters.time;

                return result;
            }

            /* We want to add all known filter properties to filter for data consistency and valid filter
             * comparisons between an old and new state.
             */
            function applyFilterMetadata(filter) {
                if (typeof filter === 'string') {
                    filter = sourceFilterService.filterStrToObject(filter);
                }

                if (!filter.applyIfExists) {
                    filter.applyIfExists = false;
                }

                filter.propertyValue = filter.value;
                sourceFilterService.assignIconClassToFilter(filter);
                sourceFilterService.assignTypeToFilter(filter);
                return filter;
            }

            function mergeVariablesWithOverrides(variables, overrides) {
                return variables.map((variable) => {
                    const property = variable.property;
                    const correspondingOverride = overrides.find(
                        (override) => override.property === property
                    );

                    if (correspondingOverride) {
                        variable = addValidOverridesToVariable(variable, correspondingOverride);
                    }

                    return variable;
                });
            }

            function addValidOverridesToVariable(variable, override) {
                variable.valueOverride =
                    override.value === null ? null : angular.copy(override.value);

                const hasPreferredSuggestionsOverride =
                    override.preferredSuggestions && override.preferredSuggestions.length;
                variable.preferredSuggestionsOverride = hasPreferredSuggestionsOverride
                    ? angular.copy(override.preferredSuggestions)
                    : null;

                return variable;
            }

            function getCleanedDashboardVariables(variables) {
                return variables.map((variable) => {
                    delete variable.valueOverride;
                    delete variable.preferredSuggestionsOverride;
                    return variable;
                });
            }

            function getSavedDashboardFilters(dashboard) {
                const savedFilters = { sources: null, variables: null, time: null, density: null };
                if (dashboard && dashboard.filters) {
                    savedFilters.sources = dashboard.filters.sources || null;
                    savedFilters.variables = dashboard.filters.variables || null;
                    savedFilters.time = dashboard.filters.time || null;
                    savedFilters.density = dashboard.filters.density || null;
                }
                return savedFilters;
            }

            function getFiltersFromSourceDashboard(sourceDashboard) {
                const filters = sourceDashboard.filters;
                // backend requires the end value of relative time filters to be 'Now' (capitalized)
                // the global time filter applies it lowercase, so this needs to be changed
                if (filters.time?.end === 'now') {
                    filters.time.end = 'Now';
                }
                return filters;
            }

            function getSavedConfigFilterOverrides(config) {
                const savedFilterOverrides = {
                    sources: null,
                    time: null,
                    density: null,
                    variables: null,
                };

                if (config && config.filtersOverride) {
                    savedFilterOverrides.sources = config.filtersOverride.sources || null;
                    savedFilterOverrides.time = config.filtersOverride.time || null;
                    savedFilterOverrides.density = config.filtersOverride.density || null;
                    savedFilterOverrides.variables = config.filtersOverride.variables || null;
                }

                return savedFilterOverrides;
            }

            function reserializeData(dashboard) {
                dashboard.charts.sort(function (v1, v2) {
                    if (v1.row < v2.row) {
                        return -1;
                    } else if (v1.row > v2.row) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }

            function deleteChartFromDashboard(dashboard, chart) {
                const chartId = chart.sf_id || chart.id;
                return removeChart(dashboard, chartId);
            }

            function createBlankWorkspace() {
                const dashboard = {
                    charts: [],
                    name: '',
                    description: '',
                    filters: { variables: null, time: null, sources: null, density: null },
                };

                return getDashboardSnapshotPayload(dashboard, [], true).then(function (payload) {
                    payload.workspace = true;
                    return shareableSnapshotService.create(
                        shareableSnapshotService.types.Dashboard,
                        dashboard.name,
                        '',
                        payload
                    );
                });
            }

            function createNewDashboard() {
                let newDashboard;
                return createBlankWorkspace()
                    .then(function (snapshot) {
                        newDashboard = snapshot;
                        if (supportService.getSupportOrg()) {
                            // don't care in support org
                            return $q.when();
                        } else {
                            const update = {
                                sf_newDashboard: snapshot.id,
                                sf_numUnseenCharts: 0,
                            };
                            return newDashboardService.updateNewDashboardInfo(update);
                        }
                    })
                    .then(function () {
                        $rootScope.$broadcast('new workspace');
                        return newDashboard;
                    });
            }

            function createNewDashboardVariableOverride({ property, value, preferredSuggestions }) {
                // NOTE(trevor): The hard coded values will never be used, and for the moment are just
                // necessary boilerplate until the data model is refined for dashboard variable overrides
                return {
                    property,
                    value: value || '',
                    preferredSuggestions: preferredSuggestions || [],
                    globalScope: true,
                    required: true,
                    description: '',
                    restricted: false,
                    replaceOnly: false,
                    applyIfExists: false,
                };
            }

            function getCurrentFiltersFromURL() {
                const sourceOverride = urlParams.getSourceFilterOverrideList();
                const variablesOverride =
                    dashboardVariablesService.getVariablesUrlOverrideAsModel();
                const timePickerOverride = urlParams.getGlobalTimePicker();
                const pointDensityOverride = urlParams.getPointDensity();

                return {
                    sources: sourceOverride,
                    variables: variablesOverride,
                    time: timePickerOverride,
                    density: pointDensityOverride,
                };
            }

            function makeDashboardReadOnly(dashboard) {
                return dashboardV2Service.lockDashboard(dashboard.id).catch((e) => {
                    $window.alert('There was an error making this dashboard read-only.');
                    return $q.reject(e);
                });
            }

            function makeDashboardEditable(dashboard) {
                return dashboardV2Service.unlockDashboard(dashboard.id).catch((e) => {
                    $window.alert('There was an error making this dashboard editable.');
                    return $q.reject(e);
                });
            }

            function getDashboardUrl(dashboardId, groupId, configId) {
                let queryParams = getDashboardSearchParamsString(groupId, configId);
                queryParams = queryParams.length ? `?${queryParams}` : '';

                return `#/dashboard/${dashboardId}${queryParams}`;
            }

            function getDashboardSearchParamsString(groupId, configId) {
                if (!featureEnabled('dashboardViews') || !groupId) return '';

                const groupStr = `groupId=${groupId}`;
                const configStr = configId ? `&configId=${configId}` : '';

                return `${groupStr}${configStr}`;
            }

            function getTimePickerParamsString() {
                return timepickerUtils.getURLParamStringForTimePicker(
                    urlParams.getGlobalTimePicker()
                );
            }

            function addDashboardSearchParams({ groupId, configId }, replace) {
                if (featureEnabled('dashboardViews')) {
                    const params = $location.search();
                    params.groupId = groupId;
                    params.configId = configId;
                    $location.search(params);
                    if (replace) {
                        $location.replace();
                    }
                }
            }

            function getDashboardGroupsForDashboard(dashboardId) {
                return dashboardGroupService.searchByDashboardId(dashboardId);
            }

            /*
             * Returns a list of all views for a particular dashboard. Each view object
             * will also contain its group's name and id.
             */
            function getAllDashboardConfigs(dashboardId) {
                if (!dashboardId) {
                    return Promise.resolve([]);
                }
                return getDashboardGroupsForDashboard(dashboardId).then(({ results }) => {
                    const configs = [];

                    results.forEach((group) => {
                        // This conditional should only be necessary as long as there are unmigrated orgs
                        if (group.dashboardConfigs) {
                            group.dashboardConfigs.forEach((config) => {
                                if (config.dashboardId === dashboardId) {
                                    config.groupName = group.name;
                                    config.groupId = group.id;
                                    configs.push(config);
                                }
                            });
                        }
                    });

                    return configs;
                });
            }

            function getConfig(configs, configId) {
                return configs?.find((config) => config.configId === configId);
            }

            function getConfigForDashboard(configs, dashboardId) {
                return configs?.find((config) => config.dashboardId === dashboardId);
            }

            function getCustomAndUserFavoriteKey(dashboard, group, configId) {
                // Only use group and config ids in the key if both are present
                // Otherwise, just use the dashboard id (implies that there are not multiple appearances)
                const useGroupAndConfig = group && (group.id || group.sf_id) && configId;

                const groupId = useGroupAndConfig ? `${group.sf_id || group.id}-` : '';
                const dashboardId = dashboard.sf_id || dashboard.id;
                const configIdStr = useGroupAndConfig ? `-${configId}` : '';

                return `${groupId}${dashboardId}${configIdStr}`;
            }

            // private functions

            function createChartsFromSource(sourceCharts) {
                const chartCreates = [];
                let now = Date.now();
                sourceCharts.forEach(function (chart) {
                    let chartToCreate;
                    if (chartVersionService.getVersion(chart) === 2) {
                        chartToCreate = angular.copy(chart);
                        delete chartToCreate.id;
                        delete chartToCreate.sf_modelVersion;
                        delete chartToCreate.sf_type;
                        delete chartToCreate.sf_chartIndex;
                        const v2promise = v2ChartAPIWrapper(chartToCreate)
                            .clone(now++)
                            .then(function (result) {
                                return result.data;
                            });
                        chartCreates.push(
                            v2promise.then(function (newChart) {
                                return angular.extend(newChart, {
                                    sf_id: newChart.id,
                                    sf_modelVersion: 2,
                                });
                            })
                        );
                    } else {
                        chartToCreate = {
                            sf_type: 'Chart',
                            sf_chart: chart.sf_chart || '',
                            sf_description: chart.sf_description || '',
                            sf_chartIndex: chart.sf_chartIndex || now++,
                        };
                        if (chart.sf_jobResolution) {
                            chartToCreate.sf_jobResolution = chart.sf_jobResolution;
                        }
                        chartbuilderUtil.prepareChartSave(chartToCreate);

                        ['sf_viewProgramText', 'sf_throttledProgramText', 'sf_programText'].forEach(
                            function (programName) {
                                delete chartToCreate[programName];
                            }
                        );

                        chartToCreate.sf_uiModel = angular.copy(chart.sf_uiModel);

                        delete chartToCreate.sf_uiModel.uiHelperValue;

                        chartCreates.push(chartV2Service.create(chartToCreate));
                    }
                });
                return $q.all(chartCreates);
            }

            function jsonify(data, synthChartIds, orgId) {
                function replacer(key, value) {
                    if (typeof value !== 'string') {
                        return value;
                    }
                    const dashregex = new RegExp('_SF_REPLACED_DASH_', 'g');
                    value = value.replace(dashregex, '-');
                    let isUpdated = false;
                    angular.forEach(synthChartIds, function (synthChartId, chartId) {
                        if (!isUpdated) {
                            const idregex = new RegExp(chartId, 'g');
                            if (idregex.test(value)) {
                                value = value.replace(idregex, synthChartId);
                                isUpdated = true;
                            }
                        }
                    });
                    if (!orgId) {
                        return value;
                    } else {
                        const orgidregex = new RegExp(orgId, 'g');
                        return value.replace(orgidregex, SYNTH_ORG_ID);
                    }
                }
                return JSON.stringify(data, replacer, 4);
            }

            function removeChart(dashboard, chartId) {
                dashboard = dashboardV2Util.deleteChart(dashboard, chartId);
                return dashboard;
            }

            function addChartsFromModels(
                currentDashboardModel,
                allCharts,
                workspace,
                newChartModels,
                saveWorkSpace
            ) {
                let dashboard;
                const prevNumCharts = (currentDashboardModel.charts || []).length;
                const dashboardPromise = workspace
                    ? currentDashboardModel
                    : dashboardV2Service.get(currentDashboardModel.id);

                const updatePromise = $q
                    .all([dashboardPromise, currentUser.orgId()])
                    .then((results) => {
                        dashboard = results[0];
                        const orgId = results[1];

                        newChartModels.forEach((model) => {
                            if (chartVersionService.getVersion(model) !== 2) {
                                model.sf_organizationID = orgId;
                            }
                        });

                        if (workspace) {
                            return $q.when(newChartModels);
                        } else {
                            return createChartsFromSource(newChartModels);
                        }
                    })
                    .then((charts) => {
                        newChartModels = charts;

                        if (workspace) {
                            dashboardV2Service.addTransientCharts(dashboard, newChartModels);
                            return dashboard;
                        } else {
                            return dashboardV2Service.addCharts(dashboard, newChartModels);
                        }
                    })
                    .then((updatedDashboard) => {
                        dashboard = updatedDashboard;
                        newChartModels.forEach((newChart) => {
                            allCharts[newChart.sf_id || newChart.id] = newChart;
                        });
                        currentDashboardModel.charts = updatedDashboard.charts;
                        if (workspace) {
                            saveWorkSpace();
                        }
                        return {
                            dashboard: updatedDashboard,
                            charts: newChartModels,
                        };
                    });

                updatePromise.finally(() => {
                    $timeout(() => {
                        scrollToFirstChart(prevNumCharts, dashboard);
                    });
                });

                return updatePromise;
            }

            function scrollToFirstChart(prevNumCharts, updatedDashboard) {
                const dashboardContent = angular.element('dashboard').last().scrollParent();
                // Calculate how far down to scroll the dashboard to see first
                // of the new charts
                let bottomPadding = 0;
                if (prevNumCharts) {
                    // Take into account 500px padding on the bottom when the
                    // dashboard has existing charts
                    bottomPadding = 530;
                    const firstChartCol = updatedDashboard.charts[prevNumCharts].col;
                    if (firstChartCol >= 6) {
                        // First new chart will fit in the right side of the last
                        // row currently in the dashboard. Go back up one row.
                        bottomPadding += 220;
                    }
                }
                if (bottomPadding) {
                    dashboardContent.animate(
                        { scrollTop: dashboardContent[0].scrollHeight - bottomPadding },
                        300
                    );
                }
            }

            // returns the minimum start and maximum end time of a dashboard's chartd in epoch ms
            function getTimeSpanForAllCharts(charts) {
                const now = Date.now();
                let start = null;
                let end = null;
                charts.forEach((chart) => {
                    const timeDefinition = chart.options ? chart.options.time : null;
                    if (!timeDefinition) {
                        return;
                    }
                    let chartStart;
                    let chartEnd;

                    if (timeDefinition.type === 'relative') {
                        chartEnd = now;
                        chartStart = now - timeDefinition.range;
                    } else {
                        chartStart = timeDefinition.start;
                        chartEnd = timeDefinition.end;
                    }

                    if (start === null) {
                        start = chartStart;
                    } else if (chartStart < start) {
                        start = chartStart;
                    }

                    if (end === null) {
                        end = chartEnd;
                    } else if (chartEnd > end) {
                        end = chartEnd;
                    }
                });

                // if we didnt find any charts with time ranges, take the default
                if (start === null) {
                    start = 0;
                }
                if (end === null) {
                    end = 0;
                }

                return {
                    start,
                    end,
                };
            }
        },
    ]);
