'use strict';

import { createV2Service } from './createV2Service';
import { alertMutingApiService } from './alertMutingApiService';
import { orgMembershipService } from './orgMembershipService.js';
import mtsService from './mtsService';
import dimensionService from './dimensionService';
import crossLinkService from './crossLinkService';
import namedTokenService from './namedTokenService';
import roleTokenService from './roleTokenService';
import dashboardGroupService from './dashboardGroupService';
import { ShareableSnapshotService } from './shareableSnapshotService';
import traceAPIService from './traceAPIService';
import alertDataService from './alertDataService';
import ssoService from './ssoService';
import navigatorService from './navigatorService';
import smartGatewayDownloadService from './smartGatewayDownloadService';
import suggestAPIService from './suggestAPIService';
import alertStateBatcher from './alertStateBatcher';
import permissionsService from './permissionsService';

angular
    .module('sfx.api.v2', ['signalview.consts', 'restangular'])
    .factory('createV2Service', createV2Service)
    .service('alertMutingApiService', alertMutingApiService)
    .factory('orgMembershipService', orgMembershipService)
    .service('namedTokenService', namedTokenService)
    .service('roleTokenService', roleTokenService)
    .service('mtsService', mtsService)
    .service('dimensionService', dimensionService)
    .service('dashboardGroupService', dashboardGroupService)
    .service('crossLinkService', crossLinkService)
    .service('traceAPIService', traceAPIService)
    .service('alertDataService', alertDataService)
    .service('navigatorService', navigatorService)
    .service('ssoService', ssoService)
    .service('smartGatewayDownloadService', smartGatewayDownloadService)
    .service('suggestAPIService', suggestAPIService)
    .service('alertStateBatcher', alertStateBatcher)
    .service('permissionsService', permissionsService)
    .service('shareableSnapshotService', ShareableSnapshotService);
