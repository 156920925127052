import { heatmap } from './heatmap';

const INTERNAL_PROPERTY_DENYLIST = [
    'jobId',
    'tsid',
    'value',
    'id',
    'parent',
    'depth',
    'computationId',
];

// useless properties
const AWS_PROPERTY_DENYLIST = [
    'aws_reservation_id',
    'aws_private_dns_name',
    'aws_public_dns_name',
    'aws_public_ip_address',
    'aws_instance_id',
    'AWSUniqueId',
    'interfaces',
    'dsname',
    'stat',
];

const HEATMAP_PROPERTY_DENYLIST = [...INTERNAL_PROPERTY_DENYLIST, ...AWS_PROPERTY_DENYLIST];

const KUBE_INFRA_NAV_ID = 'kubernetes infrastructure navigator';

const DASHBOARD_HEATMAP_NO_DATA = 'No popular property key values found';
const NAVIGATOR_HEATMAP_NO_DATA = 'No defining properties found';

angular
    .module('signalview.heatmap', [
        'd3',
        'chartdisplay',
        'sfx.external',
        'sfx.data',
        'sfx.ui',
        'sfx.charting',
    ])

    // constants
    .constant('HEATMAP_PROPERTY_DENYLIST', HEATMAP_PROPERTY_DENYLIST)
    .constant('INTERNAL_PROPERTY_DENYLIST', INTERNAL_PROPERTY_DENYLIST)
    .constant('KUBE_INFRA_NAV_ID', KUBE_INFRA_NAV_ID)
    .constant('DASHBOARD_HEATMAP_NO_DATA', DASHBOARD_HEATMAP_NO_DATA)
    .constant('NAVIGATOR_HEATMAP_NO_DATA', NAVIGATOR_HEATMAP_NO_DATA)
    .component('heatmap', heatmap);
