export default [
    '$q',
    function ($q) {
        function destroyablePromise(originalPromise, postDestroyCleanup) {
            const deferred = $q.defer();

            originalPromise.then(
                function (a) {
                    if (originalPromise) {
                        deferred.resolve(a);
                    }
                },
                function (a) {
                    if (originalPromise) {
                        deferred.reject(a);
                    }
                },
                function (a) {
                    if (originalPromise) {
                        deferred.notify(a);
                    }
                }
            );

            deferred.promise.destroy = function () {
                originalPromise = null;
                if (postDestroyCleanup) {
                    postDestroyCleanup();
                }
            };
            return deferred.promise;
        }

        return { destroyablePromise };
    },
];
