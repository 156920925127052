/*
  dimensions: [{
    propertyName: <name>,
    propertyValue: <value>,
    actionItems: [{title: <title>, action: <onclick-callback>, label: <label>}, ...],
    },
    ...
  ]
*/
import templateUrl from './crossLink.tpl.html';
import crossLinkActionsTemplateUrl from './crossLinkActions.tpl.html';

export default {
    templateUrl,
    bindings: {
        incomingDimensions: '<dimensions', // A copy of dimensions will be made,
        // Common actions should be provided via defaultActions
        dropdownAppendToBody: '<?', // When true, parent scrolling will be blocked
        onToggle: '<?', // on dropdown toggle callback. function(open, $element){}
        header: '<?',
        context: '<?', // context will be supplied to URL template under the <properties> namespace
        scrollAutoClose: '<?',
        popupAlign: '@', // left or right
        selectedTimeRange: '<?', // Takes TimePicker obj {start, end, relative}
        pinnedTime: '<', // pinnedTime from dataTable
        // {pinnedTime(time(integer)), resolution(milli-seconds)}
        defaultActions: '<', // Action items common to all dimensions,
        showConfigureLinkItem: '<', // Default True
    },
    controller: [
        '$element',
        '$location',
        '$rootScope',
        '$scope',
        '$timeout',
        'chartDisplayUtils',
        'CROSS_LINK_EVENTS',
        'crossLinkDataService',
        'crossLinkUtils',
        'urlOverridesService',
        'CROSS_LINK_TYPES',
        'routeParameterService',
        'themeService',
        function (
            $element,
            $location,
            $rootScope,
            $scope,
            $timeout,
            chartDisplayUtils,
            CROSS_LINK_EVENTS,
            crossLinkDataService,
            crossLinkUtils,
            urlOverridesService,
            CROSS_LINK_TYPES,
            routeParameterService,
            themeService
        ) {
            const $ctrl = this;
            this.crossLinkActionsTemplateUrl = crossLinkActionsTemplateUrl;

            const linkProtocol = RegExp(/^((?:https?\:)?\/\/).+$/);
            let scrollParents = null;
            let urlFiltersContext = null;
            let dropdownMenuElement;
            const bodyClassname = 'olly-cross-link-body';

            $ctrl.externalServices = [
                CROSS_LINK_TYPES.SPLUNK_LINK,
                CROSS_LINK_TYPES.EXTERNAL_LINK,
                CROSS_LINK_TYPES.KIBANA_LINK,
            ];
            $ctrl.isValidTarget = crossLinkUtils.isValidTarget;

            const CROSS_LINK_INTERNAL_TYPES = {
                PAIR_LINK: 'pairLink',
                PROPERTY_LINK: 'propertyLink',
                MATCH_ALL_LINK: 'matchAllLink',
            };

            function closeDropdown() {
                $ctrl.dropDownIsOpen = false;
            }

            // hovering crossLink dropdown, triggers changing overflow on parent elements (auto -> hidden -> auto)
            // Firefox emits onscroll event with no scroll position change, no point of closing dropdown in this situation
            function closeDropdownOnDomEvent() {
                const element = angular.element(this);
                const initialScrollPos = parseInt(element.data('lastKnownScrollPos'), 10);
                const currentScrollPos = element.scrollTop();
                const positionChanged = currentScrollPos !== initialScrollPos;

                if (positionChanged) {
                    $scope.$apply(closeDropdown);
                }
            }

            const unregisterRouteWatchGroup = routeParameterService.registerRouteWatchGroup(
                ['sources[]', 'variables[]'],
                function (loc, changed) {
                    if (!_.isEmpty(changed)) {
                        urlFiltersContext = crossLinkDataService.getContextFromURLOverrides();
                        attachCrossLinkTargets();
                    }
                }
            );

            $ctrl.$onInit = () => {
                $scope.$on(CROSS_LINK_EVENTS.INVALIDATED_CACHE, attachCrossLinkTargets);

                $ctrl.dropdownAppendToBody = !!$ctrl.dropdownAppendToBody; // No undefs
                $ctrl.isDarkTheme = themeService.dark;
                $ctrl.isSettingDirection = true;
                if (
                    $ctrl.dropdownAppendToBody &&
                    !document.body.classList.contains(bodyClassname)
                ) {
                    document.body.classList.add(bodyClassname);
                }
            };

            $ctrl.$onChanges = (changes) => {
                if (changes.defaultActions || changes.incomingDimensions) {
                    $ctrl.actionItemsPresent = false;
                }
                if (changes.incomingDimensions) {
                    // Copying helps in avoiding cyclic update triggers which happen when crossLink is used inside ng-repeat
                    // and deep dimension object is attached (read actionItems are present)
                    const dimensionsCopy = angular.copy($ctrl.incomingDimensions);

                    $ctrl.dimensions = Array.isArray(dimensionsCopy)
                        ? dimensionsCopy
                        : [dimensionsCopy];
                    $ctrl.actionItemsPresent = $ctrl.dimensions.some(dimensionHasActionItem);
                    urlFiltersContext = crossLinkDataService.getContextFromURLOverrides();

                    attachCrossLinkTargets();
                }

                if ($ctrl.defaultActions && $ctrl.defaultActions.length) {
                    $ctrl.actionItemsPresent = true;
                }

                $ctrl.alignToRight = !($ctrl.popupAlign === 'left');
                dropdownMenuElement = null;

                $ctrl.showConfigureLinkItem = $ctrl.showConfigureLinkItem !== false;
            };

            function dimensionHasActionItem(dimension) {
                return dimension.actionItems && dimension.actionItems.length > 0;
            }

            function sortCrossLinksTargets(a, b) {
                if (a.isLocal === b.isLocal) {
                    if (!a.isDefault && b.isDefault) {
                        return 1;
                    } else if (a.isDefault && !b.isDefault) {
                        return -1;
                    }
                    // favor internal nav links over internal dashboard links (as navs are considered higher priority order than dashboards in general)
                    if (
                        a.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK &&
                        b.type === CROSS_LINK_TYPES.INTERNAL_LINK
                    ) {
                        return -1;
                    } else if (
                        a.type === CROSS_LINK_TYPES.INTERNAL_LINK &&
                        b.type === CROSS_LINK_TYPES.INTERNAL_NAVIGATOR_LINK
                    ) {
                        return 1;
                    }
                }
                return !a.isLocal && b.isLocal ? 1 : -1;
            }

            // combine internal and external crosslinks targets and sort in order of "default local, local, default global, global"
            function combineAllCrosslinksTargets(dimensionTargets) {
                let internalCrossLinks = [];
                Object.values(CROSS_LINK_INTERNAL_TYPES).map((internalLinkType) => {
                    const linkProps = dimensionTargets.internal[internalLinkType] || {};
                    const modifiedLinkPropsTargets =
                        linkProps.targets?.map((target) => ({
                            ...target,
                            propertyName: linkProps.propertyName,
                            propertyValue: linkProps.propertyValue,
                            aliases: linkProps.aliases,
                            internalLinkType,
                            isLocal: !!target.isLocal,
                        })) || [];
                    internalCrossLinks = [...internalCrossLinks, ...modifiedLinkPropsTargets];
                });
                return [...internalCrossLinks, ...dimensionTargets.external].sort(
                    sortCrossLinksTargets
                );
            }

            function addAllCrosslinksTargets(dimension) {
                if (dimension?.targets) {
                    dimension.targets = {
                        ...dimension.targets,
                        allCrosslinksTargets: combineAllCrosslinksTargets(dimension.targets),
                    };
                }
            }

            function attachCrossLinkTargets() {
                const extendedContext = crossLinkDataService.getExtendedContext(
                    $ctrl.dimensions,
                    null,
                    urlFiltersContext,
                    $ctrl.context
                );

                crossLinkDataService
                    .attachCrosslinkTargets($ctrl.dimensions, extendedContext)
                    .then((overallTargetsAvailable) => {
                        $ctrl.overallTargetsAvailable = overallTargetsAvailable;
                        // Add reference for crosslinkActions template
                        if ($ctrl.dimensions.length === 1) {
                            $scope.dim = $ctrl.dimensions[0];
                            addAllCrosslinksTargets($scope.dim);
                        } else if ($ctrl.dimensions.length > 1) {
                            $ctrl.dimensions.forEach((dimension) => {
                                addAllCrosslinksTargets(dimension);
                            });
                        }
                    });
            }

            // Custom implementation, helps keep in check of crosslink being opened
            // while the other opened crosslink is getting closed
            function disableParentScrolling() {
                if (scrollParents === null) {
                    return;
                }
                scrollParents.forEach((parent) => {
                    const crosslinkScrollBlocked = parent.data('crosslinkScrollBlocked') || 0;
                    const disabledScroll = parent.data('disabledScroll');
                    parent.data('crosslinkScrollBlocked', crosslinkScrollBlocked + 1);
                    if (crosslinkScrollBlocked === 0 && !disabledScroll) {
                        parent.data('originalOverflow', parent.css('overflow'));
                        parent.data('disabledScroll', true);
                        parent.css('overflow', 'hidden');
                    }
                });
            }

            function enableParentScrolling() {
                if (scrollParents === null) {
                    return;
                }
                scrollParents.forEach(function (parent) {
                    const crosslinkScrollBlocked = Math.max(
                        parent.data('crosslinkScrollBlocked') - 1,
                        0
                    );
                    parent.data('crosslinkScrollBlocked', crosslinkScrollBlocked);
                    if (crosslinkScrollBlocked === 0) {
                        const originalOverflow = parent.data('originalOverflow');
                        parent.data('disabledScroll', false);
                        parent.css('overflow', originalOverflow);
                    }
                });
            }

            function testAndSetMenuDirection(open) {
                $ctrl.popUp = false;
                if (open) {
                    if ($ctrl.dropdownAppendToBody) {
                        $timeout(() => {
                            if (!dropdownMenuElement || dropdownMenuElement.length === 0) {
                                dropdownMenuElement = document.querySelector(
                                    'body > .cross-link-menu-container .dropdown-menu'
                                );
                            }
                            if (dropdownMenuElement) {
                                $ctrl.popUp =
                                    innerHeight <
                                    dropdownMenuElement.getBoundingClientRect().bottom;
                                $ctrl.isSettingDirection = false;
                            }
                        });
                    } else {
                        $ctrl.isSettingDirection = false;
                    }
                }
            }

            function watchScroll(element, callback) {
                element.data('lastKnownScrollPos', element.scrollTop()).on('scroll', callback);
            }

            function unwatchScroll(element, callback) {
                element.removeData('lastKnownScrollPos').off('scroll', callback);
            }

            function dropdownToggle(open) {
                if (
                    scrollParents === null &&
                    ($ctrl.scrollAutoClose || $ctrl.dropdownAppendToBody)
                ) {
                    // DOM might take time to actually render. Assign scrollParents here.
                    // This function will only be called by user interaction that is when everything is perfectly rendered
                    scrollParents = chartDisplayUtils.getScrollableParents($element);
                }

                if (open) {
                    $ctrl.haveOpened = true;
                    if (scrollParents && $ctrl.scrollAutoClose) {
                        scrollParents.forEach((parent) =>
                            watchScroll(parent, closeDropdownOnDomEvent)
                        );
                    } else if ($ctrl.dropdownAppendToBody) {
                        disableParentScrolling();
                    }
                } else {
                    if (scrollParents && $ctrl.scrollAutoClose) {
                        scrollParents.forEach((parent) =>
                            unwatchScroll(parent, closeDropdownOnDomEvent)
                        );
                    } else if ($ctrl.dropdownAppendToBody) {
                        enableParentScrolling();
                    }
                }

                testAndSetMenuDirection(open);
                if ($ctrl.onToggle) {
                    $ctrl.onToggle(open, $element);
                }
            }

            $ctrl.$onDestroy = () => {
                if ($ctrl.scrollAutoClose && scrollParents) {
                    scrollParents.forEach((parent) =>
                        unwatchScroll(parent, closeDropdownOnDomEvent)
                    );
                } else if ($ctrl.dropdownAppendToBody) {
                    enableParentScrolling();
                }

                unregisterRouteWatchGroup();
                document.body.classList.remove(bodyClassname);
            };

            $ctrl.getInternalTargetUrl = (dim, target) => {
                const internalLinkType =
                    target.internalLinkType === CROSS_LINK_INTERNAL_TYPES.MATCH_ALL_LINK
                        ? CROSS_LINK_INTERNAL_TYPES.PROPERTY_LINK
                        : target.internalLinkType;
                const internalLinkProps = dim.targets.internal[internalLinkType];
                return crossLinkUtils.getRedirectHref(internalLinkProps, dim.propertyValue, target);
            };

            $ctrl.setTargetUrl = (generator, target, dimension) => {
                const timeSpan =
                    $ctrl.selectedTimeRange ||
                    $ctrl.pinnedTime ||
                    urlOverridesService.getGlobalTimePicker();

                const properties = crossLinkDataService.getExtendedContext(
                    $ctrl.dimensions,
                    target.aliases,
                    urlFiltersContext,
                    $ctrl.context
                );

                const URL = generator(target.target, target.targetMap, dimension, timeSpan, {
                    ...target.contextData,
                    properties,
                });

                target.URL = linkProtocol.test(URL) ? URL : '//' + URL;
            };

            $ctrl.configureCrossNavigation = (dimension) => {
                if (crossLinkDataService.hasSetContext()) {
                    const extendedContext = crossLinkDataService.getExtendedContext(
                        $ctrl.dimensions,
                        null,
                        urlFiltersContext,
                        $ctrl.context
                    );
                    $rootScope.$broadcast(CROSS_LINK_EVENTS.OPEN_CONFIG_MODAL, {
                        propValPair: {
                            propertyName: dimension.propertyName,
                            propertyValue: dimension.propertyValue,
                        },
                        context: extendedContext.properties,
                    });
                } else {
                    crossLinkUtils
                        .getGlobalDataLink({
                            propertyName: dimension.propertyName,
                            propertyValue: dimension.propertyValue,
                        })
                        .then((url) => $location.url(url));
                }
            };

            // Bootstraps on-toggle callback misses callbacks when opening different dropdown while last is still open
            $scope.$watch('$ctrl.dropDownIsOpen', (newVal, oldVal) => {
                if (newVal !== undefined && oldVal !== undefined) {
                    dropdownToggle(newVal);
                }
            });

            $rootScope.$on('theme update', (event, isNewThemeDark) => {
                if (isNewThemeDark !== $ctrl.isDarkTheme) {
                    $ctrl.isDarkTheme = isNewThemeDark;
                }
            });
        },
    ],
};
