import { Capability } from '@splunk/olly-services/lib/services/CurrentUser/Capabilities';
import { wrapPromiseInQueryReporter } from '../utils/instrumentationUtils';

const STATIC_SEARCH_TYPE = 'static';

export const staticQuickActionsDataProvider = [
    'currentUser',
    '$filter',
    '$q',
    'integrationsListService',
    'themeService',
    '$rootScope',
    'detectorWizardModal',
    'featureEnabled',
    'PRODUCT_NAME',
    'hasCapability',
    'iconUtils',
    function (
        currentUser,
        $filter,
        $q,
        integrationsListService,
        themeService,
        $rootScope,
        detectorWizardModal,
        featureEnabled,
        PRODUCT_NAME,
        hasCapability,
        iconUtils
    ) {
        const availableThemes = themeService.getThemes();

        const provider = function (key, value, queryReporter) {
            const staticActionsPromise = $q.all({
                orgId: currentUser.orgId(),
                isAdmin: currentUser.isAdmin(),
            });

            return wrapPromiseInQueryReporter(
                staticActionsPromise
                    .then((result) => generateStaticActions(result, key, value))
                    .then((actions) => filterActions(actions, key, value)),
                queryReporter,
                STATIC_SEARCH_TYPE
            );
        };

        provider.getEnabledSearchTypes = () => [STATIC_SEARCH_TYPE];

        return provider;

        async function generateStaticActions(result, key, value) {
            const actions = [
                ...(await getNavigationActions(result.orgId)),
                ...(await getBaseActions()),
                ...(await getAdminActions(result.orgId, result.isAdmin)),
                ...(await getIntegrationActions()),
                ...getHelpActions(),
            ];

            const metricSearchAction = getMetricSearchAction(result.orgId, key, value);
            if (metricSearchAction) {
                actions.unshift(metricSearchAction);
            }

            actions.forEach(assignStaticImageToItem);

            return actions;
        }

        function getMetricSearchAction(orgId, key, value) {
            // when there is no key or the key is a metric type, show special link to perform metric search
            if ((!key || key === 'metric') && value && value !== 'metric') {
                const metricLink = `#/metrics/?query=${value}`;
                return {
                    label: `Search metrics for "${value}"`,
                    type: 'Metric Search',
                    href: metricLink,
                    customClass: 'with-top-border',
                };
            }
        }

        function assignStaticImageToItem(item) {
            if (iconUtils.hasIcon(item.type)) {
                item.img = iconUtils.getIconURL(item.type);
            }
        }

        function filterActions(actions, key, value) {
            return $filter('filter')(actions, function (item) {
                return (
                    item.aliases?.some(function (name) {
                        return name.toLowerCase() === value.toLowerCase();
                    }) || item.label.toLowerCase().includes(value.toLowerCase())
                );
            }).filter(function (item) {
                if (key && !item.type.toLowerCase().includes(key.toLowerCase())) {
                    return false;
                }

                return !item.enabled || item.enabled();
            });
        }

        async function getAdminActions(orgId, isAdmin) {
            const showInviteUsers = featureEnabled('newRbacExperience')
                ? await hasCapability(Capability.ASSIGN_ROLE)
                : isAdmin;

            const showAccessTokens = featureEnabled('newRbacExperience')
                ? await hasCapability(Capability.READ_NAMEDTOKEN)
                : isAdmin;

            const showSubscriptionUsage = featureEnabled('newRbacExperience')
                ? await hasCapability(Capability.READ_USAGE)
                : isAdmin;

            return [
                {
                    label: 'Invite Users',
                    href: '#/users',
                    enabled: () => showInviteUsers,
                },
                {
                    label: 'Access Tokens',
                    href: '#/organization/' + orgId + '?selectedKeyValue=sf_section:accesstokens',
                    enabled: () => showAccessTokens,
                },
                {
                    label: 'Subscription Usage',
                    href: '#/organization/' + orgId + '?selectedKeyValue=sf_section:immusage',
                    enabled: () => showSubscriptionUsage,
                },
            ]
                .map(function (item) {
                    item.type = 'Administration';
                    return item;
                })
                .filter(function (item) {
                    return item.label !== 'Subscription Usage' || featureEnabled('infra');
                });
        }

        async function getBaseActions() {
            const baseActions = [
                {
                    label: 'Switch to Automatic Theme',
                    action() {
                        themeService.setTheme(availableThemes.AUTO_THEME);
                    },
                    enabled() {
                        return themeService.getTheme() !== availableThemes.AUTO_THEME;
                    },
                },
                {
                    label: 'Switch to Dark Theme',
                    action() {
                        themeService.setTheme(availableThemes.DARK_THEME);
                    },
                    enabled() {
                        return themeService.getTheme() !== availableThemes.DARK_THEME;
                    },
                },
                {
                    label: 'Switch to Light Theme',
                    action() {
                        themeService.setTheme(availableThemes.LIGHT_THEME);
                    },
                    enabled() {
                        return themeService.getTheme() !== availableThemes.LIGHT_THEME;
                    },
                },
            ];

            const newBaseActions = [...baseActions, ...(await getUserSpecificActions())];

            newBaseActions.forEach((item) => (item.type = 'Action'));

            return newBaseActions;
        }

        async function getUserSpecificActions() {
            const actions = [];

            if (await hasCapability(Capability.CREATE_DASHBOARD)) {
                actions.push(
                    {
                        label: 'Create New Dashboard',
                        action() {
                            $rootScope.$broadcast('create a dashboard');
                        },
                    },
                    {
                        label: 'Create New Dashboard Group',
                        action() {
                            $rootScope.$broadcast('create a dashboard group');
                        },
                    }
                );
            }

            if (await hasCapability(Capability.CREATE_DETECTOR)) {
                actions.push({
                    label: 'Create New Detector',
                    action: detectorWizardModal.newDetector,
                    type: 'Action',
                });
            }

            return actions;
        }

        /* TODO: Needs Refactor.
         * Move this along with quick-actions out to shared unified services (signalview-services ??).
         * Setup a bucket based list of navigation items.
         * The idea is that each bucket can collect individual app specific nav items and
         * then quick actions can search off of that.
         *
         * Without the refactor above,
         * this will not be a complete list of possible navigation items and other quick action items.
         */
        async function getNavigationActions(orgId) {
            const actions = await getNavigationItems(orgId);

            actions.forEach((item) => (item.type = 'Navigation'));
            return actions;
        }

        async function getNavigationItems(orgId) {
            const newRbacCustomRole = featureEnabled('newRbacCustomRole');

            const apmAccess = featureEnabled('apm2');

            const showUsers = await hasCapability(Capability.READ_ORG_USER);
            const showGlobalDataLinks = await hasCapability(Capability.READ_GLOBAL_DATA_LINK);
            const hasIntegrationsCapability = await hasCapability(Capability.READ_INTEGRATION);

            const showAccessTokens = await hasCapability(Capability.READ_NAMEDTOKEN);
            const showTeams = await hasCapability(Capability.READ_TEAM);
            const navigationItems = [
                {
                    label: 'APM',
                    href: '#/apm',
                },
                {
                    label: 'Infrastructure',
                    href: '#/infra',
                },
                {
                    label: 'Hosts',
                    href: '#/infra/entity/datacenter%20hosts',
                },
                {
                    label: 'Log Observer',
                    href: '#/logs',
                },
                {
                    label: 'RUM',
                    href: '#/rum',
                },
                {
                    label: 'Dashboards',
                    href: '#/dashboards/' + orgId,
                },
                {
                    label: 'Alerts',
                    href: '#/alerts/' + orgId,
                },
                {
                    label: 'Detectors',
                    href: '#/detectors/' + orgId,
                },
                {
                    label: 'Detector Muting Rules',
                    href: '#/muting/' + orgId,
                },
                {
                    label: 'Integrations',
                    href: '#/integrations',
                    enabled: () => hasIntegrationsCapability,
                },
                {
                    label: 'Data Setup',
                    href: '#/integrations',
                    enabled: () => hasIntegrationsCapability,
                },
                {
                    label: 'Metric Finder',
                    href: '#/metrics/' + orgId,
                },
                {
                    label: 'Metric Metadata',
                    href: '#/catalog',
                },
                {
                    label: 'Access Tokens',
                    href: '#/organization/current?selectedKeyValue=sf_section:accesstokens',
                    enabled: () => showAccessTokens,
                },
                {
                    label: 'Global Data Links',
                    href: '#/organization/current?selectedKeyValue=sf_section:globaldatalinks',
                    enabled: () => showGlobalDataLinks,
                },
                {
                    label: 'Teams',
                    href: '#/organization/current?selectedKeyValue=sf_section:teams',
                    enabled: () => showTeams,
                },
                {
                    label: 'Users',
                    href: '#/users',
                    enabled: () => showUsers,
                },
                {
                    label: 'Configuration',
                    href: '#/data-management',
                    enabled: () => hasIntegrationsCapability,
                },
                {
                    label: 'Data Management',
                    href: '#/data-management',
                    enabled: () => hasIntegrationsCapability,
                },
            ];

            if (
                newRbacCustomRole &&
                (await (hasCapability(Capability.CREATE_ROLE) ||
                    hasCapability(Capability.UPDATE_ROLE) ||
                    hasCapability(Capability.DELETE_ROLE)))
            ) {
                navigationItems.push(
                    {
                        label: 'Custom roles',
                        href: '#/roles',
                    },
                    {
                        label: 'Capabilities',
                        href: '#/roles',
                    },
                    {
                        label: 'Permissions',
                        href: '#/roles',
                    }
                );
            }

            if (apmAccess) {
                navigationItems.push(
                    {
                        label: 'Trace Analyzer',
                        href: '#/apm/traces',
                        aliases: ['traces'],
                    },
                    {
                        label: 'Tag Spotlight',
                        href: '#/apm/analysis?analyze=errors',
                    },
                    {
                        label: 'Service Map',
                        href: '#/apm/troubleshooting',
                    },
                    {
                        label: 'Workflow',
                        href: '#/apm?mode=workflows',
                    }
                );
            }

            return navigationItems;
        }

        async function getIntegrationActions() {
            let allIntegrationActions = [];
            try {
                const allIntegrations = integrationsListService.getAllIntegrationsForSearch();
                const showIntegrations = await hasCapability(Capability.READ_INTEGRATION);
                const activeIntegrations = await integrationsListService.getActiveIntegrations();

                allIntegrationActions = allIntegrations.map((integration) => {
                    const hasIntegrationBeenSetUp = integration.credType
                        ? activeIntegrations?.[integration.credType]
                        : undefined;
                    const redirectPath = integrationsListService.getIntegrationRedirectPath(
                        integration,
                        hasIntegrationBeenSetUp
                    );
                    return {
                        label: integration.title,
                        href: redirectPath,
                        type: 'Integration',
                        img: integration.logo,
                        enabled: () => showIntegrations,
                    };
                });
            } catch (error) {
                console.error(error);
            } finally {
                return allIntegrationActions;
            }
        }

        function getHelpActions() {
            return [
                {
                    label: 'Built-in dashboards',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=builtindashboards&version=current',
                },
                {
                    label: 'Charts',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=charts&version=current',
                },
                {
                    label: 'Configure single sign-on using Google',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=configure.googlesignin&version=current',
                },
                {
                    label: 'Configure single sign-on using ADFS',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=configure.adfs&version=current',
                },
                {
                    label: 'Configure single sign-on using Okta',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=sso.okta&version=current',
                },
                {
                    label: 'Configure single sign-on using OneLogin',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=sso.onelogin&version=current',
                },
                {
                    label: 'Configure single sign-on using PingOne',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=configure.pingone&version=current',
                },
                {
                    label: 'Dashboards',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=dashboards&version=current',
                },
                {
                    label: 'Detectors and alerts',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=detectorsandalerts&version=current',
                },
                {
                    label: 'Create simple charts',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=simplecharts&version=current',
                },
                {
                    label: 'Finding metrics and events',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=findmetricsevents&version=current',
                },
                {
                    label: 'Gaining Insight Through Analytics',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=data.visualization.chart.analytics&version=current',
                },
                {
                    label: 'Graphite Options for Plots',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=chart.advanced.config.graphite.plot&version=current',
                },
                {
                    label: 'Hosts Page',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=infrastructure.monitor.hosts&version=current',
                },
                {
                    label: 'Using Navigators',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=infrastructure.use.navigators&version=current',
                },
                {
                    label: 'Integrate with PagerDuty',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrate.pagerduty&version=current',
                },
                {
                    label: 'Integrate with Slack',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrate.slack&version=current',
                },
                {
                    label: 'Integrate with Splunk On-Call',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrate.splunkoncall&version=current',
                },
                {
                    label: 'Integrations Guide',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrate.splunkoncall&version=current',
                },
                {
                    label: 'Metric Types',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=metrictypes.learnmore&version=current',
                },
                {
                    label: 'New Relic Options for Plots (unavailable)',
                    href: 'https://docs.splunk.com/observability/en/gdi/get-data-in/integrations.html',
                },
                {
                    label: 'Plotting Metrics and Events',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=learnmore.chartbuilder&version=current',
                },
                {
                    label: 'Recommended Detectors',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=new.built.in&version=current',
                },
                {
                    label: 'Set Up Detectors to Trigger Alerts',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=learnmore.new.detector&version=current',
                },
                {
                    label: `${PRODUCT_NAME} API Overview`,
                    href: 'https://developers.signalfx.com/',
                },
                {
                    label: 'Understanding SignalFlow analytics',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=learnmore.signalflow.analytics&version=current',
                },
                {
                    label: 'Using the Catalog to Find Metrics',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=new.chart.config&version=current',
                },
                {
                    label: 'Using Wildcards with New Relic metrics',
                    href: 'hhttps://quickdraw.splunk.com/redirect/?product=Observability&location=new.chart.config&version=current',
                },
                {
                    label: 'View Additional Data with Events',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=new.detect.alert&version=current',
                },
                {
                    label: 'View Alerts',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=learnmore.view.alerts&version=current',
                },
                {
                    label: 'Manage Alert Notifications',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=learnmore.managenotification&version=current',
                },
                {
                    label: '15-Minute Quick Start',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=learnmore.getstarted&version=current',
                },
                {
                    label: 'About Detectors and Alerts',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=detectors.and.alerts&version=current',
                },
                {
                    label: 'Adding Information to a Dashboard',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=ui.addinfo.dashboard&version=current',
                },
                {
                    label: 'Admin Guide',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=ui.admin&version=current',
                },
                {
                    label: 'Advanced Chart Options',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=ui.createcharts&version=current',
                },
                {
                    label: 'Analytics Reference Guide',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=ui.signalflow&version=current',
                },
                {
                    label: 'Basic Chart Configuration Options',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=simplecharts&version=current',
                },
                {
                    label: 'Best Practices',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=ui.bestpractices&version=current',
                },
                {
                    label: 'Better Dashboards',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=ui.dashboard.bestpractice&version=current',
                },
                {
                    label: 'Chart Terminology Quick Reference',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=ui.chartterms&version=current',
                },
                {
                    label: 'Collect Windows Metrics',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=ui.windows&version=current',
                },
                {
                    label: `Connect ${PRODUCT_NAME} to Other Monitoring Systems`,
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integrations.existing.info&version=current',
                },
                {
                    label: 'Search the Metric Finder and Metadata catalog',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=metrics.finder.metadata.catalog&version=current',
                },
                {
                    label: 'Creating and Sharing Dashboards',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=create.share.mirror.dashboards&version=current',
                },
                {
                    label: 'Creating charts or event feeds',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=create.simple.chart.event.feeds&version=current',
                },
                {
                    label: 'Dashboard Basics',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=dashboard.basics&version=current',
                },
                {
                    label: 'Data Model',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=metrics.datatypes&version=current',
                },
                {
                    label: 'Getting Around the User Interface',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=userhome.getstarted&version=current',
                },
                {
                    label: 'Glossary',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=learnmore.glossary&version=current',
                },
                {
                    label: 'Infrastructure Navigator',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=use.navigators&version=current',
                },
                {
                    label: `How ${PRODUCT_NAME} Chooses Data Resolution`,
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=datavisualization.charts.rollup.effects&version=current',
                },
                {
                    label: 'Integrate with Notification Services',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=admin.alerts.thirdparty&version=current',
                },
                {
                    label: 'Manage Users and Organizations',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=admin.manageusers&version=current',
                },
                {
                    label: 'Metrics Metadata',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=metrics.metricslanding&version=current',
                },
                {
                    label: 'Monitor Amazon Web Services (AWS)',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=infrastructure.monitor.aws&version=current',
                },
                {
                    label: 'Monitoring Elastic Applications',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=advanced.metrics&version=current',
                },
                {
                    label: 'Naming Conventions',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=metrics.metricnames&version=current',
                },
                {
                    label: 'Planning and Creating Charts',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=ui.createcharts&version=current',
                },
                {
                    label: 'Plot Configuration Options',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=datavisualization.charts.chartbuilder&version=current',
                },
                {
                    label: 'Rollups and Resolutions',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=datavisualization.charts.datares&version=current',
                },
                {
                    label: 'Saving a new dashboard',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=datavisualization.charts.clearcharts&version=current',
                },
                {
                    label: 'Send Custom Metrics',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=gdi.otheringest.custom&version=current',
                },
                {
                    label: `Send Data to ${PRODUCT_NAME}`,
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=gdi.connect&version=current',
                },
                {
                    label: 'Sharing a dashboard or chart',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=datavisualization.charts.clearcharts&version=current',
                },
                {
                    label: 'SignalFlow Query Language',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=analytics.signalflowquery&version=current',
                },
                {
                    label: `${PRODUCT_NAME} Analytics Overview`,
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=analytics.signalflow.intro&version=current',
                },
                {
                    label: 'Specifying Dashboard Settings',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=datavisualization.dashboards.view&version=current',
                },
                {
                    label: `Use Log Data in ${PRODUCT_NAME}`,
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=log.observer.connect.intro&version=current',
                },
                {
                    label: `Use the ${PRODUCT_NAME} collectd Agent`,
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=integration.collectd&version=current',
                },
                {
                    label: 'View Subscription Usage Information',
                    href: 'https://quickdraw.splunk.com/redirect/?product=Observability&location=subscription.usage.overview&version=current',
                },
            ].map(function (item) {
                item.type = 'Help';
                item.target = '_blank';
                return item;
            });
        }
    },
];
