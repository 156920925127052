angular
    .module('signalview.typeahead')

    .controller('TypeaheadDropDownController', [
        '$log',
        '$scope',
        '$timeout',
        'userAnalytics',
        '$element',
        '$q',
        function ($log, $scope, $timeout, userAnalytics, $element, $q) {
            let resultSetNumber = 0;
            let getSuggestionsDebouncePromise;
            let clearResultsTimer;
            $scope.inFocus = false;
            $scope.fetching = false;

            function getCurrentSelectionResults(searchTerm) {
                if ($scope.getValues) {
                    $scope.results = [];
                    const original = $scope.getValues();
                    if (original) {
                        const lowerCaseInput = (searchTerm || '').toLowerCase();
                        original.propertyValue
                            .filter(function (v) {
                                return v.toLowerCase().indexOf(lowerCaseInput) !== -1;
                            })
                            .forEach(function (v) {
                                $scope.results.push(
                                    angular.extend(angular.copy(original), {
                                        propertyValue: v,
                                        value: v,
                                        checkable: true,
                                    })
                                );
                            });
                        if ($scope.onResults) $scope.onResults();
                    }
                } else {
                    $log.error('Tried to get current values but no accessor provided!');
                }
            }

            function trackEventAction(eventAction) {
                if ($scope.eventCategory) {
                    userAnalytics.event($scope.eventCategory, eventAction);
                }
            }

            $scope.getSuggestionsInternal = function (userInput, searchTerm, nextGroup) {
                $scope.fetching = true;
                if (userInput) $scope.inFocus = true;
                $timeout.cancel(clearResultsTimer);
                if (
                    !$scope.tabCompletion &&
                    $scope.lastSelected &&
                    $scope.lastSelected === $scope.typedValue
                ) {
                    $scope.lastSelected = null;
                    $scope.clearSuggestionsInternal();
                    $scope.fetching = false;
                    return;
                }

                if (!searchTerm) {
                    searchTerm = $scope.typedValue;
                }
                resultSetNumber++;
                const expectedResultNumber = resultSetNumber;
                $timeout.cancel(getSuggestionsDebouncePromise);

                if ($scope.showSelectedOnly) {
                    getCurrentSelectionResults(searchTerm);
                    $scope.fetching = false;
                    return;
                }
                getSuggestionsDebouncePromise = $timeout(function () {
                    $scope
                        .getSuggestions(searchTerm, nextGroup)
                        .then(
                            function (results) {
                                if (expectedResultNumber === resultSetNumber) {
                                    $scope.results = results;
                                    let resultsIdx = -1;
                                    if ($scope.typedValue && $scope.typedValue.length) {
                                        const selectedValue = $scope.typedValue.split(':')[1];
                                        resultsIdx = results.findIndex(
                                            (item) =>
                                                item.value === selectedValue && !item.isSynthetic
                                        );
                                    }
                                    if (resultsIdx !== -1) {
                                        // Highlight selected value if it exists in the results
                                        $scope.updateSelectionKey(resultsIdx);
                                    } else if (
                                        (!$scope.typedValue && !$scope.valueSearch) ||
                                        results.length === 0
                                    ) {
                                        $scope.updateSelectionKey(null);
                                    } else {
                                        $scope.updateSelectionKey(0);
                                    }
                                    if ($scope.scrollIntoView)
                                        $scope.scrollIntoView($scope.keySelectionIndex);
                                    if ($scope.onResults) $scope.onResults();
                                } else {
                                    $log.info('Threw away stale result fetch.');
                                }
                            },
                            (reason) => {
                                $log.info('Signalflow suggest failed', reason);
                            }
                        )
                        .finally(function () {
                            if (expectedResultNumber === resultSetNumber) {
                                $scope.fetching = false;
                            }
                        });
                }, 250);
            };

            $scope.consumeEvent = function (e) {
                e.preventDefault();
                e.stopPropagation();
            };

            $scope.keyPressInternal = function (e) {
                const keyCode = e.keyCode;

                if ($scope.tabCompletion && keyCode === 9) {
                    if (!$scope.results || !$scope.results.length) {
                        $scope.getSuggestionsInternal();
                    } else {
                        // Select current item, then show suggestions in next group/position
                        const item = $scope.results[$scope.keySelectionIndex];
                        $scope.itemSelectedInternal(item);
                        $scope.getSuggestionsInternal(false, item.fullName, true);
                    }
                    $scope.consumeEvent(e);
                    trackEventAction('tab-completion-triggered');
                    return;
                }

                if ($scope.keySelectionIndex !== null && (keyCode === 13 || keyCode === 9)) {
                    //what if pending request?
                    $scope.itemSelectedInternal($scope.results[$scope.keySelectionIndex]);
                    $scope.consumeEvent(e);
                } else if (keyCode === 38) {
                    $scope.consumeEvent(e);
                    //up
                    if ($scope.results && $scope.results.length > 0) {
                        if ($scope.keySelectionIndex === null) {
                            $scope.updateSelectionKey($scope.results.length - 1);
                        } else {
                            $scope.updateSelectionKey($scope.keySelectionIndex - 1);
                            if ($scope.keySelectionIndex < 0) {
                                $scope.updateSelectionKey($scope.results.length - 1);
                            }
                        }
                    } else {
                        $scope.updateSelectionKey(0);
                    }
                    if ($scope.scrollIntoView) $scope.scrollIntoView($scope.keySelectionIndex);
                } else if ($scope.keySelectionIndex === null && keyCode === 13) {
                    // enter with no dropDown
                    if ($scope.onEnter) {
                        $scope.onEnter($scope.typedValue);
                    }
                } else if (keyCode === 40) {
                    $scope.consumeEvent(e);
                    //down
                    if ($scope.results && $scope.results.length > 0) {
                        if ($scope.keySelectionIndex === null) {
                            $scope.updateSelectionKey(0);
                        } else {
                            $scope.updateSelectionKey($scope.keySelectionIndex + 1);
                            if ($scope.keySelectionIndex > $scope.results.length - 1) {
                                $scope.updateSelectionKey(
                                    $scope.keySelectionIndex % $scope.results.length
                                );
                            }
                        }
                    }
                    if ($scope.scrollIntoView) $scope.scrollIntoView($scope.keySelectionIndex);
                } else if (keyCode === 27) {
                    //esc
                    if ($scope.reset) $scope.reset();
                }
            };

            $scope.updateSelectionKey = function (k) {
                $scope.keySelectionIndex = k;
            };

            function clearSuggestions() {
                resultSetNumber++;
                $scope.fetching = false;
                $scope.updateSelectionKey(null);
                $scope.results = null;
                if ($scope.cleanup) $scope.cleanup();
                if (!$scope.inFocus && $scope.reset && !hasValidEmptyValue()) {
                    $scope.reset();
                }
            }

            function hasValidEmptyValue() {
                return $scope.allowEmptyValue && $scope.typedValue === '';
            }

            $scope.clearSuggestionsInternal = function (userInput, immediate) {
                if (userInput) $scope.inFocus = false;
                if (immediate) {
                    clearSuggestions();
                    return $q.resolve();
                } else {
                    // in the event we blur, purge results after some time
                    // this is necessary because we don't know at time of
                    // blur event if focus will at some point re-shift o the input we
                    // are blurring on
                    return (clearResultsTimer = $timeout(clearSuggestions, 200));
                }
            };

            $scope.itemSelectedInternal = function (itm) {
                $scope.lastSelected = itm;
                $scope.onItemSelected(itm, $element);
                if (!$scope.keepTypedValue) {
                    $scope.typedValue = '';
                }
                if ($scope.cleanup) {
                    $scope.cleanup();
                }
                $scope.updateSelectionKey(null);
                if (!$scope.skipRefocus) {
                    $scope.refocusInput();
                }
                if ($scope.tabCompletion && itm.fullName) {
                    clearSuggestions();
                    $timeout(function () {
                        $scope.scrollToPosition(itm.fullName.length);
                    }, 0);
                    trackEventAction('tab-completion-selected');
                }
            };

            $scope.$watch('typedValue', function () {
                if (
                    $scope.skipAutoFocus &&
                    !$scope.inFocus &&
                    (!$scope.tabCompletion || !$scope.results || !$scope.results.length)
                ) {
                    return;
                }
                $scope.getSuggestionsInternal();
            });

            $scope.$on('setViewValue', function (event, modelChangeType, modelChangeValue) {
                if (
                    modelChangeType &&
                    $scope.modelChangeType === modelChangeType &&
                    modelChangeValue !== undefined
                ) {
                    $scope.typedValue = modelChangeValue;
                    $scope.refocusInput();
                }
            });
        },
    ]);
