import { UserQueryReporter } from '@splunk/olly-tracing/UserQueryReporter';

export const wrapPromiseInQueryReporter = <T>(
    promise: Promise<T>,
    queryReporter: UserQueryReporter,
    sectionName: string
): Promise<T> => {
    queryReporter.reportSectionLoading(sectionName);
    queryReporter.reportRenderEnd();

    return promise
        .then((result) => {
            queryReporter.reportSectionData(sectionName);
            return result;
        })
        .catch((e) => {
            queryReporter.reportSectionError(sectionName, e?.message);
            return Promise.reject(e);
        })
        .finally(() => queryReporter.reportRenderEnd());
};
