import { modifyTemplateData } from '@splunk/olly-services/lib';

angular.module('signalview.heatmap').service('createHeatmap', [
    '$log',
    '$rootScope',
    '_',
    'urlOverridesService',
    'sourceFilterService',
    'HEATMAP_THRESHOLD',
    'INACTIVE_HOST_DURATIONS',
    'navigatorModes',
    'mustache',
    'timepickerUtils',
    function (
        $log,
        $rootScope,
        _,
        urlOverridesService,
        sourceFilterService,
        HEATMAP_THRESHOLD,
        INACTIVE_HOST_DURATIONS,
        navigatorModes,
        mustache,
        timepickerUtils
    ) {
        function create() {
            const HEATMAP_VERY_LARGE_LIMIT = 1500;
            const HEATMAP_TIME_RANGE_DEFAULT = '-1h';
            const HEATMAP_TIME_RANGES = [
                {
                    value: '-5m',
                    displayName: 'Past 5 Minutes',
                },
                {
                    value: '-15m',
                    displayName: 'Past 15 Minutes',
                },
                {
                    value: '-1h',
                    displayName: 'Past 1 Hour',
                },
                {
                    value: '-12h',
                    displayName: 'Past 12 Hours',
                },
                {
                    value: '-1d',
                    displayName: 'Past Day',
                },
                {
                    value: '-1w',
                    displayName: 'Past Week',
                },
                {
                    value: '-31d',
                    displayName: 'Past Month',
                },
            ];
            // Default time range in Olly is defined in timeUtils.ts in Olly project.
            const OLLY_DEFAULT_TIME_RANGE = '-15m';

            const timeRangeDisplayNames = {};
            HEATMAP_TIME_RANGES.forEach(function (item) {
                timeRangeDisplayNames[item.value] = item.displayName;
            });

            let selection = null,
                highlighted,
                groupBy = [],
                groupBySelection,
                groupByDepth = 0,
                firstLevelGroups = {},
                secondLevelGroups = {},
                inactiveHostDuration = INACTIVE_HOST_DURATIONS.AUTO,
                colorBy,
                filterBy = [],
                threshold,
                timeRange,
                nodes,
                coloringFunction,
                hideDeadHosts = true,
                hideNulls = false,
                mode,
                modes = [],
                tab,
                hasDataValues = false,
                disableUrlSetters = false,
                customTimeRange = null;

            let dirty = false;

            function updateFilterUrl(sources) {
                if (disableUrlSetters) return;

                sources = sources || [];

                urlOverridesService.setSourceOverride(
                    sources.map(function (source) {
                        if (source.property && source.propertyValue) {
                            const notPrefix = source.NOT ? '!' : '';
                            return (
                                notPrefix +
                                source.property +
                                ':' +
                                sourceFilterService.flattenPropertyValue(source.propertyValue)
                            );
                        } else {
                            return source.query;
                        }
                    })
                );
            }

            const api = {};

            const scope = $rootScope.$new();

            api.on = scope.$on.bind(scope);

            api.getId = function (metadata) {
                if (!metadata) return null;

                const { idTemplateModified, modifiedData } = modifyTemplateData(
                    mode.idTemplate,
                    metadata
                );

                return mustache.render(idTemplateModified, modifiedData);
            };

            api.getIdDisplayName = function (metadata) {
                if (!metadata || !mode.idDisplayName) return null;

                const { idTemplateModified, modifiedData } = modifyTemplateData(
                    mode.idDisplayName,
                    metadata
                );

                return mustache.render(idTemplateModified, modifiedData);
            };

            api.getNodes = function () {
                return nodes;
            };

            // Determines when some of the UI systems should optimize for performance
            api.isVeryLarge = function () {
                if (!nodes) {
                    return false;
                }

                let visibleNodes = nodes;
                if (hideDeadHosts) {
                    visibleNodes = visibleNodes.filter((node) => !node._isDead);
                }
                if (hideNulls) {
                    visibleNodes = visibleNodes.filter((node) => node.value !== null);
                }

                return visibleNodes.length > HEATMAP_VERY_LARGE_LIMIT;
            };

            api.disableUrlSetters = function (_) {
                if (!arguments.length) {
                    return disableUrlSetters;
                }

                if (disableUrlSetters !== _) {
                    disableUrlSetters = _;
                    scope.$emit('disableUrlSetters updated', disableUrlSetters);
                }

                return api;
            };

            api.selection = function (_) {
                if (!arguments.length) {
                    return selection;
                }

                if (
                    selection !== _ &&
                    ((selection &&
                        _ &&
                        (selection.id !== _.id || (_.name && selection.name !== _.name))) ||
                        !(selection && _))
                ) {
                    selection = _;
                    $log.debug('heatmap selection updated', selection);
                    // Selecting "All" row in list view is equivalent to having no
                    // selection
                    if (mode.type !== 'list' || !selection || selection.id !== mode.displayName) {
                        scope.$emit('selection updated', selection);
                    } else {
                        scope.$emit('selection updated', null);
                    }

                    if (!disableUrlSetters) {
                        urlOverridesService.setMapSelection(selection ? selection.id : null);
                    }
                    dirty = true;
                }

                return api;
            };

            api.highlighted = function (_) {
                if (!arguments.length) {
                    return highlighted;
                }

                if (highlighted !== _) {
                    highlighted = _;
                    //$log.debug('heatmap highlighted updated', highlighted);
                    scope.$emit('highlighted updated', highlighted);
                }

                return api;
            };

            api.tab = function (_) {
                if (!arguments.length) {
                    return tab;
                }

                if (tab !== _) {
                    tab = _;
                    $log.debug('heatmap tab updated', tab);
                    scope.$emit('tab updated', tab);

                    if (!disableUrlSetters) {
                        urlOverridesService.setTab(tab);
                    }

                    dirty = true;
                }

                return api;
            };

            api.modes = function (_) {
                if (!arguments.length) {
                    return angular.copy(modes);
                }

                if (!angular.equals(modes, _)) {
                    modes = _;
                    $log.debug('heatmap modes updated', tab);
                    scope.$emit('modes updated', tab);
                }

                return api;
            };

            // Names of properties by which nodes are being grouped
            api.groupBy = function (_) {
                if (!arguments.length) {
                    return angular.copy(groupBy);
                }

                if (!angular.equals(groupBy, _)) {
                    groupBy = _;
                    $log.debug('heatmap groupBy updated', groupBy);
                    scope.$emit('groupBy updated', angular.copy(groupBy));

                    if (!disableUrlSetters) {
                        urlOverridesService.setGroupBy(groupBy);
                    }
                    dirty = true;
                }

                return api;
            };

            // Values of properties by which nodes are being grouped
            api.groupBySelection = function (_) {
                if (!arguments.length) return angular.copy(groupBySelection);

                if (!angular.equals(groupBySelection, _)) {
                    groupBySelection = _;
                    $log.debug('heatmap groupBySelection updated', groupBySelection);
                    scope.$emit('groupBySelection updated', angular.copy(groupBySelection));
                    if (!disableUrlSetters) {
                        urlOverridesService.setGroupBySelection(groupBySelection);
                    }
                    dirty = true;
                }

                return api;
            };

            api.groupByDepth = function (_) {
                if (!arguments.length) {
                    return groupByDepth;
                }

                if (groupByDepth !== _) {
                    groupByDepth = _;
                    $log.debug('heatmap groupByDepth updated', groupByDepth);
                    scope.$emit('groupByDepth updated', groupByDepth);

                    if (!disableUrlSetters) {
                        urlOverridesService.setOutlierDepth(groupByDepth);
                    }

                    dirty = true;
                }

                return api;
            };

            api.firstLevelGroups = function (_) {
                if (!arguments.length) {
                    return firstLevelGroups;
                }

                if (firstLevelGroups !== _) {
                    firstLevelGroups = _;
                }

                return api;
            };

            api.secondLevelGroups = function (_) {
                if (!arguments.length) {
                    return secondLevelGroups;
                }

                if (secondLevelGroups !== _) {
                    secondLevelGroups = _;
                }

                return api;
            };

            api.colorBy = function (_) {
                if (!arguments.length) {
                    return colorBy;
                }

                if (colorBy !== _) {
                    colorBy = _;
                    $log.debug('heatmap colorBy updated', colorBy);
                    scope.$emit('colorBy updated', colorBy);

                    if (colorBy && colorBy.id) {
                        if (!disableUrlSetters && mode.type !== 'list') {
                            urlOverridesService.setColorBy(colorBy.id);
                        }
                        dirty = true;
                    }
                }

                return api;
            };

            api.filterBy = function (_) {
                if (!arguments.length) {
                    return angular.copy(filterBy);
                }

                if (!angular.equals(filterBy, _)) {
                    filterBy = angular.copy(_);
                    $log.debug('heatmap filterBy updated', filterBy);
                    scope.$emit('filterBy updated', angular.copy(filterBy));
                    updateFilterUrl(filterBy);
                    api.selection(null);
                    api.highlighted(null);
                    dirty = true;
                }

                return api;
            };

            api.customTimeRange = function (_) {
                if (!arguments.length) {
                    return customTimeRange;
                }

                if (!angular.equals(customTimeRange, _)) {
                    customTimeRange = angular.copy(_);
                    $log.debug('heatmap customTimeRange updated', angular.copy(customTimeRange));
                    scope.$emit('customTimeRange updated', customTimeRange);
                    dirty = true;
                }

                return api;
            };

            api.threshold = function (_) {
                if (!arguments.length) {
                    return threshold;
                }

                if (threshold !== _) {
                    threshold = _;
                    $log.debug('heatmap threshold updated', threshold);
                    scope.$emit('threshold updated', threshold);
                    if (!disableUrlSetters && mode.type !== 'list') {
                        urlOverridesService.setOutlierStrategy(threshold.refName);
                    }
                    dirty = true;
                }

                return api;
            };

            api.getTimeRanges = function () {
                return HEATMAP_TIME_RANGES;
            };

            api.getTimeRangeDisplayName = function (timeRange) {
                return timeRangeDisplayNames[timeRange];
            };

            api.timeRange = function (_) {
                if (!arguments.length) {
                    return timeRange;
                }

                if (timeRange !== _) {
                    timeRange = _;
                    $log.debug('heatmap time range updated', timeRange);
                    scope.$emit('timeRange updated', timeRange);
                    if (!disableUrlSetters) {
                        urlOverridesService.setGlobalTimePicker(timeRange, 'Now', true);
                    }
                    dirty = true;
                }

                return api;
            };

            const NO_DATA_COLOR = '#ffffff';
            const DEAD_COLOR = '#444444';
            function wrappedColoringFunction(data) {
                if (data) {
                    if (data._isDead) return DEAD_COLOR;
                    if (data._hasNoData) return NO_DATA_COLOR;
                }

                if (!coloringFunction) {
                    return NO_DATA_COLOR;
                }
                return coloringFunction.call(null, data);
            }

            api.coloringFunction = function (_) {
                if (!arguments.length) return wrappedColoringFunction;

                if (coloringFunction !== _) {
                    coloringFunction = _;
                    $log.debug('heatmap coloringFunction updated', coloringFunction);
                    scope.$emit('coloringFunction updated', coloringFunction);
                }

                return api;
            };

            api.resize = function () {
                scope.$emit('resize heatmap');
            };

            api.mode = function (_) {
                if (!arguments.length) {
                    return mode;
                }

                if (mode !== _) {
                    mode = _;
                    $log.debug('heatmap mode updated', mode);
                    scope.$emit('mode updated', mode);
                }

                return api;
            };

            api.hideNulls = function (_) {
                if (!arguments.length) {
                    return hideNulls;
                }

                if (hideNulls !== _) {
                    hideNulls = _;
                    $log.debug('heatmap hideNulls updated', hideNulls);
                    scope.$emit('hideNulls updated', hideNulls);
                }
            };

            api.hideDeadHosts = function (_) {
                if (!arguments.length) {
                    return hideDeadHosts;
                }

                if (hideDeadHosts !== _) {
                    hideDeadHosts = _;
                    $log.debug('heatmap hideDeadHosts updated', hideDeadHosts);
                    scope.$emit('hideDeadHosts updated', hideDeadHosts);
                }

                return api;
            };

            api.inactiveHostDuration = function (_) {
                if (!arguments.length) {
                    return inactiveHostDuration;
                }

                if (inactiveHostDuration !== _) {
                    inactiveHostDuration = _;
                    $log.debug('heatmap inactiveHostDuration updated', inactiveHostDuration);
                    scope.$emit('inactiveHostDuration updated', inactiveHostDuration);
                }

                return api;
            };

            api.loadStateFromUrl = function () {
                const sourceOverride = urlOverridesService.getSourceOverride();
                if (sourceOverride) {
                    api.filterBy(sourceFilterService.getSourceFilters(sourceOverride));
                } else {
                    api.filterBy([]);
                }

                const tab = urlOverridesService.getTab();
                if (tab) {
                    api.tab(tab);
                }

                const groupBy = urlOverridesService.getGroupBy();
                if (groupBy) {
                    api.groupBy(groupBy);
                } else {
                    api.groupBy([]);
                    if (!disableUrlSetters) {
                        urlOverridesService.setGroupBy([]);
                    }
                }

                const groupBySelection = urlOverridesService.getGroupBySelection();
                if (groupBySelection) {
                    api.groupBySelection(groupBySelection);
                } else {
                    api.groupBySelection(null);
                    if (!disableUrlSetters) {
                        urlOverridesService.setGroupBySelection(null);
                    }
                }

                if (mode.type === 'elemental') {
                    const colorBy = urlOverridesService.getColorBy();
                    if (colorBy) {
                        const colorMode = navigatorModes.forMetric(colorBy);
                        if (colorMode && colorMode.id === mode.id) {
                            const found = mode.metrics.some(function (metric) {
                                if (metric.id === colorBy) {
                                    api.colorBy(metric);
                                    return true;
                                }
                            });

                            if (!found) {
                                api.colorBy(colorMode.metrics[0]);
                            }
                        } else {
                            $log.error('Invalid color by found in url ' + colorBy);
                            api.colorBy(mode.metrics[0]);
                        }
                    } else {
                        api.colorBy(mode.metrics[0]);
                    }
                } else {
                    api.colorBy(null);
                }

                const outlierStrategy = urlOverridesService.getOutlierStrategy();
                if (outlierStrategy) {
                    const threshold = HEATMAP_THRESHOLD.filter(function (opt) {
                        return outlierStrategy === opt.refName;
                    });

                    if (threshold.length === 1) {
                        api.threshold(threshold[0]);
                    } else {
                        $log.info('Choosing 0th threshold item due to default.');
                        api.threshold(HEATMAP_THRESHOLD[0]);
                    }
                } else {
                    api.threshold(HEATMAP_THRESHOLD[0]);
                }

                const outlierDepth = urlOverridesService.getOutlierDepth() || 0;
                api.groupByDepth(outlierDepth);

                if (mode.type === 'list') {
                    const timePicker = urlOverridesService.getGlobalTimePicker();
                    if (timePicker) {
                        const timeRange = timePicker.start;
                        if (timeRangeDisplayNames[timeRange]) {
                            api.timeRange(timeRange);
                        } else {
                            $log.warn(
                                'Invalid time range in URL (' + timeRange + '); selecting default'
                            );
                            api.timeRange(HEATMAP_TIME_RANGE_DEFAULT);
                        }
                    } else {
                        api.timeRange(HEATMAP_TIME_RANGE_DEFAULT);
                    }
                } else {
                    // Custom time range can be given when used by Olly
                    const timePicker = urlOverridesService.getGlobalTimePicker() || {
                        start: OLLY_DEFAULT_TIME_RANGE,
                        end: 'now',
                        relative: true,
                    };
                    const customTimeRange =
                        timepickerUtils.getChartConfigParametersFromURLTimeObject(timePicker);
                    api.customTimeRange(customTimeRange);
                }

                $log.debug('Finished loading state from URL');
                dirty = false;
            };

            // return list of key-value tuples for filters
            api.getDashboardFilters = function (excludeSelectionFilters) {
                const _filterBy = filterBy ? filterBy.slice(0) : [];

                const filters = _filterBy.map(function (filter) {
                    return [(filter.NOT ? '!' : '') + filter.property, filter.propertyValue];
                });

                if (excludeSelectionFilters) {
                    return filters;
                }

                function addGroupFilters(node) {
                    if (!node) return;

                    if (node.name === 'undefined') {
                        filters.push(['!_exists_', node.groupKey]);
                    } else if (node.groupKey) {
                        filters.push([node.groupKey, node.name]);
                    }

                    addGroupFilters(node.parent);
                }

                if (groupBySelection) {
                    const groupBy = api.groupBy();
                    if (mode.alwaysGroupBy && groupBy.indexOf(mode.alwaysGroupBy) === -1) {
                        groupBy.push(mode.alwaysGroupBy);
                    }
                    for (let i = 0; i < groupBySelection.length; i++) {
                        if (groupBySelection[i]) {
                            filters.push([groupBy[i], groupBySelection[i]]);
                        }
                    }
                }

                if (selection) {
                    if (mode.type === 'elemental') {
                        addGroupFilters(selection);
                    }

                    if (selection.sf_key) {
                        const idProperties = mode.idProperties
                            ? mode.idProperties.map((property) => property.id)
                            : [];
                        selection.sf_key.forEach(function (key) {
                            if (
                                [
                                    'jobId',
                                    'sf_originatingMetric',
                                    'sf_metric',
                                    'computationId',
                                ].indexOf(key) !== -1
                            )
                                return;
                            // Ignore identifying properties that were used to join
                            // metadata, to make filtering less strict
                            if (idProperties.indexOf(key) !== -1) return;
                            if (selection !== null) {
                                filters.push([key, selection[key]]);
                            }
                        });
                    } else if (mode.requiredProperties) {
                        mode.requiredProperties.forEach(function (property) {
                            filters.push(['_exists_', property]);
                        });
                    }
                }

                return filters;
            };

            api.reset = function () {
                $log.debug('Resetting heatmap');

                api.filterBy([]);
                api.groupBy([]);
                api.groupBySelection(null);
                api.threshold(HEATMAP_THRESHOLD[0]);
                api.highlighted(null);
                api.selection(null);
                api.tab(null);
                if (mode.type === 'list') {
                    api.timeRange(HEATMAP_TIME_RANGE_DEFAULT);
                }

                dirty = false;

                scope.$emit('reset');
            };

            api.isDirty = function () {
                return dirty;
            };

            api.hasDataValues = function (_) {
                if (!arguments.length) {
                    return hasDataValues;
                }

                if (hasDataValues !== _) {
                    hasDataValues = _;
                }

                return api;
            };

            api.update = _.debounce(function (_) {
                nodes = _;
                scope.$emit('nodes updated', nodes);
            }, 100);

            return api;
        }

        return create;
    },
]);
