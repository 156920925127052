import dashboardIndexTemplateUrl from './dashboardIndex.tpl.html';
import { Dashboards as sitemap } from '../../../app/routing/Sitemaps';

export default {
    bindings: {
        setCustomContent: '<',
        setHierarchicalNav: '<',
    },
    templateUrl: dashboardIndexTemplateUrl,
    controller: [
        '$filter',
        '$interval',
        '$location',
        '$log',
        '$q',
        '$rootScope',
        '$scope',
        '$timeout',
        '$window',
        'DASHBOARD_GROUP_COLUMN_WIDTH',
        'dashboardGroupService',
        'dashboardIndexFormatter',
        'QUERY_MIN_LENGTH',
        'currentUser',
        'pageService',
        'recentPagesService',
        'favoriteService',
        'imageService',
        'initService',
        'signalboost',
        'pageTypeService',
        'favoriteCacheService',
        'featureEnabled',
        'shareableSnapshotService',
        'teamsApiService',
        'teamService',
        'organizationService',
        'dashboardV2Service',
        'userAnalytics',
        'writepermissionsPermissionsChecker',
        function (
            $filter,
            $interval,
            $location,
            $log,
            $q,
            $rootScope,
            $scope,
            $timeout,
            $window,
            DASHBOARD_GROUP_COLUMN_WIDTH,
            dashboardGroupService,
            dashboardIndexFormatter,
            QUERY_MIN_LENGTH,
            currentUser,
            pageService,
            recentPages,
            favoriteService,
            imageService,
            initService,
            signalboost,
            pageTypeService,
            favoriteCacheService,
            featureEnabled,
            shareableSnapshotService,
            teamsApiService,
            teamService,
            organizationService,
            dashboardV2Service,
            userAnalytics,
            writepermissionsPermissionsChecker
        ) {
            const ctrl = this;
            ctrl.$onInit = initialize;
            ctrl.$onDestroy = onDestroy;

            function initialize() {
                ctrl.setHierarchicalNav(sitemap.name, sitemap.IDs.dashboards);
            }

            function onDestroy() {
                ctrl.setHierarchicalNav();
            }

            $scope.isReady = initService.isReady;
            const stopRendering = $interval(function () {
                if (!initService.update()) {
                    $interval.cancel(stopRendering);
                }
            }, 100);

            $scope.$on('$destroy', function () {
                $interval.cancel(stopRendering);
            });

            let teamLoadPromise = $q.when();
            teamLoadPromise = $q
                .all({
                    userTeams: teamService.getUserTeams(),
                    teams: teamsApiService.getTeamSummary(),
                })
                .then(({ userTeams, teams }) => {
                    $scope.userTeams = userTeams || [];
                    $scope.teams = teams.results;
                })
                .catch(() => {
                    $log.warn('Failed loading teams');
                });

            $scope.favoriteService = favoriteService;

            // search input box model
            $scope.query = $location.search().query || '';

            // search string used in searching filtering
            $scope.searchStr = '';

            // TODO: Refactor this. catalogObjectView.js and
            // discoveryDashboard.js share this code.
            const container = angular.element('.dashboard-group-container');

            const scrollToTop = function () {
                container.animate({ scrollTop: 0 }, 300);
            };

            const scrollToHash = function (hash) {
                const res = container.find('#' + hash);
                if (!res || !res.length) return null;
                const hashElem = res[0];
                container.animate({ scrollTop: res.offset().top - 50 }, 750);
                return hashElem;
            };

            const scrollToAnchor = function () {
                const hash = $location.hash();
                if (!hash) {
                    scrollToTop();
                } else {
                    if (!scrollToHash(hash)) {
                        // this handles deep linked urls, need time
                        // for the dashboards to fill out
                        $timeout(function () {
                            scrollToHash(hash);
                        }, 2000);
                    }
                }
            };

            /**
             * Scroll to the element with ID *anchor*.
             *
             * @param {string} anchor - The ID of the element to scroll to.
             */
            $scope.scrollTo = function (anchor) {
                const hash = $location.hash();
                if (hash === anchor) {
                    scrollToAnchor();
                } else {
                    $location.hash(anchor);
                }
            };

            $scope.$on('React:$routeUpdate', scrollToAnchor);
            container.bind('scroll', function () {
                $scope.scrolled = container.scrollTop() > 100;
                $scope.$digest();
            });

            /**
             * Array of objects representing the dashboard group sections on the page.
             */
            $scope.sections = [];

            $scope.searchSections = [];

            /**
             * Mapping from whether or not we are searching to whether or not to expand
             * a section.
             */
            $scope.isSectionVisible = {
                false: {
                    'Built-in': true,
                    Custom: true,
                    User: false,
                },
                true: {},
            };

            $scope.toggleSectionVisibility = function (title) {
                const map = $scope.isSectionVisible[$scope.isSearching];
                map[title] = !map[title];
            };

            /**
             * Arrays of objects representing default dashboard groups section.
             */
            $scope.defaultDashboardGroups = [];

            /**
             * Favorite dashboard list
             */
            const favoriteDashboards = [];

            let dashboardGroupByIdPromise;
            let dashboardByFavoriteKeyPromise;

            /**
             * Add dashboard to favorite list
             */
            function addDashboardToFavorite(key) {
                dashboardByFavoriteKeyPromise.then(function (result) {
                    const dashboard = result[key];
                    if (dashboard) {
                        if (dashboard.sf_page) {
                            dashboard.groupName = dashboard.sf_page;
                        }
                        // skip duplicates
                        // there is a possibility that someone has the same dashboard twice in favorites
                        // persisted with different favoriteKey
                        // since favoriteService has only keys (no dashboard objects) there's no way
                        // to recognize duplicates there
                        if (!favoriteDashboards.find((d) => d.uniqueId === dashboard.uniqueId)) {
                            favoriteDashboards.push(dashboard);
                        }
                    }
                });
            }

            /**
             * Remove dashboard from favorite list
             */
            $rootScope.$on('dashboard favorite remove', function (event, key) {
                let pos = -1;
                for (let i = 0; i < favoriteDashboards.length; i++) {
                    const current = favoriteDashboards[i];
                    const legacyKey = favoriteService.convertToLegacyFavorite(current.favoriteKey);
                    if (current.favoriteKey === key || legacyKey === key) {
                        pos = i;
                        break;
                    }
                }
                if (pos > -1) {
                    favoriteDashboards.splice(pos, 1);
                }
            });

            $rootScope.$on('dashboard favorite add', function (event, dashboard) {
                addDashboardToFavorite(dashboard.favoriteKey);
            });

            const tutorialDashboardNames = {
                'Welcome to SignalFx - Intro': true,
                'Welcome to SignalFx - Part 1': true,
                'Welcome to SignalFx - Part 2': true,
                'Welcome to SignalFx - Part 3': true,
                'Welcome to SignalFx - Samples': true,
            };

            /**
             * @param dashboardGroup - A formatted dashboard group.
             */
            function isTutorialDashboard(dashboardGroup, dashboard) {
                return (
                    dashboardGroup.type === 'User' &&
                    tutorialDashboardNames.hasOwnProperty(dashboard.name)
                );
            }

            /**
             * Initializing all data
             */
            function init() {
                $scope.loading = true;
                $scope.fetchError = false;
                $scope.currentUser = currentUser;
                dashboardGroupByIdPromise = $q
                    .all({
                        teamLoadPromise,
                        dashboardGroups: dashboardGroupService
                            .getAllGroupsForIndex()
                            .then(
                                writepermissionsPermissionsChecker.extendDashboardGroupsWithWritePermissions
                            ),
                        dashboards: dashboardV2Service.searchCacheForIndex(),
                        currentOrg: organizationService.get(),
                    })
                    .then(
                        function (result) {
                            const dashboardGroupById = {};
                            result.dashboardGroups.forEach(function (dashboardGroup) {
                                const v1Group = dashboardGroupService.convertToV1(dashboardGroup);
                                dashboardGroupById[dashboardGroup.id] = v1Group;
                            });
                            const dashboards = result.dashboards.results;
                            const dashboardsByGroupId = pageService.collectDashboardsByGroupId(
                                dashboardGroupById,
                                dashboards
                            );
                            angular.forEach(dashboardsByGroupId, function (dashboards, id) {
                                dashboardGroupById[id].dashboards = dashboards;
                            });

                            const currentOrg = result.currentOrg;

                            if (currentOrg.sampleDashboardGroupId) {
                                const sampleDashboardGroup =
                                    dashboardGroupById[currentOrg.sampleDashboardGroupId];
                                if (sampleDashboardGroup) {
                                    sampleDashboardGroup.sf_serviceDiscoveryVersion = '';
                                }
                            }

                            return dashboardGroupById;
                        },
                        function () {
                            userAnalytics.event('dashboard-index', 'initialization-error');
                            $scope.fetchError = true;
                            return $q.reject();
                        }
                    )
                    .then(function (dashboardGroupById) {
                        const formattedDashboardGroupById = {};
                        angular.forEach(dashboardGroupById, function (dashboardGroup, id) {
                            const formattedDashboardGroup =
                                dashboardIndexFormatter.formatDashboardGroup(dashboardGroup);
                            let formattedDashboards;
                            // TODO(trevor): I believe it should now be safe to remove the branched logic here. All
                            // TODO(trevor): groups should be sent with their valid dashboardConfigs, and an empty
                            // TODO(trevor): configs list should indicate that the group is empty, which is also valid
                            if (
                                dashboardGroup.sf_dashboardConfigs &&
                                dashboardGroup.sf_dashboardConfigs.length
                            ) {
                                formattedDashboards = dashboardGroup.sf_dashboardConfigs
                                    // Filter out any configs for dashboards that aren't in the group
                                    // I believe we are only seeing these because of a hiccup with the migration
                                    .filter((config) => {
                                        const foundMatch = dashboardGroup.dashboards.some(
                                            (dash) => {
                                                return (
                                                    (dash.id || dash.sf_id) === config.dashboardId
                                                );
                                            }
                                        );

                                        if (!foundMatch) {
                                            $log.warn(
                                                'Config not found in dashboards',
                                                config,
                                                dashboardGroup.dashboards
                                            );
                                        }

                                        return foundMatch;
                                    })
                                    .map((config) => {
                                        const correspondingDashboard =
                                            dashboardGroup.dashboards.find((dash) => {
                                                return (
                                                    (dash.id || dash.sf_id) === config.dashboardId
                                                );
                                            });

                                        // Use the dashboard to pass the groupId through so a url can be generated for a particular instance of a dashboard
                                        if (
                                            correspondingDashboard &&
                                            !correspondingDashboard.groupId
                                        ) {
                                            correspondingDashboard.groupId =
                                                dashboardGroup.sf_id || dashboardGroup.id;
                                        }

                                        return dashboardIndexFormatter.formatDashboard({
                                            dashboardGroupFavoriteKey:
                                                formattedDashboardGroup.getFavoriteKey(
                                                    correspondingDashboard,
                                                    dashboardGroup,
                                                    config.configId
                                                ),
                                            dashboard: correspondingDashboard,
                                            config,
                                            dashboardGroup,
                                        });
                                    });
                            } else {
                                const config = {};
                                formattedDashboards = dashboardGroup.dashboards.map((dashboard) => {
                                    return dashboardIndexFormatter.formatDashboard({
                                        dashboardGroupFavoriteKey:
                                            formattedDashboardGroup.getFavoriteKey(
                                                dashboard,
                                                dashboardGroup
                                            ),
                                        dashboard,
                                        config,
                                        dashboardGroup,
                                    });
                                });
                            }

                            formattedDashboardGroup.dashboards = formattedDashboards;
                            formattedDashboardGroupById[id] = formattedDashboardGroup;
                        });

                        return formattedDashboardGroupById;
                    });

                /**
                 * Map from whether or not we're searching to a map from dashboard group
                 * ID to whether or not its visible.
                 */
                $scope.isDashboardGroupVisible = {
                    true: {},
                    false: {},
                };

                /**
                 * @returns A mapping from dashboard ID to formatted dashboard.
                 */
                const getDashboardById = dashboardGroupByIdPromise.then(function (
                    dashboardGroupById
                ) {
                    const dashboardById = {};
                    angular.forEach(dashboardGroupById, function (dashboardGroup) {
                        angular.forEach(dashboardGroup.dashboards, function (dashboard) {
                            // TODO(trevor): This accounts for all 3 possible recents formats. Need to run a migration similar to favorites
                            if (dashboard.configId) {
                                dashboardById[dashboard.configId] = dashboard;
                            }

                            dashboardById[dashboard.id] = dashboard;
                        });
                    });
                    return dashboardById;
                });

                function getUnsavedDashboardById() {
                    return currentUser
                        .orgPreferences()
                        .then(function (prefs) {
                            const dashboardId = prefs.sf_newDashboard;
                            if (dashboardId) {
                                return shareableSnapshotService.getIgnoreNotFound(dashboardId);
                            }
                        })
                        .then((snapshot) => {
                            const unsavedDashboardById = {};
                            if (snapshot) {
                                snapshot.href = `#/temp/dashboard/${snapshot.id}`;
                                snapshot.name = 'Unsaved Dashboard';
                                unsavedDashboardById[snapshot.id] = snapshot;
                            }
                            return unsavedDashboardById;
                        });
                }

                dashboardByFavoriteKeyPromise = getDashboardById.then(function (dashboardById) {
                    const dashboardByFavoriteKey = {};
                    angular.forEach(dashboardById, function (dashboard) {
                        dashboardByFavoriteKey[dashboard.favoriteKey] = dashboard;
                        // TODO(trevor): Remove this after all favorites are migrated to the 3-id format
                        dashboardByFavoriteKey[dashboard.id || dashboard.sf_id] = dashboard;
                    });
                    return dashboardByFavoriteKey;
                });

                $q.all({
                    dashboardsByFavoriteKey: dashboardByFavoriteKeyPromise,
                    favoritesObject: favoriteService.getFavorite(),
                }).then(function (response) {
                    const favoritesInfo = Object.keys(response.favoritesObject)
                        .map(function (key) {
                            const legacyKey = favoriteService.convertToLegacyFavorite(key);
                            const dashboard =
                                response.dashboardsByFavoriteKey[key] ||
                                response.dashboardsByFavoriteKey[legacyKey];

                            if (dashboard) {
                                // could have been deleted
                                return {
                                    sf_dashboard: dashboard.name,
                                    sf_page: dashboard.sf_page,
                                    sf_type: 'Dashboard',
                                    sf_description: dashboard.description,
                                    sf_id: dashboard.id,
                                    favoriteKey: dashboard.favoriteKey,
                                };
                            } else {
                                // TODO(trevor): Temporary fix, need a long term solution here
                                // favoriteService.remove(key);
                                return null;
                            }
                        })
                        .filter(function (fav) {
                            return fav;
                        });
                    favoriteCacheService.put(favoritesInfo);
                });

                const initSectionsPromise = dashboardGroupByIdPromise
                    .then(function (dashboardGroupById) {
                        const rv = {};
                        angular.forEach(dashboardGroupById, function (dashboardGroup, id) {
                            const newDashboardGroup = angular.copy(dashboardGroup);
                            rv[id] = newDashboardGroup;
                            if (newDashboardGroup.type === 'User') {
                                newDashboardGroup.dashboards = newDashboardGroup.dashboards.filter(
                                    function (dashboard) {
                                        return !isTutorialDashboard(newDashboardGroup, dashboard);
                                    }
                                );
                            }
                        });
                        return rv;
                    })
                    .then(function (dashboardGroupById) {
                        const orderBy = $filter('orderBy');
                        const dashboardGroupsByType = _.groupBy(
                            _.values(dashboardGroupById),
                            'type'
                        );
                        return _.mapValues(pageTypeService.getTypes(), function (type) {
                            const dashboardGroups = dashboardGroupsByType[type.display] || [];
                            const filteredDashboardGroups = orderBy(
                                dashboardGroups.filter(function (dashboardGroup) {
                                    return dashboardGroup.dashboards.length > 0;
                                }),
                                'title'
                            );
                            return {
                                title: type.display,
                                dashboardGroups: filteredDashboardGroups,
                                originalDashboardGroups: filteredDashboardGroups,
                            };
                        });
                    });

                function getGroupFromSections(sections, type, id) {
                    const typeSection = sections.find((section) => section.title === type.display);
                    const groups = typeSection.dashboardGroups;
                    return groups.find((group) => group.groupId === id);
                }

                function getOriginalGroupFromSections(sections, type, id) {
                    const typeSection = sections.find((section) => section.title === type.display);
                    const groups = typeSection.originalDashboardGroups;
                    return groups.find((group) => group.groupId === id);
                }

                function getOrderedDashboards(targetGroup, orderedGroup) {
                    if (featureEnabled('dashboardViews')) {
                        return dashboardGroupService.getOrderedConfigs(
                            targetGroup.dashboards,
                            orderedGroup.dashboardConfigs
                        );
                    } else {
                        return dashboardGroupService.getOrderedConfigsByDashboardIds(
                            targetGroup.dashboards,
                            orderedGroup.dashboards
                        );
                    }
                }

                let userPageId;
                /* Keep occurences of user dashboard group in sync when reordered */
                $scope.$on('dashboards reordered on index page', function (event, dashboardGroup) {
                    if (dashboardGroup.id === userPageId || $scope.searchSections.length) {
                        const type = pageTypeService.getType(dashboardGroup);
                        //user or custom section
                        const mainGroup = getGroupFromSections(
                            $scope.sections,
                            type,
                            dashboardGroup.id
                        );
                        const orderedDashboards = getOrderedDashboards(mainGroup, dashboardGroup);
                        mainGroup.dashboards = orderedDashboards;

                        // primary section
                        if (dashboardGroup.id === userPageId) {
                            const primaryUserGroup = $scope.defaultDashboardGroups.find(
                                (group) => group.groupId === userPageId
                            );
                            primaryUserGroup.dashboards = orderedDashboards;
                        }

                        // search sections
                        if ($scope.searchSections.length) {
                            const searchGroup = getOriginalGroupFromSections(
                                $scope.searchSections,
                                type,
                                dashboardGroup.id
                            );
                            searchGroup.dashboards = orderedDashboards;
                        }
                    }
                });

                const initDefaultGroupPromise = $q
                    .all({
                        dashboardById: getDashboardById,
                        dashboardGroupById: dashboardGroupByIdPromise,
                        unsavedDashboardById: getUnsavedDashboardById(),
                        favorites: favoriteService.getFavorite(),
                        recentDashboardIds: recentPages.getRecentDashboardIds(),
                        userPageId: signalboost.user.getDefaultPageId(),
                    })
                    .then(
                        function (result) {
                            userPageId = result.userPageId;
                            const dashboardById = result.dashboardById;
                            const dashboardGroupById = result.dashboardGroupById;
                            const unsavedDashboardById = result.unsavedDashboardById;
                            Object.keys(result.favorites).forEach(addDashboardToFavorite);

                            const now = Date.now();
                            const seenConfigIdsForRecents = new Set();
                            const defaultDashboardGroups = [
                                {
                                    id: 'favorite' + now,
                                    title: 'Favorites',
                                    iconUrl: imageService.getIconURL('icon_favorite.png'),
                                    dashboards: favoriteDashboards,
                                    emptyText: 'Your starred dashboards will appear here.',
                                },
                                {
                                    id: 'recent' + now,
                                    title: 'Recent',
                                    iconUrl: imageService.getIconURL('icon_recent.png'),
                                    emptyText: 'Your recently viewed dashboards will appear here.',
                                    dashboards: result.recentDashboardIds
                                        .map(function (recentDashInfo) {
                                            // TODO(trevor): Needs to be simplified by a migration
                                            if (recentDashInfo.snapshotId) {
                                                const snapshot =
                                                    unsavedDashboardById[recentDashInfo.snapshotId];
                                                if (snapshot) {
                                                    snapshot.isSnapshot = true;
                                                }
                                                return snapshot;
                                            } else {
                                                const recentDashboard =
                                                    dashboardById[
                                                        recentDashInfo.configId ||
                                                            recentDashInfo.dashboardId ||
                                                            recentDashInfo
                                                    ] || {};
                                                const groupName = (
                                                    dashboardGroupById[
                                                        recentDashInfo.groupId ||
                                                            recentDashboard.groupId
                                                    ] || {}
                                                ).title;
                                                recentDashboard.groupName = groupName;
                                                return recentDashboard;
                                            }
                                        })
                                        .filter(function (dashboard) {
                                            if (dashboard === undefined || dashboard === null) {
                                                return false;
                                            }

                                            let isValid = false;
                                            // Trevor: This is a precaution taken to avoid two different recent entries mapping
                                            // to the same dashboard object, which should never happen. Distinct appearances in
                                            // a views org will have different config ids, while a non views org will never have
                                            // multiple occurrences of the same dashboard
                                            if (dashboard.configId) {
                                                isValid = !seenConfigIdsForRecents.has(
                                                    dashboard.configId
                                                );
                                                seenConfigIdsForRecents.add(dashboard.configId);
                                            } else {
                                                isValid = dashboard.isSnapshot;
                                            }

                                            if (!isValid) {
                                                // We should never reach this case, since on the index page, all orgs will receive
                                                // the list of configs with a group
                                                $log.warn(
                                                    `No config for dashboard: ${dashboard.id}`
                                                );
                                            }

                                            return isValid;
                                        }),
                                },
                            ];
                            const userDashboardGroup = angular.copy(
                                dashboardGroupById[result.userPageId]
                            );
                            if (userDashboardGroup) {
                                userDashboardGroup.groupId = userDashboardGroup.id; // id is a unique UI id, groupId is the metabase id
                                userDashboardGroup.id = 'user' + now;
                                userDashboardGroup.emptyText =
                                    'Dashboards you create will appear here.';
                                defaultDashboardGroups.push(userDashboardGroup);
                            } else {
                                $log.error(
                                    'Cannot find user default page with pageId ' + result.userPageId
                                );
                            }
                            return defaultDashboardGroups;
                        },
                        function () {
                            $log.warn('No default dashboard group section.');
                            return $q.when([]);
                        }
                    );

                return $q
                    .all({
                        sections: initSectionsPromise,
                        defaultDashboardGroups: initDefaultGroupPromise,
                    })
                    .then(
                        function (result) {
                            $scope.sections = [result.sections.BUILTIN, result.sections.CUSTOM];
                            $scope.sections.push(result.sections.USER);

                            $scope.defaultDashboardGroups = result.defaultDashboardGroups;
                            angular.forEach(
                                $scope.defaultDashboardGroups,
                                function (dashboardGroup) {
                                    $scope.isDashboardGroupVisible['false'][
                                        dashboardGroup.id
                                    ] = true;
                                }
                            );
                            if ($scope.query.length > 0) {
                                performSearch(true);
                            }
                            $scope.loading = false;
                            setWatchQuery();
                        },
                        function (e) {
                            $log.error('failed initializing dashboard index', e);
                            $scope.loading = false;
                        }
                    );
            }

            init().then(function () {
                if ($location.hash()) {
                    scrollToAnchor();
                }
            });

            /** Whether or not the user has entered a search query. */
            $scope.isSearching = false;

            $scope.toggleDashboardGroupVisibility = function (id) {
                const map = $scope.isDashboardGroupVisible[$scope.isSearching];
                map[id] = !map[id];
            };

            function setAllDashboardGroupVisible(isSearching, visible) {
                dashboardGroupByIdPromise.then(function (dashboardGroupById) {
                    angular.forEach(dashboardGroupById, function (dashboardGroup, id) {
                        $scope.isDashboardGroupVisible[isSearching][id] = visible;
                    });
                });
                $scope.showAllClicked = true;
            }

            function setAllSectionVisible(isSearching, visible) {
                angular.forEach($scope.sections, function (section) {
                    $scope.isSectionVisible[isSearching][section.title] = visible;
                });
            }

            let queryTimeout;

            function performSearch(force) {
                const previousIsSearching = $scope.isSearching;
                $scope.isSearching = force || $scope.query.length >= QUERY_MIN_LENGTH;
                $location.replace();
                $location.search('query', $scope.query || null);
                if ($scope.isSearching && !previousIsSearching) {
                    // searching
                    setAllSectionVisible($scope.isSearching, true);
                }

                const pattern = {
                    title: $scope.query,
                    dashboards: { name: $scope.query },
                };
                if ($scope.isSearching) {
                    // Lazy-load search sections
                    if ($scope.searchSections.length === 0) {
                        $scope.searchSections = angular.copy($scope.sections);
                    }

                    $scope.searchSections.forEach(function (section) {
                        section.dashboardGroups = $filter('searchField')(
                            pattern,
                            section.originalDashboardGroups
                        );

                        if (!previousIsSearching) {
                            // Mark all of the filtered groups visible
                            section.dashboardGroups.forEach((group) => {
                                $scope.isDashboardGroupVisible[true][group.id] = true;
                            });
                        }
                    });
                    $scope.searchStr = $scope.query;
                } else {
                    $scope.searchStr = '';
                }

                queryTimeout = null;
                $scope.loading = false;
            }

            let loadingTimeout;

            /**
             * Trigger search operation with delay.
             * @param force force searching regardless of character count.
             * @param time delay till searching
             */
            function triggerSearchTimeout(force, time) {
                // Perform filter with the query string after timeout.
                if (queryTimeout) {
                    $timeout.cancel(queryTimeout);
                    queryTimeout = null;
                }
                queryTimeout = $timeout(function () {
                    performSearch(force);
                }, time);

                if (
                    !loadingTimeout &&
                    !$scope.loading &&
                    ($scope.isSearching !== $scope.query.length >= QUERY_MIN_LENGTH || force)
                ) {
                    // trigger loading if need to
                    loadingTimeout = $timeout(function () {
                        $scope.loading = true;
                        loadingTimeout = null;
                    }, time / 2);
                }
            }

            $scope.searchKeyPressed = function ($event) {
                // enter
                if ($event.keyCode === 13) {
                    triggerSearchTimeout(true, 100);
                }
            };

            function setWatchQuery() {
                $scope.$watch('query', function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        triggerSearchTimeout(false, 200);
                    }
                });
            }

            /** Whether or not all dashboards should be visible. */
            $scope.showDashboards = false;
            /**
             * Collapse all dashboard groups if *showDashboard* otherwise expand all
             * dashboard groups.
             */
            $scope.toggleDashboards = function () {
                $scope.showDashboards = !$scope.showDashboards;
                setAllDashboardGroupVisible($scope.isSearching, $scope.showDashboards);
            };

            function getColumnCount() {
                const el = angular.element('.dashboard-groups-all');
                return el.length
                    ? Math.max(Math.floor(el.width() / DASHBOARD_GROUP_COLUMN_WIDTH), 1)
                    : null;
            }

            const updateColumnCount = function () {
                // in olly, we cant guarantee this element being present at initialization.  retry once in
                // an attempt to find it after rendering.
                const defer = $q.defer();
                const colCount = getColumnCount();
                if (colCount === null) {
                    $timeout(function () {
                        // on retry, give up and default to 1
                        $scope.columnCount = getColumnCount() || 1;
                        defer.resolve();
                    }, 100);
                } else {
                    $scope.columnCount = colCount;
                    defer.resolve();
                }

                return defer.promise;
            };

            updateColumnCount();
            angular.element($window).bind(
                'resize',
                _.debounce(() => {
                    $scope.$apply(updateColumnCount);
                }, 100)
            );
        },
    ],
};
