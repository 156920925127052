/* How to add a new sitemap:
 * 1. Create an object including every ID to be used in the sitemap
 * 2. Create the sitemap
 * 3. Export a const with the new data
 */
import { SiteMapEntry } from '@splunk/olly-common/Nav';

const dashboardIds = {
    chartName: 'chartName',
    dashboardGroup: 'dashboardGroup',
    dashboardName: 'dashboardName',
    dashboards: 'dashboards',
    newChart: 'newChart',
    newDashboard: 'newDashboard',
    teamName: 'teamName',
};

const dataManagementIds = {
    dataManagement: 'dataManagement',
    metricMetadata: 'metricMetadata',
};

const metricFinderIds = { metricFinder: 'metricFinder' };

const monitoringIds = {
    monitoring: 'monitoring',
    alertLogs: 'alertLogs',
    alerts: 'alerts',
    bounces: 'bounces',
    detectors: 'detectors',
    editDetector: 'editDetector',
    mutingRules: 'mutingRules',
    templates: 'templates',
};

const dashboards: SiteMapEntry = {
    id: dashboardIds.dashboards,
    label: 'Dashboards',
    path: '/dashboards',
    children: [
        { id: dashboardIds.teamName, label: 'Team Name' },
        {
            id: dashboardIds.dashboardGroup,
            children: [
                {
                    id: dashboardIds.dashboardName,
                    children: [{ id: dashboardIds.chartName, label: 'New chart' }],
                },
            ],
        },
        { id: dashboardIds.newChart, label: 'New chart', isHistoricalPath: true },
        { id: dashboardIds.newDashboard, label: 'New dashboard', isHistoricalPath: true },
    ],
};

const metricFinder: SiteMapEntry = {
    id: metricFinderIds.metricFinder,
    label: 'Metric finder',
    path: '/metrics',
};

const monitoring: SiteMapEntry = {
    id: monitoringIds.monitoring,
    label: 'Detectors & SLOs',
    path: '/alerts',
    children: [
        { id: monitoringIds.alerts, label: 'Active alerts' },
        {
            id: monitoringIds.detectors,
            label: 'Detectors',
            children: [{ id: monitoringIds.editDetector }],
        },
        { id: monitoringIds.templates, label: 'Templates' },
        { id: monitoringIds.mutingRules, label: 'Muting rules' },
        { id: monitoringIds.bounces, label: 'Bounces' },
    ],
};

const dataManagement: SiteMapEntry = {
    id: dataManagementIds.dataManagement,
    label: 'Data Management',
    path: '/data-management',
    children: [{ id: dataManagementIds.metricMetadata, label: 'Metric Metadata' }],
};

export const Dashboards = { name: 'Dashboards', map: dashboards, IDs: dashboardIds };
export const MetricFinder = { name: 'MetricFinder', map: metricFinder, IDs: metricFinderIds };
export const Monitoring = { name: 'Monitoring', map: monitoring, IDs: monitoringIds };
export const DataManagement = {
    name: 'DataManagement',
    map: dataManagement,
    IDs: dataManagementIds,
};
