const CHART_DISPLAY_EVENTS = {
    CHART_CLICK_PIN: 'chart click pin',
    CHART_CLOSE_TOOLTIPS: 'chart.closeTooltips',
    CHART_DISPLAY_INITIALIZED: 'chartDisplayInitialized',
    CHART_DISPLAY_WINDOW_EVENTS: 'chart display window events',
    CHART_EVENT_STREAM_INIT: 'chart event stream init',
    CHART_ROLLUP_DETERMINED: 'chartRollupDetermined',
    CHART_TIME_RANGE_SELECTED: 'chartTimeRangeSelected',
    CHART_WINDOW_MISALIGNED: 'chartWindowMisaligned',
    CONTEXT_RESIZE: 'context-resize',
    DERIVED_STREAM_INITIATED: 'derivedStreamInitiated',
    DETECTOR_NATIVE_RANGE_SELECTED: 'detectorNativeRangeSelected',
    INCREMENT_CHART_LOADS_EXPECTED: 'incrementChartLoadsExpected',
    INCREMENT_CHART_LOADS_SUCCEEDED: 'incrementChartLoadsSucceeded',
    INSTRUMENTATION_FULL_LOAD: 'instrumentation.chartdisplay.fullload',
    JOB_RESOLUTION_DETERMINED: 'jobResolutionDetermined',
    JOB_FETCH_CAPS: 'jobFetchCaps',
    JOB_FEEDBACK_RECEIVED: 'jobFeedbackReceived',
    LEGEND_KEYS_RECOMPUTED: 'legendKeysRecomputed',
    LEGEND_TAB_SELECTED: 'legend tab selected',
    RENDER_STATS: 'renderStats',
    REPLACED_FILTER_REPORT: 'REPLACED_FILTER_REPORT',
    REQUEST_INIT_TIME_PICKER: 'requestInitializeTimePicker',
    RESET_LEGEND_PIN: 'reset legend pin',
    RESTART_PROGRESS: 'restartProgress',
    SELECT_TAB: 'select tab',
    SELECT_PREVIOUS_TAB: 'select previous tab',
    SET_LEGEND_CONTENT: 'set legend content',
};

const DETECTOR_CHART_IDENTIFIERS = {
    MAIN: 'main',
    NATIVE: 'native',
};

angular
    .module('signalview.chartdisplay.shared', [])
    .constant('CHART_DISPLAY_EVENTS', CHART_DISPLAY_EVENTS)
    .constant('DETECTOR_CHART_IDENTIFIERS', DETECTOR_CHART_IDENTIFIERS);
