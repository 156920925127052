export default [
    'pageDisplayTitle',
    'pageTypeService',
    'dashboardUtil',
    function (pageDisplayTitle, pageTypeService, dashboardUtil) {
        return {
            formatDashboardGroup,
            formatDashboard,
        };

        function formatDashboardGroup(dashboardGroup) {
            const dashboardGroupType = pageTypeService.getType(dashboardGroup);
            return {
                description: dashboardGroup.description || dashboardGroup.sf_description,
                id: dashboardGroup.id || dashboardGroup.sf_id,
                groupId: dashboardGroup.id || dashboardGroup.sf_id, // to distinguish from non - group index elements
                title: pageDisplayTitle(dashboardGroup),
                iconUrl: dashboardGroupType.getIconUrl(dashboardGroup),
                dashboards: [],
                type: dashboardGroupType.display,
                getFavoriteKey: dashboardGroupType.getFavoriteKey,
                // TODO(trevor): work things out to ditch sf_
                sf_dashboardConfigs:
                    dashboardGroup.dashboardConfigs || dashboardGroup.sf_dashboardConfigs,
                hasWritePermission: dashboardGroup.hasWritePermission,
            };
        }

        /*
         * dashboardGroupFavoriteKey and dashboard are expected, config is
         * optional. This allows us to still handle groups and dashboards that
         * haven't migrated during the dashboardViews beta
         */
        function formatDashboard({ dashboardGroupFavoriteKey, dashboard, config, dashboardGroup }) {
            if (!config) {
                config = {};
            }

            const groupId = dashboardGroup.sf_id || dashboardGroup.id;
            const dashboardId = dashboard.sf_id || dashboard.id;

            return {
                description:
                    config.descriptionOverride || dashboard.sf_description || dashboard.description,
                name: config.nameOverride || dashboard.sf_dashboard || dashboard.name,
                href: dashboardUtil.getDashboardUrl(dashboard.id, groupId, config.configId),
                id: dashboard.sf_id || dashboard.id,
                configId: config.configId,
                sf_page: dashboardGroup.sf_page,
                favoriteKey: dashboardGroupFavoriteKey,
                uniqueId: config.configId || dashboardId, // configId will be unique for views orgs, if no config exists, assume non views
                groupType: pageTypeService.getType(dashboardGroup).display, // so we can determine the type for dashboards in Favorites/Recents
            };
        }
    },
];
